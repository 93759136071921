import {Col, Container, Row} from "reactstrap";
import React from "react";
import {useIntl} from "react-intl";
import {Button} from '@liquid-design/liquid-design-react'
import {useDispatch, useSelector} from "react-redux";
import {IConfigReducer} from "../../../store/reducers/configReducer";
import StoreTypes from "StoreTypes";
import _ from "lodash";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {setPlcProperty, CreateOpcValue} from "../../../utils/plcUtils";
import {Label} from "@progress/kendo-react-labels";
import {AnalogueSensorEndress} from "../../../models/PLC/AnalogueSensorEndress";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import {getDigitNumber, getSensorDeadBand} from "../ControlPanels/settingsHelpers";

export interface IEndressSensorCalibrationProps {
    nodeId: string
}

/**
 * EndressSensorCalibration component
 * @param nodeId
 * @constructor
 */
export default function EndressSensorCalibration({nodeId}: IEndressSensorCalibrationProps) {

    const intl = useIntl();
    const dispatch = useDispatch();

    //Getting information from store
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config);

    //Get reference to the sensor
    const sensor: AnalogueSensorEndress = _.find(config.Instances.ControlModules, (item: ControlModuleBase) => {
        return item.NodeId === nodeId;
    }) as AnalogueSensorEndress;

    /**
     * Sending ZeroPoint information to Endress sensor
     */
    function onClick() {

        let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bZeroPointAsk', true);
        setPlcProperty(config, opcValue, {
            userLabel: 'label.event.ZeroPointSet', unit: '',
            eventTriggering: UserEventTriggering.BeforeAction
        }, dispatch);
    }

    //Get sensor current currentStep, with unit
    let sensorValueString = sensor.Out_rCurrentValue.toFixed(getDigitNumber(sensor.Out_rCurrentValue, getSensorDeadBand(sensor, 'Out_rCurrentValue', sensor.Out_rCurrentValue))).toString() + ' ' + sensor.Values[0].Cfg_suSensorUnit.Unit;

    return <>
        <Container>
            <Row style={{paddingTop: '5px'}}>
                <Col>
                    <Container>
                        <Row>
                            <Col style={{padding: '0px'}}>
                                <Button
                                    style={{width: '100%'}}
                                    onClick={onClick}>{intl.formatMessage({id: 'label.SetZeroPoint'})}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col>
                    <div style={{margin: '0 auto', width: 'fit-content', paddingBottom: '15px'}}>
                        <Label
                            editorId='CurrentSensorValue'>{intl.formatMessage({id: 'label.CurrentSensorValue'})}</Label>
                        <div id='CurrentSensorValue' style={{fontWeight: 'bold', fontSize: '26px'}}>
                            {sensorValueString}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </>;
}