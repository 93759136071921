import React from 'react'
import {PortWidget} from '@projectstorm/react-diagrams'

import {
    BaseArtifactWidgetProps,
    BaseArtifactWidgetState,
    BaseArtifactWidget
} from '../../BaseArtifact/BaseArtifactWidget'
import {InletArtifactModel} from './InletArtifactModel'

import InletVisual from './InletVisual'

import * as DefaultPort from '../../Port'
import * as StoreTypes from 'StoreTypes'
import {connect} from 'react-redux'
import {IConfigReducer} from '../../../../store/reducers/configReducer'

export interface InletNodeWidgetProps extends BaseArtifactWidgetProps<InletArtifactModel> {
    size: number
    config: IConfigReducer
}

export interface InletNodeWidgetState extends BaseArtifactWidgetState<InletArtifactModel> {
}

class IntletArtifactWidget extends BaseArtifactWidget<InletNodeWidgetProps, InletNodeWidgetState> {
    constructor(props: InletNodeWidgetProps) {
        super(props)
        this.state = {controlPanel: false, selected: 0, show: false, virtualSelected: 0}
    }

    calculateLabelsPosition = () => {
        if (this.labelRef.current) {
            const labelDimensions = {
                width: this.labelRef.current.offsetWidth,
                height: this.labelRef.current.offsetHeight
            }

            const labelCoordinates = {
                x: this.props.size / 2 - labelDimensions.width / 2,
                y: this.props.size - 50
            }

            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`
        }
    };

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

    render() {
        return (
            <>

                <div
                    className='InletArtifact-node'
                    style={{
                        position: 'relative',
                        width: this.props.size,
                        height: this.props.size
                    }}
                >
                    <svg
                        width={this.props.size}
                        height={this.props.size}
                        viewBox='0 0 86 117'
                        preserveAspectRatio='xMidYMid'
                        dangerouslySetInnerHTML={{
                            __html: InletVisual.build(BaseArtifactWidget.getConditionsStatus(this.props.node.getConditions(), this.props.config))
                        }}
                    />
                    <span style={{
                        fontFamily: 'Lato',
                        fontWeight: 1000,
                        color: '#494953',
                        fontSize: '19px',
                        position: 'absolute',
                        top: this.props.size / 2 - 13,
                        left: - 35
                    }}>{this.props.node.displayName}</span>
                    <PortWidget
                        port={this.props.node.getPort('right')} engine={this.props.engine}
                        style={{
                            position: 'absolute',
                            top: this.props.size / 2 - 8,
                            left: this.props.size - 30
                        }}
                    >
                        <DefaultPort.Port/>
                    </PortWidget>

                    {super.render()}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(IntletArtifactWidget)
