import {NodeModelGenerics, PortModelOptions} from '@projectstorm/react-diagrams'

import {BaseArtifactModel} from '../../BaseArtifact/BaseArtifactModel'
import {ElbowPortArtifactModel} from './ElbowPortModel'
import {Ports0Deg, Ports0DegSwitched} from './ElbowPortsConfig'
import {Ports90Deg, Ports90DegSwitched} from './ElbowPortsConfig90'
import {Ports180Deg, Ports180DegSwitched} from './ElbowPortsConfig180'
import {Ports360Deg, Ports360DegSwitched} from './ElbowPortsConfig360'
import {IConditions} from '../../CustomLinks/PipeLink/PipeLinkModel'

export interface YNodeModelGenerics {
    PORT: ElbowArtifactModel;
}

export class ElbowArtifactModel extends BaseArtifactModel<NodeModelGenerics & YNodeModelGenerics> {
    constructor(code: string, name: string, nodeId: string, orientation: string = 'horizontal', conditions: IConditions[] = []) {
        super(code, name, nodeId, orientation, {type: 'Elbow'}, conditions);

        let portsConfig = Ports0Deg

        let rotationAngle = 0
        let portsFlip = false

        if (code.includes('-')) {
            let artifactParams = code.split('-')

            rotationAngle = parseInt(artifactParams[0])
            if (rotationAngle === 360)
                rotationAngle = -90

            if (artifactParams.length === 3 && artifactParams[2] === "SP") {
                portsFlip = true
            }
        }

        switch (rotationAngle) {
            case 0:
                portsConfig = portsFlip ? Ports0DegSwitched : Ports0Deg
                break
            case 90:
                portsConfig = portsFlip ? Ports90DegSwitched : Ports90Deg
                break
            case 180:
                portsConfig = portsFlip ? Ports180DegSwitched : Ports180Deg
                break
            case -90:
                portsConfig = portsFlip ? Ports360DegSwitched : Ports360Deg
                break
        }

        portsConfig.forEach((port: PortModelOptions) => this.addPort(
            new ElbowPortArtifactModel(port.name, port.alignment)
        ))
    }
}
