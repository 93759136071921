import React, { useEffect, useState } from 'react'
import {Button, Modal} from '@liquid-design/liquid-design-react'
import {IConfigReducer} from '../store/reducers/configReducer'
import {useSelector} from 'react-redux'
import * as StoreTypes from 'StoreTypes'
import useMountEffect from '../utils/useMountEffect'
import {
    Grid,
    GridColumn as Column,
    GridToolbar
} from '@progress/kendo-react-grid'
import {apiDataSet} from '../models/Api/apiDataSet'
import {process} from '@progress/kendo-data-query'
import {FormattedMessage, IntlShape, useIntl} from 'react-intl'
import {Dialog} from '@progress/kendo-react-dialogs'
import {ScaleLoader} from 'react-spinners'
import {ColumnMenu} from "../utils/GridColumnFilter";
import {ExportConfigurationPanel, IDataSetExportInformation} from "./ExportConfigurationPanel";

export const RecordPanel = () => {

    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const intl: IntlShape = useIntl()
    const [isDeleting, setDeleteData] = useState(false)
    const [isGettingData, setGetData] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [dataSetList, setDataSetList] = useState([])
    const [dataSetListDisplay, setdataSetListDisplay] = useState({
        result: {data: [], total: 0},
        dataState: {skip: 0, take: 20}
    })
    const [isRecordConfigDialogVisible, setRecordConfigDialogVisible] = useState(false)
    const [exportedDataSetInformation, setExportedDataSetInformation] = useState(undefined as IDataSetExportInformation)
    const [isSigningActivated, setIsSigningActivated] = useState(false)

    const createDataState = (data: any, dataState: any) => {
        return {
            result: process(data.slice(0), dataState),
            dataState: dataState
        };
    }

    useMountEffect(() => {
        getDataset()
    })

    function getDataset() {
        setGetData(true)
        fetch(`${config.APIBaseUrl}/history/api/DataSets/v1/DataSets`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                withValues: false
            })
        })
            .then((resp) => resp.json())
            .then((resp: apiDataSet[]) => {
                let datalist =
                    resp.map((dataSet) => ({
                        id: dataSet.Id.toString(),
                        name: dataSet.Name,
                        code: dataSet.Code,
                        selected: false
                    }))
                setDataSetList(datalist)
                setdataSetListDisplay(createDataState(datalist, {skip: 0, take: 20}))
                setGetData(false)
            })

    }

    useMountEffect(() => {
        //Gett siging configuration from API
        fetch(`${config.APIBaseUrl}/Settings/api/Settings/v1/SigningConfiguration`, {
            method: 'GET'
        })
          .then((resp) => resp.json())
          .then((setting) => {
              setIsSigningActivated(setting?.SignExports ?? false);
          })
          .catch((err) => {
              console.error(err);
          });
    })

    function onDataHeaderChange(e: any) {
        const checked = e.syntheticEvent.target.checked;
        setdataSetListDisplay(prevState => {
            // Clone the current state to avoid directly mutating it
            let updatedList = JSON.parse(JSON.stringify(prevState.result.data)) as typeof prevState.result.data;

            // Update all items' selected property
            updatedList.forEach(item => item.selected = checked);

            // Create the new display list
            let newDisplayList = createDataState(updatedList, {skip: currentPage, take: 20});

            // Return the new display list
            return newDisplayList;
        });
    }

    function onSelectionChange(e: any) {
        setdataSetListDisplay(prevState => {
            // Clone the current state to avoid directly mutating it
            let updatedList = JSON.parse(JSON.stringify(prevState.result.data)) as typeof prevState.result.data;

            // Find the clicked item in the cloned array and update its selected property
            let clickedItem = updatedList.find((item) => item.id === e.dataItem.id);
            if (clickedItem) {
                clickedItem.selected = !clickedItem.selected;
            }

            // Create the new display list
            let newDisplayList = createDataState(updatedList, {skip: currentPage, take: 20});

            // Return the new display list
            return newDisplayList;
        });
    }


    const CommandCell = (props: any) => (
        <>
            <td className="k-command-cell">
                { !isSigningActivated && (
                  <span style={{cursor: 'pointer'}} onClick={() => deleteRecord(props.dataItem.id)}>🗑️</span>
                ) }
                <span style={{cursor: 'pointer'}} onClick={() => exportRecord(props.dataItem)}> 📤</span>
            </td>
        </>
    )

    function exportRecord(Data: any) {
        setExportedDataSetInformation(Data as IDataSetExportInformation);
        setRecordConfigDialogVisible(true);
    }

    function OnCloseRecordConfigurationModal() {
        setRecordConfigDialogVisible(false);
        setExportedDataSetInformation(undefined);
    }

    function deleteRecord(DataSetId: any) {
        setDeleteData(true)
        fetch(`${config.APIBaseUrl}/history/api/DataSets/v1/DataSet/${DataSetId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                withValues: false
            })
        })
            .then(resp => {
                getDataset()
                setDeleteData(false)
            })

    }

    function deleteSelection() {
        setDeleteData(true);
        const fetchresp = dataSetListDisplay.result.data
          .filter(element => element.selected)
          .map(element =>
            fetch(`${config.APIBaseUrl}/history/api/DataSets/v1/DataSet/${element.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    withValues: false,
                }),
            }));

        Promise.all(fetchresp)
          .then(resp => {
              getDataset();
              setDeleteData(false);
          });
    }

    return (
        <>
            <div style={{height: '100%'}}>
                <Grid
                  className="gridWithFilterIcon"
                  style={ { height: 'calc(100vh - 350px)' } }
                  selectedField={isSigningActivated ? undefined : "selected"}
                  sortable={true}
                    pageable={{
                        buttonCount: 15,
                        info: true,
                        type: 'numeric',
                        pageSizes: [10, 20, 50, 100],
                        previousNext: true
                    }}
                    onHeaderSelectionChange={(event) => {
                        onDataHeaderChange(event)
                    }}
                    onSelectionChange={(event) => {
                        onSelectionChange(event)
                    }}
                    {...dataSetListDisplay.dataState}
                    data={dataSetListDisplay.result}
                    onDataStateChange={(event) => {
                        setCurrentPage(event.dataState.skip)
                        setdataSetListDisplay(createDataState(dataSetList, event.dataState))
                    }}
                >
                    { !isSigningActivated && (
                      <GridToolbar>
                          <Button
                            disabled={isSigningActivated}
                            icon='bin'
                            size='small'
                            onClick={() => deleteSelection()}
                          >
                              <FormattedMessage id='label.DeleteSelection'/>
                          </Button>
                      </GridToolbar>
                    ) }
                    { !isSigningActivated && (
                      <Column
                        field="selected"
                        width="70px"
                      />
                    )}
                    <Column
                      {...isSigningActivated ? { width: "420"} : {}}
                      field="name"
                      filter="text"
                      title={intl.formatMessage({id: 'label.TableName'})}
                      columnMenu={ColumnMenu}/>
                    <Column cell={CommandCell} width="80px"/>
                </Grid>
            </div>
            {(isGettingData || isDeleting) && (
                <Dialog
                    title={isDeleting ? intl.formatMessage({id: 'label.DeleteRecord'}) : intl.formatMessage({id: 'label.GetHistory'})}
                    closeIcon={false}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <ScaleLoader
                            height={50}
                            width={10}
                            radius={4}
                            margin={2}
                            color={"#123abc"}
                            loading={isGettingData}
                        />
                    </div>
                </Dialog>)}
            {isRecordConfigDialogVisible && exportedDataSetInformation && (
                <Modal
                    label={intl.formatMessage({id: 'label.SelectSamplingRate'}, {dataSetName: exportedDataSetInformation.name})}
                    open={isRecordConfigDialogVisible}
                    onClose={() => OnCloseRecordConfigurationModal()}>
                    <div className='container'>
                        <ExportConfigurationPanel dataSetInfo={exportedDataSetInformation} onDataSetExported={OnCloseRecordConfigurationModal}/>
                    </div>
                </Modal>)}
        </>
    )
}
