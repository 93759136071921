import {PortModelAlignment} from '@projectstorm/react-diagrams'

export const Ports360Deg = [
    {
        name: 'in',
        alignment: PortModelAlignment.LEFT
    },
    {
        name: 'out',
        alignment: PortModelAlignment.TOP
    }
]

export const Ports360DegSwitched = [
    {
        name: 'in',
        alignment: PortModelAlignment.TOP
    },
    {
        name: 'out',
        alignment: PortModelAlignment.LEFT
    }
]