import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {Container, Row, Col} from 'reactstrap';
import {Theme, Dropdown, Button} from '@liquid-design/liquid-design-react'
import {apiOpcValue} from "../../../models/Api/apiOpcValue";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {FloatingLabel} from '@progress/kendo-react-labels';
import {IConfigReducer} from "../../../store/reducers/configReducer";
import {useDispatch, useSelector} from "react-redux";
import StoreTypes from "StoreTypes";
import {IUserEventInfo, setPlcProperty} from "../../../utils/plcUtils";
import {
    getEnumTextCode, IRegulationMode,
    renderPositionRegulationParams,
    renderRegulRegulationParams
} from "./settingsHelpers";
import {StepMotorValve} from "../../../models/PLC/StepMotorValve";
import {Membrane} from "../../../models/PLC/Membrane";
import CommonSwitches from "./CommonSwitches";
import {useIntl} from "react-intl";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import { getModeOptions } from './StateOptions'

export interface IValveControlPanelProps {
    nodeId: string,
    membraneNodeId: string
}

export default function ValveControlPanel({nodeId, membraneNodeId}: IValveControlPanelProps) {

    const dispatch = useDispatch();
    const intl = useIntl();

    const [accessGranted,setAccessGranted]=useState(false)
    const [accessRefuse,setAccessRefused]=useState(false)
    const [passwordModalOpen,setPasswordModalOpen]=useState(false) 
    const [passwordValue,setPasswordValue]=useState('') 

    //Getting information from store
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)

    //Get the pump associated ControlModule
    const valve: StepMotorValve = _.find(config.Instances.ControlModules, (item: ControlModuleBase) => {
        return item.NodeId === nodeId;
    }) as StepMotorValve;

    useEffect(() => {
        setCurrentRegulationMode(valve.Set_iControlStyle)
    }, [valve.Set_iControlStyle])

    //Get the EquipmentModule that contains the ControlModule
    const equipmentModule: any = _.find(config.Instances.EquipmentModules, (em: any) => {
        return _.find(em.ControlModules, (cm: ControlModuleBase) => {
            return cm.NodeId === nodeId;
        });
    });

    //Flag that indicates if the SPTFF mode is activated (neede for regulation Interlock)
    const isSptffModeActivated = (config.Unit.Set.iUserOptionWeightUser || config.Unit.Set.iFactOptionWeight) && config.Unit.Set.bVisibilityWE101;

    //Hard coded regulation modes for
    let regulationModes: IRegulationMode[];
    if (isSptffModeActivated) {
        if (equipmentModule.Cfg_sTag.includes("RETENTATE")) {
            regulationModes = [
                {name: intl.formatMessage({ id: "label.ValvePosition" }), id: '1', textCode: 'label.ValvePosition'},
                {name: intl.formatMessage({ id: "label.PressureRegulation" }), id: '2', textCode: 'label.PressureRegulation' }
            ];
        } else {
            regulationModes = [
                {name: intl.formatMessage({ id: "label.ValvePosition"}), id: '1', textCode: 'label.ValvePosition'}
            ];
        }
    } else {
        if (config.Unit.Set.iFactOptionFlowmeters) {
            regulationModes = [
                {name: intl.formatMessage({ id: "label.ValvePosition"}), id: '1', textCode: 'label.ValvePosition'},
                {name: intl.formatMessage({ id: "label.PressureRegulation"}), id: '2', textCode: 'label.PressureRegulation'},
                {name: intl.formatMessage({ id: "label.FlowRegulation"}), id: '3', textCode: 'label.FlowRegulation'},
                {name: intl.formatMessage({ id: "label.TMP"}), id: '4', textCode: 'label.TMP'}
            ];
        } else {

            regulationModes = [
                {name: intl.formatMessage({ id: "label.ValvePosition"}), id: '1', textCode: 'label.ValvePosition'},
                {name: intl.formatMessage({ id: "label.PressureRegulation"}), id: '2', textCode: 'label.PressureRegulation'},
                {name: intl.formatMessage({ id: "label.TMP"}), id: '4', textCode: 'label.TMP'}
            ];
        }
    }


    //Getting Membrane Equipment Module for access to TMP
    const membrane: Membrane = _.find(config.Instances.EquipmentModules, (em: any) => {
        return em.NodeId === membraneNodeId;
    }) as Membrane;

    //Getting initial ControlStyle
    const [currentRegulationMode, setCurrentRegulationMode] = useState(valve.Set_iControlStyle);

    /**
     Applies new regulation mode to the PLC
     **/
    const applyRegulationModeChange = () => {

        let controlStyle: apiOpcValue = new apiOpcValue();
        controlStyle.NodeId = valve.NodeId + '.Set_iControlStyle';
        controlStyle.Value = currentRegulationMode;

        //Pushing information to the PLC
        setPlcProperty(config, controlStyle, {
            userLabel: 'label.event.RegulationModeChanged',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            enumValue: getEnumTextCode(regulationModes, currentRegulationMode)
        }, dispatch);
    };

    /**
     * Changes local value of the control style
     * @param selectedOption
     */
    const localRegulationModeChange = (selectedOption: any) => {
        setCurrentRegulationMode(parseInt(selectedOption.id));
    };

    /**
     Switching mode between Automatic <-> Manual
     **/
    const onModeChange = (newValue: any) => {

        let mode: apiOpcValue = new apiOpcValue();
        mode.NodeId = nodeId + '.Set_bMode';
        mode.Value = !newValue;

        let eventInfo:IUserEventInfo = {
            userLabel: mode.Value ? 'label.event.ManualModeSet' : 'label.event.AutoModeSet',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }
        setPlcProperty(config, mode, eventInfo, dispatch);
    };

    /**
     * Rendering speed regulation parameters
     **/
    function renderRegulationParameters() {
        let pressureSensor;
        let flowSensor;

        //Sensors are different, depending on the container EM
        if (equipmentModule.Cfg_sTag.includes("RETENTATE")) {
            pressureSensor = equipmentModule.RetentatePressureSensor;
            flowSensor = equipmentModule.RetentateFlowSensor;
        } else {
            pressureSensor = equipmentModule.FiltratePressureSensor;
            flowSensor = equipmentModule.FiltrateFlowSensor;
        }

        switch (currentRegulationMode.toString()) {
            case '1' :
                //Position regulation params
                return renderPositionRegulationParams(config, !valve.Set_bMode, valve, dispatch, intl);
            case '2':
                //Pressure regulation
                return renderRegulRegulationParams(config, !valve.Set_bMode, valve, equipmentModule.PressureRegul, pressureSensor, dispatch, intl,accessGranted,setAccessGranted,passwordModalOpen,setPasswordModalOpen,passwordValue,setPasswordValue,accessRefuse,setAccessRefused);
            case '3':
                //Flow regulation
                return renderRegulRegulationParams(config, !valve.Set_bMode, valve, equipmentModule.FlowRegul, flowSensor, dispatch, intl,accessGranted,setAccessGranted,passwordModalOpen,setPasswordModalOpen,passwordValue,setPasswordValue,accessRefuse,setAccessRefused);
            case '4':
                return renderRegulRegulationParams(config, !valve.Set_bMode, valve, equipmentModule.TMPRegul, membrane.TMP, dispatch, intl,accessGranted,setAccessGranted,passwordModalOpen,setPasswordModalOpen,passwordValue,setPasswordValue,accessRefuse,setAccessRefused);
            default:
                return <>
                    <h3>{intl.formatMessage({ id: "No regulation mode selected"})}</h3>
                </>;
        }
    }

    function onFullOpenClickHandler() {
        let openAction: apiOpcValue = new apiOpcValue();
        openAction.NodeId = nodeId + '.Inp_bFullOpen';
        openAction.Value = true;

        setPlcProperty(config, openAction, {
            userLabel: 'label.event.FullOpened',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }, dispatch);
    }

    function onFullCloseClickHandler() {
        let closeAction: apiOpcValue = new apiOpcValue();
        closeAction.NodeId = nodeId + '.Inp_bFullClose';
        closeAction.Value = true;

        setPlcProperty(config, closeAction, {
            userLabel: 'label.event.FullClosed',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }, dispatch);
    }

    return (
        <Theme themeName={config.theme}>
            <Container>
                <Row style={{padding: '5px'}}>
                    <Col>
                        <CommonSwitches stateOptions={getModeOptions(intl)} isAutoMode={!valve.Set_bMode}
                                        onModeChange={onModeChange} hideStartStop={true}/>
                    </Col>
                    <Col>
                        <Button onClick={onFullOpenClickHandler}
                                disabled={!valve.Set_bMode || valve.Set_iControlStyle !== 1}>{intl.formatMessage({ id: "label.FullOpen"})}</Button>
                    </Col>
                </Row>
                <Row style={{padding: '5px'}}>
                    <Col xs="auto">
                        <FloatingLabel label={intl.formatMessage({ id: "label.RegulationMode"})}

                                       editorId='RegulationMode'
                                       editorDisabled={!valve.Set_bMode}
                                       editorValue={currentRegulationMode.toString()}>
                            <Dropdown
                                id='RegulationMode'
                                options={regulationModes}
                                value={currentRegulationMode.toString()}
                                onSubmit={localRegulationModeChange}
                                disabled={!valve.Set_bMode}
                            />
                        </FloatingLabel>&nbsp;
                        <br/>
                        <br/>
                        <div style={{width: '100%'}}>
                            <Button style={{float: 'right'}}
                                    disabled={currentRegulationMode === valve.Set_iControlStyle}
                                    onClick={applyRegulationModeChange}>{intl.formatMessage({ id: "label.ApplyRegulationMode"})}</Button>
                        </div>
                    </Col>
                    <Col xs="auto">
                        {renderRegulationParameters()}
                    </Col>
                </Row>
            </Container>
        </Theme>
    )
}