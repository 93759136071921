/**
 * Dependencies
 */
import React, { useEffect, useRef, useState } from 'react'
import {Switch, Route} from 'react-router-dom'
import {connect, useDispatch} from 'react-redux'
import StoreTypes from 'StoreTypes'
import MQTT from 'mqtt'
import {Theme} from '@liquid-design/liquid-design-react'
import routes from './routes'
import MQTTConfig from './config/MQTT.config'
import {configActions} from './store/actions'
import Header from './components/Header'
import SideNav from './components/SideBar'
import Footer from './components/Footer'
import LoadingScreen from './components/LoadingScreen'
import SensorsSummarySidebar from './components/SensorsSummarySidebar'
import {apiBrokerPayload} from './models/Api/apiBrokerPayload'
import {UnitStructure} from './models/PLC/UnitStructure'
import {IntlProvider} from 'react-intl'
import useMountEffect from './utils/useMountEffect'
import ErrorView from './views/ErrorView'
import {apiAlarms} from "./models/Api/apiAlarms";
import {IConfigReducer} from './store/reducers/configReducer'
import RecipeDialog from './components/RecipeDialog'

import {load} from '@telerik/kendo-intl'
import {loadMessages, LocalizationProvider, IntlProvider as IntlService} from '@progress/kendo-react-intl'

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import { applicationVersions } from './config/applicationVersions'

const cldrList: any = [];

// TODO: Update this list when all translations are provided
[
    'de-DE',
    'en-US',
    'es-ES',
    'fr-FR',
    'it-IT',
    'zh-CN',
    'ja'
].forEach((lang) => {
    const messages = require(`./translations/grid-messages/${lang}/${lang}.json`)
    loadMessages(messages, lang)

    const cldrLang = lang.split('-')[0]
    cldrList.push(
        require(`cldr-numbers-full/main/${cldrLang}/currencies.json`),
        require(`cldr-numbers-full/main/${cldrLang}/numbers.json`),
        require(`cldr-dates-full/main/${cldrLang}/ca-gregorian.json`),
        require(`cldr-dates-full/main/${cldrLang}/dateFields.json`),
        require(`cldr-dates-full/main/${cldrLang}/timeZoneNames.json`)
    )
})

load(
    likelySubtags,
    currencyData,
    weekData,
    ...cldrList
);


let MQTTBrokerUrl: string
let MQTTClient: MQTT.Client

/**
 * Content
 */
const App = (props: { config: IConfigReducer }) => {
    /**
     * App state
     */
    const [isConfigLoaded, setIsConfigLoaded] = useState(false)
    const [loadingState, setLoadingState] = useState(50)
    const isConfigLoadedRef = useRef(isConfigLoaded)
    isConfigLoadedRef.current = isConfigLoaded

    useEffect(() => {
      if (!isConfigLoaded) {
        const interval = setInterval(() => {
          setLoadingState((l) => l === 1 ? 2 : 1)
        }, 1000)
        return () => clearInterval(interval)
      }
    }, [isConfigLoaded])

    //let incomingMessages:apiDatas[] = [];

    /**
     * Store state / dispatch
     */
    const {config} = props
    const dispatch = useDispatch()

    /**
     * Subscribe / un-subscribe MQTT on mount/un-mount
     */
    useMountEffect(() => {
        /**
         * Fetches cogent config
         */
        fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/Structure/Get?refreshValues=true`, {
            method: 'GET'
        })
            .then((resp) => resp.json())
            .then((resp: UnitStructure) => {
                dispatch(configActions.hydrate(resp))
                dispatch(configActions.buildIndexes())
                fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/Language/Current`, {
                    method: 'GET'
                })
                    .then((resp) => resp.json())
                    .then((lang) => {
                        fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/Language/LocalizedStrings`, {
                            method: 'GET'
                        }).then((resp) => resp.json())
                            .then((resp: Record<string, string>) => {

                                dispatch(configActions.setLanguage(lang))
                                dispatch(configActions.updateTranslations(resp))

                                setTimeout(() => {
                                    setIsConfigLoaded(true)
                                }, 1000)

                                // MQTT connection
                                MQTTBrokerUrl = `ws://${window.location.hostname}:8000/mqtt`
                                MQTTClient = MQTT.connect(MQTTBrokerUrl, MQTTConfig)

                                // MQTT events listening
                                MQTTClient.on('connect', () => console.log('[MQTT] connected : ', MQTTConfig.clientId))
                                MQTTClient.on('reconnect', () => console.log('[MQTT] reconnecting'))
                                MQTTClient.on('close', () => console.log('[MQTT] closed'))
                                MQTTClient.on('offline', () => console.log('[MQTT] offline'))
                                MQTTClient.on('error', (err) => console.error('[MQTT] error:', err))

                                /**
                                 * Handles MQTT message
                                 * @param topic Subscribed topic
                                 * @param msg Received message
                                 */
                                MQTTClient.on('message', (topic: string, msg: any): void => {
                                    if (isConfigLoadedRef.current) {
                                        const {Payload, MessageCategory}: apiBrokerPayload = JSON.parse(msg)

                                        //if (Payload.NodeId.includes(".Unit.Set.") || Payload.NodeId.includes("Sts_bRunning") || Payload.NodeId.includes("Inp_bCommand"))
                                        //    console.log(Payload)
                                        // const t1 = performance.now()
                                        switch (MessageCategory.Name) {
                                            case 'DataEvent': {
                                                dispatch(configActions.updateDataMQTT(Payload))
                                                //Messages are pushed in an array and treated in loop, with just one dispatch
                                                //incomingMessages.push(Payload)
                                                break
                                            }
                                            case 'AlarmEvent': {
                                                dispatch(configActions.updateAlarmMQTT(Payload as apiAlarms))
                                                break
                                            }
                                            case 'ProcessEvent': {
                                                dispatch(configActions.updateProcessEventMQTT(Payload))
                                                break
                                            }
                                            case 'SequenceEvent': {
                                                dispatch(configActions.updateSequenceEventMQTT(Payload))
                                                break
                                            }
                                            case 'UserEvent': {
                                                dispatch(configActions.updateUserEventMQTT(Payload))
                                                break
                                            }
                                            case 'ActionEvent': {
                                                dispatch(configActions.updateDefaultMQTT(Payload))
                                                break
                                            }
                                            case 'None': {
                                                //console.log("None type event", Payload)
                                                dispatch(configActions.updateDefaultMQTT(Payload))
                                                break
                                            }
                                            default: {
                                                console.error('[MQTT] message error:', MessageCategory, Payload)
                                            }
                                        }
                                    }
                                })
                                // MQTT subscribe

                                MQTTClient.subscribe('OPC/#', {qos: 2})
                                // setInterval(() => {
                                //   console.log(`Average for ${updateAvg.length} store updates: ${updateAvg.length/(updateAvg.reduce((previous, current) => current += previous))}ms`)
                                // }, 5000)
                            })

                    })
            })
            .catch((e) => {
              dispatch(configActions.setError({ status: 0, message: 'Unable to connect to API' }))
            })

        // Return function to call on un-mount
        return function cleanup() {
            if (MQTTClient) MQTTClient.end()
        }
    })

    useEffect(() => {
      if (config.BackendVersion) {
        fetch(config.UpdaterBaseUrl + '/Updater/api/Updater/v1/Status')
          .then((resp) => resp.json())
          .then((data) => {
            console.log('Control Software Versions')
            console.table({
              Package: data.CurrentPackage.PackageVersion,
              Updater: data.UpdaterVersion,
              WebClient: applicationVersions.mainHmi,
              BuiltIn: applicationVersions.localHmi,
              PLC: config.Unit.Sts.sProgrammVersion,
              API: config.BackendVersion
            })
          })
          .catch((e) => {
            console.error('Could not load Package/Updater versions', e)
            console.log('Control Software Versions')
            console.table({
              WebClient: applicationVersions.mainHmi,
              BuiltIn: applicationVersions.localHmi,
              PLC: config.Unit.Sts.sProgrammVersion,
              API: config.BackendVersion
            })
          })
      }
    }, [config.BackendVersion, config.Unit, config.UpdaterBaseUrl])

    function getKendoLocalizationName() {
        switch (config.locale) {
            case 'fr':
                return 'fr-FR'
            case 'en':
                return 'en-US'
            case 'de':
                return 'de-DE'
            case 'it':
                return 'it-IT'
            case 'es':
                return 'es-ES'
            case 'zh':
                return 'zh-CN'
            case 'ja':
                return 'ja'
            default:
                return config.locale
        }
    }

    return (
        <IntlProvider locale={config.locale} messages={config.localeRecords}>
            <IntlService locale={config.locale}>
                <LocalizationProvider language={getKendoLocalizationName()}>
                    <Theme themeName={config.theme}>
                        {!config.error ?
                            isConfigLoaded ? (
                                <div>
                                    <Header/>
                                    <div className='alignedContainer'>
                                        <SideNav/>
                                        <div id="CenterDiv" style={{
                                            height: '100%',
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}>
                                            <Switch>
                                                {routes.map((route, i) => (
                                                    <Route
                                                        key={i}
                                                        path={route.path}
                                                        exact={route.exact}
                                                        children={<route.main/>}
                                                    />
                                                ))}
                                            </Switch>
                                            <Footer/>
                                        </div>
                                        <SensorsSummarySidebar/>
                                    </div>
                                    {config.Recipe.Phase[0].iPromptType === 2 && <RecipeDialog/>}
                                </div>
                            ) : (
                                <LoadingScreen percent={loadingState} applicationName={config.applicationName}/>
                            )
                            : (
                                <ErrorView/>
                            )}
                    </Theme>
                </LocalizationProvider>
            </IntlService>
        </IntlProvider>
    )
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(
    React.memo(App, (prevProps, nextProps) => {
        return (
            prevProps.config.theme === nextProps.config.theme &&
            prevProps.config.APIBaseUrl === nextProps.config.APIBaseUrl &&
            prevProps.config.locale === nextProps.config.locale &&
            prevProps.config.localeRecords === nextProps.config.localeRecords &&
            prevProps.config.Recipe === nextProps.config.Recipe &&
            prevProps.config.error === nextProps.config.error
        )
    })
)
