import {Col, Container, Row} from "reactstrap";
import React from "react";
import {useIntl} from "react-intl";
import {Button} from '@liquid-design/liquid-design-react'
import {useDispatch, useSelector} from "react-redux";
import {IConfigReducer} from "../../../store/reducers/configReducer";
import StoreTypes from "StoreTypes";
import _ from "lodash";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {setPlcProperty, CreateOpcValue, setPlcPropertyAsync} from "../../../utils/plcUtils";
import {Label} from "@progress/kendo-react-labels";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {AnalogueSensorLevitronics} from "../../../models/PLC/AnalogueSensorLevitronics";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import {getDigitFormat, getDigitNumber, getSensorDeadBand} from "../ControlPanels/settingsHelpers";

export interface ILevitronicsSensorCalibrationProps {
    nodeId: string
}

/**
 * LevitronicsSensorCalibration component
 * @param nodeId
 * @constructor
 */
export default function LevitronicsSensorCalibration({nodeId}: ILevitronicsSensorCalibrationProps) {

    const intl = useIntl();
    const dispatch = useDispatch();

    //Getting information from store
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config);

    //Get reference to the sensor
    const sensor: AnalogueSensorLevitronics = _.find(config.Instances.ControlModules, (item: ControlModuleBase) => {
        return item.NodeId === nodeId;
    }) as AnalogueSensorLevitronics;

    /**
     * Sending ZeroAdjustment information to sensor
     */
    function onZeroPointClick() {

        let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bZeroAdjustment', true);
        setPlcProperty(config, opcValue, {
            userLabel: 'label.event.ZeroPointSet', unit: '',
            eventTriggering: UserEventTriggering.BeforeAction
        }, dispatch);
    }

    /**
     * Reset flow meter counter reset
     */
    function onCounterZeroClick() {

        let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bVolCounterReset', true);
        setPlcProperty(config, opcValue, {
            userLabel: 'label.event.CounterReset', unit: '',
            eventTriggering: UserEventTriggering.BeforeAction
        }, dispatch);
    }

    //Get sensor current currentStep, with unit
    let sensorValueString = sensor.Out_rCurrentValue.toFixed(getDigitNumber(sensor.Out_rCurrentValue, getSensorDeadBand(sensor, 'Out_rCurrentValue', sensor.Out_rCurrentValue))).toString() + ' ' + sensor.Values[0].Cfg_suSensorUnit.Unit;

    return <>
        <Container>
            <Row style={{paddingTop: '5px'}}>
                <Col>
                    <Container>
                        <Row style={{paddingTop: '15px'}}>
                            <Col>
                                <Label
                                    editorId='kFactor'>{intl.formatMessage({id: 'label.KFactor'})}</Label>
                                <br/>
                                <NumericTextBox
                                    id='kFactor'
                                    format={"0" + getDigitFormat(sensor.Sts_rKfactor, 0)}
                                    min={0.1}
                                    max={30}
                                    step={1}
                                    spinners={false}
                                    value={sensor.Sts_rKfactor}
                                    onChange={async (e) => {
                                        //Propagate change on Enter button press
                                        if (e.nativeEvent.key === "Enter") {
                                            try {
                                                let opcValue = CreateOpcValue(sensor.NodeId + '.Set_rKfactor', e.value);
                                                await setPlcPropertyAsync(config, opcValue, {
                                                    userLabel: 'label.event.KFactorSet',
                                                    unit: '',
                                                    eventTriggering: UserEventTriggering.BeforeAction
                                                }, dispatch);

                                                opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bChangeKfactor', true);
                                                await setPlcPropertyAsync(config, opcValue, undefined, dispatch);
                                            } catch (error) {
                                                //TODO: Add better error handling !
                                                alert(error.message);
                                            }
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row style={{paddingTop: '15px'}}>
                            <Col>
                                <Button
                                    style={{width: '100%'}}
                                    onClick={onZeroPointClick}
                                    disabled={sensor.Sts_bZeroAdjustmentActive}>{intl.formatMessage({id: 'label.SetZeroPoint'})}

                                </Button>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: '15px'}}>
                            <Col>
                                <Button
                                    style={{width: '100%'}}
                                    onClick={onCounterZeroClick}>{intl.formatMessage({id: 'label.CounterReset'})}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col>
                    <div style={{margin: '0 auto', width: 'fit-content', paddingBottom: '15px'}}>
                        <Label
                            editorId='CurrentSensorValue'>{intl.formatMessage({id: 'label.CurrentSensorValue'})}</Label>
                        <div id='CurrentSensorValue' style={{fontWeight: 'bold', fontSize: '26px'}}>
                            {sensorValueString}
                        </div>
                    </div>
                    <br/>
                    <div style={{margin: '0 auto', width: 'fit-content', paddingBottom: '15px'}}>
                        <Label
                            editorId='VolumeCounter'>{intl.formatMessage({id: 'label.VolumeCounter'})}</Label>
                        <div id='VolumeCounter' style={{fontWeight: 'bold', fontSize: '26px'}}>
                            {sensor.Sts_rVolumePulseCounter.toFixed(getDigitNumber(sensor.Sts_rVolumePulseCounter, getSensorDeadBand(sensor, 'Sts_rVolumePulseCounter', sensor.Sts_rVolumePulseCounter))) + ' ' + sensor.Values[1].Cfg_suSensorUnit.Unit}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </>;
}