import {PortModelAlignment} from '@projectstorm/react-diagrams'

export default [
    {
        name: 'left',
        alignment: PortModelAlignment.LEFT
    },
    {
        name: 'right',
        alignment: PortModelAlignment.RIGHT
    }
]
