import React from 'react'
import PumpNodeWidget from './PumpArtifactWidget'
import { PumpArtifactModel } from './PumpArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class PumpArtifactFactory extends AbstractReactFactory<PumpArtifactModel, DiagramEngine> {
  constructor () {
    super('Pump')
  }

  generateReactWidget (event: any): JSX.Element {
    return <PumpNodeWidget engine={this.engine} size={80} node={event.model} />
  }

  generateModel (event: any) {
    return new PumpArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
