import _ from 'lodash'
import React from 'react'
import {Container, Row, Col} from 'reactstrap';
import {Glyph, Theme} from '@liquid-design/liquid-design-react'
import {apiOpcValue} from "../../../models/Api/apiOpcValue";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {FloatingLabel, Label} from '@progress/kendo-react-labels';
import {IConfigReducer} from "../../../store/reducers/configReducer";
import {useDispatch, useSelector} from "react-redux";
import StoreTypes from "StoreTypes";
import {IUserEventInfo, setPlcProperty} from "../../../utils/plcUtils";
import CommonSwitches from "./CommonSwitches";
import {AnalogueSensorTCU} from "../../../models/PLC/AnalogueSensorTCU";
import {useIntl} from "react-intl";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import {getPumpStateOptions} from './StateOptions'
import {getDigitFormat, getDigitNumber, getSensorDeadBand} from "./settingsHelpers";

export interface IHeatExchangerControlPanelProps {
    nodeId: string
}

export default function HeatExchangerControlPanel({nodeId}: IHeatExchangerControlPanelProps) {

    const dispatch = useDispatch();

    //Getting information from store
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const intl = useIntl();

    //Get the TCU associated ControlModule
    const sensorTCU: AnalogueSensorTCU = _.find(config.Instances.ControlModules, (item: ControlModuleBase) => {
        return item.NodeId === nodeId;
    }) as AnalogueSensorTCU;

    /**
     Switching mode between Start <-> Stop
     **/
    const onStateChange = (newValue: any) => {

        let state: apiOpcValue = new apiOpcValue();
        state.NodeId = nodeId + '.Inp_bCommandManual';
        state.Value = newValue;

        let eventInfo: IUserEventInfo = {
            userLabel: newValue ? 'label.event.Started' : 'label.event.Stopped',
            unit: '',
            eventTriggering: newValue ? UserEventTriggering.BeforeAction : UserEventTriggering.AfterAction,
            showValue: false
        }

        setPlcProperty(config, state, eventInfo, dispatch);
    };

    /**
     Switching mode between Automatic <-> Manual
     **/
    const onModeChange = (newValue: any) => {

        let mode: apiOpcValue = new apiOpcValue();
        mode.NodeId = nodeId + '.Set_bMode';
        mode.Value = !newValue;

        let eventInfo: IUserEventInfo = {
            userLabel: mode.Value ? 'label.event.ManualModeSet' : 'label.event.AutoModeSet',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }

        setPlcProperty(config, mode, eventInfo, dispatch);
    };

    //Get set point variable
    let tempSetPoint = sensorTCU.Set_bMode ? sensorTCU.Set_rTemperatureManualSP : sensorTCU.Set_rTemperatureAutoSP;
    let communicationInProgress = sensorTCU.Sts_bSendingCommand || sensorTCU.Sts_bWaitingResponse;
    let deadBand = getSensorDeadBand(sensorTCU, 'Out_rCurrentValue', sensorTCU.Out_rCurrentValue)

    return (
        <Theme themeName={config.theme}>
            <Container>
                <Row>
                    <Col>
                        {communicationInProgress && <div>
                            <Glyph name="dot" color="#0567b7"/><span
                            style={{
                                fontSize: '19px',
                                fontWeight: 'bold',
                                verticalAlign: 'text-bottom'
                            }}>&nbsp;{intl.formatMessage({id: 'label.SendingCommandToTCU'})}</span>
                        </div>}
                    </Col>
                </Row>
                <CommonSwitches stateOptions={getPumpStateOptions(intl)} isAutoMode={!sensorTCU.Set_bMode}
                                isStarted={sensorTCU.Sts_bRunCommand} onModeChange={onModeChange}
                                onStateChange={onStateChange} startStopDisabled={communicationInProgress}
                                autoManuDisabled={true}/>
                <Row style={{padding: '15px'}}>
                    <Col xs="6">
                        <Label
                            editorId='CurrentTcuSetPoint'>{intl.formatMessage({id: 'label.CurrentTcuSetPoint'})}</Label>
                        <div id='CurrentTcuSetPoint' style={{fontWeight: 'bold', fontSize: '19px'}}>
                            {sensorTCU.Sts_rCurrentSetpoint.toFixed(getDigitNumber(sensorTCU.Sts_rCurrentSetpoint, deadBand)) + ' ' + sensorTCU.Values[0].Cfg_suSensorUnit.Unit}
                        </div>
                        <br/>
                        <FloatingLabel label={intl.formatMessage({id: 'label.SetPoint'})}
                                       editorId='TempSetPoint'
                                       editorValue={tempSetPoint.toString()}>
                            <NumericTextBox
                                id='TempSetPoint'
                                format={"0" + getDigitFormat(tempSetPoint, deadBand) + " " + sensorTCU.Values[0].Cfg_suSensorUnit.Unit}
                                disabled={!sensorTCU.Set_bMode || communicationInProgress}
                                min={sensorTCU.Cfg_rEGUMinimum}
                                max={sensorTCU.Cfg_rEGUMaximum}
                                spinners={false}
                                value={tempSetPoint}
                                onChange={(e) => {
                                    //Propagate change on Enter button press
                                    if (e.nativeEvent.key === "Enter") {

                                        let mode: apiOpcValue = new apiOpcValue();
                                        if (sensorTCU.Set_bMode)
                                            mode.NodeId = sensorTCU.NodeId + '.Set_rTemperatureManualSP';
                                        else
                                            mode.NodeId = sensorTCU.NodeId + '.Set_rTemperatureAutoSP';
                                        mode.Value = e.value;

                                        setPlcProperty(config, mode, {
                                            userLabel: 'label.event.TemperatureSetPointSet',
                                            unit: sensorTCU.Values[0].Cfg_suSensorUnit.Name,
                                            eventTriggering: UserEventTriggering.BeforeAction
                                        }, dispatch);
                                    }
                                }}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <Label
                            editorId='CurrentSensorValue'>{intl.formatMessage({id: 'label.CurrentSensorValue'})}</Label>
                        <div id='CurrentSensorValue' style={{fontWeight: 'bold', fontSize: '19px'}}>
                            {sensorTCU.Out_rCurrentValue.toFixed(getDigitNumber(sensorTCU.Out_rCurrentValue, deadBand)) + ' ' + sensorTCU.Values[0].Cfg_suSensorUnit.Unit}
                        </div>
                    </Col>
                </Row>
            </Container>
        </Theme>
    )
}