import React, {useRef, useState} from 'react'
import {connect} from 'react-redux'
import {Button, Checkbox, Dropdown, Modal} from '@liquid-design/liquid-design-react'
import useMountEffect from '../../utils/useMountEffect'
import {apiDataSet} from '../../models/Api/apiDataSet'
import {downloadBlob} from 'download.js'
import {
    Chart,
    ChartArea,
    ChartCategoryAxis,
    ChartCategoryAxisCrosshair,
    ChartCategoryAxisCrosshairTooltip,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries, ChartSeriesItem, ChartSeriesItemProps, ChartSeriesItemTooltip, ChartTitle,
    ChartTooltip,
    ChartValueAxis, ChartValueAxisItem, ChartValueAxisItemProps, ChartXAxis, ChartXAxisItem, ChartYAxis, ChartYAxisItem
} from '@progress/kendo-react-charts'
import {
    createNoteVisual, FormAutoComplete,
    FormDropDownList, FormInput,
    FormNumericTextBox,
    FormRadioGroup,
    FormTextArea,
    generateEventsNotesFromDataset,
    IDataRange,
    IEventTooltipInfo,
    TrendsUtils
} from './TrendsUtils'
import {FormattedMessage, useIntl} from 'react-intl'
import StoreTypes from "StoreTypes"
import {Dialog} from "@progress/kendo-react-dialogs";
import {ScaleLoader} from "react-spinners";
import {apiDataSetOptimized} from "../../models/Api/apiDataSetOptimized";
import {CategoryAxisNotes} from "@progress/kendo-react-charts/dist/npm/option-types/category-axis-item/notes.interface";
import {Popup} from "@progress/kendo-react-popup";
import {ISelectedEvents} from "../../store/reducers/configReducer";
import {getDigitFormat} from "../../components/Diagram/ControlPanels/settingsHelpers";
import defaultChartDateFormats from "../../config/applicationParameters";
import {IsEmptyOrNullOrUndefined} from "../../utils/plcUtils";
import moment from "moment";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {apiCalculatedValues} from "../../models/Api/apiCalculatedValues";
import {ListView} from "@progress/kendo-react-listview";
import {handleResponse} from "../../utils/responseUtils";
import {ExportConfigurationPanel, IDataSetExportInformation} from "../ExportConfigurationPanel";

export interface IDataSet {
    name: string
    id: string
    code: string
}

const HistoryTrendsView = (props: any) => {
    const {config} = props
    const intl = useIntl()
    const chart = useRef(null)

    const [currentDataSet, setCurrentDataSet] = useState<apiDataSetOptimized | null>(null)
    const [dataSetList, setDataSetList] = useState([])
    const [isFetchingData, setFetchingData] = useState(false);
    const [isZooming, setZooming] = useState(false)
    const [isCalculationDialogOpen, setIsCalculationDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedEvents, setSelectedEvents] = useState<ISelectedEvents>({
        processEvents: false,
        userEvents: false,
        alarmEvents: false,
        sequenceEvents: false,
        comments: false
    })
    const [maskedSensors, setMaskedSensors] = useState<string[]>([])
    const [isRecordConfigDialogVisible, setRecordConfigDialogVisible] = useState(false)
    const [exportedDataSetInformation, setExportedDataSetInformation] = useState(undefined as IDataSetExportInformation)
    const dataRange = useRef<IDataRange>({min: undefined, max: undefined})
    const setDataRange = (data: IDataRange) => {
        dataRange.current = data;
    };
    //Event tooltip state
    const [tooltipParams, _setTooltipParams] = useState<IEventTooltipInfo>({
        showEventTooltip: false,
        eventTooltipContent: '',
        offset: {left: 150, top: 50}
    })
    const currentStateRef = React.useRef(tooltipParams);
    const setTooltipParams = (data: IEventTooltipInfo) => {
        currentStateRef.current = data;
        _setTooltipParams(data);
    };

    const notes: CategoryAxisNotes = {
        data: [],
        visual: createNoteVisual
    }

    /**
     * MouseEnter event on chart surface : opens event tooltip if suitable
     * @param e
     */
    function handleMouseEnter(e: any) {
        if (e.element.parent.tooltip === undefined) {
            return;
        }

        if (e.originalEvent.target.nodeName === "circle") {
            let elementRect = e.originalEvent.target.getBoundingClientRect()
            if (!currentStateRef.current.showEventTooltip) {

                setTooltipParams({
                    showEventTooltip: true,
                    eventTooltipContent: e.element.parent.tooltip,
                    offset: {left: elementRect.left, top: elementRect.top + elementRect.height}
                })
            }
        }
    }

    /**
     * MouseEnter event on chart surface : closes
     * @param e
     */
    function handleMouseLeave(e: any) {
        if (e.element.parent.tooltip === undefined) {
            return;
        }

        if (currentStateRef.current.showEventTooltip) {
            setTooltipParams({
                showEventTooltip: false,
                eventTooltipContent: '',
                offset: {left: 0, top: 0}
            })
        }
    }

    useMountEffect(() => {
        //Loading existing datasets, without values
        setFetchingData(true)
        fetch(`${config.APIBaseUrl}/history/api/DataSets/v1/DataSets`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                withValues: false
            })
        })
            .then((resp) => resp.json())
            .then((resp: apiDataSet[]) => {
                setDataSetList(resp.map((dataSet) => ({
                    id: dataSet.Id.toString(),
                    name: dataSet.Name,
                    code: dataSet.Code
                })))
                setFetchingData(false);
            })

        //Handlers for popup on events
        chart.current.chartInstance.surface.bind("mouseenter", handleMouseEnter);
        chart.current.chartInstance.surface.bind("mouseleave", handleMouseLeave);
    })

    /**
     * Loads the selected dataset data
     * @param dataSet
     */
    function changeDataSet(dataSet: IDataSet) {
        if (dataSet !== null) {
            setFetchingData(true)
            fetch(`${config.APIBaseUrl}/history/api/DataSets/v1/DataSet/${parseInt(dataSet.id)}`)
                .then((resp) => resp.json())
                .then((resp: apiDataSetOptimized) => {

                    let timeSlots = Object.keys(resp.TimeSlots)

                    if (timeSlots.length > 0) {
                        setDataRange({min: resp.StartTimeslot, max: resp.EndTimeslot})
                    } else {
                        setDataRange({min: undefined, max: undefined})
                    }

                    setCurrentDataSet(resp)
                    setFetchingData(false)
                }).catch(() => {
                setFetchingData(false)
            })
        } else {
            setCurrentDataSet(null)
        }
    }

    function reloadDataSet() {
        if (currentDataSet !== null) {
            setFetchingData(true)
            fetch(`${config.APIBaseUrl}/history/api/DataSets/v1/DataSet/${currentDataSet.Id}`)
                .then((resp) => resp.json())
                .then((resp: apiDataSetOptimized) => {

                    let timeSlots = Object.keys(resp.TimeSlots)

                    if (timeSlots.length > 0) {
                        setDataRange({min: resp.StartTimeslot, max: resp.EndTimeslot})
                    } else {
                        setDataRange({min: undefined, max: undefined})
                    }

                    setCurrentDataSet(resp)
                    setFetchingData(false)
                }).catch(() => {
                setFetchingData(false)
            })
        } else {
            setCurrentDataSet(null)
        }
    }

    /**
     * Opens Export configuration modal
     */
    function exportDataSet(startDate?: any, endDate?: any) {

        const exportConfiguration: IDataSetExportInformation = {
            id: currentDataSet.Id,
            code: currentDataSet.Code,
            name: currentDataSet.Name,
            startDate: startDate,
            endDate: endDate
        };

        setExportedDataSetInformation(exportConfiguration as IDataSetExportInformation);
        setRecordConfigDialogVisible(true);
    }

    /*
    * Handles export configuration model closing
    * */
    function OnCloseRecordConfigurationModal() {
        setRecordConfigDialogVisible(false);
        setExportedDataSetInformation(undefined);
    }

    /**
     * Resets Zoom
     */
    function resetZoom() {
        if (currentDataSet) {
            setDataRange({min: currentDataSet.StartTimeslot, max: currentDataSet.EndTimeslot})
            setZooming(false)
        }
    }

    /**
     * Saving selected events
     * @param name
     */
    function toggleSelectedSensor(name: string) {
        let modified = Object.assign({}, selectedEvents) as any;

        modified[name] = !modified[name];

        setSelectedEvents(modified)
    }

    let seriesData: any = []
    let columnKeys = currentDataSet ? Object.keys(currentDataSet.ColumnsDescriptions) : [];

    //Get sensor tag list, without calculated values
    let sensorTags = Array.from(new Set(columnKeys.filter(item => !currentDataSet.ColumnsDescriptions[item].NodeId.startsWith("ns=5")).map((colKey: any) => {
        return {
            label: currentDataSet.ColumnsDescriptions[colKey].Tag,
            value: currentDataSet.ColumnsDescriptions[colKey].NodeId
        }
    })))

    if (columnKeys.length > 0) {
        let timeSlots = Object.keys(currentDataSet.TimeSlots);

        timeSlots.forEach((timestamp) => {
            let timeSlotData = currentDataSet.TimeSlots[timestamp]
            let currentSerie: any = {"GatewayTimestamp": new Date(timestamp)}

            for (let i = 0; i < timeSlotData.length; i++) {
                currentSerie[i.toString()] = timeSlotData[i];
            }

            seriesData.push(currentSerie)
        })
    }

    let items: ChartSeriesItemProps[] = columnKeys.map((colKey, i) => {
        let colDesc = currentDataSet.ColumnsDescriptions[colKey]

        return (
            <ChartSeriesItem
                key={`Item${i}`}
                name={colDesc.Tag}
                type="line"
                field={colDesc.ColumnIndex.toString()}
                style="smooth"
                categoryField="GatewayTimestamp"
                data={seriesData}
                //aggregate={testAggregate}
                aggregate="avg"
                axis={colDesc.Unit}
                categoryAxis="Timeline"
                markers={{visible: false}}
                visible={!maskedSensors.includes(colDesc.Tag)}>
                <ChartSeriesItemTooltip render={TrendsUtils.nestedTooltipRender} format={'0' + getDigitFormat(1, 0)}/>
            </ChartSeriesItem>
        )
    })
    let units = Array.from(new Set(columnKeys.map((colKey: any) => currentDataSet.ColumnsDescriptions[colKey].Unit)))

    let axis: ChartValueAxisItemProps[] = units.map((unit, i) => {
        return (
            <ChartValueAxisItem
                key={`Axis${i}`}
                name={`${unit}`}
                narrowRange={true}
                title={{text: unit}}
                visible={true}
                labels={{format: "n"}}>
            </ChartValueAxisItem>
        )
    })

    //Generating axis notes info (events bullets)
    notes.data = generateEventsNotesFromDataset(currentDataSet, selectedEvents)
    /**
     * Formula types
     */
    const formulaTypes = [
        {label: intl.formatMessage({id: 'label.CalculationFormula'}), value: 'calculated'},
        {label: intl.formatMessage({id: 'label.AggregatedFormula'}), value: 'aggregated'}
    ]

    /**
     * Aggregation functions
     */
    const aggregationFunctions = [
        {label: intl.formatMessage({id: 'label.AverageFunction'}), value: 'average'},
        {label: intl.formatMessage({id: 'label.MinFunction'}), value: 'min'},
        {label: intl.formatMessage({id: 'label.MaxFunction'}), value: 'max'},
    ];

    const mathFunctions = [
        {label: 'Ln(x)'},
        {label: 'Log(x,base)'},
        {label: 'Sqrt(x)'},
        {label: 'Pow(x,y)'},
    ];

    /**
     *  Comment Form validator function for text area
     * @param value
     */
    const requiredValidator = (value: string) => value ? "" : intl.formatMessage({id: 'label.FieldRequired'});

    function createCalculatedValueEventHandler(formData: any) {

        setFetchingData(true);

        let calculatedValue = new apiCalculatedValues()
        calculatedValue.Id = 0
        calculatedValue.DataSetId = currentDataSet.Id

        if (formData.formulaType === 'calculated') {
            calculatedValue.Name = formData.formulaName
            calculatedValue.IsAggregate = false
            calculatedValue.Formula = formData.formulaExpression
        } else {
            calculatedValue.Name = `${formData.aggregationFunction.label}(${formData.aggregationSensor})`
            calculatedValue.IsAggregate = true
            calculatedValue.Formula = `${formData.aggregationFunction.value}|${formData.aggregationSensor}|${formData.sampleWidth}`
        }

        fetch(`${config.APIBaseUrl}/history/api/DataSets/v1/CalculatedValue/Add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(calculatedValue)
        })
            .then(handleResponse)
            .then((resp: apiDataSetOptimized) => {
                //Reloading dataset to get the new calculated values
                //reloadDataSet();

                let timeSlots = Object.keys(resp.TimeSlots)

                if (timeSlots.length > 0) {
                    setDataRange({min: resp.StartTimeslot, max: resp.EndTimeslot})
                } else {
                    setDataRange({min: undefined, max: undefined})
                }

                setCurrentDataSet(resp);
                setFetchingData(false);
                setIsCalculationDialogOpen(false);
            }).catch((resp: any) => {
            //TODO: Add a better window
            setFetchingData(false);
            alert(intl.formatMessage({id: resp.message}, {'0': formData.formulaExpression}))
        })
    }

    const RenderFormula = (props: any) => {
        let item: apiCalculatedValues = props.dataItem;
        return (
            <div className='row p-2 border-bottom align-middle' style={{margin: 0}}>
                <div className='col-10'>
                    <h2 style={{fontSize: 14, color: '#454545', marginBottom: 0}}
                        className="text-uppercase">{item.Name}</h2>
                    <div style={{fontSize: 12, color: "#a0a0a0"}}>{item.Formula}</div>
                </div>
                <div className='col-2'>
                    <Button icon='bin' onClick={() => {
                        fetch(`${config.APIBaseUrl}/history/api/DataSets/v1/CalculatedValue/${item.Id}`, {
                            method: 'DELETE'
                        }).then(() => {
                            //Reloading dataset to update calculated values
                            reloadDataSet();
                        })
                    }}/>
                </div>
            </div>
        );
    }

    return (
        <>
            {isFetchingData &&
                <Dialog title={intl.formatMessage({id: 'label.LoadingDataPleaseWait'})} closeIcon={false}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <ScaleLoader
                            height={50}
                            width={10}
                            radius={4}
                            margin={2}
                            color={"#123abc"}
                            loading={isFetchingData}
                        />
                    </div>
                </Dialog>}
            {isCalculationDialogOpen &&
                <Dialog title={intl.formatMessage({id: 'label.CalculationDialog'})}
                        closeIcon={true}
                        onClose={() => setIsCalculationDialogOpen(false)}
                        width={500}>
                    <Form
                        onSubmit={createCalculatedValueEventHandler}
                        initialValues={{
                            'formulaType': 'calculated'
                        }}
                        render={(formRenderProps: any) => (
                            <FormElement>
                                <fieldset className={'k-form-fieldset'}>
                                    <Field
                                        id={'formulaType'}
                                        name={'formulaType'}
                                        label={intl.formatMessage({id: 'label.FormulaType'})}
                                        layout={'horizontal'}
                                        component={FormRadioGroup}
                                        data={formulaTypes}
                                    />
                                    {formRenderProps.valueGetter('formulaType') === 'calculated' ? <>
                                        <Field
                                            id={'formulaName'}
                                            name={'formulaName'}
                                            label={intl.formatMessage({id: 'label.FormulaName'})}
                                            component={FormInput}
                                            validator={requiredValidator}
                                        />
                                        <Field
                                            id={'formulaExpression'}
                                            name={'formulaExpression'}
                                            label={intl.formatMessage({id: 'label.FormulaExpression'})}
                                            hint={'([PI001] + Ln([PI201]))/2'}
                                            component={FormTextArea}
                                            validator={requiredValidator}
                                        />
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center'
                                        }}>
                                            <Field
                                                id={'sensorHelper'}
                                                name={'sensorHelper'}
                                                label={intl.formatMessage({id: 'label.AggregationSensor'})}
                                                component={FormAutoComplete}
                                                textField={'label'}
                                                data={sensorTags}
                                                hint={intl.formatMessage({id: 'label.StartTypingSensorTag'})}
                                            />
                                            <Button icon='add' onClick={(e: any) => {
                                                e.preventDefault();
                                                if (formRenderProps.valueGetter('sensorHelper') !== "") {
                                                    // Add Tag to field
                                                    let previousValue = formRenderProps.valueGetter('formulaExpression') === undefined ? '' : formRenderProps.valueGetter('formulaExpression')
                                                    formRenderProps.onChange('formulaExpression', {value: previousValue + `[${formRenderProps.valueGetter('sensorHelper')}]`});
                                                }
                                            }}>
                                            </Button>
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center'
                                        }}>
                                            <Field
                                                id={'mathHelper'}
                                                name={'mathHelper'}
                                                label={intl.formatMessage({id: 'label.MathFormula'})}
                                                component={FormAutoComplete}
                                                textField={'label'}
                                                data={mathFunctions}
                                            />
                                            <Button icon='add' onClick={(e: any) => {
                                                e.preventDefault();
                                                if (formRenderProps.valueGetter('mathHelper') !== "") {
                                                    // Add Tag to field
                                                    let previousValue = formRenderProps.valueGetter('formulaExpression') === undefined ? '' : formRenderProps.valueGetter('formulaExpression')
                                                    formRenderProps.onChange('formulaExpression', {value: previousValue + `${formRenderProps.valueGetter('mathHelper')}`});
                                                }
                                            }}>
                                            </Button>
                                        </div>

                                    </> : <>
                                        <Field
                                            id={'aggregationSensor'}
                                            name={'aggregationSensor'}
                                            label={intl.formatMessage({id: 'label.AggregationSensor'})}
                                            component={FormAutoComplete}
                                            textField={'label'}
                                            data={sensorTags}
                                            validator={requiredValidator}
                                            hint={intl.formatMessage({id: 'label.StartTypingSensorTag'})}
                                        />
                                        <Field
                                            id={'aggregationFunction'}
                                            name={'aggregationFunction'}
                                            label={intl.formatMessage({id: 'label.AggregationFunction'})}
                                            component={FormDropDownList}
                                            textField={'label'}
                                            data={aggregationFunctions}
                                            validator={requiredValidator}
                                        />
                                        <Field
                                            id={'sampleWidth'}
                                            name={'sampleWidth'}
                                            label={intl.formatMessage({id: 'label.SampleWidth'})}
                                            format={'n'}
                                            component={FormNumericTextBox}
                                            validator={requiredValidator}
                                        />
                                    </>}
                                    <span className={'k-form-separator'}/>
                                    <div className="k-form-buttons">
                                        <Button
                                            primary={true}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit}>
                                            <FormattedMessage id='label.AddCalculatedValue'/>
                                        </Button>
                                        <Button onClick={formRenderProps.onFormReset}>
                                            <FormattedMessage id='label.Clear'/>
                                        </Button>
                                    </div>
                                </fieldset>
                            </FormElement>
                        )}
                    />
                </Dialog>
            }

            {isDeleteDialogOpen &&
                <Dialog title={intl.formatMessage({id: 'label.CalculationDialog'})}
                        closeIcon={true}
                        onClose={() => setIsDeleteDialogOpen(false)}
                        width={500}>
                    <ListView
                        data={currentDataSet.CalculatedValues}
                        item={RenderFormula}
                        style={{width: "100%", height: "400px"}}
                    />
                </Dialog>
            }

            {isRecordConfigDialogVisible && currentDataSet && (
                <Modal
                    label={intl.formatMessage({id: 'label.SelectSamplingRate'}, {dataSetName: currentDataSet.Name})}
                    open={isRecordConfigDialogVisible}
                    onClose={() => OnCloseRecordConfigurationModal()}>
                    <div className='container'>
                        <ExportConfigurationPanel dataSetInfo={exportedDataSetInformation} onDataSetExported={OnCloseRecordConfigurationModal}/>
                    </div>
                </Modal>)}

            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}} className="ScrollBar">
                <div style={{display: "flex", justifyContent: 'center'}}>
                    <Dropdown
                        label={intl.formatMessage({id: 'label.SelectDataset'})}
                        options={dataSetList}
                        onSubmit={(t: IDataSet) => changeDataSet(t)}
                    />
                    <Button style={{marginRight: '10px'}} icon='historicalData'
                            disabled={!currentDataSet} onClick={() => reloadDataSet()}/>
                    <Button style={{marginRight: '5px'}} icon='exportFile'
                            disabled={!currentDataSet} onClick={() => exportDataSet()}>
                        <FormattedMessage id='label.ExportDataset'/>
                    </Button>
                    <Button style={{marginRight: '10px'}} icon='exportFile'
                            disabled={!currentDataSet || !isZooming}
                            onClick={() => exportDataSet(dataRange.current.min, dataRange.current.max)}>
                        <FormattedMessage id='label.ExportCurrentView'/>
                    </Button>
                    <Button icon='repost'
                            style={{marginRight: '10px'}}
                            disabled={!isZooming} onClick={() => resetZoom()}>
                        <FormattedMessage id='label.ResetZoom'/>
                    </Button>
                    <Button icon='pencil'
                            style={{marginRight: '5px'}}
                            disabled={!currentDataSet}
                            onClick={() => setIsCalculationDialogOpen(true)}/>
                    <Button icon='bin'
                            disabled={!currentDataSet || currentDataSet.CalculatedValues.length === 0}
                            onClick={() => setIsDeleteDialogOpen(true)}/>
                </div>
                <div style={{height: 'calc(100% - 80px)'}}>
                    <Chart ref={ctx => chart.current = ctx}
                           pannable={{lock: 'y'}}
                           onLegendItemClick={(i) => {

                               let newMaskedSensors = []
                               if (maskedSensors.indexOf(i.text) > -1) {
                                   newMaskedSensors = maskedSensors.filter((sensor) => sensor !== i.text)
                               } else {
                                   newMaskedSensors.push(...maskedSensors)
                                   newMaskedSensors.push(i.text)
                               }

                               setMaskedSensors(newMaskedSensors)
                           }}
                           zoomable={{mousewheel: {lock: 'y'}, selection: {lock: 'y'}}}
                           transitions={false}
                           style={{height: '100%'}}
                           onZoom={(event) => {
                               //Saving viewport in the state
                               if (event.axisRanges.Timeline) {
                                   if (dataRange.current.min !== event.axisRanges.Timeline.min || dataRange.current.max !== event.axisRanges.Timeline.max) {
                                       setDataRange({
                                           min: event.axisRanges.Timeline.min,
                                           max: event.axisRanges.Timeline.max
                                       })
                                       if (!isZooming) {
                                           setZooming(true)
                                       }
                                   }
                               }
                           }}
                           onDrag={(event) => {
                               //Saving viewport in the state
                               if (dataRange.current.min !== event.axisRanges.Timeline.min || dataRange.current.max !== event.axisRanges.Timeline.max) {
                                   setDataRange({
                                       min: event.axisRanges.Timeline.min,
                                       max: event.axisRanges.Timeline.max
                                   })
                                   if (!isZooming) {
                                       setZooming(true)
                                   }
                               }
                           }}>
                        <ChartArea margin={5}/>
                        <ChartLegend position="bottom"/>
                        <ChartTooltip/>
                        <ChartSeries>
                            {items || []}
                        </ChartSeries>
                        <ChartValueAxis>
                            {axis || []}
                        </ChartValueAxis>
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                                name="Timeline"
                                type="date"
                                visible={true}
                                baseUnit="milliseconds"
                                baseUnitStep="auto"
                                maxDateGroups={60}
                                maxDivisions={20}
                                justified={false}
                                min={dataRange.current.min}
                                max={dataRange.current.max}
                                majorGridLines={{visible: false}}
                                notes={notes || undefined}
                                labels={{
                                    rotation: 45, dateFormats: defaultChartDateFormats
                                }}>
                                {seriesData.length > 0 ?
                                    (<ChartCategoryAxisCrosshair>
                                        <ChartCategoryAxisCrosshairTooltip/>
                                    </ChartCategoryAxisCrosshair>) : []
                                }
                            </ChartCategoryAxisItem>
                        </ChartCategoryAxis>
                    </Chart>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Checkbox style={{paddingRight: '15px'}}
                                  label={intl.formatMessage({id: 'label.ProcessEvents'})}
                                  isChecked={selectedEvents.processEvents}
                                  onChange={() => toggleSelectedSensor('processEvents')}/>
                        <Checkbox style={{paddingRight: '15px'}}
                                  label={intl.formatMessage({id: 'label.UserEvents'})}
                                  isChecked={selectedEvents.userEvents}
                                  onChange={() => toggleSelectedSensor('userEvents')}/>
                        <Checkbox style={{paddingRight: '15px'}}
                                  label={intl.formatMessage({id: 'label.AlarmEvents'})}
                                  isChecked={selectedEvents.alarmEvents}
                                  onChange={() => toggleSelectedSensor('alarmEvents')}/>
                        <Checkbox style={{paddingRight: '15px'}}
                                  label={intl.formatMessage({id: 'label.SequenceEvents'})}
                                  isChecked={selectedEvents.sequenceEvents}
                                  onChange={() => toggleSelectedSensor('sequenceEvents')}/>
                        <Checkbox label={intl.formatMessage({id: 'label.Comments'})}
                                  isChecked={selectedEvents.comments}
                                  onChange={() => toggleSelectedSensor('comments')}/>

                    </div>
                    {currentDataSet && currentDataSet.Excursions && currentDataSet.Excursions.length > 0 &&
                        <div style={{marginTop: '10px'}}>
                            <Chart>
                                <ChartTitle text={intl.formatMessage({id: 'label.TmpFluxExcursion'})}/>
                                <ChartTooltip/>
                                <ChartSeries>
                                    <ChartSeriesItem
                                        type="scatter"
                                        data={currentDataSet.Excursions}
                                        xField="Xvalue"
                                        yField="Yvalue">
                                        <ChartSeriesItemTooltip render={TrendsUtils.nestedExcursionTooltipRender}/>
                                    </ChartSeriesItem>
                                </ChartSeries>
                                <ChartXAxis>
                                    <ChartXAxisItem
                                        title={{text: `${intl.formatMessage({id: 'label.TmpAxis'})} [${currentDataSet.Excursions[0].XvalueUnit}]`}}/>
                                </ChartXAxis>
                                <ChartYAxis>
                                    <ChartYAxisItem
                                        title={{text: `${intl.formatMessage({id: 'label.FluxAxis'})} [${currentDataSet.Excursions[0].YvalueUnit}]`}}/>
                                </ChartYAxis>
                            </Chart>
                        </div>
                    }
                </div>
                {tooltipParams.showEventTooltip && <Popup
                    show={tooltipParams.showEventTooltip}
                    //anchor={currentStateRef.current.eventTooltipTargetElement}
                    offset={tooltipParams.offset}
                    popupClass='even-popup-content'
                    animate={false}>
                    <>{tooltipParams.eventTooltipContent}</>
                </Popup>}
            </div>
        </>
    )
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(
    React.memo(HistoryTrendsView, (prevProps, nextProps) => {
        return (
            prevProps.config.theme === nextProps.config.theme &&
            prevProps.config.APIBaseUrl === nextProps.config.APIBaseUrl &&
            prevProps.config.locale === nextProps.config.locale &&
            prevProps.config.localeRecords === nextProps.config.localeRecords
        )
    })
)
