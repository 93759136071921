/**
 * Dependencies
 */
import React from 'react'

import {Bowl, Headline} from '@liquid-design/liquid-design-react'

export interface ILoadingScreenProps {
    percent: number,
    applicationName:string
}

/**
 * Content
 */
const LoadingScreen = (props: ILoadingScreenProps) => {
    return (
        <div style={{
            height: '100vh',
            width: '100vw',
            backgroundColor: '#DDDDDD',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <div>
                <Bowl animationDuration={1000} percent={props.percent} width={300}/>
            </div>
            <div>
                <Headline>
                    {props.applicationName} is loading, please wait...
                </Headline>
            </div>
        </div>
    )
}

export default LoadingScreen
