import React from 'react'
import {Dialog, DialogActionsBar} from '@progress/kendo-react-dialogs'
import {FormattedMessage, useIntl} from 'react-intl'
import {IConfigReducer} from '../store/reducers/configReducer'
import {useSelector} from 'react-redux'
import StoreTypes from "StoreTypes"
import {setPlcProperty} from "../utils/plcUtils";
import {apiOpcValue} from "../models/Api/apiOpcValue";
import {UserEventTriggering} from "../models/PLC/UserEventTriggering";

const RecipeDialog = () => {
    const intl = useIntl()
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)

    function sendPromptResponse(resp: boolean) {
        // fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/PropertyValue/Set?pushToPlc=true`, {
        //     method: 'PUT',
        //     headers: {
        //         'Content-type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         NodeId: resp ? config.Recipe.Phase[0].NodeId + '.iPromptResponse' : config.Recipe.OperationCtrl.NodeId + '.bAbort',
        //         Value: resp ? 2 : true
        //     })
        // })

        let respValue: apiOpcValue = new apiOpcValue();
        respValue.NodeId = resp ? config.Recipe.Phase[0].NodeId + '.iPromptResponse' : config.Recipe.OperationCtrl.NodeId + '.bAbort'
        respValue.Value = resp ? 2 : true

        setPlcProperty(config,respValue,{
            userLabel: 'label.event.MacroStepStartResponseSet',
            unit: "",
            eventTriggering: UserEventTriggering.BeforeAction} , undefined)
    }

    return (
        <Dialog title={intl.formatMessage({id: 'label.StartMacroStep'})} closeIcon={false}>
            <DialogActionsBar>
                <button className="k-button" onClick={() => sendPromptResponse(true)}>
                    <FormattedMessage id='label.Yes'/>
                </button>
                <button className="k-button" onClick={() => sendPromptResponse(false)}>
                    <FormattedMessage id='label.No'/>
                </button>
            </DialogActionsBar>
        </Dialog>
    )
}

export default RecipeDialog