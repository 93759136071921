import React from 'react'
import InletNodeWidget from './IntletArtifactWidget'
import { InletArtifactModel } from './InletArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class InletArtifactFactory extends AbstractReactFactory<InletArtifactModel, DiagramEngine> {
  constructor () {
    super('Inlet')
  }

  generateReactWidget (event: any): JSX.Element {
    return <InletNodeWidget engine={this.engine} size={83.8} node={event.model} />
  }

  generateModel (event: any) {
    return new InletArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
