/**
 * Visual builder for Y Artifact
 */
import {ControlModuleBase} from '../../../../models/PLC/ControlModuleBase'
import {DriveControlPID} from '../../../../models/PLC/DriveControlPID'

export default class YVisual {
    /**
     * Builds up svg
     * @returns SVG as a string
     * @param name
     * @param controlModules
     */
    static build(name: string, controlModules: Record<string, ControlModuleBase>, isSptffModeACtivated: boolean): string {
        let isRetentate = false
        let isEnabled = false
        let isTransfer = false
        if (name === 'T1') {
            if ((controlModules['P001/SIC001'] as DriveControlPID).Sts_bRunning) {
                isEnabled = !isSptffModeACtivated
                isRetentate = !isSptffModeACtivated
            }
            if ((controlModules['P401/SIC401'] as DriveControlPID).Sts_bRunning) {
                isEnabled = true
                isTransfer = true
            }
        } else {
            if ((controlModules['P001/SIC001'] as DriveControlPID).Sts_bRunning) {
                isRetentate = true
                isEnabled = true
                isTransfer = true
            }
        }

        if (name === 'T2') {
            if ((controlModules['P001/SIC001'] as DriveControlPID).Sts_bRunning) {
                isEnabled = false
                isRetentate = true
                isTransfer = true
            }
        }

        return `<g transform="matrix(1,0,0,1,-1.14059e-06,-7.53406e-05)">
        <g transform="matrix(0.669977,0,0,0.669977,-45.0028,-75.1833)">
            <g transform="matrix(1,0,0,1,59.628,7.16927)">
                <rect x="82.054" y="105.14" width="52.635" height="173.696" style="fill:rgb(213,213,217);"/>
            </g>
            <g transform="matrix(1,0,0,1,59.026,7.45125)">
                <rect x="8.449" y="165.744" width="111.165" height="51.924" style="fill:rgb(213,213,217);"/>
            </g>
            <g transform="matrix(1,0,0,1,59.4465,7.35077)">
                <rect x="89.314" y="104.958" width="38.478" height="173.696" style="fill:white;"/>
            </g>
            <g transform="matrix(0.99799,0,0,1,59.2667,7.33509)">
                <rect x="8.217" y="172.946" width="111.514" height="37.752" style="fill:rgb(247,247,255);"/>
            </g>
            <g transform="matrix(1,0,0,0.433931,59.628,66.6733)">
                <rect x="96.756" y="104.958" width="23.232" height="173.878" style="fill:${isTransfer ? 'rgb(15,105,175)' : '#767680'};"/>
            </g>
            <g transform="matrix(1.01044,0,0,1,58.6334,7.39317)">
                <rect x="8.449" y="180.264" width="111.282" height="23" style="fill:${isEnabled ? 'rgb(15,105,175)' : '#767680'};"/>
            </g>
            <g transform="matrix(1,0,0,0.566376,59.628,128.231)">
                <rect x="96.756" y="104.958" width="23.232" height="173.878" style="fill:${isRetentate ? 'rgb(15,105,175)' : '#767680'};"/>
            </g>
        </g>
    </g>`
    }
}