import * as StoreTypes from 'StoreTypes'
import {configActionsTypes} from '../actions'
import {UnitStructure} from '../../models/PLC/UnitStructure'
import {LinkModel} from '@projectstorm/react-diagrams'
import {Themes} from '../../views/SettingsView'
import _ from 'lodash'
import {apiDatas} from '../../models/Api/apiDatas'
import moment from 'moment'
import {assignDeep} from '../../utils/assignDeep'
import {apiOpcValue} from "../../models/Api/apiOpcValue";
import {apiEvents} from "../../models/Api/apiEvents";
import {apiAlarms} from "../../models/Api/apiAlarms";
import {apiComments} from "../../models/Api/apiComments";

export interface IError {
    status: number
    message: string
}

export interface ISelectedEvents {
    processEvents: boolean
    userEvents: boolean
    alarmEvents: boolean
    sequenceEvents: boolean
    comments: boolean
}

export interface IConfigReducer extends UnitStructure {
    APIBaseUrl: string
    UpdaterBaseUrl: string
    UpdaterClientBaseUrl: string
    tagToF_: Record<string, string>
    nodeIdToStore: Record<string, string[]>
    didGenerateDiagrams: boolean
    didPauseLive: boolean
    linkArtifacts: LinkModel[]
    theme: Themes
    locale: string
    localeRecords: Record<string, string>
    error: IError
    maskedSensors: string[]
    selectedEvents: ISelectedEvents,
    applicationName: string
}

const initialState: IConfigReducer = {
    LoggingElapsedTime: "00:00:00",
    BackendVersion:"",
    SystemId: "",
    IpAddress: "",
    AH: null as any,
    AvailableSensorUnits: [],
    Cfg_sDescription: '',
    Cfg_sTag: '',
    DataLoggingState: null as any,
    EO: null as any,
    Instances: null as any,
    NodeId: '',
    OPC: null as any,
    ParentObject: null as any,
    Recipe: null as any,
    Unit: null as any,
    UserEvents: [],
    UserOptions: [],
    ProcessEvents: [],
    SequenceEvents: [],
    APIBaseUrl: `http://${window.location.hostname}:5000`,
    UpdaterBaseUrl: `http://${window.location.hostname}:5002`,
    UpdaterClientBaseUrl: `http://${window.location.hostname}:8100`,
    tagToF_: null,
    nodeIdToStore: null,
    didGenerateDiagrams: false,
    didPauseLive: false,
    linkArtifacts: [],
    theme: Themes.richBlue,
    locale: 'en',
    localeRecords: {},
    Comments: [],
    error: null,
    maskedSensors: [],
    selectedEvents: {
        processEvents: false,
        userEvents: true,
        alarmEvents: true,
        sequenceEvents: true,
        comments: true
    },
    applicationName:"Bio4C™ Control Software for Cogent® Lab System"
}

/**
 * Updates event list to keep only the last 10 minutes
 * @param eventList
 * @param timeDiff - Time difference in minutes
 */
function getUpdatedEventList(eventList: apiEvents[], timeDiff: number) {
    // Find the latest timestamp from the event list
    const latestTimestamp = Math.max(...eventList.map(ue => new Date(ue.GatewayTimestamp).getTime()));

    // Convert the latest timestamp to a moment object
    const latestMoment = moment(latestTimestamp);

    // Filter events to keep only those within the last `timeDiff` minutes
    return _.filter(eventList, ue => {
        const eventMoment = moment(new Date(ue.GatewayTimestamp));
        return latestMoment.diff(eventMoment, 'minutes') < timeDiff;
    });
}

export const configReducer = (state = initialState, action: StoreTypes.RootAction) => {
    let newState;
    const timeDiff = 600;
    switch (action.type) {
        case configActionsTypes.HYDRATE: {
            newState = {
                ...state,
                ...action.payload as UnitStructure
            }
            break;
        }
        case configActionsTypes.BUILD_INDEXES: {
            // Build tagToF_ index
            const tagToF_: { [key: string]: string } = {}
            const nodeIdToStore: { [key: string]: string[] } = {}
            const {ControlModules} = state.Instances
            const AAPListOfDAP = ["DAPCriticalProcessHi",
                "DAPCriticalProcessLo",
                "DAPProcessWarningHi",
                "DAPProcessWarningLo",
                "DAPSafetyHi",
                "DAPSafetyLo",
                "DAPSystemHi",
                "DAPSystemLo",
                "DAPOutOfRange"];
            Object.keys(ControlModules).forEach(
                (CMName) => tagToF_[CMName] = ControlModules[CMName].NodeId.split('..')[1].split('.')[3]
            )
            // Build nodeIdToObject index
            _.cloneDeepWith(state, (v) => {
                if (!_.isObject(v) && typeof v == 'string' && v.includes('ns=4')) {
                    const suffix = v.split('..')[1]
                    const splitSuffix = suffix.split('.')
                    let path: string[] = []
                    // Remove BTP in front of NodeID because BTP equals to root state
                    if (splitSuffix[0] === 'BTP') splitSuffix.shift()
                    splitSuffix.forEach((step, i) => {
                        if (i === 0 && (step === 'RECIPE')) {
                            // Rename step
                            path.push('Recipe')
                        } else if (i === 1 && (step === 'CM' || step === 'EM')) {
                            // Rename step
                            path.push(`${step === 'CM' ? 'ControlModules' : 'EquipmentModules'}`)
                        } else if (step.startsWith('DAP') && AAPListOfDAP.includes(step)) {
                            // Access DAP from AAP
                            path.push('AAP', step)
                        } else if (step.startsWith('F_')) {
                            // Get ControlModule Tag from F_
                            const CM = _.findKey(tagToF_, (o) => o === step)
                            path.push(`${CM}`)
                        } else if (step.includes('[')) {
                            // Get value from an array
                            const val = step.match('\\[(.*?)\\]')
                            const index = step.substring(0, step.length - (val[1].toString().length + 2))
                            path.push(`${index}[${parseInt(val[1])}]`)
                        } else if (step !== 'Obj') {
                            // Browse further
                            path.push(`${step}`)
                        }
                    })
                    nodeIdToStore[v] = path
                }
            })
            newState = {
                ...state,
                tagToF_,
                nodeIdToStore
            }
            break;
        }
        case configActionsTypes.SET_DID_GENERATE_DIAGRAMS: {
            newState = {
                ...state,
                didGenerateDiagrams: action.payload as boolean
            }
            break;
        }

        case configActionsTypes.SET_DID_PAUSE_LIVE: {
            newState = {
                ...state,
                didPauseLive: action.payload as boolean
            }
            break;
        }

        case configActionsTypes.SET_THEME: {
            newState = {
                ...state,
                theme: action.payload as Themes
            }
            break;
        }
        case configActionsTypes.SET_LANGUAGE: {
            newState = {
                ...state,
                locale: action.payload
            }
            break;
        }
        case configActionsTypes.UPDATE_TRANSLATIONS: {
            newState = {
                ...state,
                localeRecords: action.payload
            }
            break;
        }
        case configActionsTypes.SWITCH_SENSOR_MASKING: {
            const toUpdate = action.payload as string
            newState = {
                ...state,
                maskedSensors: state.maskedSensors.indexOf(toUpdate) > -1 ? state.maskedSensors.filter((sensor) => sensor !== action.payload) : [
                    ...state.maskedSensors,
                    action.payload
                ]
            }
            break;
        }
        case configActionsTypes.SWITCH_EVENTS_MASKING: {
            const toUpdate = action.payload as ISelectedEvents
            newState = {
                ...state,
                selectedEvents: toUpdate
            }
            break;
        }
        case configActionsTypes.ADD_COMMENT: {
            const comment = (action.payload as apiComments);

            // Combine existing comments with the new comment
            const allComments = [...state.Comments, comment];

            // Find the latest timestamp from all comments
            const latestTimestamp = Math.max(...allComments.map(ue => new Date(ue.Start).getTime()));

            // Convert to moment object for comparison
            const latestMoment = moment(latestTimestamp);

            // Filter comments to keep only those within the last `timeDiff` minutes relative to the latest timestamp
            const updatedComments = _.filter(state.Comments, ue => {
                const commentMoment = moment(new Date(ue.Start));
                return latestMoment.diff(commentMoment, 'minutes') < timeDiff;
            });

            newState = {
                ...state,
                Comments: [
                    ...updatedComments,
                    comment
                ]
            }
            break;
        }
        case configActionsTypes.UPDATE_DEFAULT: {
            const item = action.payload as apiOpcValue
            const containerObjectModeId = item.NodeId.substring(0, item.NodeId.lastIndexOf("."));

            const itemPath = [
                ...state.nodeIdToStore[containerObjectModeId]
            ]
            itemPath.push(item.NodeId.split('.').pop())

            let assigned: UnitStructure = assignDeep(state, itemPath, item)

            newState = {
                ...assigned,
                didGenerateDiagrams: false
            }
            break;
        }

        /*
        MQTT Updates functions
        * */
        case configActionsTypes.UPDATE_DEFAULT_MQTT: {
            const item = action.payload as apiDatas
            const itemPath = [
                ...state.nodeIdToStore[item.ParentNodeId]
            ]
            itemPath.push(item.NodeId.split('.').pop())
            let assigned: UnitStructure = assignDeep(state, itemPath, item.Value)
            newState = {
                ...assigned,
                didGenerateDiagrams: false
            }
            break;
        }

        case configActionsTypes.UPDATE_DATA_MQTT: {
            const {ControlModules} = state.Instances
            const CM = (action.payload as apiDatas)
            const tag = CM.Tag
            const nodeId = CM.NodeId
            const history = (ControlModules[tag]).History[nodeId]

            // Keep last 10 minutes
            const updatedHistory: { [key: string]: number } = {}
            const latestTimestamp = new Date(CM.GatewayTimestamp).getTime() / 1000

            Object.keys(history).sort().forEach((o) => {
                const past = new Date(o).getTime() / 1000
                if (latestTimestamp - past < timeDiff) {
                    updatedHistory[o] = history[o]
                }
            })

            newState = {
                ...state,
                Instances: {
                    ...state.Instances,
                    ControlModules: {
                        ...state.Instances.ControlModules,
                        [tag]: {
                            ...state.Instances.ControlModules[tag],
                            [nodeId.split('.').pop()]: CM.Value,
                            History: {
                                ...state.Instances.ControlModules[tag].History,
                                [nodeId]: {
                                    ...updatedHistory,
                                    [CM.GatewayTimestamp.toString()]: CM.Value
                                }
                            }
                        }
                    }
                }
            }
            break;
        }

        case configActionsTypes.UPDATE_DATA_MQTT_ARRAY: {
            const payloadArray: apiDatas[] = action.payload as apiDatas[];

            // Determine the latest timestamp from the payload array
            const latestTimestamp = Math.max(...payloadArray.map(item => new Date(item.GatewayTimestamp).getTime()));

            let updatedState: IConfigReducer = {
                ...state
            };

            payloadArray.forEach((item) => {
                const { ControlModules } = updatedState.Instances;
                const CM = item;
                const tag = CM.Tag;
                const nodeId = CM.NodeId;
                const history = (ControlModules[tag]).History[nodeId];

                // Keep last 10 minutes based on the latest timestamp
                const updatedHistory: { [key: string]: number } = {};
                const latestMoment = moment(latestTimestamp);

                Object.keys(history).forEach((o) => {
                    const past = new Date(o).getTime();
                    if (latestMoment.diff(moment(past), 'minutes') < timeDiff) {
                        updatedHistory[o] = history[o];
                    }
                });

                updatedState = {
                    ...updatedState,
                    Instances: {
                        ...updatedState.Instances,
                        ControlModules: {
                            ...updatedState.Instances.ControlModules,
                            [tag]: {
                                ...updatedState.Instances.ControlModules[tag],
                                [nodeId.split('.').pop()]: CM.Value,
                                History: {
                                    ...updatedState.Instances.ControlModules[tag].History,
                                    [nodeId]: {
                                        ...updatedHistory,
                                        [CM.GatewayTimestamp.toString()]: CM.Value
                                    }
                                }
                            }
                        }
                    }
                };
            });
            newState = updatedState;

            break;
        }

        case configActionsTypes.UPDATE_ALARM_MQTT: {
            const item = action.payload as apiAlarms;
            const containerObjectModeId = item.NodeId.substring(0, item.NodeId.lastIndexOf("."));

            const itemPath = [
                ...state.nodeIdToStore[containerObjectModeId]
            ];
            itemPath.push(item.NodeId.split('.').pop());

            let assigned: UnitStructure = assignDeep(state, itemPath, item);

            // Combine existing alarm events with the new alarm event
            const allAlarmEvents = [...state.AH.Sts.Alarm.AlarmEvents, item];

            // Determine the latest timestamp from all alarm events
            const latestTimestamp = Math.max(...allAlarmEvents.map(ue => new Date(ue.GatewayTimestamp).getTime()));

            // Convert to moment object for comparison
            const latestMoment = moment(latestTimestamp);

            // Keep last 10 minutes based on the latest timestamp
            const updatedAlarmEvents = _.filter(state.AH.Sts.Alarm.AlarmEvents, ue => {
                const eventMoment = moment(new Date(ue.GatewayTimestamp));
                return latestMoment.diff(eventMoment, 'minutes') < timeDiff;
            });

            assigned.AH.Sts.Alarm.AlarmEvents = [...updatedAlarmEvents, item];

            newState = {
                ...assigned,
                didGenerateDiagrams: false,
            };
            break;
        }
        case configActionsTypes.UPDATE_USER_EVENT_MQTT: {
            const event = (action.payload as apiEvents);
            let updatedUserEvents = getUpdatedEventList(state.UserEvents, timeDiff);

            newState = {
                ...state,
                UserEvents: [
                    ...updatedUserEvents,
                    event
                ]
            }
            break;
        }

        case configActionsTypes.UPDATE_PROCESS_EVENT_MQTT: {
            const event = (action.payload as apiEvents);
            const itemPath = [
                ...state.nodeIdToStore[event.ParentNodeId]
            ]
            itemPath.push(event.NodeId.split('.').pop())
            let assigned: UnitStructure = assignDeep(state, itemPath, event.Value)

            let updatedProcessEvents = getUpdatedEventList(state.ProcessEvents, timeDiff);

            newState = {
                ...assigned,
                didGenerateDiagrams: false,
                ProcessEvents: [
                    ...updatedProcessEvents,
                    event
                ]
            }
            break;
        }

        case configActionsTypes.UPDATE_SEQUENCE_EVENT_MQTT: {
            const event = (action.payload as apiEvents);
            const itemPath = [
                ...state.nodeIdToStore[event.ParentNodeId]
            ]
            itemPath.push(event.NodeId.split('.').pop())
            let assigned: UnitStructure = assignDeep(state, itemPath, event.Value)

            let updatedSequenceEvents = getUpdatedEventList(state.ProcessEvents, timeDiff);

            newState = {
                ...assigned,
                didGenerateDiagrams: false,
                ProcessEvents: [
                    ...updatedSequenceEvents,
                    event
                ]
            }
            break;
        }

        case configActionsTypes.SET_ERROR: {
            newState = {
                ...state,
                error: action.payload as IError
            }
            break;
        }

        default: {
            newState = state
        }
    }
    return newState
}
