import {UnitStructure} from '../../models/PLC/UnitStructure'
import {Themes} from '../../views/SettingsView'
import {apiDatas} from '../../models/Api/apiDatas'
import {apiOpcValue} from "../../models/Api/apiOpcValue";
import {apiAlarms} from "../../models/Api/apiAlarms";
import {apiComments} from "../../models/Api/apiComments";
import { IError, ISelectedEvents } from '../reducers/configReducer'

export enum configActionsTypes {
    HYDRATE = '[CONFIG] HYDRATE',
    BUILD_INDEXES = '[CONFIG] BUILD_INDEXES',
    SET_DID_GENERATE_DIAGRAMS = '[CONFIG] SET_DID_GENERATE_DIAGRAMS',
    SET_DID_PAUSE_LIVE = '[CONFIG] SET_DID_PAUSE_LIVE',
    SET_THEME = '[CONFIG] SET_THEME',
    UPDATE_DATA_MQTT = '[CONFIG] UPDATE_DATA_MQTT',
    UPDATE_ALARM_MQTT = '[CONFIG] UPDATE_ALARM_MQTT',
    UPDATE_USER_EVENT_MQTT = '[CONFIG] UPDATE_USER_EVENT_MQTT',
    UPDATE_PROCESS_EVENT_MQTT = '[CONFIG] UPDATE_PROCESS_EVENT_MQTT',
    UPDATE_SEQUENCE_EVENT_MQTT = '[CONFIG] UPDATE_SEQUENCE_EVENT_MQTT',
    UPDATE_DEFAULT_MQTT = '[CONFIG] UPDATE_DEFAULT_MQTT',
    UPDATE_DEFAULT = '[CONFIG] UPDATE_DEFAULT',
    SET_LANGUAGE = '[CONFIG] SET_LANGUAGE',
    UPDATE_TRANSLATIONS = '[CONFIG] UPDATE_TRANSLATIONS',
    SWITCH_SENSOR_MASKING = '[CONFIG] SWITCH_SENSOR_MASKING',
    ADD_COMMENT = '[CONFIG] ADD_COMMENT',
    SWITCH_EVENTS_MASKING = '[CONFIG] SWITCH_EVENTS_MASKING',
    UPDATE_DATA_MQTT_ARRAY = '[CONFIG] UPDATE_DATA_MQTT_ARRAY',
    SET_ERROR = '[CONFIG] SET_ERROR'
}

export const configActions = {
    hydrate: (config: UnitStructure) => {
        return {
            type: configActionsTypes.HYDRATE,
            payload: config
        }
    },
    buildIndexes: () => {
        return {
            type: configActionsTypes.BUILD_INDEXES,
            payload: {}
        }
    },
    setDidGenerateDiagrams: (status: boolean) => {
        return {
            type: configActionsTypes.SET_DID_GENERATE_DIAGRAMS,
            payload: status
        }
    },
    setDidPauseLive: (status: boolean) => {
        return {
            type: configActionsTypes.SET_DID_PAUSE_LIVE,
            payload: status
        }
    },
    setTheme: (theme: Themes) => {
        return {
            type: configActionsTypes.SET_THEME,
            payload: theme
        }
    },
    updateDataMQTT: (data: apiDatas) => {
        return {
            type: configActionsTypes.UPDATE_DATA_MQTT,
            payload: data
        }
    },
    updateAlarmMQTT: (data: apiAlarms) => {
        return {
            type: configActionsTypes.UPDATE_ALARM_MQTT,
            payload: data
        }
    },
    updateUserEventMQTT: (data: apiDatas) => {
        return {
            type: configActionsTypes.UPDATE_USER_EVENT_MQTT,
            payload: data
        }
    },
    updateProcessEventMQTT: (data: apiDatas) => {
        return {
            type: configActionsTypes.UPDATE_PROCESS_EVENT_MQTT,
            payload: data
        }
    },
    updateSequenceEventMQTT: (data: apiDatas) => {
        return {
            type: configActionsTypes.UPDATE_SEQUENCE_EVENT_MQTT,
            payload: data
        }
    },
    updateDefaultMQTT: (data: apiDatas) => {
        return {
            type: configActionsTypes.UPDATE_DEFAULT_MQTT,
            payload: data
        }
    },
    updateDefault: (data: apiOpcValue) => {
        return {
            type: configActionsTypes.UPDATE_DEFAULT,
            payload: data
        }
    },
    setLanguage: (lang: string) => {
        return {
            type: configActionsTypes.SET_LANGUAGE,
            payload: lang
        }
    },
    updateTranslations: (translations: Record<string, string>) => {
        return {
            type: configActionsTypes.UPDATE_TRANSLATIONS,
            payload: translations
        }
    },
    switchSensorMasking: (sensor: string) => {
        return {
            type: configActionsTypes.SWITCH_SENSOR_MASKING,
            payload: sensor
        }
    },
    setSelectedEvents: (selectedEvents: ISelectedEvents) => {
        return {
            type: configActionsTypes.SWITCH_EVENTS_MASKING,
            payload: selectedEvents
        }
    },
    addComment: (data: apiComments) => {
        return {
            type: configActionsTypes.ADD_COMMENT,
            payload: data
        }
    },
    updateDataMQTTArray: (data: apiDatas[]) => {
        return {
            type: configActionsTypes.UPDATE_DATA_MQTT_ARRAY,
            payload: data
        }
    },
    setError: (error: IError) => {
        return {
            type: configActionsTypes.SET_ERROR,
            payload: error
        }
    },
}
