import React from 'react'
import ThreeWayValveNodeWidget from './ThreeWayValveArtifactWidget'
import { ThreeWayValveArtifactModel } from './ThreeWayValveArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class ThreeWayValveArtifactFactory extends AbstractReactFactory<ThreeWayValveArtifactModel, DiagramEngine> {
  constructor () {
    super('ThreeWayValve')
  }

  generateReactWidget (event: any): JSX.Element {
    return <ThreeWayValveNodeWidget engine={this.engine} size={80} node={event.model} />
  }

  generateModel (event: any) {
    return new ThreeWayValveArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
