/**
 * Visual builder for Beaker Artifact
 */
export default class BeakerVisual {
  /**
   * Builds up svg
   * @param outlineColor Outline color to apply depending on focus/not
   * @param pathId Path to display (may be different when active)
   * @returns SVG as a string
   */
  static build (outlineColor: string, pathId: boolean = false): string {
    return `<g>
        <rect x="0" y="43" width="33" height="4" style="fill:rgb(73,73,83);"/>
        <path d="M3,37L30,37L33,43L0,43L3,37Z" style="fill:rgb(118,118,128);fill-rule:nonzero;"/>
        <path d="M31.974,3.727C31.974,3.722 31.974,3.718 31.974,3.713C31.974,1.662 25.041,0 16.487,0C7.934,0 1,1.662 1,3.713C1,3.718 1,3.722 1,3.727L1,3.727L1,37.273C1,39.331 7.94,41 16.5,41C25.06,41 32,39.331 32,37.273L32,3.727L31.974,3.727Z" style="fill:rgb(237,237,243);"/>
        <path d="M29.093,16L4,16L4,33.85C4,35.59 9.617,37 16.546,37C23.475,37 29.093,35.59 29.093,33.85L29.093,16Z" style="fill:rgb(15,105,175);"/>
        <ellipse cx="16.5" cy="16" rx="12.5" ry="3" style="fill:rgb(111,165,207);"/>
        <ellipse cx="16.5" cy="6" rx="12.5" ry="3" style="fill:rgb(213,213,217);fill-opacity:0.7;"/>
        <circle cx="23.666" cy="25.432" r="1.472" style="fill:white;"/>
        <circle cx="20.428" cy="30.436" r="1.766" style="fill:white;"/>
    </g>`
  }
}
