import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import logger from 'redux-logger'
import { unstable_batchedUpdates } from 'react-dom';
import { batchedSubscribe } from 'redux-batched-subscribe';
import _ from 'lodash'

const middleware: any = [
  thunk
]
const isEnabled: boolean = false
if (process.env.NODE_ENV !== 'production' && isEnabled) {
  middleware.push(logger)
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const debounceNotify = _.debounce(notify => notify(), 500, {maxWait:800});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(...middleware)
    ,batchedSubscribe(debounceNotify)
    //,batchedSubscribe(unstable_batchedUpdates)
))

export default store