import React from 'react'
import {PortWidget} from '@projectstorm/react-diagrams'

import {ThreeWayValveArtifactModel} from './ThreeWayValveArtifactModel'
import {
    BaseArtifactWidgetProps,
    BaseArtifactWidgetState,
    BaseArtifactWidget, NodeLabel
} from '../../BaseArtifact/BaseArtifactWidget'

import ThreeWayValveVisual from './ThreeWayValveVisual'

import * as DefaultPort from '../../Port'
import { IConfigReducer } from '../../../../store/reducers/configReducer'
import { connect } from 'react-redux'
import * as StoreTypes from 'StoreTypes'
import _ from "lodash";
import CustomLabelWidget from "../../BaseArtifact/CustomLabelWidget";

export interface ThreeWayValveNodeWidgetProps extends BaseArtifactWidgetProps<ThreeWayValveArtifactModel> {
    size: number
    config: IConfigReducer
}

export interface ThreeWayValveNodeWidgetState extends BaseArtifactWidgetState<ThreeWayValveArtifactModel> {
}

class ThreeWayValveArtifactWidget extends BaseArtifactWidget<ThreeWayValveNodeWidgetProps, ThreeWayValveNodeWidgetState> {
    constructor(props: ThreeWayValveNodeWidgetProps) {
        super(props)
        this.state = {controlPanel: true, selected: 0, show: false, virtualSelected: 0}
    }

    calculateLabelsPosition = () => {
        if (this.labelRef.current) {
            const labelDimensions = {
                width: this.labelRef.current.offsetWidth,
                height: this.labelRef.current.offsetHeight
            }

            let labelCoordinates = {
                x: this.props.size - 10,
                y: this.props.size / 2 - 8
            }

            if(this.props.node.code.startsWith('90-')) {
                labelCoordinates = {
                    x: this.props.size / 2 - labelDimensions.width / 2 + 8,
                    y: -20
                }
            }

            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`

        }
    };

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

    render() {
        return (
            <div
                className='ThreeWayValveArtifact-node'
                style={{
                    position: 'relative',
                    width: this.props.size,
                    height: this.props.size,

                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                    super.showPopup(e)
                }}
            >
                <div style={{transform: this.props.node.code.startsWith('90-') ? 'rotate(-90deg)' : 'none'}}>
                    <svg
                        width={this.props.size}
                        height={this.props.size}
                        viewBox='0 0 36 42'
                        preserveAspectRatio='xMidYMid'
                        dangerouslySetInnerHTML={{
                            __html: ThreeWayValveVisual.build(BaseArtifactWidget.getConditionsStatus(this.props.node.getConditions(), this.props.config))
                        }}
                    />
                    <PortWidget
                        port={this.props.node.getPort('top')} engine={this.props.engine}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: this.props.size / 2 + 2,
                            // backgroundColor:'#fa0707'
                        }}
                    >
                        <DefaultPort.Port/>
                    </PortWidget>
                    <PortWidget
                        port={this.props.node.getPort('left')} engine={this.props.engine}
                        style={{
                            position: 'absolute',
                            top: this.props.size / 2 - 8,
                            left: 0,
                            // backgroundColor:'#35f800'
                        }}
                    >
                        <DefaultPort.Port/>
                    </PortWidget>
                    <PortWidget
                        port={this.props.node.getPort('bottom')} engine={this.props.engine}
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: this.props.size / 2 + 2,
                            // backgroundColor:'#fcef05'
                        }}
                    >
                        <DefaultPort.Port/>
                    </PortWidget>
                </div>
                {super.render()}
            </div>
        )
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
  config: state.config
})

export default connect(mapStateToProps)(ThreeWayValveArtifactWidget)
