/**
 * Dependencies
 */
import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import routes from '../routes'

import {Navigation} from '@liquid-design/liquid-design-react'
import {useIntl} from 'react-intl'
import useMountEffect from '../utils/useMountEffect'

/**
 * Content
 */
const CustomSideNav = () => {
    const intl = useIntl()
    const [activeTab, setActiveTab] = useState(routes.findIndex((r) => r.path === window.location.pathname))
    const history = useHistory()

    useMountEffect(() => {
        history.listen((location) => {
            setActiveTab(routes.findIndex((r) => r.path === location.pathname))
        })
    })

    return (
        <div style={{overflow:'hidden', height:'100%', backgroundColor:'#F3F3F7'}}>
            <Navigation
                title='' // Set to null to prevent title rendering
                iconUrl='/dev/null' // Set to random thing to prevent icon rendering
                activeTabIndex={activeTab}
                tabs={routes.map((route, i) => {
                    return {
                        title: intl.formatMessage({id: route.title}),
                        iconName: route.icon,
                        onClick: () => {
                            history.push(route.path)
                            setActiveTab(i)
                        },
                        active: !!i
                    }
                })}
            />
        </div>
    )
}

export default CustomSideNav
