import React, {useEffect, useState} from 'react'
import * as StoreTypes from 'StoreTypes'
import {connect, useDispatch, useSelector} from 'react-redux'

import createEngine from '@projectstorm/react-diagrams'
import {CanvasWidget} from '@projectstorm/react-canvas-core'

import PIDDiagramModel from '../components/Diagram/PIDDiagramModel'
import LoadingScreen from '../components/LoadingScreen'
import {Button, Modal, Glyph} from '@liquid-design/liquid-design-react'

import {
    diagramArtifacts,
    EArtifacts
} from '../config/diagram.config'
import {configActions} from '../store/actions'
import {ModuleBase} from '../models/PLC/ModuleBase'
import {IConfigReducer} from '../store/reducers/configReducer'
import {BaseArtifactModel} from '../components/Diagram/BaseArtifact/BaseArtifactModel'
import {Lab150ConfigPanel} from '../components/Diagram/ConfigPanel/Lab150ConfigPanel'
import {Lab800ConfigPanel} from '../components/Diagram/ConfigPanel/Lab800ConfigPanel'
import {Lab6000ConfigPanel} from '../components/Diagram/ConfigPanel/Lab6000ConfigPanel'
import {useIntl} from "react-intl";
import {withTheme} from "styled-components";
import {Lab6000Generation} from '../components/Diagram/Generation/Lab6000Generation'
import {Lab800Generation} from '../components/Diagram/Generation/Lab800Generation'
import {Lab150Generation} from '../components/Diagram/Generation/Lab150Generation'
import useMountEffect from '../utils/useMountEffect'
import {Rectangle} from "@projectstorm/geometry";

const DiagramView = () => {
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const dispatch = useDispatch()
    const intl = useIntl()
    const [engine] = useState(createEngine({
        registerDefaultZoomCanvasAction: false
    }))
    const [model] = useState<PIDDiagramModel>(new PIDDiagramModel())
    const [isClearModalOpen, setIsClearModalOpen] = useState(false)
    const [isConfigModalOpen, setIsConfigModalOpen] = useState(false)

    useMountEffect(() => {
        window.addEventListener('resize', () => {
            zoomToFit()
        })
        return function cleanup() {
            window.removeEventListener('resize', () => zoomToFit())
        }
    })

    /**
     * Executed on mount only
     */
    useEffect(() => {
        model.getNodes().forEach((node) => model.removeNode(node))
        model.getLinks().forEach((link) => model.removeLink(link))
        const equipmentModules: ModuleBase[] = []
        Object.keys(config.Instances.EquipmentModules).forEach(
            (EMName) => config.Instances.EquipmentModules[EMName].Sts_bEnable && equipmentModules.push(config.Instances.EquipmentModules[EMName])
        )
        model.addAll(...generateDiagramArtifacts(equipmentModules))
        dispatch(configActions.setDidGenerateDiagrams(true))
        setTimeout(() => zoomToFit(), 200)
    }, [config.Unit.Set])

    /**
     * Register artifacts factories
     */
    function registerFactories() {
        Object.keys(diagramArtifacts).forEach((artifact) => {
            if (artifact.includes('Link')) {
                engine
                    .getLinkFactories()
                    .registerFactory(diagramArtifacts[artifact as EArtifacts].factory)
            } else {
                engine
                    .getNodeFactories()
                    .registerFactory(diagramArtifacts[artifact as EArtifacts].factory)
            }
        })
    }

    /**
     * Generate diagram artifacts and update the store
     */
    function generateDiagramArtifacts(equipmentModules: ModuleBase[]): BaseArtifactModel[] {
        let labConfig: any = undefined
        switch (config.Unit.Set.iConfiguredBTPSize) {
            case 1:
                labConfig = new Lab150Generation(config, intl);
                break;
            case 2:
                labConfig = new Lab800Generation(config, intl);
                break;
            case 3:
                labConfig = new Lab6000Generation(config, intl);
                break;
        }
        const artifacts: BaseArtifactModel[] = []
        equipmentModules.forEach((EM) => {
            if (EM.Sts_bEnable) {
                /**
                 * Cut the numbers after EM name
                 * @example TCU002 -> TCU
                 */
                const EMName = EM.Cfg_sTag.slice(0, EM.Cfg_sTag.length - 3)
                switch (EMName) {
                    case 'TANK':
                        artifacts.push(...labConfig.generateTankArtifacts())
                        break
                    case 'TCU':
                        artifacts.push(...labConfig.generateTCUArtifacts())
                        break
                    case 'FEED':
                        artifacts.push(...labConfig.generateFeedArtifacts())
                        break
                    case 'MEMBRANE':
                        artifacts.push(...labConfig.generateMembraneArtifacts())
                        break
                    case 'RETENTATE':
                        artifacts.push(...labConfig.generateRetentateArtifacts())
                        break
                    case 'FILTRATE':
                        artifacts.push(...labConfig.generateFiltrateArtifacts())
                        break
                    default:
                        throw new Error(`No such ${EMName} EquipmentModule existing.`)
                }
            }
        })

        /**
         * Generate EM links
         */
        const mappedArtifacts = labConfig.getMappedArtifacts(artifacts)

        artifacts.push(...labConfig.generateLinks(config, mappedArtifacts))

        return artifacts
    }

    /**
     * Resize diagram on zoom/un-zoom
     * TODO: Try to make the up sizing animation more fluid
     */
    function zoomToFit() {
        const canvas = engine.getCanvas()
        if (canvas) {
            const model = engine.getModel()

            let nodesRect; // nodes bounding rectangle
            let allNodes = model.getNodes()

            // get nodes bounding box with margin
            nodesRect = engine.getBoundingNodesRect(allNodes, undefined);

            if (nodesRect) {

                nodesRect =  new Rectangle(
                    nodesRect.getTopLeft().x - 50,
                    nodesRect.getTopLeft().y,
                    nodesRect.getWidth() + 170,
                    nodesRect.getHeight()
                );

                // there is something we should zoom on
                let canvasRect = canvas.getBoundingClientRect();
                let canvasTopLeftPoint = {
                    x: canvasRect.left,
                    y: canvasRect.top
                };
                let nodeLayerTopLeftPoint = {
                    x: canvasTopLeftPoint.x + engine.getModel().getOffsetX(),
                    y: canvasTopLeftPoint.y + engine.getModel().getOffsetY()
                };

                const xFactor = canvas.clientWidth / nodesRect.getWidth();
                const yFactor = canvas.clientHeight / nodesRect.getHeight();
                const zoomFactor = xFactor < yFactor ? xFactor : yFactor;

                model.setZoomLevel(zoomFactor * 100);

                let nodesRectTopLeftPoint = {
                    x: nodeLayerTopLeftPoint.x + nodesRect.getTopLeft().x * zoomFactor,
                    y: nodeLayerTopLeftPoint.y + nodesRect.getTopLeft().y * zoomFactor
                };

                model.setOffset(
                    model.getOffsetX() + canvasTopLeftPoint.x - nodesRectTopLeftPoint.x,
                    model.getOffsetY() + canvasTopLeftPoint.y - nodesRectTopLeftPoint.y
                );
                engine.repaintCanvas();
            }
        }
    }

    function openSettings() {
        if (config.Recipe.OperationCtrl.iOperationId === 0) {
            setIsConfigModalOpen(true)
        } else {
            setIsClearModalOpen(true)
        }
    }

    function clearSequenceAndOpenSettings() {
        fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/PropertyValue/Set?pushToPlc=true`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                NodeId: config.Recipe.OperationCtrl.NodeId + '.bClearRecipe',
                Value: true
            })
        })
            .then((resp) => {
                if (resp.ok) {
                    setIsClearModalOpen(false)
                    setIsConfigModalOpen(true)
                }
            })
            .catch(console.error)
    }

    registerFactories()

    // Locks model background (Components can still move)
    model.setLocked(true);

    // Adds model to engine
    engine.setModel(model)

    const btpSize = config.Unit.Set.iConfiguredBTPSize

    let controlPanel

    switch (btpSize) {
        case 1:
            controlPanel = <Lab150ConfigPanel/>
            break;
        case 2:
            controlPanel = <Lab800ConfigPanel/>
            break;
        case 3:
            controlPanel = <Lab6000ConfigPanel/>
    }

    return engine ? (
        <>
            <Modal
                label={intl.formatMessage({id: "label.ProcessConfiguration"})}
                open={isConfigModalOpen}
                onClose={() => setIsConfigModalOpen(false)}
            >
                <div>
                    {controlPanel}
                </div>
            </Modal>
            <Modal
                label={intl.formatMessage({id: 'label.ClearSequence'})}
                open={isClearModalOpen}
                onClose={() => setIsClearModalOpen(false)}
                className='text-center'
            >
                <Button style={{marginRight: '10px'}} size='big' icon='arrowCheck'
                        onClick={() => clearSequenceAndOpenSettings()}>Clear current sequence</Button>
                <Button size='big' appearance='secondary' icon='close'
                        onClick={() => setIsClearModalOpen(false)}>Cancel</Button>
            </Modal>
            <div className='diagramContainer'>
                <div style={{position: 'absolute', top: '60px', right: '145px', zIndex: 2}}>
                    <Button size='big' appearance='ghost' disabled={config.Recipe.OperationCtrl.iState !== 0 && config.Recipe.OperationCtrl.iState !== 3} onClick={() => openSettings()}><Glyph  size={40} name="settings"/></Button>
                </div>
                <CanvasWidget className='base-canvas' engine={engine}/>
            </div>
        </>
    ) : <LoadingScreen percent={50} applicationName={config.applicationName}/>
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(
    React.memo(withTheme(DiagramView), (prevProps, nextProps) => {
        return (
            prevProps.config.theme === nextProps.config.theme &&
            prevProps.config.APIBaseUrl === nextProps.config.APIBaseUrl &&
            prevProps.config.NodeId === nextProps.config.NodeId &&
            prevProps.config.Instances === nextProps.config.Instances &&
            prevProps.config.tagToF_ === nextProps.config.tagToF_
        )
    })
)