import * as _ from 'lodash'
import {NodeModel, NodeModelGenerics, DiagramEngine, DefaultLabelModel} from '@projectstorm/react-diagrams'
import {DeserializeEvent} from '@projectstorm/react-canvas-core'
import { IConditions } from '../CustomLinks/PipeLink/PipeLinkModel'

export class BaseArtifactModel<G extends NodeModelGenerics = NodeModelGenerics> extends NodeModel<G> {
    public code: string
    public displayName: string
    public nodeId: string
    orientation: string = 'horizontal'
    protected conditions: IConditions[] = []
    protected labels: DefaultLabelModel[]

    constructor(code: string, displayName: string, nodeId: string, orientation: string, options: G['OPTIONS'], conditions: IConditions[] = []) {
        super(options)

        this.labels = []
        this.displayName = displayName
        this.nodeId = nodeId
        this.code = code
        this.orientation = orientation;
        this.conditions = conditions;
    }

    serialize() {
        return {
            ...super.serialize(),
            labels: _.map(this.labels, label => {
                return label.serialize()
            }),
            displayName: this.displayName,
            nodeId: this.nodeId,
            code: this.code
        }
    }

    deserialize(event: DeserializeEvent<this>) {
        super.deserialize(event)

        // deserialize labels
        _.forEach(event.data.labels || [], (label: any) => {
            const labelOb = (event.engine as DiagramEngine).getFactoryForLabel(label.type).generateModel({})
            labelOb.deserialize({
                ...event,
                data: label
            })
            this.labels.push(labelOb as DefaultLabelModel)
        })

        this.code = event.data.code
        this.displayName = event.data.displayName
        this.nodeId = event.data.nodeId
    }

    addLabel(sensorId: string, label: string, extras: any = null) {
        const labelOb = new DefaultLabelModel({id: sensorId, extras})
        labelOb.setLabel(label)

        // labelOb.setParent(this);
        this.labels.push(labelOb)
    }

    updateLabel(sensorId: string, label: string) {
        const labelOb = this.labels.find(item => item.getID() === sensorId)

        if (labelOb !== undefined) {
            labelOb.setLabel(label)
        }
    }

    getLabels() {
        return this.labels
    }

    getConditions() {
        return this.conditions
    }
}
