/*
*Handles response form fetch API, with proper error handling
*  */
export const handleResponse = (response: Response) => {

    return response.json()
        .then((json) => {
            if (!response.ok) {
                const error = Object.assign({}, json, {
                    status: response.status
                });

                return Promise.reject(error);
            }

            return Promise.resolve(json);
        });
}