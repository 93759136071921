import {UnitStructure} from '../models/PLC/UnitStructure'
import _ from 'lodash/fp'
import dotPropImmutable from 'dot-prop-immutable'

export const assignDeep = (unit: UnitStructure, path: string[], value: any): UnitStructure => {

    //for sub array, sytnx is diffeernt than the usual one: tab[index] becomes tab.index
    for (let i = 0; i < path.length; i++) {
        path[i] = path[i].replace("[", ".").replace("]", "")
    }

    return dotPropImmutable.set(unit, path.join('.'), value.Value)
}