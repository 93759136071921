import PipeLinkModel from './PipeLinkModel'
import { PipeLinkWidget } from './PipeLinkWidget'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'
import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'
import React from 'react'

export const Keyframes = keyframes`
  from {
    stroke-dashoffset: 24;
  }
  to {
    stroke-dashoffset: 0;
  }
`
const selected = css`
  stroke-dasharray: 10, 2;
  animation: ${Keyframes} 1s linear infinite;
`

export const Path = styled.path<{ flowOn: boolean }>`
  ${p => p.flowOn && selected};
  fill: none;
  pointer-events: all;
`

export class PipeLinkFactory<Link extends PipeLinkModel = PipeLinkModel> extends AbstractReactFactory<Link, DiagramEngine> {
  constructor (type = 'PipeLink') {
    super(type)
  }

  generateReactWidget (event: any): JSX.Element {
    return <PipeLinkWidget link={event.model} diagramEngine={this.engine} />
  }

  generateModel (event: any): Link {
    return new PipeLinkModel('defaultCode', 'defaultName') as Link
  }

  generateLinkSegment (model: Link, selected: boolean, path: string) {
    return (
      <>
        <Path
          flowOn={model.getFlow()}
          stroke="#D5D5D9"
          strokeWidth="25"
          d={path}
        />
        <Path
          flowOn={model.getFlow()}
          stroke="white"
          strokeWidth="18"
          d={path}
        />
        <Path
          flowOn={model.getFlow()}
          stroke={model.getOptions().color}
          strokeWidth="11"
          d={path}
        />
        </>
    )
  }
}
