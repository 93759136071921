import {IClientOptions} from 'mqtt'

const config: IClientOptions = {
    keepalive: 60,
    clientId: 'BTP_WebApplication'+ Math.random().toString(16).substr(2, 8),
    protocolId: 'MQTT',
    protocolVersion: 4,
    clean: true,
    reconnectPeriod: 1000,
    connectTimeout: 30 * 1000,
    will: {
        topic: 'LTW',
        payload: 'BTP_WebApplication:disconnected',
        qos: 0,
        retain: false
    },
    rejectUnauthorized: false
}

export default config
