import {Col, Container, Row} from "reactstrap";
import React, {useState} from "react";
import {IntlShape, useIntl} from "react-intl";
import {Button, Glyph} from '@liquid-design/liquid-design-react'
import {useDispatch, useSelector} from "react-redux";
import {IConfigReducer} from "../../../store/reducers/configReducer";
import StoreTypes from "StoreTypes";
import _ from "lodash";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {AnalogueSensorWired} from "../../../models/PLC/AnalogueSensorWired";
import {CreateOpcValue, IsNullOrUndefined, setPlcProperty} from "../../../utils/plcUtils";
import {Label} from "@progress/kendo-react-labels";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Card, CardBody, CardTitle, Stepper} from "@progress/kendo-react-layout";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {configActions} from "../../../store/actions";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import {getDigitFormat, getDigitNumber, getSensorDeadBand} from "../ControlPanels/settingsHelpers";

export interface IWiredSensorCalibrationProps {
    nodeId: string
}

/**
 * Calibration statuses (Sts_wSensorCalibrationStatus is a set of bits)
 */
enum CalibrationStatus {

    WaitingForCalibration = 1 << 0,          // 0001
    FirstPointSuccessful = 1 << 1,          // 0010 - 8
    Performing = 1 << 2,                    // 0100 -
    SecondPointSuccessful = 1 << 3,         // 1000
    CalibrationSuccessful = 1 << 4,         // 1 0000
    TareModeOn = 1 << 5,                    // 10 0000
    CalibrationInProgress = 1 << 6,         //100 0000
    UnstableSensorSignal = 1 << 7,          //1000 0000
    StableSensorSignal = 1 << 8,            //1 0000 0000
    LastCalibrationSuccessful = 1 << 9     //10 0000 0000
}

/**
 * Get string associated with the current Status
 * @param wordStatus
 */
function getStatusText(wordStatus: number) {

    if ((wordStatus & CalibrationStatus.FirstPointSuccessful) === CalibrationStatus.FirstPointSuccessful) {
        return 'label.' + CalibrationStatus[CalibrationStatus.FirstPointSuccessful]
    }

    if ((wordStatus & CalibrationStatus.CalibrationSuccessful) === CalibrationStatus.CalibrationSuccessful) {
        return 'label.' + CalibrationStatus[CalibrationStatus.CalibrationSuccessful]
    }

    if ((wordStatus & CalibrationStatus.StableSensorSignal) === CalibrationStatus.StableSensorSignal) {
        return 'label.' + CalibrationStatus[CalibrationStatus.StableSensorSignal]
    }

    if ((wordStatus & CalibrationStatus.UnstableSensorSignal) === CalibrationStatus.UnstableSensorSignal) {
        return 'label.' + CalibrationStatus[CalibrationStatus.UnstableSensorSignal]
    }

    if ((wordStatus & CalibrationStatus.CalibrationInProgress) === CalibrationStatus.CalibrationInProgress) {
        return 'label.' + CalibrationStatus[CalibrationStatus.CalibrationInProgress]
    }

    if ((wordStatus & CalibrationStatus.Performing) === CalibrationStatus.Performing) {
        return 'label.' + CalibrationStatus[CalibrationStatus.Performing]
    }

    return 'label.' + CalibrationStatus[CalibrationStatus.WaitingForCalibration];
}

function IsStatusActivated(sensor: AnalogueSensorWired, statusToTest: CalibrationStatus) {
    if (IsNullOrUndefined(sensor))
        return false;

    if ((sensor.Sts_wSensorCalibrationStatus & statusToTest) === statusToTest)
        return true;

    return false;
}

function getStatusColor(wordStatus: number) {

    if ((wordStatus & CalibrationStatus.FirstPointSuccessful) === CalibrationStatus.FirstPointSuccessful) {
        return '#52c626'
    }

    if ((wordStatus & CalibrationStatus.CalibrationSuccessful) === CalibrationStatus.CalibrationSuccessful) {
        return '#52c626'
    }

    if ((wordStatus & CalibrationStatus.StableSensorSignal) === CalibrationStatus.StableSensorSignal) {
        return '#0567b7'
    }

    if ((wordStatus & CalibrationStatus.UnstableSensorSignal) === CalibrationStatus.UnstableSensorSignal) {
        return '#ec3030'
    }

    if ((wordStatus & CalibrationStatus.CalibrationInProgress) === CalibrationStatus.CalibrationInProgress) {
        return '#0567b7'
    }

    if ((wordStatus & CalibrationStatus.Performing) === CalibrationStatus.Performing) {
        return '#0567b7'
    }

    return '#0567b7'
}

/**
 * Render calibration status Header
 * @param intl
 * @param sensor
 */
function renderCalibrationStatus(intl: IntlShape, sensor: AnalogueSensorWired) {
    return <>
        <Row>
            <Col>
                <div style={{margin: '0 auto', width: 'fit-content', paddingBottom: '15px'}}>
                    <Glyph name="dot" color={getStatusColor(sensor.Sts_wSensorCalibrationStatus)}/>
                    <span style={{
                        fontSize: '19px',
                        fontWeight: 'bold',
                        verticalAlign: 'text-bottom'
                    }}>&nbsp;{intl.formatMessage({id: getStatusText(sensor.Sts_wSensorCalibrationStatus)})}</span>
                </div>
            </Col>
        </Row>
    </>
}

/**
 * Render stability parameters fields
 * @param config
 * @param dispatch
 * @param intl
 * @param sensor
 * @param disableFactors
 * @param calibrationInProgress
 */
function renderStabilityFactorAndDuration(config: IConfigReducer, dispatch: any, intl: IntlShape, sensor: AnalogueSensorWired, disableFactors: boolean, calibrationInProgress: boolean) {
    return <>
        <Row style={{paddingTop: '5px'}}>
            <Col>
                <Label editorId='StabilityFactor'>{intl.formatMessage({id: 'label.CalibrationStabilityFactor'})}</Label>
                <br/>
                <NumericTextBox
                    id='StabilityFactor'
                    format={"p" + getDigitNumber(sensor.Set_rDeviationPercentage, 0)}
                    disabled={disableFactors || calibrationInProgress}
                    min={0}
                    max={1}
                    step={0.1}
                    spinners={false}
                    value={sensor.Set_rDeviationPercentage / 100}
                    onChange={(e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            let opcValue = CreateOpcValue(sensor.NodeId + '.Set_rDeviationPercentage', e.value * 100);
                            setPlcProperty(config, opcValue, {
                                userLabel: 'label.event.DeviationPercentageSet',
                                unit: '%',
                                eventTriggering: UserEventTriggering.BeforeAction
                            }, dispatch);
                        }
                    }}
                />
            </Col>
            <Col>
                <Label
                    editorId='StabilityDuration'>{intl.formatMessage({id: 'label.CalibrationStabilityDuration'})}</Label>
                <br/>
                <NumericTextBox
                    id='StabilityDuration'
                    format={"0" + getDigitFormat(sensor.Set_iSamplingDuration, 0) + " s"}
                    disabled={disableFactors || calibrationInProgress}
                    min={0}
                    max={100}
                    step={1}
                    spinners={false}
                    value={sensor.Set_iSamplingDuration}
                    onChange={(e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            let opcValue = CreateOpcValue(sensor.NodeId + '.Set_iSamplingDuration', e.value);
                            setPlcProperty(config, opcValue, {
                                userLabel: 'label.event.SamplingDurationSet',
                                unit: 's',
                                eventTriggering: UserEventTriggering.BeforeAction
                            }, dispatch);
                        }
                    }}
                />
            </Col>
        </Row>
    </>
}

/**
 * Render Weight fields and calibration buttons
 * @param config
 * @param dispatch
 * @param intl
 * @param sensor
 * @param disableReferenceOne
 * @param showReferenceTwo
 * @param disableReferenceTwo
 * @param showCalibrateButton
 * @param calibrationInProgress
 */
function renderWeightReferences(config: IConfigReducer, dispatch: any, intl: IntlShape, sensor: AnalogueSensorWired, disableReferenceOne: boolean, showReferenceTwo: boolean, disableReferenceTwo: boolean, showCalibrateButton: boolean, showRedoCancelButton: boolean, calibrationInProgress: boolean) {
    const sensorUnit = sensor.Values[0].Cfg_suSensorUnit.Unit;
    let deadBand = getSensorDeadBand(sensor, sensor.Values[0].Name, sensor.Out_rCurrentValue)
    return <>
        <Row style={{paddingTop: '5px'}}>
            <Col>
                <Label
                    editorId='ReferencePointOne'>{intl.formatMessage({id: 'label.ReferenceValueOne'})}</Label>
                <br/>
                <NumericTextBox
                    id='ReferencePointOne'
                    format={"0" + getDigitFormat(sensor.Set_rReferenceSensor1, deadBand) + " " + sensorUnit}
                    disabled={disableReferenceOne || calibrationInProgress}
                    min={sensor.Cfg_rEGUMinimum}
                    max={sensor.Cfg_rEGUMaximum}
                    step={0.1}
                    spinners={false}
                    value={sensor.Set_rReferenceSensor1}
                    onChange={(e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            let opcValue = CreateOpcValue(sensor.NodeId + '.Set_rReferenceSensor1', e.value);
                            setPlcProperty(config, opcValue, {
                                userLabel: 'label.event.WeightRefValueOne',
                                unit: sensorUnit,
                                eventTriggering: UserEventTriggering.BeforeAction
                            }, dispatch);
                        }
                    }}
                />
            </Col>
            <Col>
                {showReferenceTwo && <>
                    <Label
                        editorId='ReferencePointTwo'>{intl.formatMessage({id: 'label.ReferenceValueTwo'})}</Label>
                    <br/>
                    <NumericTextBox
                        id='ReferencePointTwo'
                        format={"0" + getDigitFormat(sensor.Set_rReferenceSensor2, deadBand) + " " + sensorUnit}
                        disabled={disableReferenceTwo || calibrationInProgress}
                        min={sensor.Cfg_rEGUMinimum}
                        max={sensor.Cfg_rEGUMaximum}
                        step={0.1}
                        spinners={false}
                        value={sensor.Set_rReferenceSensor2}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {
                                let opcValue = CreateOpcValue(sensor.NodeId + '.Set_rReferenceSensor2', e.value);
                                setPlcProperty(config, opcValue, {
                                    userLabel: 'label.event.WeightRefValueTwo',
                                    unit: sensorUnit,
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }
                        }}
                    /></>}
            </Col>
        </Row>
        <Row style={{paddingTop: '5px'}}>
            <Col>
                {showCalibrateButton && <Button disabled={calibrationInProgress} onClick={() => {
                    //PLC should start calibration process
                    let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bCalibrateSensor', true);
                    setPlcProperty(config, opcValue, {
                        userLabel: 'label.event.CalibrationStarted', unit: '',
                        eventTriggering: UserEventTriggering.BeforeAction,
                        showValue: false
                    }, dispatch);

                    //Updating store for UI performance
                    let status = CreateOpcValue(sensor.NodeId + '.Sts_wSensorCalibrationStatus', CalibrationStatus.Performing);
                    dispatch(configActions.updateDefault(status))

                }}>{intl.formatMessage({id: 'label.Calibrate'})}</Button>}
                {showRedoCancelButton && <>
                    <Button disabled={calibrationInProgress} onClick={() => {
                        //PLC should restart calibration process
                        let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bRestartSensorCalibration', true);
                        setPlcProperty(config, opcValue, {
                            userLabel: 'label.event.CalibrationRestarted',
                            unit: '',
                            eventTriggering: UserEventTriggering.BeforeAction,
                            showValue: false
                        }, dispatch);

                        //Updating store for UI performance
                        let status = CreateOpcValue(sensor.NodeId + '.Sts_wSensorCalibrationStatus', CalibrationStatus.Performing);
                        dispatch(configActions.updateDefault(status))
                    }}>{intl.formatMessage({id: 'label.RedoStep'})}</Button>
                    <Button disabled={calibrationInProgress} onClick={() => {
                        let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bCancelSensorCalibration', true);
                        setPlcProperty(config, opcValue, {
                            userLabel: 'label.event.CalibrationCanceled',
                            unit: '',
                            eventTriggering: UserEventTriggering.BeforeAction,
                            showValue: false
                        }, dispatch);
                    }}>{intl.formatMessage({id: 'label.CancelCalibration'})}</Button>
                </>}
            </Col>
            <Col/>
        </Row>
    </>
}

/**
 * Render current calibration step content
 * @param config
 * @param dispatch
 * @param intl
 * @param sensor
 * @param stepId
 */
function renderCurrentStepContent(config: IConfigReducer, dispatch: any, intl: IntlShape, sensor: AnalogueSensorWired, stepId: number) {
    // eslint-disable-next-line
    let calibrationInProgress = (IsStatusActivated(sensor, CalibrationStatus.Performing) || IsStatusActivated(sensor, CalibrationStatus.CalibrationInProgress) && (!IsStatusActivated(sensor, CalibrationStatus.FirstPointSuccessful) && !IsStatusActivated(sensor, CalibrationStatus.SecondPointSuccessful)));

    switch (stepId) {
        case 0 :
            return (
                <Container>
                    <Row>
                        <Col>
                            <div style={{margin: '0 auto', width: 'fit-content', paddingTop: '15px'}}>
                                <Card style={{width: 400}}>
                                    <CardBody>
                                        <CardTitle>{intl.formatMessage({id: 'label.WiredCalibrationIntroductionTitle'})}</CardTitle>
                                        <p style={{whiteSpace: 'pre'}}>{intl.formatMessage({id: 'label.WiredCalibrationIntroduction'})}</p>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )
        case 1 :
            return (
                <Container>
                    {renderCalibrationStatus(intl, sensor)}
                    {renderStabilityFactorAndDuration(config, dispatch, intl, sensor, false, calibrationInProgress)}
                    {renderWeightReferences(config, dispatch, intl, sensor, false, false, true, !IsStatusActivated(sensor, CalibrationStatus.FirstPointSuccessful), IsStatusActivated(sensor, CalibrationStatus.FirstPointSuccessful), calibrationInProgress)}
                </Container>
            )
        case 2 :
            if (IsStatusActivated(sensor, CalibrationStatus.SecondPointSuccessful) && IsStatusActivated(sensor, CalibrationStatus.CalibrationSuccessful))
                return (
                    <Container>
                        {renderCalibrationStatus(intl, sensor)}
                        <Row>
                            <Col>
                                <div style={{margin: '0 auto', width: 'fit-content', paddingTop: '15px'}}>
                                    <Card style={{width: 400}}>
                                        <CardBody>
                                            <CardTitle>{intl.formatMessage({id: 'label.WiredCalibrationIntroductionTitle'})}</CardTitle>
                                            <p style={{whiteSpace: 'pre'}}>{intl.formatMessage({id: 'label.WiredCalibrationConclusion'})}</p>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )
            else
                return (
                    <Container>
                        {renderCalibrationStatus(intl, sensor)}
                        {renderStabilityFactorAndDuration(config, dispatch, intl, sensor, true, calibrationInProgress)}
                        {renderWeightReferences(config, dispatch, intl, sensor, true, true, IsStatusActivated(sensor, CalibrationStatus.SecondPointSuccessful), !IsStatusActivated(sensor, CalibrationStatus.SecondPointSuccessful), false, calibrationInProgress)}
                    </Container>
                )
    }
}

/**
 * WiredSensorCalibration component
 * @param nodeId
 * @constructor
 */
export default function WiredSensorCalibration({nodeId}: IWiredSensorCalibrationProps) {

    const intl = useIntl();
    const dispatch = useDispatch();
    const [dialogOpen, openDialog] = useState(false);
    const [currentStep, setCurrentStep] = React.useState(0);

    const calibrationSteps = [
        {label: intl.formatMessage({id: 'label.CalibrationStart'})},
        {label: intl.formatMessage({id: 'label.CalibrationStepOne'})},
        {label: intl.formatMessage({id: 'label.CalibrationStepTwo'})}
    ];

    //Getting information from store
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config);

    //Get reference to the sensor
    const sensor: AnalogueSensorWired = _.find(config.Instances.ControlModules, (item: ControlModuleBase) => {
        return item.NodeId === nodeId;
    }) as AnalogueSensorWired;

    /**
     * Determines if the Next button is disabled or not
     */
    function isNextButtonDisabled() {
        //Initial state
        if (currentStep === 0)
            return false;

        if (currentStep === 1 && IsStatusActivated(sensor, CalibrationStatus.FirstPointSuccessful))
            return false;

        if (currentStep === 2 && (IsStatusActivated(sensor, CalibrationStatus.SecondPointSuccessful)
            || IsStatusActivated(sensor, CalibrationStatus.CalibrationSuccessful)))
            return false;

        return true;
    }

    /**
     * Next button click handler
     */
    const onNextStep = () => {

        //First click on next means start calibrating
        if (currentStep === 0) {
            let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bCalibrationMode', true);
            setPlcProperty(config, opcValue, {
                userLabel: 'label.event.CalibrationStarted', unit: '',
                eventTriggering: UserEventTriggering.BeforeAction,
                showValue: false
            }, dispatch);
        }
        setCurrentStep(currentStep + 1);
    };

    /**
     * Exit button click handler
     */
    const onExit = () => {
        if (currentStep !== 0) {
            let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bCancelSensorCalibration', true);
            setPlcProperty(config, opcValue, {
                userLabel: 'label.event.CalibrationCanceled', unit: '',
                eventTriggering: UserEventTriggering.BeforeAction,
                showValue: false
            }, dispatch);
        }

        openDialog(false);
        setCurrentStep(0);
    };

    const onFinish = () => {
        openDialog(false);
        setCurrentStep(0);
    };

    /**
     * Tare sensor
     */
    function onTare() {
        //Clearing TAre information
        let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bTareSensor', true);

        //Pushing information to the PLC
        setPlcProperty(config, opcValue, {
            userLabel: 'label.event.SensorTared', unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }, dispatch);
    }

    /**
     * Clearing Tare information from Sensor
     */
    function onCleatTare() {
        //Clearing TAre information
        let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bClearTare', true);

        //Pushing information to the PLC
        setPlcProperty(config, opcValue, {
            userLabel: 'label.event.TareCleared', unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }, dispatch);
    }

    //Get sensor current currentStep, with unit
    let sensorValueString = sensor.Out_rCurrentValue.toFixed(getDigitNumber(sensor.Out_rCurrentValue, getSensorDeadBand(sensor, 'Out_rCurrentValue', sensor.Out_rCurrentValue))).toString() + ' ' + sensor.Values[0].Cfg_suSensorUnit.Unit;

    return <>
        <Container>
            <Row>
                <Col>
                    <div>
                        <Glyph name="dot"
                               color={IsStatusActivated(sensor, CalibrationStatus.LastCalibrationSuccessful) ? '#86ea25' : '#E61E50'}/><span
                        style={{
                            fontSize: '19px',
                            fontWeight: 'bold',
                            verticalAlign: 'text-bottom'
                        }}>&nbsp;{IsStatusActivated(sensor, CalibrationStatus.LastCalibrationSuccessful) ? intl.formatMessage({id: 'label.SensorCalibrationActive'}) : intl.formatMessage({id: 'label.NoActiveSensorCalibration'})}</span>
                    </div>
                </Col>
            </Row>
            <Row style={{paddingTop: '5px'}}>
                <Col>
                    <Container>
                        <Row>
                            <Col style={{padding: '0px'}}>
                                <Button
                                    style={{width: '100%'}}
                                    onClick={() => openDialog(true)}>{intl.formatMessage({id: 'label.StandardCalibration'})}</Button>
                            </Col>
                        </Row>
                        {sensor.Cfg_iSensorType === 1 && <Row style={{paddingTop: '5px'}}>
                            <Col style={{
                                paddingTop: '0px',
                                paddingBottom: '0px',
                                paddingLeft: '0px',
                                paddingRight: '5px'
                            }}>
                                <Button style={{width: '100%'}}
                                        onClick={onTare}>{intl.formatMessage({id: 'label.Tare'})}</Button>
                            </Col>
                            <Col style={{
                                paddingTop: '0px',
                                paddingBottom: '0px',
                                paddingLeft: '5px',
                                paddingRight: '0px'
                            }}>
                                <Button disabled={!sensor.Sts_bTareSensor}
                                        style={{width: '100%'}}
                                        onClick={onCleatTare}>{intl.formatMessage({id: 'label.ClearTare'})}</Button>
                            </Col>
                        </Row>}
                    </Container>
                </Col>
                <Col>
                    <div style={{margin: '0 auto', width: 'fit-content', paddingBottom: '15px'}}>
                        <Label
                            editorId='CurrentSensorValue'>{intl.formatMessage({id: 'label.CurrentSensorValue'})}</Label>
                        <div id='CurrentSensorValue' style={{fontWeight: 'bold', fontSize: '26px'}}>
                            {sensorValueString}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        {dialogOpen &&
        <Dialog title={intl.formatMessage({id: 'label.SensorCalibrationProcess'})}
                width={900}
                height={450}
                closeIcon={false}>
            <Stepper value={currentStep} items={calibrationSteps} animationDuration={false}/>
            {renderCurrentStepContent(config, dispatch, intl, sensor, currentStep)}
            <DialogActionsBar>
                {currentStep !== 3 && <button className="k-button"
                                              onClick={onExit}>{intl.formatMessage({id: 'label.Exit'})}</button>}
                {currentStep < 2 ? <button className="k-button"
                                           disabled={isNextButtonDisabled()}
                                           onClick={onNextStep}>{intl.formatMessage({id: 'label.Next'})}</button> :
                    <button className="k-button"
                            disabled={!IsStatusActivated(sensor, CalibrationStatus.SecondPointSuccessful)}
                            onClick={onFinish}>{intl.formatMessage({id: 'label.CalibrationFinish'})}</button>}
            </DialogActionsBar>
        </Dialog>}
    </>;
}