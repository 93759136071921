import React from 'react'
import {PortWidget} from '@projectstorm/react-diagrams'
import {HeatExchangerArtifactModel} from './HeatExchangerArtifactModel'
import {
    BaseArtifactWidgetProps,
    BaseArtifactWidgetState,
    BaseArtifactWidget
} from '../../BaseArtifact/BaseArtifactWidget'
import HeatExchangerVisual from './HeatExchangerVisual'
import * as DefaultPort from '../../Port'
import {connect} from 'react-redux'
import * as StoreTypes from 'StoreTypes'
import {IConfigReducer} from '../../../../store/reducers/configReducer'
import {ControlModuleBase} from '../../../../models/PLC/ControlModuleBase'
import _ from "lodash";
import {TabStrip, TabStripTab} from "@progress/kendo-react-layout";
import AlarmSettingsPanel from "../../ControlPanels/AlarmSettingsPanel";
import HeatExchangerControlPanel from "../../ControlPanels/HeatExchangerControlPanel";
import {AnalogueSensorTCU} from "../../../../models/PLC/AnalogueSensorTCU";

export interface HeatExchangerNodeWidgetProps extends BaseArtifactWidgetProps<HeatExchangerArtifactModel> {
    height: number;
    width: number;
    config: IConfigReducer
}

export interface HeatExchangerNodeWidgetState extends BaseArtifactWidgetState<HeatExchangerArtifactModel> {
}

class HeatExchangerArtifactWidget extends BaseArtifactWidget<HeatExchangerNodeWidgetProps, HeatExchangerNodeWidgetState> {
    constructor(props: HeatExchangerNodeWidgetProps) {
        super(props)
        this.state = {controlPanel: true, selected: 0, show: false, virtualSelected: 0}
    }

    calculateLabelsPosition = () => {
        if (this.labelRef.current) {
            const labelDimensions = {
                width: this.labelRef.current.offsetWidth,
                height: this.labelRef.current.offsetHeight
            };

            const labelCoordinates = {
                x: this.props.width / 2 - labelDimensions.width / 2,
                y: this.props.height - 80,
            };
            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`
        }
    };

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

    renderControlPanelContent() {
        return (<TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
            <TabStripTab title='Control'>
                <HeatExchangerControlPanel nodeId={this.props.node.nodeId}/>
            </TabStripTab>
            <TabStripTab title='Alarm'>
                <AlarmSettingsPanel nodeId={this.props.node.nodeId}/>
            </TabStripTab>
        </TabStrip>);
    }

    render() {

        const sensor = this.props.config.Instances.ControlModules[this.props.node.displayName] as AnalogueSensorTCU
        let alarmState = 0
        if (sensor.Sts_bAlarm) {
            if (sensor.Sts_bAlarmSystem || sensor.Sts_bAlarmSafety || sensor.Sts_bAlarmCriticalProcess) {
                alarmState = 1
            } else {
                alarmState = 2
            }
        }
        return (
            <div
                className='HeatExchangerArtifact-node'
                style={{
                    position: 'relative',
                    width: this.props.width,
                    height: this.props.height,
                    cursor: this.state.controlPanel ? 'pointer' : 'auto'
                }}
            >
                <svg
                    width={this.props.width}
                    height={this.props.height}
                    viewBox='0 0 56 22'
                    preserveAspectRatio='xMidYMid'
                    dangerouslySetInnerHTML={{
                        __html: HeatExchangerVisual.build(BaseArtifactWidget.getConditionsStatus(this.props.node.getConditions(), this.props.config), sensor.Set_bMode, alarmState)
                    }}
                    onClick={(e) => {
                        if (!this.state.show)
                            super.showPopup(e)
                    }}
                />
                <PortWidget
                    port={this.props.node.getPort('left')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: this.props.height / 2 - 8,
                        left: 0
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                <PortWidget
                    port={this.props.node.getPort('right')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: this.props.height / 2 - 8,
                        left: this.props.width - 14
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                {super.render()}
            </div>
        )
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(HeatExchangerArtifactWidget)
