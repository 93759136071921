import { LinkModel, PortModel, PortModelAlignment } from '@projectstorm/react-diagrams'

import PipeLinkModel from '../../CustomLinks/PipeLink/PipeLinkModel'

export class EmptyPortModel extends PortModel {
  constructor (name: string, alignment: PortModelAlignment) {
    super({
      type: 'Empty',
      name: name,
      alignment: alignment
    })
  }

  createLinkModel (): LinkModel {
    return new PipeLinkModel('defaultCode', 'defaultDisplayName')
  }
}
