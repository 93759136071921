/**
 * Dependencies
 */
import React from 'react'

import {Sparkline, ChartArea, ChartSeriesItem, ChartSeries, ChartSeriesItemTooltip} from '@progress/kendo-react-charts'
import {withTheme} from 'styled-components'

import 'hammerjs'
import * as StoreTypes from 'StoreTypes'
import {connect} from 'react-redux'
import {IConfigReducer} from '../../store/reducers/configReducer'
import {getDigitFormat, getDigitNumber, getSensorDeadBand} from "../Diagram/ControlPanels/settingsHelpers";
import {ValueProperties} from "../../models/PLC/ValueProperties";
import {DeadBandList} from "../../models/PLC/DeadBand";
import {TrendsUtils} from "../../views/trends/TrendsUtils";
import { useIntl } from 'react-intl'

export interface ISensorSummaryProps {
    sensor: string
    config: IConfigReducer
    theme: any
}

export interface IFrameColor {
    backgroundColor: string
    color: string
    borderColor: string
    border: string
}

/**
 * Content
 */
const SensorSummary = (props: ISensorSummaryProps) => {
    const {sensor: tag, config} = props
    const {colors} = props.theme
    const intl = useIntl()

    const sensor = config.Instances.ControlModules[tag] as any
    const seriesColors = ["#000000"]

    const getFrameColor = (): IFrameColor => {
        let frameColor: IFrameColor = {
            backgroundColor: "#F8F8FC",
            color: colors.black.base,
            border: "solid",
            borderColor: "#D5D5D9"
        }

        if (sensor.Sts_bAlarm) {
            if (sensor.Sts_bAlarmSystem || sensor.Sts_bAlarmSafety || sensor.Sts_bAlarmCriticalProcess) {
                frameColor.backgroundColor = '#FCE9EE'
                frameColor.borderColor = "#E61E50"
            } else {
                frameColor.backgroundColor = '#FFFAEB'
                frameColor.borderColor = "#FFC832"
            }
        }
        return frameColor
    }

    const mainValue: ValueProperties = sensor.Values.find((item: ValueProperties) => {
        return item.IsMainValue === true
    });
    const mainValueKey: string = `${sensor.NodeId}.${mainValue.Name}`
    const data = sensor.History[mainValueKey]
    const formattedData = Object.keys(data).sort().map((k) => data[k])
    const currentValue: number = sensor[mainValue.Name];

    let unit = mainValue.Cfg_suSensorUnit.Unit
    if (mainValue.Cfg_sTag.includes('PCV')) {
        unit = sensor.Cfg_bValveType ? `% ${intl.formatMessage({id: 'label.Closed'})}` : `% ${intl.formatMessage({id: 'label.Opened'})}`;
    }

    return (
        <div className='frame' style={getFrameColor()}>
            {sensor.Cfg_sTag}
            <br/>
            <Sparkline seriesColors={seriesColors} renderAs="canvas">
                <ChartArea background={getFrameColor().backgroundColor}/>
                <ChartSeries>
                    <ChartSeriesItem data={formattedData}>
                        <ChartSeriesItemTooltip format={'0' + getDigitFormat(1, DeadBandList.getDefaultDeadBand(mainValue.Cfg_dDeadBands))}/>
                    </ChartSeriesItem>
                </ChartSeries>
            </Sparkline>
            <br/>
            {currentValue.toFixed(getDigitNumber(currentValue, DeadBandList.getDeadBandFor(mainValue.Cfg_dDeadBands, currentValue)))} {unit}
            <br/>
        </div>
    )
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default withTheme(
    connect(mapStateToProps)(
        React.memo(SensorSummary, (prevProps, nextProps) => {
            return (
                prevProps.config.theme === nextProps.config.theme &&
                prevProps.config.Instances.ControlModules[prevProps.sensor] === nextProps.config.Instances.ControlModules[nextProps.sensor]
            )
        })
    )
)
