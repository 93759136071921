import {NodeModelGenerics, PortModelOptions} from '@projectstorm/react-diagrams'
import {BaseArtifactModel} from '../../BaseArtifact/BaseArtifactModel'
import { IConditions } from '../../CustomLinks/PipeLink/PipeLinkModel'
import {Port0Deg, Port0DegSwitched} from "./YPortsConfig";
import {Port90Deg, Port90DegSwitched} from "./YPortsConfig90";
import {Port180Deg, Port180DegSwitched} from "./YPortsConfig180";
import {Port360Deg, Port360DegSwitched} from "./YPortsConfig360";
import {ElbowPortArtifactModel} from "../ElbowArtifact/ElbowPortModel";

export interface YNodeModelGenerics {
    PORT: YArtifactModel;
}

export class YArtifactModel extends BaseArtifactModel<NodeModelGenerics & YNodeModelGenerics> {

    constructor(code: string, name: string, nodeId: string, orientation: string = 'horizontal', conditions: IConditions[] = []) {
        super(code, name, nodeId, orientation, {type: 'Y'}, conditions);

        let portsConfig = Port0Deg
        let rotationAngle = 0
        let portsFlip = false

        if (code.includes('-')) {
            let artifactParams = code.split('-')

            rotationAngle = parseInt(artifactParams[0])
            if (rotationAngle === 360)
                rotationAngle = -90

            if (artifactParams.length === 3 && artifactParams[2] === "SP") {
                portsFlip = true
            }
        }

        switch (rotationAngle) {
            case 0:
                portsConfig = portsFlip ? Port0DegSwitched : Port0Deg
                break
            case 90:
                portsConfig = portsFlip ? Port90DegSwitched : Port90Deg
                break
            case 180:
                portsConfig = portsFlip ? Port180DegSwitched : Port180Deg
                break
            case -90:
                portsConfig = portsFlip ? Port360DegSwitched : Port360Deg
                break
        }

        portsConfig.forEach((port: PortModelOptions) => this.addPort(
            new ElbowPortArtifactModel(port.name, port.alignment)
        ))
    }
}
