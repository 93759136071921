import React from 'react'
import YNodeWidget from './YArtifactWidget'
import { YArtifactModel } from './YArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class YArtifactFactory extends AbstractReactFactory<YArtifactModel, DiagramEngine> {
  constructor () {
    super('Y')
  }

  generateReactWidget (event: any): JSX.Element {
    return <YNodeWidget engine={this.engine} size={83} node={event.model} />
  }

  generateModel (event: any) {
    return new YArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
