import React from 'react'
import {TooltipContext} from '@progress/kendo-react-charts'
import {FormattedDate, FormattedMessage, FormattedTime} from "react-intl";
import {apiEvents} from "../../models/Api/apiEvents";
import {EEventCategory} from "../../models/Api/EEventCategory";
import {apiAlarms} from "../../models/Api/apiAlarms";
import {IConfigReducer, ISelectedEvents} from "../../store/reducers/configReducer";
import {apiComments} from "../../models/Api/apiComments";
import {Circle, geometry, Group, Path, Text} from "@progress/kendo-drawing";
import {AxisNoteVisualArgs} from "@progress/kendo-react-charts/dist/npm/common/property-types";
import {IsEmptyOrNullOrUndefined, IsNullOrUndefined} from "../../utils/plcUtils";
import {FieldWrapper} from "@progress/kendo-react-form";
import {DateTimePicker} from "@progress/kendo-react-dateinputs";
import {Error, Hint, Label} from '@progress/kendo-react-labels';
import {apiDataSetOptimized} from "../../models/Api/apiDataSetOptimized";
import {apiEnum} from "../../models/PLC/apiEnum";
import {Input, NumericTextBox, RadioGroup} from "@progress/kendo-react-inputs";
import {AutoComplete, ComboBox, DropDownList} from "@progress/kendo-react-dropdowns";

export const TrendsUtils = {
    nestedTooltipRender: function (tlContext: TooltipContext) {
        return (
            <span>
                <b>{tlContext.point.series.name} : {tlContext.point.formattedValue} {tlContext.point.series.axis}</b>
                <br/>
                <FormattedDate day={'numeric'} month={'short'} year={'numeric'} value={tlContext.point.category}/>&nbsp;
                <FormattedTime hour={'2-digit'} minute={'numeric'}
                               second={'numeric'}
                               fractionalSecondDigits={3}
                               value={tlContext.point.category}/>
            </span>
        )
    },
    nestedExcursionTooltipRender: function (tlContext: TooltipContext) {
        return (
            <div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                    <div>{tlContext.point.dataItem.Yvalue} {tlContext.point.dataItem.YvalueUnit}</div>
                    <div>{tlContext.point.dataItem.Xvalue} {tlContext.point.dataItem.XvalueUnit}</div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <div>
                        <FormattedDate day={'numeric'} month={'short'} year={'numeric'}
                                       value={tlContext.point.category}/>&nbsp;
                        <FormattedTime hour={'2-digit'} minute={'numeric'}
                                       second={'numeric'}
                                       fractionalSecondDigits={3}
                                       value={tlContext.point.dataItem.GatewayTimestamp}/>
                    </div>
                </div>
            </div>)
    }
}

export interface IEventTooltipInfo {
    showEventTooltip: boolean
    eventTooltipContent: string
    offset: any
}

export interface IDataRange {
    min: any
    max: any
}

/**
 * Generates CategoryAxisNotes data for all events available in the unit
 * @param config
 */
export function generateEventsNotes(config: IConfigReducer): any[] {
    let notesData: any[] = [];

    if (config.selectedEvents.userEvents) {
        config.UserEvents.forEach((event: apiEvents) => {
            let newData = createEventNoteItem(event);
            notesData.push(newData)
        })
    }

    if (config.selectedEvents.alarmEvents) {
        config.AH.Sts.Alarm.AlarmEvents.forEach((event: apiAlarms) => {
            let newData = createEventNoteItem(event);
            notesData.push(newData)
        });
    }

    if (config.selectedEvents.comments) {
        config.Comments.forEach((event: apiComments) => {
            let newData = createCommentNoteItem(event);
            notesData.push(newData)
        })
    }

    if (config.selectedEvents.processEvents) {
        config.ProcessEvents.forEach((event: apiEvents) => {
            let newData = createEventNoteItem(event);
            notesData.push(newData)
        })
    }

    if (config.selectedEvents.sequenceEvents) {
        config.SequenceEvents.forEach((event: apiEvents) => {
            let newData = createEventNoteItem(event);
            notesData.push(newData)
        })
    }

    return notesData;
}

export function generateEventsNotesFromDataset(dataSet: apiDataSetOptimized, selectedEvents: ISelectedEvents): any[] {

    let notesData: any[] = [];

    if (dataSet) {

        if (dataSet.Alarms.length > 0 && selectedEvents.alarmEvents) {
            dataSet.Alarms.forEach((event: apiAlarms) => {
                event.Category = new apiEnum<EEventCategory>()
                event.Category.Value = EEventCategory.AlarmEvent
                let newData = createEventNoteItem(event);

                notesData.push(newData)
            });
        }

        if (dataSet.Comments.length > 0 && selectedEvents.comments) {
            dataSet.Comments.forEach((event: apiComments) => {
                let newData = createCommentNoteItem(event);
                notesData.push(newData)
            })
        }

        if (dataSet.Events.length > 0 && selectedEvents.userEvents) {
            let userEvents = dataSet.Events.filter((event) => {
                return event.Category.Value === EEventCategory.UserEvent
            })

            userEvents.forEach((event: apiEvents) => {
                let newData = createEventNoteItem(event);
                notesData.push(newData)
            })
        }

        if (dataSet.Events.length > 0 && selectedEvents.processEvents) {
            let processEvents = dataSet.Events.filter((event) => {
                return event.Category.Value === EEventCategory.ProcessEvent
            })
            processEvents.forEach((event: apiEvents) => {
                let newData = createEventNoteItem(event);
                notesData.push(newData)
            })
        }

        if (dataSet.Events.length > 0 && selectedEvents.sequenceEvents) {
            let sequenceEvents = dataSet.Events.filter((event) => {
                return event.Category.Value === EEventCategory.SequenceEvent
            })
            sequenceEvents.forEach((event: apiEvents) => {
                let newData = createEventNoteItem(event);
                notesData.push(newData)
            })
        }
    }

    return notesData;
}

/**
 * Creates CategoryAxisNote item
 * @param event
 */
export function createEventNoteItem(event: apiEvents | apiAlarms) {

    let markerShape: string = "circle";
    let markerSize: number = 25;
    let markerColor: string = '#2d68cd';
    let markerLabel: string = "E"
    let markerLabelColor: string = "#FFFFFF"

    //Select appearance, depending on the event category
    switch (event.Category.Value) {
        case EEventCategory.AlarmEvent:
            let alarmEvent = event as apiAlarms
            markerShape = "triangle"
            markerLabel = "A";

            if (alarmEvent.Critical) {
                markerColor = "#E61E50"
            } else {
                markerColor = "#FFC832"
            }
            break;
        case EEventCategory.ProcessEvent:
            markerShape = "triangle"
            markerLabel = "P";
            markerColor = "#b74300"
            break;
        case EEventCategory.SequenceEvent:
            markerShape = "triangle"
            markerLabel = "S";
            markerColor = "#6aac90"
            break;
    }

    //we will use unzoned date to show dates "as we get it"
    let unzonedDate = event.GatewayTimestamp.toString();
    unzonedDate = unzonedDate.substring(0, unzonedDate.length - 1);

    return {
        label: {
            text: event,
            visible: false,
        },
        value: new Date(unzonedDate),
        icon: {
            type: markerShape,
            size: markerSize,
            background: markerColor,
            label: markerLabel,
            labelColor: markerLabelColor
        },
        line: {
            length: 0
        }
    };
}

/**
 * Generates comment items
 * @param event
 */
export function createCommentNoteItem(event: apiComments) {

    let markerShape: string = "square";
    let markerSize: number = 25;
    let markerColor: string = '#7c7c7c'
    let markerLabel: string = "C"
    let markerLabelColor: string = "#FFFFFF"

    let unzonedDate = event.Start.toString();

    //we will use unzoned date to show dates "as we get it", if needed
    if(unzonedDate.endsWith("Z")) {
        unzonedDate = event.Start.toString();
        unzonedDate = unzonedDate.substring(0, unzonedDate.length - 1);
    }
    else {
        unzonedDate = event.Start.toString();
    }

    return {
        label: {
            text: event,
            visible: false,
        },
        value: new Date(unzonedDate),
        icon: {
            type: markerShape,
            size: markerSize,
            background: markerColor,
            label: markerLabel,
            labelColor: markerLabelColor
        },
        line: {
            length: 0
        }
    };
}

/**
 * Draws the artifact associated with the note
 * @param e
 */
export function createNoteVisual(e: AxisNoteVisualArgs) {

    let targetPoint = {x: e.rect.center().x, y: e.rect.origin.y};
    let line = new Path()
        .moveTo(targetPoint.x, targetPoint.y)
        .lineTo(targetPoint.x, 19);
    line.stroke(e.options.icon.background);
    const radius = e.options.icon.size / 2;

    let circle = new Circle(new geometry.Circle([targetPoint.x, targetPoint.y - radius], radius), {
        fill: {
            color: e.options.icon.background
        },
        stroke: {
            color: '#8f8f8f',
            width: 2
        }
    });

    let label = IsEmptyOrNullOrUndefined(e.options.icon.label) ? '' : e.options.icon.label;
    let labelColor = IsEmptyOrNullOrUndefined(e.options.icon.labelColor) ? '#000000' : e.options.icon.labelColor;
    let text = new Text(label, [targetPoint.x, targetPoint.y]);
    let bbox = text.bbox();
    text.fill(labelColor)
    text.position([targetPoint.x - radius + (radius * 2 - bbox.width()) / 2, targetPoint.y - radius * 2 + (radius * 2 - bbox.height()) / 2]);

    let visual = new Group().append(line, circle, text) as any;
    visual.tooltip = generateEventTooltipContent(e);

    return visual
}

/**
 * Generates Tooltip content, depending on the event category
 * @param note
 */
export function generateEventTooltipContent(note: any) {
    if (note.text.Start) {
        let comment: apiComments = note.text;

        return (<>
            <strong><FormattedMessage id={comment.Content}/></strong>
            <FormattedDate value={note.value}/>&nbsp;<FormattedTime hour={'numeric'} minute={'numeric'}
                                                                    second={'numeric'} value={note.value}/>
        </>)
    } else {
        let baseEvent: apiEvents = note.text;

        //Tooltip content depends on the event category
        switch (baseEvent.Category.Value) {
            case EEventCategory.AlarmEvent:
                let alarmEvent: apiAlarms = note.text

                return (<>
                    <strong>{alarmEvent.SensorTag}:<FormattedMessage id={alarmEvent.UserLabel}/></strong>
                    <strong><FormattedMessage id={alarmEvent.AlarmEvent.TextCode}/></strong>
                    <FormattedDate value={note.value}/>&nbsp;<FormattedTime hour={'numeric'} minute={'numeric'}
                                                                            second={'numeric'}
                                                                            value={note.value}/>
                </>)
            case EEventCategory.UserEvent:
                let eventValue = '';

                if (!baseEvent.NodeId.includes("BTP.UserEvents")) {
                    eventValue = ` - ${baseEvent.Tag}`
                    if (!IsNullOrUndefined(baseEvent.Value)) {
                        if (typeof baseEvent.Value !== 'string' || !baseEvent.Value.startsWith("label.")) {
                            eventValue += ` : ${baseEvent.Value}`;
                            if (!IsNullOrUndefined(baseEvent.Unit)) {
                                eventValue += baseEvent.Unit;
                            }
                        } else {
                            eventValue += ' : ';
                        }
                    }
                }

                return (<>
                    <strong><FormattedMessage
                        id={baseEvent.UserLabel}/>{eventValue}{typeof baseEvent.Value === 'string' && baseEvent.Value.startsWith("label.") ?
                        <FormattedMessage id={baseEvent.Value}/> : <></>}</strong>
                    <FormattedDate value={note.value}/>&nbsp;<FormattedTime hour={'numeric'} minute={'numeric'}
                                                                            second={'numeric'}
                                                                            value={note.value}/>
                </>)
            case EEventCategory.SequenceEvent:
            case EEventCategory.ProcessEvent:
                let eventStringValue = "";

                if(baseEvent.Value != undefined) {
                    eventStringValue+=": ";
                    if(baseEvent.Value.Value != undefined) {
                        eventStringValue += baseEvent.Value.Value.toString();
                    }
                    else {
                        eventStringValue += baseEvent.Value.toString();
                    }
                }
                return (<>
                    <strong>{baseEvent.Tag}&nbsp;-&nbsp;<FormattedMessage
                        id={baseEvent.UserLabel}/>{eventStringValue}</strong>
                    <FormattedDate value={note.value}/>&nbsp;<FormattedTime hour={'numeric'} minute={'numeric'}
                                                                            second={'numeric'}
                                                                            value={note.value}/>
                </>)

            default:
                return (<>
                    <strong><FormattedMessage id={baseEvent.UserLabel}/></strong>
                    <FormattedDate value={note.value}/>&nbsp;<FormattedTime hour={'numeric'} minute={'numeric'}
                                                                            second={'numeric'}
                                                                            value={note.value}/>
                </>)
        }
    }
}

export const FormInput = (fieldRenderProps: any) => {
    const {validationMessage, touched, label, id, valid, disabled, hint, type, optional, ...others} = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    return (
        <FieldWrapper>
            <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>{label}</Label>
            <div className={'k-form-field-wrap'}>
                <Input
                    valid={valid}
                    type={type}
                    id={id}
                    disabled={disabled}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    {...others}
                />
                {
                    showHint &&
                    <Hint id={hintId}>{hint}</Hint>
                }
                {
                    showValidationMessage &&
                    <Error id={errorId}>{validationMessage}</Error>
                }
            </div>
        </FieldWrapper>
    );
};

export const FormDateTimePicker = (fieldRenderProps: any) => {
    const {validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others} = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
                {label}
            </Label>
            <DateTimePicker
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormTextArea = (fieldRenderProps: any) => {
    const {validationMessage, touched, label, id, valid, hint, optional, ...others} = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    return (
        <FieldWrapper>
            <Label editorId={id} editorValid={valid} optional={optional}>{label}</Label>
            <textarea
                aria-describedby={`${hintId} ${errorId}`}
                className={'k-textarea k-autofill'}
                id={id}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormRadioGroup = (fieldRenderProps: any) => {
    const {
        validationMessage,
        touched,
        id,
        label,
        valid,
        disabled,
        hint,
        visited,
        modified,
        ...others
    } = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    return (
        <FieldWrapper>
            <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid}
                   editorDisabled={disabled}>{label}</Label>
            <RadioGroup
                id={id}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ariaLabelledBy={labelId}
                valid={valid}
                disabled={disabled}
                ref={editorRef}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormNumericTextBox = (fieldRenderProps: any) => {
    const {validationMessage, touched, label, id, valid, disabled, hint, ...others} = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    return (
        <FieldWrapper>
            <Label editorId={id} editorValid={valid} editorDisabled={disabled}>{label}</Label>
            <NumericTextBox
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormDropDownList = (fieldRenderProps: any) => {
    const {validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others} = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
            >
                {label}
            </Label>
            <DropDownList
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormComboBox = (fieldRenderProps: any) => {
    const {validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others} = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
                {label}
            </Label>
            <ComboBox
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};

export const FormAutoComplete = (fieldRenderProps: any) => {
    const {validationMessage, touched, label, id, valid, disabled, hint, wrapperStyle, ...others} = fieldRenderProps;
    const editorRef = React.useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    const labelId = label ? `${id}_label` : '';

    return (
        <FieldWrapper style={wrapperStyle}>
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
            >
                {label}
            </Label>
            <AutoComplete
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                ref={editorRef}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
            />
            {
                showHint &&
                <Hint id={hintId}>{hint}</Hint>
            }
            {
                showValidationMessage &&
                <Error id={errorId}>{validationMessage}</Error>
            }
        </FieldWrapper>
    );
};