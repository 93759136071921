import React from 'react'
import SensorNodeWidget from './SensorArtifactWidget'
import { SensorArtifactModel } from './SensorArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class SensorArtifactFactory extends AbstractReactFactory<SensorArtifactModel, DiagramEngine> {
  constructor () {
    super('Sensor')
  }

  generateReactWidget (event: any): JSX.Element {
    return <SensorNodeWidget engine={this.engine} size={80} node={event.model} />
  }

  generateModel (event: any) {
    return new SensorArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
