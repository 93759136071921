import {NodeModel, LinkModel} from '@projectstorm/react-diagrams'
import {AbstractReactFactory} from '@projectstorm/react-canvas-core'

import PipeLinkModel from '../components/Diagram/CustomLinks/PipeLink/PipeLinkModel'
import {PipeLinkFactory} from '../components/Diagram/CustomLinks/PipeLink/PipeLinkFactory'

import {MembraneArtifactModel} from '../components/Diagram/CustomArtifacts/MembraneArtifact/MembraneArtifactModel'
import {MembraneArtifactFactory} from '../components/Diagram/CustomArtifacts/MembraneArtifact/MembraneArtifactFactory'

import {PumpArtifactModel} from '../components/Diagram/CustomArtifacts/PumpArtifact/PumpArtifactModel'
import {PumpArtifactFactory} from '../components/Diagram/CustomArtifacts/PumpArtifact/PumpArtifactFactory'

import {TankArtifactModel} from '../components/Diagram/CustomArtifacts/TankArtifact/TankArtifactModel'
import {TankArtifactFactory} from '../components/Diagram/CustomArtifacts/TankArtifact/TankArtifactFactory'

import {ValveArtifactModel} from '../components/Diagram/CustomArtifacts/ValveArtifact/ValveArtifactModel'
import {ValveArtifactFactory} from '../components/Diagram/CustomArtifacts/ValveArtifact/ValveArtifactFactory'

import {ControlledValveArtifactModel} from '../components/Diagram/CustomArtifacts/ControlledValveArtifact/ControlledValveArtifactModel'
import {ControlledValveArtifactFactory} from '../components/Diagram/CustomArtifacts/ControlledValveArtifact/ControlledValveArtifactFactory'

import {ThreeWayValveArtifactModel} from '../components/Diagram/CustomArtifacts/ThreeWayValveArtifact/ThreeWayValveArtifactModel'
import {ThreeWayValveArtifactFactory} from '../components/Diagram/CustomArtifacts/ThreeWayValveArtifact/ThreeWayValveArtifactFactory'

import {HeatExchangerArtifactModel} from '../components/Diagram/CustomArtifacts/HeatExchangerArtifact/HeatExchangerArtifactModel'
import {HeatExchangerArtifactFactory} from '../components/Diagram/CustomArtifacts/HeatExchangerArtifact/HeatExchangerArtifactFactory'

import {SensorArtifactModel} from '../components/Diagram/CustomArtifacts/SensorArtifact/SensorArtifactModel'
import {SensorArtifactFactory} from '../components/Diagram/CustomArtifacts/SensorArtifact/SensorArtifactFactory'

import {BeakerArtifactModel} from '../components/Diagram/CustomArtifacts/BeakerArtifact/BeakerArtifactModel'
import {BeakerArtifactFactory} from '../components/Diagram/CustomArtifacts/BeakerArtifact/BeakerArtifactFactory'

import {YArtifactModel} from '../components/Diagram/UtilsArtifacts/YArtifact/YArtifactModel'
import {YArtifactFactory} from '../components/Diagram/UtilsArtifacts/YArtifact/YArtifactFactory'
import {VerticalValveArtifactModel} from '../components/Diagram/CustomArtifacts/VerticalValveArtifact/VerticalValveArtifactModel'
import {VerticalValveArtifactFactory} from '../components/Diagram/CustomArtifacts/VerticalValveArtifact/VerticalValveArtifactFactory'
import {OutletArtifactModel} from "../components/Diagram/UtilsArtifacts/OutletArtifact/OutletArtifactModel";
import {OutletArtifactFactory} from "../components/Diagram/UtilsArtifacts/OutletArtifact/OutletArtifactFactory";
import {InletArtifactModel} from "../components/Diagram/UtilsArtifacts/InletArtifact/InletArtifactModel";
import {InletArtifactFactory} from "../components/Diagram/UtilsArtifacts/InletArtifact/InletArtifactFactory";
import {ElbowArtifactModel} from '../components/Diagram/UtilsArtifacts/ElbowArtifact/ElbowArtifactModel'
import {ElbowArtifactFactory} from '../components/Diagram/UtilsArtifacts/ElbowArtifact/ElbowArtifactFactory'
import {EmptyArtifactModel} from "../components/Diagram/UtilsArtifacts/EmptyArtifact/EmptyArtifactModel";
import {EmptyArtifactFactory} from "../components/Diagram/UtilsArtifacts/EmptyArtifact/EmptyArtifactFactory";

export interface IDiagramArtifact {
    model: NodeModel | LinkModel | any,
    factory: AbstractReactFactory<any, any>
}

export enum EArtifacts {
    PipeLink = 'PipeLink',
    Membrane = 'Membrane',
    Pump = 'Pump',
    Tank = 'Tank',
    Valve = 'Valve',
    VerticalValve = 'VerticalValve',
    ControlledValve = 'ControlledValve',
    ThreeWayValve = 'ThreeWayValve',
    HeatExchanger = 'HeatExchanger',
    Sensor = 'Sensor',
    Beaker = 'Beaker',
    Y = 'Y',
    Outlet = 'Outlet',
    Inlet = 'Inlet',
    Elbow = 'Elbow',
    Empty = 'Empty'
}

export interface ITextCodeToArtifact {
    textCode: string,
    artifact: IDiagramArtifact
}

export const diagramArtifacts: Record<EArtifacts, IDiagramArtifact> = {
    PipeLink: {
        model: PipeLinkModel,
        factory: new PipeLinkFactory()
    },
    Membrane: {
        model: MembraneArtifactModel,
        factory: new MembraneArtifactFactory()
    },
    Pump: {
        model: PumpArtifactModel,
        factory: new PumpArtifactFactory()
    },
    Tank: {
        model: TankArtifactModel,
        factory: new TankArtifactFactory()
    },
    Valve: {
        model: ValveArtifactModel,
        factory: new ValveArtifactFactory()
    },
    VerticalValve: {
        model: VerticalValveArtifactModel,
        factory: new VerticalValveArtifactFactory()
    },
    ControlledValve: {
        model: ControlledValveArtifactModel,
        factory: new ControlledValveArtifactFactory()
    },
    ThreeWayValve: {
        model: ThreeWayValveArtifactModel,
        factory: new ThreeWayValveArtifactFactory()
    },
    HeatExchanger: {
        model: HeatExchangerArtifactModel,
        factory: new HeatExchangerArtifactFactory()
    },
    Sensor: {
        model: SensorArtifactModel,
        factory: new SensorArtifactFactory()
    },
    Beaker: {
        model: BeakerArtifactModel,
        factory: new BeakerArtifactFactory()
    },
    Y: {
        model: YArtifactModel,
        factory: new YArtifactFactory()
    },
    Outlet: {
        model: OutletArtifactModel,
        factory: new OutletArtifactFactory()
    },
    Inlet: {
        model: InletArtifactModel,
        factory: new InletArtifactFactory()
    },
    Elbow: {
        model: ElbowArtifactModel,
        factory: new ElbowArtifactFactory()
    },
    Empty: {
        model: EmptyArtifactModel,
        factory: new EmptyArtifactFactory()
    }
}

export const textCodeToArtifact: ITextCodeToArtifact[] = [
    {
        textCode: 'artifact.analogSensor',
        artifact: diagramArtifacts.Sensor
    },
    {
        textCode: 'artifact.digitalSensor',
        artifact: diagramArtifacts.Sensor
    },
    {
        textCode: 'artifact.pump',
        artifact: diagramArtifacts.Pump
    },
    {
        textCode: 'artifact.membrane',
        artifact: diagramArtifacts.Membrane
    },
    {
        textCode: 'artifact.tank',
        artifact: diagramArtifacts.Tank
    },
    {
        textCode: 'artifact.controlledValve',
        artifact: diagramArtifacts.ControlledValve
    },
    {
        textCode: 'artifact.manualValve',
        artifact: diagramArtifacts.Valve
    },
    {
        textCode: 'artifact.verticalValve',
        artifact: diagramArtifacts.VerticalValve
    },
    {
        textCode: 'artifact.heatExchanger',
        artifact: diagramArtifacts.HeatExchanger
    },
    {
        textCode: 'artifact.beaker',
        artifact: diagramArtifacts.Beaker
    },
]

export const fakeDiagramsControlModules: Record<string, IDiagramArtifact> = {
    TANK: diagramArtifacts.Tank,
    MEMBRANE: diagramArtifacts.Membrane,
    TCU: diagramArtifacts.HeatExchanger,
    FILTRATE: diagramArtifacts.Empty
}

export interface IControlConfig {
    name: string
    orientation: 'vertical' | 'horizontal'
    force: boolean
    position: number[]
}

export const labSizesConfig: any = {
    Lab150: {
        TANK: {
            position: [120, 350],
            main: fakeDiagramsControlModules.TANK,
            virtualSensors: ['VOL001', 'WI001', 'AG002/SIC002'],
            controls: [
                {
                    name: 'IN2',
                    orientation: 'horizontal',
                    force: false,
                    position: [40, 371]
                },
                {
                    name: 'HV004',
                    orientation: 'horizontal',
                    force: false,
                    position: [60, 384]
                },
                {
                    name: 'HV002',
                    orientation: 'vertical',
                    force: false,
                    position: [178, 290]
                },
                {
                    name: 'HV201',
                    orientation: 'horizontal',
                    force: false,
                    position: [300, 384]
                },
                {
                    name: 'HV101',
                    orientation: 'vertical',
                    force: true,
                    position: [510, 260]
                },
                {
                    name: 'ME4',
                    orientation: 'vertical',
                    force: true,
                    position: [516, 148]
                },
                {
                    name: 'TE1',
                    orientation: 'horizontal',
                    force: false,
                    position: [196, 726]
                },
                {
                    name: 'TE2',
                    orientation: 'horizontal',
                    force: false,
                    position: [515.6, 556]
                },
                {
                    name: 'HV401',
                    orientation: 'horizontal',
                    force: true,
                    position: [491, 182]
                },
                {
                    name: 'PSH401',
                    orientation: 'horizontal',
                    force: false,
                    position: [260, 182]
                },
                {
                    name: 'IN1',
                    orientation: 'horizontal',
                    force: false,
                    position: [40, 180]
                },
                {
                    name: 'P401/SIC401',
                    orientation: 'horizontal',
                    force: false,
                    position: [145, 115.5]
                },
                {
                    name: 'HV001',
                    orientation: 'horizontal',
                    force: true,
                    position: [280, 722]
                }
            ]
        },
        TCU: {
            position: [400, 721],
            main: fakeDiagramsControlModules.TCU,
            virtualSensors: [],
            controls: [
                {
                    name: 'TIC002',
                    orientation: 'horizontal',
                    force: true,
                    position: [418, 770]
                }
            ]
        },
        FEED: {
            position: [570, 644.5],
            main: 'P001/SIC001',
            virtualSensors: ['FLO001'],
            controls: [
                {
                    name: 'TI001',
                    orientation: 'horizontal',
                    force: false,
                    position: [700, 711]
                },
                {
                    name: 'PI001',
                    orientation: 'horizontal',
                    force: false,
                    position: [820, 711]
                },
                {
                    name: 'PSH001',
                    orientation: 'horizontal',
                    force: false,
                    position: [920, 711]
                },
                {
                    name: 'FE1',
                    orientation: 'vertical',
                    force: true,
                    position: [1078, 916.7]
                },
                {
                    name: 'DR1',
                    orientation: 'vertical',
                    force: true,
                    position: [1130, 900]
                },
                {
                    name: 'HV701',
                    orientation: 'vertical',
                    force: true,
                    position: [1050, 721]
                },
                {
                    name: 'AI001',
                    orientation: 'horizontal',
                    force: false,
                    position: [1100, 711]
                },
                {
                    name: 'AI002',
                    orientation: 'horizontal',
                    force: false,
                    position: [1180, 711]
                }
            ]
        },
        MEMBRANE: {
            position: [1350, 300],
            main: fakeDiagramsControlModules.MEMBRANE,
            virtualSensors: ['TMP001', 'DP001', 'FLU001', 'NWP001', 'PER001', 'FLU201', 'PER201','FLU202','PER202','FLU203','PER203'],
            controls: [
                {
                    name: 'ME2',
                    orientation: 'horizontal',
                    force: false,
                    position: [1360, 146]
                },
                {
                    name: 'ME3',
                    orientation: 'horizontal',
                    force: false,
                    position: [1420, 146]
                },
                {
                    name: 'HV003',
                    orientation: 'vertical',
                    force: true,
                    position: [1320, 620]
                },
                {
                    name: 'ME1',
                    orientation: 'horizontal',
                    force: false,
                    position: [1360, 724]
                },
                {
                    name: 'HV702',
                    orientation: 'vertical',
                    force: true,
                    position: [1410, 820]
                },
                {
                    name: 'ME4',
                    orientation: 'horizontal',
                    force: false,
                    position: [1429, 916.3]
                },
                {
                    name: 'DR3',
                    orientation: 'vertical',
                    force: true,
                    position: [1495, 900]
                },
            ]
        },
        RETENTATE: {
            position: [980, 42.5], //89
            main: 'PCV101',
            virtualSensors: [],
            controls:
              [
                  {
                      name: 'PI101',
                      orientation: 'horizontal',
                      force: false,
                      position: [1080, 120]
                  },
                  {
                      name: 'E1',
                      orientation: 'horizontal',
                      force: false,
                      position: [528, 146]
                  },
                  {
                      name: 'E2',
                      orientation: 'horizontal',
                      force: false,
                      position: [718, 146]
                  },
                  {
                      name: 'E3',
                      orientation: 'horizontal',
                      force: false,
                      position: [528, 146]
                  },
                  {
                      name: 'FI101',
                      orientation: 'horizontal',
                      force: false,
                      position: [880, 120]
                  },
                  {
                      name: 'WI101',
                      orientation: 'horizontal',
                      force: false,
                      position: [690, 280]
                  }
              ]
        }
        ,
        FILTRATE: {
            position: [1600, 118],
            main: fakeDiagramsControlModules.FILTRATE,
            virtualSensors: [],
            controls: [
                {
                    name: 'PI201',
                    orientation: 'horizontal',
                    force: false,
                    position: [1600, 120]
                },
                {
                    name: 'PCV201',
                    orientation: 'horizontal',
                    force: false,
                    position: [1700, 42]
                },
                {
                    name: 'FI201',
                    orientation: 'horizontal',
                    force: false,
                    position: [1780, 120]
                },
                {
                    name: 'FIE1',
                    orientation: 'vertical',
                    force: false,
                    position: [1904, 147]
                },
                {
                    name: 'FIT1',
                    orientation: 'horizontal',
                    force: true,
                    position: [1906.3, 200]
                },
                {
                    name: 'FIT2',
                    orientation: 'horizontal',
                    force: true,
                    position: [1906.3, 450]
                },
                {
                    name: 'FIE2',
                    orientation: 'vertical',
                    force: false,
                    position: [1918, 700]
                },
                {
                    name: 'WI201',
                    orientation: 'vertical',
                    force: false,
                    position: [2000, 550]
                },
                {
                    name: 'WI202',
                    orientation: 'vertical',
                    force: false,
                    position: [2000, 650]
                },
                {
                    name: 'WI203',
                    orientation: 'vertical',
                    force: false,
                    position: [2000, 750]
                },
                {
                    name: 'DR2',
                    orientation: 'vertical',
                    force: true,
                    position: [2000, 550]
                },
            ]
        }
    },
    Lab800: {
        TANK: {
            position: [120, 350],
            main: fakeDiagramsControlModules.TANK,
            virtualSensors: ['VOL001', 'WI001', 'AG002/SIC002'],
            controls: [
                {
                    name: 'IN2',
                    orientation: 'horizontal',
                    force: false,
                    position: [40, 371]
                },
                {
                    name: 'HV004',
                    orientation: 'horizontal',
                    force: false,
                    position: [60, 384]
                },
                {
                    name: 'HV002',
                    orientation: 'vertical',
                    force: false,
                    position: [178, 290]
                },
                {
                    name: 'HV201',
                    orientation: 'horizontal',
                    force: false,
                    position: [300, 384]
                },
                {
                    name: 'HV101',
                    orientation: 'vertical',
                    force: true,
                    position: [510, 260]
                },
                {
                    name: 'ME4',
                    orientation: 'vertical',
                    force: true,
                    position: [516, 148]
                },
                {
                    name: 'TE1',
                    orientation: 'horizontal',
                    force: false,
                    position: [196, 726]
                },
                {
                    name: 'TE2',
                    orientation: 'horizontal',
                    force: false,
                    position: [515.6, 556]
                },
                {
                    name: 'HV401',
                    orientation: 'horizontal',
                    force: true,
                    position: [491, 182]
                },
                {
                    name: 'PSH401',
                    orientation: 'horizontal',
                    force: false,
                    position: [260, 182]
                },
                {
                    name: 'IN1',
                    orientation: 'horizontal',
                    force: false,
                    position: [40, 180]
                },
                {
                    name: 'P401/SIC401',
                    orientation: 'horizontal',
                    force: false,
                    position: [145, 115.5]
                },
                {
                    name: 'HV001',
                    orientation: 'horizontal',
                    force: true,
                    position: [280, 722]
                }
            ]
        },
        TCU: {
            position: [400, 721],
            main: fakeDiagramsControlModules.TCU,
            virtualSensors: [],
            controls: [
                {
                    name: 'TIC002',
                    orientation: 'horizontal',
                    force: true,
                    position: [418, 770]
                }
            ]
        },
        FEED: {
            position: [570, 644.5],
            main: 'P001/SIC001',
            virtualSensors: ['FLO001'],
            controls: [
                {
                    name: 'TI001',
                    orientation: 'horizontal',
                    force: false,
                    position: [700, 711]
                },
                {
                    name: 'PI001',
                    orientation: 'horizontal',
                    force: false,
                    position: [820, 711]
                },
                {
                    name: 'PSH001',
                    orientation: 'horizontal',
                    force: false,
                    position: [920, 711]
                },
                {
                    name: 'T2',
                    orientation: 'horizontal',
                    force: true,
                    position: [995, 727]
                },
                {
                    name: 'FE1',
                    orientation: 'vertical',
                    force: true,
                    position: [1024.7, 916.3]
                },
                {
                    name: 'DR1',
                    orientation: 'vertical',
                    force: true,
                    position: [1100, 900]
                },
                {
                    name: 'HV701',
                    orientation: 'vertical',
                    force: true,
                    position: [1006, 820]
                },
                {
                    name: 'AI001',
                    orientation: 'horizontal',
                    force: false,
                    position: [1100, 711]
                },
                {
                    name: 'AI002',
                    orientation: 'horizontal',
                    force: false,
                    position: [1180, 711]
                }
            ]
        },
        MEMBRANE: {
            position: [1350, 300],
            main: fakeDiagramsControlModules.MEMBRANE,
            virtualSensors: ['TMP001', 'DP001', 'FLU001', 'NWP001', 'PER001', 'FLU201', 'PER201'],
            controls: [
                {
                    name: 'ME2',
                    orientation: 'horizontal',
                    force: false,
                    position: [1360, 146]
                },
                {
                    name: 'ME3',
                    orientation: 'horizontal',
                    force: false,
                    position: [1420, 146]
                },
                {
                    name: 'HV003',
                    orientation: 'vertical',
                    force: true,
                    position: [1320, 620]
                },
                {
                    name: 'ME1',
                    orientation: 'horizontal',
                    force: false,
                    position: [1326, 724]
                },
                {
                    name: 'HV702',
                    orientation: 'vertical',
                    force: true,
                    position: [1435, 620]
                },
                {
                    name: 'ME4',
                    orientation: 'horizontal',
                    force: false,
                    position: [1454, 724]
                },
                {
                    name: 'DR3',
                    orientation: 'vertical',
                    force: true,
                    position: [1520, 707]
                },
            ]
        },
        RETENTATE: {
            position: [980, 42.5], //89
            main: 'PCV101',
            virtualSensors: [],
            controls:
                [
                    {
                        name: 'PI101',
                        orientation: 'horizontal',
                        force: false,
                        position: [1080, 120]
                    },
                    {
                        name: 'E1',
                        orientation: 'horizontal',
                        force: false,
                        position: [528, 146]
                    },
                    {
                        name: 'E2',
                        orientation: 'horizontal',
                        force: false,
                        position: [718, 146]
                    },
                    {
                        name: 'E3',
                        orientation: 'horizontal',
                        force: false,
                        position: [528, 146]
                    },
                    {
                        name: 'FI101',
                        orientation: 'horizontal',
                        force: false,
                        position: [880, 120]
                    },
                    {
                        name: 'WI101',
                        orientation: 'horizontal',
                        force: false,
                        position: [690, 280]
                    }
                ]
        }
        ,
        FILTRATE: {
            position: [1600, 120],
            main: 'PI201',
            virtualSensors: [],
            controls: [
                {
                    name: 'PCV201',
                    orientation: 'horizontal',
                    force: false,
                    position: [1700, 42.5]
                },
                {
                    name: 'FI201',
                    orientation: 'horizontal',
                    force: false,
                    position: [1780, 120]
                },
                {
                    name: 'FIE1',
                    orientation: 'vertical',
                    force: false,
                    position: [1904, 147]
                },
                {
                    name: 'AI203',
                    orientation: 'vertical',
                    force: false,
                    position: [1890, 250]
                },
                {
                    name: 'AI201',
                    orientation: 'vertical',
                    force: false,
                    position: [1890, 350]
                },
                {
                    name: 'AI202',
                    orientation: 'vertical',
                    force: false,
                    position: [1890, 450]
                },
                {
                    name: 'FIE2',
                    orientation: 'vertical',
                    force: false,
                    position: [1917.6, 550]
                },
                {
                    name: 'WI201',
                    orientation: 'vertical',
                    force: false,
                    position: [2000, 550]
                },
                {
                    name: 'DR2',
                    orientation: 'vertical',
                    force: true,
                    position: [2000, 550]
                },
            ]
        }
    },
    Lab6000: {
        TANK: {
            position: [120, 310],
            main: fakeDiagramsControlModules.TANK,
            virtualSensors: ['VOL001', 'WI001', 'AG002/SIC002'],
            controls: [
                {
                    name: 'IN2',
                    orientation: 'horizontal',
                    force: false,
                    position: [40, 331]
                },
                {
                    name: 'HV101',
                    orientation: 'vertical',
                    force: true,
                    position: [510, 260]
                },
                {
                    name: 'T1',
                    orientation: 'vertical',
                    force: true,
                    position: [500, 138]
                },
                {
                    name: 'ME4',
                    orientation: 'vertical',
                    force: true,
                    position: [516, 148]
                },
                {
                    name: 'TE1',
                    orientation: 'horizontal',
                    force: false,
                    position: [196, 726]
                },
                {
                    name: 'TE2',
                    orientation: 'horizontal',
                    force: false,
                    position: [515.6, 516]
                },
                {
                    name: 'HV401',
                    orientation: 'horizontal',
                    force: true,
                    position: [400, 133]
                },
                {
                    name: 'PSH401',
                    orientation: 'horizontal',
                    force: false,
                    position: [260, 122]
                },
                {
                    name: 'IN1',
                    orientation: 'horizontal',
                    force: false,
                    position: [40, 120]
                },
                {
                    name: 'P401/SIC401',
                    orientation: 'horizontal',
                    force: false,
                    position: [145, 55.5]
                },
                {
                    name: 'HV001',
                    orientation: 'horizontal',
                    force: true,
                    position: [280, 722]
                }
            ]
        },
        TCU: {
            position: [400, 721],
            main: 'TIC002',
            virtualSensors: [],
            controls: []
        },
        FEED: {
            position: [570, 644.5],
            main: 'P001/SIC001',
            virtualSensors: ['FLO001'],
            controls: [
                {
                    name: 'TI001',
                    orientation: 'horizontal',
                    force: false,
                    position: [700, 711]
                },
                {
                    name: 'PI001',
                    orientation: 'horizontal',
                    force: false,
                    position: [820, 711]
                },
                {
                    name: 'PSH001',
                    orientation: 'horizontal',
                    force: false,
                    position: [920, 711]
                },
                {
                    name: 'T2',
                    orientation: 'horizontal',
                    force: true,
                    position: [995, 727]
                },
                {
                    name: 'FE1',
                    orientation: 'vertical',
                    force: true,
                    position: [1024.7, 916.3]
                },
                {
                    name: 'DR1',
                    orientation: 'vertical',
                    force: true,
                    position: [1100, 900]
                },
                {
                    name: 'HV701',
                    orientation: 'vertical',
                    force: true,
                    position: [1006, 820]
                },
                {
                    name: 'AI001',
                    orientation: 'horizontal',
                    force: false,
                    position: [1100, 711]
                },
                {
                    name: 'AI002',
                    orientation: 'horizontal',
                    force: false,
                    position: [1200, 711]
                }
            ]
        },
        MEMBRANE: {
            position: [1350, 300],
            main: fakeDiagramsControlModules.MEMBRANE,
            virtualSensors: ['TMP001', 'DP001', 'FLU001', 'NWP001', 'PER001', 'FLU201', 'PER201'],
            controls: [
                {
                    name: 'ME2',
                    orientation: 'horizontal',
                    force: false,
                    position: [1360, 146]
                },
                {
                    name: 'ME3',
                    orientation: 'horizontal',
                    force: false,
                    position: [1420, 146]
                },
                {
                    name: 'HV003',
                    orientation: 'vertical',
                    force: true,
                    position: [1320, 620]
                },
                {
                    name: 'ME1',
                    orientation: 'horizontal',
                    force: false,
                    position: [1326, 724]
                },
                {
                    name: 'HV702',
                    orientation: 'vertical',
                    force: true,
                    position: [1435, 620]
                },
                {
                    name: 'ME4',
                    orientation: 'horizontal',
                    force: false,
                    position: [1454, 724]
                },
                {
                    name: 'DR3',
                    orientation: 'vertical',
                    force: true,
                    position: [1520, 707]
                },
            ]
        },
        RETENTATE: {
            position: [980, 42.5], //89
            main: 'PCV101',
            virtualSensors: [],
            controls:
                [
                    {
                        name: 'PI101',
                        orientation: 'horizontal',
                        force: false,
                        position: [1080, 120]
                    },
                    {
                        name: 'E1',
                        orientation: 'horizontal',
                        force: false,
                        position: [528, 146]
                    },
                    {
                        name: 'E2',
                        orientation: 'horizontal',
                        force: false,
                        position: [718, 146]
                    },
                    {
                        name: 'FI101',
                        orientation: 'horizontal',
                        force: false,
                        position: [880, 120]
                    },
                    {
                        name: 'WI101',
                        orientation: 'horizontal',
                        force: false,
                        position: [690, 280]
                    }
                ]
        }
        ,
        FILTRATE: {
            position: [1600, 120],
            main: 'PI201',
            virtualSensors: [],
            controls: [
                {
                    name: 'PCV201',
                    orientation: 'horizontal',
                    force: false,
                    position: [1700, 42.5]
                },
                {
                    name: 'FI201',
                    orientation: 'horizontal',
                    force: false,
                    position: [1780, 120]
                },
                {
                    name: 'FIE1',
                    orientation: 'vertical',
                    force: false,
                    position: [1904, 147]
                },
                {
                    name: 'AI203',
                    orientation: 'vertical',
                    force: false,
                    position: [1890, 250]
                },
                {
                    name: 'AI201',
                    orientation: 'vertical',
                    force: false,
                    position: [1890, 350]
                },
                {
                    name: 'AI202',
                    orientation: 'vertical',
                    force: false,
                    position: [1890, 450]
                },
                {
                    name: 'FIE2',
                    orientation: 'vertical',
                    force: false,
                    position: [1917.6, 550]
                },
                {
                    name: 'WI201',
                    orientation: 'vertical',
                    force: false,
                    position: [2000, 550]
                },
                {
                    name: 'DR2',
                    orientation: 'vertical',
                    force: true,
                    position: [2000, 550]
                },
            ]
        }
    }
}