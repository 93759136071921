/**
 * Visual builder for Y Artifact
 */
import {ControlModuleBase} from '../../../../models/PLC/ControlModuleBase'

export default class ElbowVisual {
    /**
     * Builds up svg
     * @returns SVG as a string
     * @param name
     * @param controlModules
     * @param isFlowOn
     */
    static build(name: string, controlModules: Record<string, ControlModuleBase>, isFlowOn: boolean): string {
        return `<g transform="matrix(1,0,0,1,-33.0128,-124.112)">
        <g>
            <path d="M33.013,124.145L57.997,124.112L58.064,136.587L71.689,136.756L71.587,161.705L54.582,161.57C40.372,159.737 33.102,151.627 33.047,136.992L33.013,124.145Z" style="fill:rgb(213,213,217);"/>
            <path d="M36.495,124.145L54.481,124.179L54.58,139.429C54.66,139.802 54.941,140.102 55.485,140.09L71.621,140.17L71.587,158.19L53.669,158.088C43.04,156.285 36.837,150.413 36.394,137.736L36.495,124.145Z" style="fill:white;"/>
            <path d="M39.96,124.174L50.975,124.179L51.036,139.992C51.343,142.087 52.161,143.294 54.934,143.628L71.643,143.675L71.614,154.728L54.481,154.545C45.995,153.454 40.914,149.042 39.964,138.061L39.96,124.174Z" style="fill:${isFlowOn ? 'rgb(15,105,175)' : '#767680'};"/>
        </g>
    </g>`
    }
}