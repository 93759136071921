import React from 'react'
import OutletNodeWidget from './OutletArtifactWidget'
import { OutletArtifactModel } from './OutletArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class OutletArtifactFactory extends AbstractReactFactory<OutletArtifactModel, DiagramEngine> {
  constructor () {
    super('Outlet')
  }

  generateReactWidget (event: any): JSX.Element {
    return <OutletNodeWidget engine={this.engine} size={83.8} node={event.model} />
  }

  generateModel (event: any) {
    return new OutletArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
