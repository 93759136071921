/**
 * Visual builder for Sensor Artifact
 */
export default class SensorVisual {
  /**
   * Builds up svg
   * @param alarmState
   * @param sensorName Sensor name
   * @returns SVG as a string
   */
  static build (alarmState: number, sensorName: string): string {
    const id = sensorName.slice(sensorName.length - 3)
    const tag = sensorName.substring(0, sensorName.length-3)
    let fill = 'rgb(73,73,83)'
    let stroke = 'rgb(213,213,217)'
    let text = '#FFFFFF'
    switch (alarmState) {
      case 1: {
        fill = '#FCE9EE'
        stroke = '#E61E50'
        text = '#494953'
        break;
      }
      case 2: {
        fill = '#FFFAEB'
        stroke = '#FFC832'
        text = '#494953'
        break;
      }
    }
    return `<g transform="matrix(1,0,0,1,-4.37438e-06,5.62562e-06)">
        <g transform="matrix(1,0,0,1,-4.87689,-25.8769)">
            <circle cx="21" cy="42" r="12" style="fill:${fill};stroke:${stroke};stroke-width:2px;"/>
            <g transform="matrix(1,0,0,1,3.55271e-15,-0.493955)">
                <g transform="matrix(1,0,0,1,3.80337,28.7976)">
                    <text x="17px" y="12px" class="diagramText" style="fill:${text}">${tag}</text>
                </g>
                <g transform="matrix(1,0,0,1,5.41821,34.5518)">
                    <text x="15.8px" y="14px" class="diagramText" style="fill:${text}">${id}</text>
                </g>
            </g>
        </g>
    </g>`
  }
}
