import {NodeModelGenerics, PortModelOptions} from '@projectstorm/react-diagrams'

import {BaseArtifactModel} from '../../BaseArtifact/BaseArtifactModel'
import {HeatExchangerPortArtifactModel} from './HeatExchangerPortModel'
import HeatExchangerPorts from './HeatExchangerPortsConfig'
import { IConditions } from '../../CustomLinks/PipeLink/PipeLinkModel'

export interface HeatExchangerNodeModelGenerics {
    PORT: HeatExchangerArtifactModel;
}

export class HeatExchangerArtifactModel extends BaseArtifactModel<NodeModelGenerics & HeatExchangerNodeModelGenerics> {
    constructor(code: string, name: string, nodeId: string, orientation: string = 'horizontal', conditions: IConditions[] = []) {
        super(code, name, nodeId, orientation, {type: 'HeatExchanger'}, conditions)
        HeatExchangerPorts.forEach((port: PortModelOptions) => this.addPort(
            new HeatExchangerPortArtifactModel(port.name, port.alignment)
        ))
    }
}
