import React from 'react'
import {PortWidget} from '@projectstorm/react-diagrams'
import {TankArtifactModel} from './TankArtifactModel'
import {
    BaseArtifactWidgetProps,
    BaseArtifactWidgetState,
    BaseArtifactWidget
} from '../../BaseArtifact/BaseArtifactWidget'
import TankVisual from './TankVisual'
import * as DefaultPort from '../../Port'
import {connect} from 'react-redux'
import * as StoreTypes from 'StoreTypes'
import {IConfigReducer} from '../../../../store/reducers/configReducer'
import {Agitator} from '../../../../models/PLC/Agitator'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import TankControlPanel from '../../ControlPanels/TankControlPanel'

export interface TankNodeWidgetProps extends BaseArtifactWidgetProps<TankArtifactModel> {
    height: number,
    width: number,
    config: IConfigReducer
}

export interface TankNodeWidgetState extends BaseArtifactWidgetState<TankArtifactModel> {
}

class TankArtifactWidget extends BaseArtifactWidget<TankNodeWidgetProps, TankNodeWidgetState> {
    constructor(props: TankNodeWidgetProps) {
        super(props)
        this.state = {controlPanel: true, selected: 0, show: false, virtualSelected:0}
    };

    calculateLabelsPosition = () => {
        if (this.labelRef.current) {
            const labelDimensions = {
                width: this.labelRef.current.offsetWidth,
                height: this.labelRef.current.offsetHeight
            }

            let labelCoordinates = {
                x: -116, //Using fixed size (max label width of 166 for AG label)
                y: 85
            }
            if(this.props.config.Unit.Set.iConfiguredBTPSize === 1) {
                labelCoordinates.x = this.props.width;
            }

            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`
        }
    };

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

    renderControlPanelContent(): any {
        return (<TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
            <TabStripTab title='Control'>
                <TankControlPanel nodeId={this.props.node.nodeId}/>
            </TabStripTab>
        </TabStrip>);
    }

    render() {
        const sensor = this.props.config.Instances.ControlModules['AG002/SIC002'] as Agitator
        return (
            <div
                className='TankArtifact-node'
                style={{
                    position: 'relative',
                    width: this.props.width,
                    height: this.props.height,
                    cursor: 'pointer'
                }}
            >
                <svg
                    width={this.props.width}
                    height={this.props.height}
                    viewBox='0 0 114 185'
                    preserveAspectRatio='xMidYMid'
                    dangerouslySetInnerHTML={{
                        __html: TankVisual.build(sensor.Sts_bRunning, sensor.Set_iControlStyle, sensor.Sts_bEnable, sensor.Sts_bInterlocked, !sensor.Set_bMode)
                    }}
                    onClick={(e) => {
                        if (sensor.Sts_bEnable) {
                            if (!this.state.show)
                                super.showPopup(e);
                        }
                    }}
                />
                <PortWidget
                    port={this.props.node.getPort('left')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: 55,
                        left: 0
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                <PortWidget
                  port={this.props.node.getPort('HV002-out')} engine={this.props.engine}
                  style={{
                      position: 'absolute',
                      top: 20,
                      left: this.props.width / 2.25
                  }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                <PortWidget
                  port={this.props.node.getPort('HV201-out')} engine={this.props.engine}
                  style={{
                      position: 'absolute',
                      top: 55,
                      right: 0
                  }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                <PortWidget
                    port={this.props.node.getPort('right')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: this.props.height - this.props.height / 4,
                        left: this.props.width - 40
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                <PortWidget
                    port={this.props.node.getPort('bottom')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: this.props.height - 20,
                        left: this.props.width / 2.25
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                {super.render()}
            </div>
        )
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(TankArtifactWidget)
