import { PortModelAlignment } from '@projectstorm/react-diagrams'

export default [
  {
    name: 'retentate',
    alignment: PortModelAlignment.TOP
  },
  {
    name: 'feed',
    alignment: PortModelAlignment.BOTTOM
  },
  {
    name: 'filtrate',
    alignment: PortModelAlignment.TOP
  },
  {
    name: 'drain',
    alignment: PortModelAlignment.BOTTOM
  }
]
