/**
 * Visual builder for Valve Artifact
 */
export default class InletVisual {
    /**
     * Builds up svg
     * @returns SVG as a string
     * @param isValveOpen
     */
    static build(isValveOpen: boolean): string {
        return `<g transform="matrix(1,0,0,1,-1.14059e-06,-7.53406e-05)">
        <g transform="matrix(0.669977,0,0,0.669977,-45.0028,-75.1833)">
            <g transform="matrix(1,0,0,1,59.026,7.45125)">
                <rect x="8.449" y="165.744" width="111.514" height="51.924" style="fill:rgb(213,213,217);"/>
            </g>
            <g transform="matrix(0.99799,0,0,1,59.2667,7.33509)">
                <rect x="8.217" y="172.946" width="111.514" height="37.752" style="fill:rgb(247,247,255);"/>
            </g>
            <g transform="matrix(1.01044,0,0,1,58.6334,7.39317)">
                <rect x="8.449" y="180.264" width="111.510" height="23" style="fill:${isValveOpen ? 'rgb(15,105,175)' : '#767680'};"/>
            </g>
        </g>
    </g>`
    }
}
