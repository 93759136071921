import {NodeModelGenerics, PortModelOptions} from '@projectstorm/react-diagrams'

import {BaseArtifactModel} from '../../BaseArtifact/BaseArtifactModel'
import {BeakerPortArtifactModel} from './BeakerPortModel'
import BeakerPorts from './BeakerPortsConfig'

export interface BeakerNodeModelGenerics {
    PORT: BeakerArtifactModel;
}

export class BeakerArtifactModel extends BaseArtifactModel<NodeModelGenerics & BeakerNodeModelGenerics> {
    constructor(code: string, name: string, nodeId: string, orientation: string = 'horizontal') {
        super(code, name, nodeId, orientation, {type: 'Beaker'})
        BeakerPorts.forEach((port: PortModelOptions) => this.addPort(
            new BeakerPortArtifactModel(port.name, port.alignment)
        ))
    }
}
