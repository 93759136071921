//Start - Stop actions
import { IntlShape } from 'react-intl'

export const getPumpStateOptions = (intl: IntlShape) => {
  return [
    {
      label: intl.formatMessage({ id: "label.Stop" }),
      value: false,
      selectedBackgroundColor: "#0F69AF",
    },
    {
      label: intl.formatMessage({ id: "label.Start" }),
      value: true,
      selectedBackgroundColor: "#0F69AF"
    }
  ];
}

//Automatic / Manual options
export const getModeOptions = (intl: IntlShape) => {
  return [
    {
      label: intl.formatMessage({ id: "label.Manual" }),
      value: false,
      selectedBackgroundColor: "#0F69AF"
    },
    {
      label: intl.formatMessage({ id: "label.Automatic" }),
      value: true,
      selectedBackgroundColor: "#0F69AF",
    }
  ];
}