/**
 * Visual builder for Membrane Artifact
 */
export default class MembraneVisual {
  /**
   * Builds up svg
   * @returns SVG as a string
   * @param isSIC001_P001Running
   */
  static build (isSIC001_P001Running: boolean): string {
    return `<g transform="matrix(1,0,0,1,-68.9917,-8)">
        <g>
            <path d="M76.829,11.265C77.855,9.261 79.917,8 82.169,8L125.855,8C128.109,8 130.173,9.263 131.198,11.271L138.348,25.271C140.387,29.263 137.488,34 133.005,34L75,34C70.514,34 67.615,29.258 69.659,25.265L76.829,11.265Z" style="fill:rgb(118,118,128);fill-rule:nonzero;"/>
            <path d="M73,31C73,27.686 75.686,25 79,25L129,25C132.314,25 135,27.686 135,31L135,172C135,175.314 132.314,178 129,178L79,178C75.686,178 73,175.314 73,172L73,31Z" style="fill:${isSIC001_P001Running ? 'rgb(15,105,175)' : '#7B7B85'};fill-rule:nonzero;"/>
            <path d="M131,31L131,172L139,172L139,31L131,31ZM129,174L79,174L79,182L129,182L129,174ZM77,172L77,31L69,31L69,172L77,172ZM79,29L104,29L104,21L79,21L79,29ZM104,29L129,29L129,21L104,21L104,29ZM79,174C77.895,174 77,173.105 77,172L69,172C69,177.523 73.477,182 79,182L79,174ZM131,172C131,173.105 130.105,174 129,174L129,182C134.523,182 139,177.523 139,172L131,172ZM139,31C139,25.477 134.523,21 129,21L129,29C130.105,29 131,29.895 131,31L139,31ZM77,31C77,29.895 77.895,29 79,29L79,21C73.477,21 69,25.477 69,31L77,31Z" style="fill:rgb(73,73,83);fill-rule:nonzero;"/>
            <path d="M101.5,29.333L97.5,24L110,24L110,25.5L107,29.333L104.5,32.5L104,32.5L101.5,29.333Z" style="fill:rgb(73,73,83);fill-rule:nonzero;"/>
            <path d="M73,31C73,27.686 75.686,25 79,25L95.193,25C97.252,25 99.167,26.055 100.266,27.796L103.576,33.037C103.987,33.687 104.948,33.649 105.306,32.968L107.813,28.206C108.85,26.234 110.895,25 113.122,25L129,25C132.314,25 135,27.686 135,31L135,172C135,175.314 132.314,178 129,178L79,178C75.686,178 73,175.314 73,172L73,31Z" style="fill:${isSIC001_P001Running ? 'rgb(15,105,175)' : '#7B7B85'};fill-rule:nonzero;"/>
            <rect x="103" y="33" width="3" height="145" style="fill:${isSIC001_P001Running ? 'rgb(111,165,207)' : '#D6D6DA'};"/>
            <path d="M107.5,173.167L113,178.5L94.5,179.5L95,177L101,173.167L103,169.5L105,169L107.5,173.167Z" style="fill:rgb(73,73,83);fill-rule:nonzero;"/>
            <path d="M74,31C74,28.239 76.239,26 79,26L95.193,26C96.909,26 98.504,26.88 99.42,28.33L102.73,33.571C103.552,34.872 105.474,34.796 106.191,33.434L108.698,28.671C109.562,27.029 111.266,26 113.122,26L129,26C131.761,26 134,28.239 134,31L134,102L136,102L136,31C136,27.134 132.866,24 129,24L113.122,24C110.523,24 108.138,25.44 106.928,27.74L104.421,32.503L101.111,27.262C99.829,25.231 97.595,24 95.193,24L79,24C75.134,24 72,27.134 72,31L72,102L74,102L74,31Z" style="fill:white;"/>
            <path d="M134,171C134,173.761 131.761,176 129,176L113.807,176C112.091,176 110.496,175.12 109.58,173.67L106.191,168.305C105.406,167.062 103.594,167.062 102.809,168.305L99.42,173.67C98.504,175.12 96.909,176 95.193,176L79,176C76.239,176 74,173.761 74,171L74,100L72,100L72,171C72,174.866 75.134,178 79,178L95.193,178C97.595,178 99.829,176.769 101.111,174.738L104.5,169.373L107.889,174.738C109.171,176.769 111.405,178 113.807,178L129,178C132.866,178 136,174.866 136,171L136,100L134,100L134,171Z" style="fill:white;"/>
            <path d="M117,16.111C120.787,16.111 123.857,14.917 123.857,13.444C123.857,13.293 123.825,13.145 123.762,13L125,13L125,17L109,17L109,13L110.238,13C110.175,13.145 110.143,13.293 110.143,13.444C110.143,14.917 113.213,16.111 117,16.111Z" style="fill:rgb(118,118,128);"/>
            <path d="M91,16.111C94.787,16.111 97.857,14.917 97.857,13.444C97.857,13.293 97.825,13.145 97.762,13L99,13L99,17L83,17L83,13L84.238,13C84.175,13.145 84.143,13.293 84.143,13.444C84.143,14.917 87.213,16.111 91,16.111Z" style="fill:rgb(118,118,128);"/>
        </g>
    </g>`
  }
}
