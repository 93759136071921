import React, {useState} from 'react'

import {withTheme} from 'styled-components'
import {IConfigReducer} from '../../../store/reducers/configReducer'
import {useSelector} from 'react-redux'
import StoreTypes from 'StoreTypes'
import {FormattedMessage, useIntl} from 'react-intl'

import {Button, Card, LightBox} from '@liquid-design/liquid-design-react'
import {apiSequenceSteps} from '../../../models/Api/apiSequenceSteps'

export interface IMacroStep {
    id: number
    macroStepName: string
    lightBoxLabel: string
    sequenceID: number
    stepNumber: number
    isDraggable: boolean
    isModel: boolean
    isNotEditable?: boolean
    onDelete: () => void
    theme: any
    children: React.ReactNode
    onSave?: (triggerChanges: boolean, settings?: any, ) => any
    loadedValues?: apiSequenceSteps[]
    disabled?: boolean
}

const MacroStep = (props: IMacroStep) => {

    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const intl = useIntl()
    const colors = props.theme.colors.primary

    const [isLightBoxOpen, setIsLightBoxOpen] = useState(false)

    function dragStart(e: React.DragEvent<HTMLDivElement>) {
        e.dataTransfer.setData('Ms_id', (e.target as any).id)
    }

    function dragOver(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation()
    }

    function validConfiguration() {
        setIsLightBoxOpen(false)
        props.onSave(true)
    }

    return (
        <>
            <Card
                id={props.id.toString()}
                onDragStart={(e: React.DragEvent<HTMLDivElement>) => dragStart(e)}
                onDragOver={(e: React.DragEvent<HTMLDivElement>) => dragOver(e)}
                draggable={props.isDraggable}
                style={{
                    backgroundColor: props.isDraggable ? colors.base : props.isModel ? colors.lightest : colors.base,
                    color: '#FFFFFF'
                }}
            >
                {!props.isModel ? `${props.stepNumber} | ${intl.formatMessage({id: props.macroStepName})} | ` : intl.formatMessage({id: props.macroStepName})}
                {!props.isModel && (
                    <>
                        {!props.isNotEditable && (
                            <span onClick={() => setIsLightBoxOpen(true)}>⚙️</span>
                        )}

                        <span onClick={props.onDelete}>🗑️</span>
                    </>
                )}
            </Card>
            <LightBox
                label={intl.formatMessage({id: props.lightBoxLabel})}
                open={isLightBoxOpen}
                onClose={() => setIsLightBoxOpen(false)}
                reactModalProps={{style: {content: {width: '50%', margin: '0 auto'}}}}
            >
                {props.children}
                <Button disabled={props.disabled} className="float-right" icon='progressBarCheckMark' onClick={() => validConfiguration()}>
                    <FormattedMessage id='label.ValidConfiguration'/>
                </Button>
                <br/>
            </LightBox>
        </>
    )

}

export default withTheme(MacroStep)
