import React from 'react'
import {PortWidget} from '@projectstorm/react-diagrams'

import {YArtifactModel} from './YArtifactModel'
import {
    BaseArtifactWidgetProps,
    BaseArtifactWidgetState,
    BaseArtifactWidget
} from '../../BaseArtifact/BaseArtifactWidget'

import YVisual from './YVisual'

import * as DefaultPort from '../../Port'
import * as StoreTypes from 'StoreTypes'
import {connect} from 'react-redux'
import {IConfigReducer} from '../../../../store/reducers/configReducer'

export interface YNodeWidgetProps extends BaseArtifactWidgetProps<YArtifactModel> {
    size: number
    config: IConfigReducer
}

export interface YNodeWidgetState extends BaseArtifactWidgetState<YArtifactModel> {
}

class YArtifactWidget extends BaseArtifactWidget<YNodeWidgetProps, YNodeWidgetState> {
    constructor(props: YNodeWidgetProps) {
        super(props)
        this.state = {controlPanel: true, selected: 0, show: false, virtualSelected: 0}
    }

    calculateLabelsPosition = () => {
        if (this.labelRef.current) {
            const labelDimensions = {
                width: this.labelRef.current.offsetWidth,
                height: this.labelRef.current.offsetHeight
            }

            const labelCoordinates = {
                x: this.props.size / 2 - labelDimensions.width / 2,
                y: this.props.size - 50
            }

            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`
        }
    };

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

    render() {

        const isSptffModeActivated = (this.props.config.Unit.Set.iUserOptionWeightUser || this.props.config.Unit.Set.iFactOptionWeight) && this.props.config.Unit.Set.bVisibilityWE101;

        let rotationAngle = 0
        let portsFlip = false

        if (this.props.node.code.includes('-')) {
            let artifactParams = this.props.node.code.split('-')

            rotationAngle = parseInt(artifactParams[0])
            if (rotationAngle === 360)
                rotationAngle = -90

            if (artifactParams.length === 3 && artifactParams[2] === "SP") {
                portsFlip = true
            }
        }

        return (
            <div
                className='YArtifact-node'
                style={{
                    position: 'relative',
                    width: this.props.size,
                    height: this.props.size,
                    transform: rotationAngle !== 0 ? `rotate(${rotationAngle}deg)` : 'none'
                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                    super.showPopup(e)
                }}
            >
                <svg
                    width={this.props.size}
                    height={this.props.size}
                    viewBox='0 0 86 117'
                    preserveAspectRatio='xMidYMid'
                    dangerouslySetInnerHTML={{
                        __html: YVisual.build(this.props.node.displayName, this.props.config.Instances.ControlModules, isSptffModeActivated)
                    }}
                />
                <PortWidget
                    port={this.props.node.getPort(portsFlip ? 'out' : 'in')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 16,
                        //backgroundColor: portsFlip ? 'green' : 'red'
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                <PortWidget
                    port={this.props.node.getPort('outangle')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: this.props.size / 2 - 8,
                        left: 4,
                        //backgroundColor: 'blue'
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                <PortWidget
                    port={this.props.node.getPort(portsFlip ? 'in' : 'out')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 16,
                        //backgroundColor: portsFlip ? 'red' : 'green'
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                {super.render()}
            </div>
        )
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(YArtifactWidget)
