import React from 'react'
import ControlledValveNodeWidget from './ControlledValveArtifactWidget'
import { ControlledValveArtifactModel } from './ControlledValveArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class ControlledValveArtifactFactory extends AbstractReactFactory<ControlledValveArtifactModel, DiagramEngine> {
  constructor () {
    super('ControlledValve')
  }

  generateReactWidget (event: any): JSX.Element {
    return <ControlledValveNodeWidget engine={this.engine} height={90} width={80} node={event.model} />
  }

  generateModel (event: any) {
    return new ControlledValveArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
