import {NodeModelGenerics, PortModelOptions} from '@projectstorm/react-diagrams'

import {BaseArtifactModel} from '../../BaseArtifact/BaseArtifactModel'
import {VerticalValvePortArtifactModel} from './VerticalValvePortModel'
import VerticalValvePorts from './VerticalValvePortsConfig'
import { IConditions } from '../../CustomLinks/PipeLink/PipeLinkModel'

export interface VerticalValveNodeModelGenerics {
    PORT: VerticalValveArtifactModel;
}

export class VerticalValveArtifactModel extends BaseArtifactModel<NodeModelGenerics & VerticalValveNodeModelGenerics> {
    constructor(code: string, name: string, nodeId: string, orientation: string = 'horizontal', conditions: IConditions[] = []) {
        super(code, name, nodeId, orientation, {type: 'VerticalValve'}, conditions)
        VerticalValvePorts.forEach((port: PortModelOptions) => this.addPort(
            new VerticalValvePortArtifactModel(port.name, port.alignment)
        ))
    }
}
