import React from 'react'
import MembraneNodeWidget from './MembraneArtifactWidget'
import { MembraneArtifactModel } from './MembraneArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class MembraneArtifactFactory extends AbstractReactFactory<MembraneArtifactModel, DiagramEngine> {
  constructor () {
    super('Membrane')
  }

  generateReactWidget (event: any): JSX.Element {
    return <MembraneNodeWidget engine={this.engine} height={240} width={120} node={event.model} />
  }

  generateModel (event: any) {
    return new MembraneArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
