/**
 * Visual builder for HeatExchanger Artifact
 */
export default class HeatExchangerVisual {
  /**
   * Builds up svg
   * @returns SVG as a string
   * @param isEnabled
   * @param isManual
   * @param alarmState
   */
  static build (isEnabled: boolean, isManual: boolean, alarmState: number): string {
    let fill = isEnabled ? 'rgb(15,105,175)' : '#494953'
    switch (alarmState) {
      case 1: {
        fill = '#E61E50'
        break;
      }
      case 2: {
        fill = '#FFC832'
        break;
      }
    }
    return `<g transform="matrix(1,0,0,1,-0.000545,-0.945545)">
        <g transform="matrix(1,0,0,1,0.953375,-0.574695)">
            <g transform="matrix(0.931235,0,0,0.931235,-0.00741931,1.52024)">
                <path d="M1.743,3.428L7,0L15.268,0C15.948,0 16.5,0.552 16.5,1.232C16.5,1.572 16.776,1.848 17.116,1.848L27,1.848L38.913,1.771C39.238,1.769 39.5,1.505 39.5,1.181C39.5,0.529 40.029,0 40.681,0L49,0L54.297,3.305C55.973,4.35 56.308,6.651 55,8.13L29,8.13L29,8.5L1,8.5C-0.328,6.93 0.021,4.552 1.743,3.428Z" style="fill:rgb(189,189,195);"/>
            </g>
            <g transform="matrix(1,0,0,1,-1.93283,0.763832)">
                <path d="M51.317,4.482C52.843,4.501 54.075,5.744 54.075,7.275L54.075,18.45L54.074,18.486C54.055,20.012 52.812,21.244 51.281,21.244L41.969,21.244L41.932,21.243C40.892,21.23 39.989,20.649 39.519,19.795L16.481,19.795C16.006,20.658 15.087,21.244 14.031,21.244L4.719,21.244L4.683,21.243C3.157,21.224 1.925,19.981 1.925,18.45L1.925,7.275L1.926,7.239C1.945,5.713 3.188,4.481 4.719,4.481L14.031,4.481L14.068,4.482C15.108,4.495 16.011,5.076 16.481,5.93L39.519,5.93C39.995,5.066 40.913,4.481 41.969,4.481L51.281,4.481L51.317,4.482ZM51.293,6.373L41.969,6.372C41.627,6.372 41.33,6.562 41.176,6.842C40.844,7.446 40.209,7.821 39.519,7.821L16.481,7.821C15.791,7.821 15.156,7.446 14.824,6.842C14.672,6.566 14.38,6.377 14.056,6.373C14.056,6.373 14.02,6.372 14.031,6.372L4.719,6.372C4.225,6.372 3.823,6.77 3.817,7.251C3.817,7.251 3.816,7.287 3.817,7.275L3.817,18.45C3.817,18.944 4.214,19.346 4.695,19.352C4.695,19.352 4.731,19.353 4.719,19.352L14.031,19.352C14.373,19.352 14.67,19.163 14.824,18.883C15.156,18.279 15.791,17.904 16.481,17.904L39.519,17.904C40.209,17.904 40.844,18.279 41.176,18.883C41.328,19.159 41.62,19.348 41.944,19.352C41.944,19.352 41.98,19.353 41.969,19.352L51.281,19.352C51.775,19.352 52.177,18.955 52.183,18.474C52.183,18.474 52.184,18.438 52.183,18.45L52.183,7.275C52.183,6.781 51.786,6.379 51.293,6.373Z" style="fill:none;stroke:${isManual ? 'rgb(34,34,34)' : 'invisible'};stroke-width:1.89px;stroke-dasharray:1.89,1.89;"/>
            </g>
            <g transform="matrix(1,0,0,1,-1.93283,0.763832)">
                <path d="M51.317,4.482C52.843,4.501 54.075,5.744 54.075,7.275L54.075,18.45L54.074,18.486C54.055,20.012 52.812,21.244 51.281,21.244L41.969,21.244L41.932,21.243C40.892,21.23 39.989,20.649 39.519,19.795L16.481,19.795C16.006,20.658 15.087,21.244 14.031,21.244L4.719,21.244L4.683,21.243C3.157,21.224 1.925,19.981 1.925,18.45L1.925,7.275L1.926,7.239C1.945,5.713 3.188,4.481 4.719,4.481L14.031,4.481L14.068,4.482C15.108,4.495 16.011,5.076 16.481,5.93L39.519,5.93C39.995,5.066 40.913,4.481 41.969,4.481L51.281,4.481L51.317,4.482Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M51.317,4.482C52.843,4.501 54.075,5.744 54.075,7.275L54.075,18.45L54.074,18.486C54.055,20.012 52.812,21.244 51.281,21.244L41.969,21.244L41.932,21.243C40.892,21.23 39.989,20.649 39.519,19.795L16.481,19.795C16.006,20.658 15.087,21.244 14.031,21.244L4.719,21.244L4.683,21.243C3.157,21.224 1.925,19.981 1.925,18.45L1.925,7.275L1.926,7.239C1.945,5.713 3.188,4.481 4.719,4.481L14.031,4.481L14.068,4.482C15.108,4.495 16.011,5.076 16.481,5.93L39.519,5.93C39.995,5.066 40.913,4.481 41.969,4.481L51.281,4.481L51.317,4.482ZM51.293,6.373L41.969,6.372C41.627,6.372 41.33,6.562 41.176,6.842C40.844,7.446 40.209,7.821 39.519,7.821L16.481,7.821C15.791,7.821 15.156,7.446 14.824,6.842C14.672,6.566 14.38,6.377 14.056,6.373C14.056,6.373 14.02,6.372 14.031,6.372L4.719,6.372C4.225,6.372 3.823,6.77 3.817,7.251C3.817,7.251 3.816,7.287 3.817,7.275L3.817,18.45C3.817,18.944 4.214,19.346 4.695,19.352C4.695,19.352 4.731,19.353 4.719,19.352L14.031,19.352C14.373,19.352 14.67,19.163 14.824,18.883C15.156,18.279 15.791,17.904 16.481,17.904L39.519,17.904C40.209,17.904 40.844,18.279 41.176,18.883C41.328,19.159 41.62,19.348 41.944,19.352C41.944,19.352 41.98,19.353 41.969,19.352L51.281,19.352C51.775,19.352 52.177,18.955 52.183,18.474C52.183,18.474 52.184,18.438 52.183,18.45L52.183,7.275C52.183,6.781 51.786,6.379 51.293,6.373Z" style="fill:rgb(213,213,217);"/>
            </g>
            <g transform="matrix(0.740344,0,0,0.606351,5.33753,5.74375)">
                <path d="M-2.052,7C-2.052,6.448 -1.604,6 -1.052,6L7.597,6C8.149,6 8.597,6.448 8.597,7L8.597,7.556L47.403,7.556L47.403,7C47.403,6.448 47.851,6 48.403,6L57.052,6C57.604,6 58.052,6.448 58.052,7L58.052,19C58.052,19.552 57.604,20 57.052,20L48.403,20C47.851,20 47.403,19.552 47.403,19L47.403,18.444L8.597,18.444L8.597,19C8.597,19.552 8.149,20 7.597,20L-1.052,20C-1.604,20 -2.052,19.552 -2.052,19L-2.052,7Z" style="fill:${fill}"/>
            </g>
        </g>
    </g>`
  }
}
