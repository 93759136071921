import React from 'react';

const NotificationHeader = (props: any) => {

    const { content } = props

    // Apply className based type and category
    function getClassName(category: string) {
        if (category) {
            category = category.toLowerCase();
            switch (category) {
                case "safety":
                case "system":
                case "critical":
                    return "header_bkground_red";
                case "warning":
                    return "header_bkground_orange";
                case "ack_alm":
                    return "header_bkground_gery";
                case "unack_alm":
                    return "header_bkground_white";
                case "info":
                    return "header_bkground_blue";
                default:
                    return "header_bkground_blue";
            }
        } else {
            return "header_bkground_blue";
        }
    }

    if (content && content.length) {
        return (
            <div
                className={`${getClassName(content[0].notificationCategory)} orch-notification-header-navbar`}>
                <div
                    className="orch-notification-header-title alarm-header-truncateText">{content[0].notificationTitle}</div> {/**/}
                <div
                    className="orch-notification-header-description alarm-header-truncateText">{content[0].notificationDesc}</div> {/**/}
            </div>
        );
    }
}

export default NotificationHeader