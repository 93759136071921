import {IPanelOptions} from "./settingsHelpers";
import {Col, Container, Row} from "reactstrap";
import SwitchSelector from "../../SwitchSelector";
import React from "react";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {useIntl} from "react-intl";
import { getModeOptions, getPumpStateOptions } from './StateOptions'

export interface ICommonSwitchesProps {
    stateOptions: IPanelOptions[],
    isAutoMode: boolean,
    isStarted?: boolean,
    onModeChange: (newValue: any) => void,
    onStateChange?: (newValue: any) => void,
    startStopDisabled?: boolean,
    hideStartStop?: boolean,
    autoManuDisabled?: boolean
}

export default function CommonSwitches({stateOptions, isAutoMode, isStarted = false, onModeChange, onStateChange, startStopDisabled = false, hideStartStop = false, autoManuDisabled = false}: ICommonSwitchesProps) {

    const intl = useIntl();
    const [dialogVisible, toggleDialog] = React.useState(false);

    //Get original state from the ControlModule
    const stateInitialSelectedIndex = stateOptions.findIndex(({value}) => value === isStarted);
    const modeInitialSelectedIndex = getModeOptions(intl).findIndex(({value}) => value === isAutoMode);

    return <>
        {dialogVisible && <Dialog title={intl.formatMessage({id: "label.ConfirmModeChangeTitle"})}
                                  onClose={() => toggleDialog(!dialogVisible)}>
            <p style={{
                margin: "25px",
                textAlign: "center"
            }}>{intl.formatMessage({id: "label.ConfirmModeChangeContent"})}</p>
            <DialogActionsBar>
                <button className="k-button"
                        onClick={() => {
                            toggleDialog(!dialogVisible);
                        }}> {intl.formatMessage({id: "label.No"})}</button>
                <button className="k-button"
                        onClick={() => {
                            onModeChange(!isAutoMode);
                            toggleDialog(!dialogVisible);
                        }}>{intl.formatMessage({id: "label.Yes"})}</button>
            </DialogActionsBar>
        </Dialog>}
        <Container>
            <Row style={{padding: '5px'}}>
                <Col>
                    <div style={autoManuDisabled ? {pointerEvents: "none", opacity: "0.4", height:'35px'} : {height:'35px'}}>
                        <SwitchSelector
                            onChange={() => {
                                if (isAutoMode) {
                                    toggleDialog(!dialogVisible);
                                } else {
                                    onModeChange(!isAutoMode);
                                }
                            }}
                            options={getModeOptions(intl)}
                            fontSize={16}
                            initialSelectedIndex={modeInitialSelectedIndex}
                            forcedSelectedIndex={modeInitialSelectedIndex}
                        />
                    </div>
                </Col>
                {!hideStartStop && <Col>
                    {/*Hiding switch selector this way because it does not have its own "disabled" property*/}
                    <div style={isAutoMode || startStopDisabled ? {pointerEvents: "none", opacity: "0.4", height:'35px'} : {height:'35px'}}>
                        <SwitchSelector
                            onChange={onStateChange}
                            options={getPumpStateOptions(intl)}
                            fontSize={16}
                            initialSelectedIndex={stateInitialSelectedIndex}
                            forcedSelectedIndex={stateInitialSelectedIndex}
                        />
                    </div>
                </Col>}
            </Row>
        </Container>
    </>;
}