import React, {useEffect, useRef, useState} from 'react'
import * as StoreTypes from 'StoreTypes'
import {useSelector} from 'react-redux'
import useMountEffect from '../utils/useMountEffect'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import {
    Grid,
    GridColumn as Column,
    GridRowProps
} from '@progress/kendo-react-grid'
import {process} from '@progress/kendo-data-query'
import {IConfigReducer} from '../store/reducers/configReducer'
import {useIntl} from 'react-intl'
import {apiAlarmAndEvent} from '../models/Api/apiAlarmAndEvent'
import {Dialog} from '@progress/kendo-react-dialogs'
import {ScaleLoader} from 'react-spinners'
import {Dropdown} from '@liquid-design/liquid-design-react'
import {IDataSet} from './trends/HistoryTrendsView'
import {apiDataSet} from '../models/Api/apiDataSet'
import {EEventCategory} from '../models/Api/EEventCategory'
import {ColumnMenu} from "../utils/GridColumnFilter";
import {IsNullOrUndefined} from "../utils/plcUtils";


const AlarmsView = () => {
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const intl = useIntl()
    const parsedData = config.AH.Sts.Alarm.ActiveAlarms.map((alarm) => {

        let unzonedate = alarm.AlarmEvent.Value === 2 ? alarm.Created.toString() : alarm.Acknowledged.toString();

        if (unzonedate.endsWith('Z')) {
            unzonedate = unzonedate.substring(0, unzonedate.length - 1);
        }

        // TODO: Add date to alarm model
        (alarm as any).Date = new Date(unzonedate)

        return alarm
    })

    const [dataSetList, setDataSetList] = useState([])
    const [isGettingAlarm, setGetAlarm] = useState(false)
    const [AlarmHistory, setAlarmHistory] = useState<apiAlarmAndEvent[]>([])
    const [AlarmHistorydisplay, setAlarmHistorydisplay] = useState({
        result: {data: [], total: 0},
        dataState: {skip: 0, take: 10}
    })
    const [CurrentAlarmisplay, setCurrentAlarmdisplay] = useState({
        result: {data: [], total: 0},
        dataState: {skip: 0, take: 10}
    })
    const [selectedTab, setSelectedTab] = useState(0)
    const isBlinking = useRef(false)

    useMountEffect(() => {
        //console.log(parsedData)
        const interval = setInterval(() => isBlinking.current = !isBlinking.current, 1000)
        setCurrentAlarmdisplay(createDataState(parsedData, {skip: 0, take: 10}))
        return function cleanup() {
            clearInterval(interval)
        }
    })

    function CellRender(props: any) {
        const value = props.dataItem as any;

        let strValue: string;

        //Get the cell information
        if (props.field === "UserLabel") {
            strValue = (value[props.field] === null) ? '' : value[props.field]
        } else {
            strValue = (value[props.field] === null) ? '' : value[props.field].TextCode;
        }

        //If it's a textCode, try to translate it
        if (strValue.startsWith("label.")) {
            strValue = intl.formatMessage({id: strValue})
        }

        return (
            <td>{strValue}</td>
        );
    }


    function rowRender(tr: React.ReactElement<HTMLTableRowElement>, props: GridRowProps, histo: boolean) {
        const trProps: any = {
            style: {
                backgroundColor: ""
            }
        }

        //Color for alarm event
        if (props.dataItem.AlarmEvent != null) {
            if (props.dataItem.Type.Value === 4) {//non critical alarm
                if (props.dataItem.AlarmEvent.Value === 2 && !histo) {
                    trProps.style = isBlinking.current ? {backgroundColor: "rgb(255, 165, 0, 0.32)"} : {backgroundColor: "rgb(255, 255, 255, 0.32)"}
                } else {
                    trProps.style = {backgroundColor: "rgb(255, 165, 0, 0.32)"}
                }
            } else {//Critical alarm
                if (props.dataItem.AlarmEvent.Value === 2 && !histo) {
                    trProps.style = isBlinking.current ? {backgroundColor: "rgb(243, 23, 0, 0.32)"} : {backgroundColor: "rgb(255, 255, 255, 0.32)"}
                } else {
                    trProps.style = {backgroundColor: "rgb(243, 23, 0, 0.32)"}
                }
            }
        }
        //Color for other event
        else {
            switch (props.dataItem.Category.Value) {
                case EEventCategory.ProcessEvent:
                    trProps.style = {backgroundColor: ""};
                    break;
                case EEventCategory.SequenceEvent:
                    trProps.style = {backgroundColor: ""};
                    break;
                case EEventCategory.UserEvent:
                    trProps.style = {backgroundColor: ""};
                    break;
            }
        }
        return React.cloneElement(tr, trProps, tr.props.children)
    }

    const createDataState = (data: any, dataState: any) => {
        return {
            result: process(data.slice(0), dataState),
            dataState: dataState
        };
    }

    function gethistoryalarm(id: string) {

        let dataSetId: any = []
        let datestart: string
        if (id != null) {
            dataSetId = [id]
        } else {
            dataSetId = []
            datestart = ""
        }

        setGetAlarm(true)
        fetch(`${config.APIBaseUrl}/history/api/DataSets/v1/AlarmsAndEvents/Get`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                    Start: "",
                    End: "",
                    DataSetIds: dataSetId,
                    AlarmTypes: [],
                    Categories: []
                }
            )
        })
            .then((resp) => resp.json())
            .then((histo) => {
                setGetAlarm(false)
                console.log(histo)
                let data = histo.map((alarm: any) => {
                    // TODO: Add date to alarm model
                    let unzonedate = alarm.GatewayTimestamp.toString();

                    if (unzonedate.endsWith('Z')) {
                        unzonedate = unzonedate.substring(0, unzonedate.length - 1);
                    }

                    (alarm as any).Date = new Date(unzonedate)
                    if (alarm.SensorTag === null) {
                        alarm.SensorTag = alarm.Tag
                    }
                    return alarm
                })

                setAlarmHistory(data)
                setAlarmHistorydisplay(createDataState(data, {skip: 0, take: 10}))
            })
            .catch((err) => console.error(err))

    }

    function changeTab(selecttab: number) {
        //If tab history get historical data and dataset
        setSelectedTab(selecttab)
        if (selecttab === 1) {
            gethistoryalarm(null)
            getDataset()
        }
    }

    function getDataset() {
        fetch(`${config.APIBaseUrl}/history/api/DataSets/v1/DataSets`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                withValues: false
            })
        })
            .then((resp) => resp.json())
            .then((resp: apiDataSet[]) => {
                setDataSetList(resp.map((dataSet) => ({
                    id: dataSet.Id.toString(),
                    name: dataSet.Name,
                    code: dataSet.Code
                })))
            })
    }

    function changeDataSet(dataSet: IDataSet) {
        let datasetId: any;

        if (IsNullOrUndefined(dataSet))
            datasetId = null;
        else
            datasetId = dataSet.id

        gethistoryalarm(datasetId)
    }


    return (
        <>
            <div style={{height: '100%'}}>
                <TabStrip
                    selected={selectedTab}
                    onSelect={(tab) => changeTab(tab.selected)}
                    animation={false}
                >
                    <TabStripTab title={intl.formatMessage({id: 'label.CurrentAlarms'})}>
                        <div style={{height: '100%'}}>
                            <Grid
                                style={{height: 'calc(100vh - 350px)'}}
                                sortable={true}
                                pageable={{
                                    buttonCount: 15,
                                    info: true,
                                    type: 'numeric',
                                    pageSizes: [10, 20, 50, 100],
                                    previousNext: true
                                }}
                                {...CurrentAlarmisplay.dataState}
                                data={CurrentAlarmisplay.result}
                                onDataStateChange={(event) => {
                                    setCurrentAlarmdisplay(createDataState(parsedData, event.dataState))
                                }}
                                rowRender={(tr, props) => rowRender(tr, props, false)}
                            >
                                <Column field="Date" title={intl.formatMessage({id: 'label.TableDate'})}
                                        format={'{0:G}'}
                                        filter="date" columnMenu={ColumnMenu} sortable={true}/>
                                <Column field="SensorTag"
                                        title={intl.formatMessage({id: 'label.TableName'})}
                                        filter="text"
                                        columnMenu={ColumnMenu}/>
                                <Column field="UserLabel" filter="text"
                                        cell={(props) => CellRender(props)}
                                        title={intl.formatMessage({id: 'label.TableAlarmLabel'})}
                                        columnMenu={ColumnMenu}/>
                                <Column field="AlarmEvent" filter="text"
                                        cell={(props) => CellRender(props)}
                                        title={intl.formatMessage({id: 'label.TableState'})}
                                        columnMenu={ColumnMenu}/>
                            </Grid>
                        </div>
                    </TabStripTab>
                    <TabStripTab title={intl.formatMessage({id: 'label.EventAndAlarms'})}>
                        <Dropdown
                            label={intl.formatMessage({id: 'label.SelectDataset'})}
                            options={dataSetList}
                            onSubmit={(t: any) => changeDataSet(t)}
                        />
                        <div id="GridContainer" style={{height: '100%'}}>
                            <Grid
                                style={{height: 'calc(100vh - 380px)'}}
                                sortable={{
                                    allowUnsort: true,
                                    mode: 'single'
                                }}
                                pageable={{
                                    buttonCount: 15,
                                    info: true,
                                    type: 'numeric',
                                    pageSizes: [10, 20, 50, 100],
                                    previousNext: true
                                }}
                                {...AlarmHistorydisplay.dataState}
                                data={AlarmHistorydisplay.result}
                                onDataStateChange={(event) => {
                                    setAlarmHistorydisplay(createDataState(AlarmHistory, event.dataState))
                                }}
                                rowRender={(tr, props) => rowRender(tr, props, true)}
                            >
                                <Column field="Date" title={intl.formatMessage({id: 'label.TableDate'})}
                                        filter="date"
                                        format={'{0:G}'} columnMenu={ColumnMenu}/>
                                <Column field="Category" filter="text"
                                        title={intl.formatMessage({id: 'label.TableCategory'})}
                                        cell={(props) => CellRender(props)}
                                        columnMenu={ColumnMenu}/>
                                <Column field="SensorTag" title={intl.formatMessage({id: 'label.TableName'})}
                                        columnMenu={ColumnMenu}/>
                                <Column field="UserLabel" filter="text"
                                        cell={(props) => CellRender(props)}
                                        title={intl.formatMessage({id: 'label.TableEventLabel'})}
                                        columnMenu={ColumnMenu}/>
                                <Column field="AlarmEvent" filter="text"
                                        title={intl.formatMessage({id: 'label.TableState'})}
                                        cell={(props) => CellRender(props)}
                                        columnMenu={ColumnMenu}/>
                                <Column field="Type" filter="text"
                                        title={intl.formatMessage({id: 'label.TableCriticality'})}
                                        cell={(props) => CellRender(props)}
                                        columnMenu={ColumnMenu}/>
                                <Column field="Value" filter="text"
                                        title={intl.formatMessage({id: 'label.TableValue'})}
                                        columnMenu={ColumnMenu}/>
                            </Grid>
                        </div>
                    </TabStripTab>
                </TabStrip>
            </div>
            {isGettingAlarm && (
                <Dialog title={intl.formatMessage({id: 'label.GetHistory'})} closeIcon={false}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <ScaleLoader
                            height={50}
                            width={10}
                            radius={4}
                            margin={2}
                            color={"#123abc"}
                            loading={isGettingAlarm}
                        />
                    </div>
                </Dialog>)}
        </>
    )
}

export default AlarmsView
