import {NodeModelGenerics, PortModelOptions} from '@projectstorm/react-diagrams'

import {BaseArtifactModel} from '../../BaseArtifact/BaseArtifactModel'
import {ValvePortArtifactModel} from './ValvePortModel'
import ValvePorts from './ValvePortsConfig'
import { IConditions } from '../../CustomLinks/PipeLink/PipeLinkModel'

export interface ValveNodeModelGenerics {
    PORT: ValveArtifactModel;
}

export class ValveArtifactModel extends BaseArtifactModel<NodeModelGenerics & ValveNodeModelGenerics> {
    constructor(code: string, name: string, nodeId: string, orientation: string = 'horizontal', conditions: IConditions[] = []) {
        super(code, name, nodeId, orientation, {type: 'Valve'}, conditions)
        ValvePorts.forEach((port: PortModelOptions) => this.addPort(
            new ValvePortArtifactModel(port.name, port.alignment)
        ))
    }
}
