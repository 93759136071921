import {apiSequenceMacroSteps} from '../../../../models/Api/apiSequenceMacroSteps'

/**
 * Update macro step values with user settings / default macro step values
 * @param settings User settings
 * @param macroStep Macro step to update
 */
function merge(settings: any, macroStep: apiSequenceMacroSteps) {
    const {SequenceSteps} = macroStep
    Object.keys(settings).forEach((key) => {
        switch (key) {
            // Pop-up answer (false = prompt, true otherwise)
            case 'isAutoModeOn': {
                if (settings.isAutoModeOn) {
                    SequenceSteps[0].ActionIModType = 0
                    SequenceSteps[0].ActionIModId = 0
                    SequenceSteps[0].ActionICode = 0
                    SequenceSteps[0].ActionRValue = 0
                    SequenceSteps[1].SequenceCriterias[0].IModeType = 0
                    SequenceSteps[1].SequenceCriterias[0].IModId = 0
                    SequenceSteps[1].SequenceCriterias[0].IOperator = 0
                    SequenceSteps[1].SequenceCriterias[0].ICode = 0
                    SequenceSteps[1].SequenceCriterias[0].RValue = 0
                } else {
                    SequenceSteps[0].ActionIModType = 1001
                    SequenceSteps[0].ActionIModId = 0
                    SequenceSteps[0].ActionICode = 6
                    SequenceSteps[0].ActionRValue = 0
                    SequenceSteps[1].SequenceCriterias[0].IModeType = 1001
                    SequenceSteps[1].SequenceCriterias[0].IModId = 0
                    SequenceSteps[1].SequenceCriterias[0].IOperator = 4
                    SequenceSteps[1].SequenceCriterias[0].ICode = 10
                    SequenceSteps[1].SequenceCriterias[0].RValue = 2
                }
                break;
            }
            // Memorize Flux and TMP
            case 'memorization': {
                if (!settings.memorization) {
                    SequenceSteps[28].ActionIModType = 0
                    SequenceSteps[28].ActionIModId = 0
                    SequenceSteps[28].ActionICode = 0
                    SequenceSteps[28].ActionRValue = 0
                    SequenceSteps[29].SequenceCriterias[0].IModeType = 0
                    SequenceSteps[29].SequenceCriterias[0].IModId = 0
                    SequenceSteps[29].SequenceCriterias[0].IOperator = 0
                    SequenceSteps[29].SequenceCriterias[0].ICode = 0
                    SequenceSteps[29].SequenceCriterias[0].RValue = 0
                } else {
                    SequenceSteps[28].ActionIModType = 200
                    SequenceSteps[28].ActionIModId = 1
                    SequenceSteps[28].ActionICode = 10
                    SequenceSteps[28].ActionRValue = 0
                    SequenceSteps[29].SequenceCriterias[0].IModeType = 200
                    SequenceSteps[29].SequenceCriterias[0].IModId = 1
                    SequenceSteps[29].SequenceCriterias[0].IOperator = 4
                    SequenceSteps[29].SequenceCriterias[0].ICode = 6
                    SequenceSteps[29].SequenceCriterias[0].RValue = 1
                }
                break;
            }
            case 'isTransfertOptionWired': {
                if (!settings.isTransfertOptionWired) {
                    SequenceSteps[18].ActionIModType = 0
                    SequenceSteps[18].ActionIModId = 0
                    SequenceSteps[18].ActionICode = 0
                    SequenceSteps[18].ActionRValue = 0

                    SequenceSteps[19].ActionIModType = 0
                    SequenceSteps[19].ActionIModId = 0
                    SequenceSteps[19].ActionICode = 0
                    SequenceSteps[19].ActionRValue = 0

                    SequenceSteps[20].ActionIModType = 0
                    SequenceSteps[20].ActionIModId = 0
                    SequenceSteps[20].ActionICode = 0
                    SequenceSteps[20].ActionRValue = 0

                    SequenceSteps[21].ActionIModType = 0
                    SequenceSteps[21].ActionIModId = 0
                    SequenceSteps[21].ActionICode = 0
                    SequenceSteps[21].ActionRValue = 0

                    SequenceSteps[22].ActionIModType = 0
                    SequenceSteps[22].ActionIModId = 0
                    SequenceSteps[22].ActionICode = 0
                    SequenceSteps[22].ActionRValue = 0
                }
                break;
            }
            case 'isFiltratePCVOptionWired': {
                if (!settings.isFiltratePCVOptionWired) {
                    SequenceSteps[23].ActionIModType = 0
                    SequenceSteps[23].ActionIModId = 0
                    SequenceSteps[23].ActionICode = 0
                    SequenceSteps[23].ActionRValue = 0

                    SequenceSteps[24].ActionIModType = 0
                    SequenceSteps[24].ActionIModId = 0
                    SequenceSteps[24].ActionICode = 0
                    SequenceSteps[24].ActionRValue = 0
                }
                break;
            }
            case 'isFlowmetersOptionWired': {
                if (!settings.isFlowmetersOptionWired) {
                    macroStep.SequenceSteps[6].ActionIModType = 0
                    macroStep.SequenceSteps[6].ActionIModId = 0
                    macroStep.SequenceSteps[6].ActionICode = 0
                    macroStep.SequenceSteps[6].ActionRValue = 0
                }
                break;
            }
            case 'SIC001_P001DropdownSelected': {
                switch (settings.SIC001_P001DropdownSelected.id) {
                    case '1': {
                        // Speed regulation
                        macroStep.SequenceSteps[14].ActionICode = 7
                        macroStep.SequenceSteps[13].ActionRValue = 1
                        break;
                    }
                    case '2': {
                        // Delta P regulation
                        macroStep.SequenceSteps[14].ActionICode = 6
                        macroStep.SequenceSteps[13].ActionRValue = 4
                        break;
                    }
                    case '3': {
                        // Flow regulation
                        macroStep.SequenceSteps[14].ActionICode = 5
                        macroStep.SequenceSteps[13].ActionRValue = 3
                        break;
                    }
                }
                break;
            }
            case 'SIC001_P001SetPointSelected': {
                macroStep.SequenceSteps[14].ActionRValue = settings.SIC001_P001SetPointSelected
                break;
            }
            case 'PCV101DropdownSelected': {
                switch (settings.PCV101DropdownSelected.id) {
                    case '1': {
                        // Position
                        macroStep.SequenceSteps[17].ActionICode = 2
                        macroStep.SequenceSteps[16].ActionRValue = 1
                        break;
                    }
                    case '2': {
                        // Pressure
                        macroStep.SequenceSteps[17].ActionICode = 3
                        macroStep.SequenceSteps[16].ActionRValue = 2
                        break;
                    }
                    case '3': {
                        // TMP regulation
                        macroStep.SequenceSteps[17].ActionICode = 5
                        macroStep.SequenceSteps[16].ActionRValue = 4
                        break;
                    }
                }
                break;
            }
            case 'PCV101SetPointSelected': {
                macroStep.SequenceSteps[17].ActionRValue = settings.PCV101SetPointSelected
                break;
            }
            case 'PCV201DropdownSelected': {
                if (settings.PCV201DropdownSelected === undefined) {
                    //Setting default "no value" values
                    macroStep.SequenceSteps[24].ActionICode = 0
                    macroStep.SequenceSteps[23].ActionRValue = 0
                } else {

                    switch (settings.PCV201DropdownSelected.id) {
                        case '1': {
                            // Position
                            macroStep.SequenceSteps[23].ActionRValue = 1
                            macroStep.SequenceSteps[24].ActionICode = 2
                            break;
                        }
                        case '2': {
                            // Pressure
                            macroStep.SequenceSteps[23].ActionRValue = 2
                            macroStep.SequenceSteps[24].ActionICode = 3
                            break;
                        }
                        case '3': {
                            // TMP regulation
                            macroStep.SequenceSteps[23].ActionRValue = 4
                            macroStep.SequenceSteps[24].ActionICode = 5
                            break;
                        }
                        case '4': {
                            // Flow regulation
                            macroStep.SequenceSteps[23].ActionRValue = 3
                            macroStep.SequenceSteps[24].ActionICode = 4
                            break;
                        }
                    }
                }
                break;
            }
            case 'PCV201SetPointSelected': {
                macroStep.SequenceSteps[24].ActionRValue = settings.PCV201SetPointSelected
                break;
            }
            case 'SIC401_P401DropdownSelected': {
                switch (settings.SIC401_P401DropdownSelected.id) {
                    // Speed
                    case '1': {
                        // Speed regulation
                        macroStep.SequenceSteps[20].ActionRValue = 1

                        // Disable Level Start Pump
                        macroStep.SequenceSteps[18].ActionIModType = 0
                        macroStep.SequenceSteps[18].ActionIModId = 0
                        macroStep.SequenceSteps[18].ActionICode = 0
                        macroStep.SequenceSteps[18].ActionRValue = 0
                        // Disable Level Stop Pump
                        macroStep.SequenceSteps[19].ActionIModType = 0
                        macroStep.SequenceSteps[19].ActionIModId = 0
                        macroStep.SequenceSteps[19].ActionICode = 0
                        macroStep.SequenceSteps[19].ActionRValue = 0
                        break;
                    }
                    case '2': {
                        // Delta level regulation
                        macroStep.SequenceSteps[20].ActionRValue = 2
                        // Level start setting
                        macroStep.SequenceSteps[18].ActionIModType = 116
                        macroStep.SequenceSteps[18].ActionIModId = 1
                        macroStep.SequenceSteps[18].ActionICode = 3
                        // Level Stop Setting
                        macroStep.SequenceSteps[19].ActionIModType = 116
                        macroStep.SequenceSteps[19].ActionIModId = 1
                        macroStep.SequenceSteps[19].ActionICode = 4
                        // Disable Start using Tank (Speed)
                        macroStep.SequenceSteps[22].ActionIModType = 0
                        macroStep.SequenceSteps[22].ActionIModId = 0
                        macroStep.SequenceSteps[22].ActionICode = 0
                        macroStep.SequenceSteps[22].ActionRValue = 0
                        break;
                    }
                }
                break;
            }
            case 'SIC401_P401StartSelected': {
                switch (settings.SIC401_P401StartSelected) {
                    case 0: {
                        // Set speed mode
                        macroStep.SequenceSteps[20].ActionRValue = 1
                        // Disable Level Start Pump
                        macroStep.SequenceSteps[18].ActionIModType = 0
                        macroStep.SequenceSteps[18].ActionIModId = 0
                        macroStep.SequenceSteps[18].ActionICode = 0
                        macroStep.SequenceSteps[18].ActionRValue = 0
                        // Disable Level Stop Pump
                        macroStep.SequenceSteps[19].ActionIModType = 0
                        macroStep.SequenceSteps[19].ActionIModId = 0
                        macroStep.SequenceSteps[19].ActionICode = 0
                        macroStep.SequenceSteps[19].ActionRValue = 0
                        // Stop pump using Tank (Speed)
                        macroStep.SequenceSteps[22].ActionIModType = 116
                        macroStep.SequenceSteps[22].ActionIModId = 1
                        macroStep.SequenceSteps[22].ActionICode = 7
                        macroStep.SequenceSteps[22].ActionRValue = 0
                        break;
                    }
                    case 1: {
                        // Start Pump
                        macroStep.SequenceSteps[22].ActionIModType = 116
                        macroStep.SequenceSteps[22].ActionIModId = 1
                        macroStep.SequenceSteps[22].ActionICode = 7
                        macroStep.SequenceSteps[22].ActionRValue = 1
                        break;
                    }
                }
                break;
            }
            case 'SIC401_P401SetPointSelected': {
                macroStep.SequenceSteps[21].ActionRValue = settings.SIC401_P401SetPointSelected
                break;
            }
            case 'SIC401_P401LevelStart': {
                macroStep.SequenceSteps[18].ActionRValue = settings.SIC401_P401LevelStart
                break;
            }
            case 'SIC401_P401LevelStop': {
                macroStep.SequenceSteps[19].ActionRValue = settings.SIC401_P401LevelStop
                break;
            }
        }
    })
}

export default merge