import React from 'react'
import {PortWidget} from '@projectstorm/react-diagrams'

import { BaseArtifactWidgetProps, BaseArtifactWidgetState, BaseArtifactWidget } from '../../BaseArtifact/BaseArtifactWidget'
import {ValveArtifactModel} from './ValveArtifactModel'

import ValveVisual from './ValveVisual'

import * as DefaultPort from '../../Port'
import * as StoreTypes from 'StoreTypes'
import { connect } from 'react-redux'
import { IConfigReducer } from '../../../../store/reducers/configReducer'

export interface ValveNodeWidgetProps extends BaseArtifactWidgetProps<ValveArtifactModel> {
    size: number
    config: IConfigReducer
}

export interface ValveNodeWidgetState extends BaseArtifactWidgetState<ValveArtifactModel> {
}

class ValveArtifactWidget extends BaseArtifactWidget<ValveNodeWidgetProps, ValveNodeWidgetState> {
    constructor(props: ValveNodeWidgetProps) {
        super(props)
        this.state = {controlPanel: false, selected: 0, show: false, virtualSelected: 0}
    }

    calculateLabelsPosition = () => {
        if (this.labelRef.current) {
            const labelDimensions = {
                width: this.labelRef.current.offsetWidth,
                height: this.labelRef.current.offsetHeight
            }

            const labelCoordinates = {
                x: this.props.size / 2 - labelDimensions.width / 2 + 8,
                y: this.props.size - 80
            }

            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`
        }
    };

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

  render () {
    return (
      <div
        className='ValveArtifact-node'
        style={{
          position: 'relative',
          width: this.props.size,
          height: this.props.size
        }}
      >
        <svg
          width={this.props.size}
          height={this.props.size}
          viewBox='0 0 32 26'
          preserveAspectRatio='xMidYMid'
          dangerouslySetInnerHTML={{
            __html: ValveVisual.build(BaseArtifactWidget.getConditionsStatus(this.props.node.getConditions(), this.props.config))
          }}
        />
        <PortWidget
          port={this.props.node.getPort('left')} engine={this.props.engine}
          style={{
            position: 'absolute',
            top: this.props.size / 2 - 9,
            left: 0
          }}
        >
          <DefaultPort.Port />
        </PortWidget>
        <PortWidget
          port={this.props.node.getPort('right')} engine={this.props.engine}
          style={{
            position: 'absolute',
            top: this.props.size / 2 - 9,
            right: 0
          }}
        >
          <DefaultPort.Port />
        </PortWidget>
        {super.render()}
      </div>
    )
  }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
  config: state.config
})

export default connect(mapStateToProps)(ValveArtifactWidget)
