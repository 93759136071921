import { PortModelAlignment } from '@projectstorm/react-diagrams'

export default [
  {
    name: 'left',
    alignment: PortModelAlignment.TOP
  },
  {
    name: 'right',
    alignment: PortModelAlignment.BOTTOM
  }
]
