import { NodeModelGenerics, PortModelOptions } from '@projectstorm/react-diagrams'

import { BaseArtifactModel } from '../../BaseArtifact/BaseArtifactModel'
import { MembranePortArtifactModel } from './MembranePortModel'
import MembranePorts from './MembranePortsConfig'

export interface MembraneNodeModelGenerics {
  PORT: MembraneArtifactModel;
}

export class MembraneArtifactModel extends BaseArtifactModel<NodeModelGenerics & MembraneNodeModelGenerics> {
  constructor (code: string, name: string, nodeId: string, orientation: string = 'horizontal') {
    super(code, name, nodeId, orientation, { type: 'Membrane' })
    MembranePorts.forEach((port: PortModelOptions) => this.addPort(
      new MembranePortArtifactModel(port.name, port.alignment)
    ))
  }
}
