import React, {useCallback, useState} from 'react';
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import {
    RadioButton,
    RadioButtonChangeEvent,
} from "@progress/kendo-react-inputs";
import {Button} from "@liquid-design/liquid-design-react";
import {IsEmptyOrNullOrUndefined} from "../utils/plcUtils";
import moment from "moment/moment";
import {IConfigReducer} from "../store/reducers/configReducer";
import {useSelector} from "react-redux";
import StoreTypes from "StoreTypes";
import {Dialog} from "@progress/kendo-react-dialogs";
import {ScaleLoader} from "react-spinners";
import {downloadBlob} from "download.js";

export interface IDataSetExportInformation {
    id: number;
    name: string;
    code: string;
    startDate?: any;
    endDate?: any;
}

enum SampleRate {
    NoResampling = 0,
    FiveSecond = 5,
    TenSecond = 10,
    ThirtySecond = 30,
    OneMinute = 60,
    FiveMinute = 300,
    TenMinute = 600
}

type IProps = {
    dataSetInfo: IDataSetExportInformation;
    onDataSetExported?: () => void;
}

export const ExportConfigurationPanel: React.FC<IProps> = (props) => {
    const intl: IntlShape = useIntl();
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const [isFetchingData, setFetchingData] = useState(false);
    const [sampleRate, setSampleRate] = useState(SampleRate.NoResampling);

    const onSampleRateChanged = useCallback(
        (e: RadioButtonChangeEvent) => {
            setSampleRate(e.value);
        },
        [setSampleRate]
    );

    function exportDataSet() {

        let url = `${config.APIBaseUrl}/Reporting/api/Reports/v1/GetDataSetExcelFile/${props.dataSetInfo.id}`
        let queryString = "";

        // Partial export, providing date range
        if (!IsEmptyOrNullOrUndefined(props.dataSetInfo.startDate) && !IsEmptyOrNullOrUndefined(props.dataSetInfo.endDate)) {
            queryString = `start=${moment(new Date(props.dataSetInfo.startDate)).format('YYYY-MM-DD HH:mm:ss')}&end=${moment(new Date(props.dataSetInfo.endDate)).format('YYYY-MM-DD HH:mm:ss')}`;
        }

        //Custom sample rate
        if (sampleRate !== SampleRate.NoResampling) {
            if (queryString !== "")
                queryString = `${queryString}&`

            queryString = queryString + `sampleRate=${sampleRate}`;
        }

        // Finalizing url with querystring, if provided
        if (queryString !== "") {
            url = `${url}?${queryString}`
        }

        setFetchingData(true)

        fetch(url, {
            method: 'GET'
        })
            .then((resp) => resp.blob())
            .then((blob) => {
                downloadBlob(`${props.dataSetInfo.code}.xlsx`, blob);
                setFetchingData(false);

                // Inform parent, if a callback is provided
                props?.onDataSetExported();
            }).catch(() => {
            setFetchingData(false)
        })
    }

    return (
        <>
            <div>
                <RadioButton
                    name="radioSampleRate"
                    value={SampleRate.NoResampling}
                    checked={sampleRate === SampleRate.NoResampling}
                    label={intl.formatMessage({id: "label.ExportAllData"})}
                    onChange={onSampleRateChanged}
                />
            </div>
            <div style={{marginTop: '15px'}}>
                <div style={{fontWeight: "bold"}}>
                    <FormattedMessage id='label.RecordSampleInterval'/>
                </div>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div>
                        <RadioButton
                            name="radioSampleRate"
                            value={SampleRate.FiveSecond}
                            checked={sampleRate === SampleRate.FiveSecond}
                            label={intl.formatMessage({id: "label.FiveSeconds"})}
                            onChange={onSampleRateChanged}
                        />
                        <br/>
                        <RadioButton
                            name="radioSampleRate"
                            value={SampleRate.TenSecond}
                            checked={sampleRate === SampleRate.TenSecond}
                            label={intl.formatMessage({id: "label.TenSeconds"})}
                            onChange={onSampleRateChanged}
                        />
                        <br/>
                        <RadioButton
                            name="radioSampleRate"
                            value={SampleRate.ThirtySecond}
                            checked={sampleRate === SampleRate.ThirtySecond}
                            label={intl.formatMessage({id: "label.ThirtySeconds"})}
                            onChange={onSampleRateChanged}
                        />
                    </div>
                    <div style={{marginLeft: "50px"}}>
                        <RadioButton
                            name="radioSampleRate"
                            value={SampleRate.OneMinute}
                            checked={sampleRate === SampleRate.OneMinute}
                            label={intl.formatMessage({id: "label.OneMinute"})}
                            onChange={onSampleRateChanged}
                        />
                        <br/>
                        <RadioButton
                            name="radioSampleRate"
                            value={SampleRate.FiveMinute}
                            checked={sampleRate === SampleRate.FiveMinute}
                            label={intl.formatMessage({id: "label.FiveMinutes"})}
                            onChange={onSampleRateChanged}
                        />
                        <br/>
                        <RadioButton
                            name="radioSampleRate"
                            value={SampleRate.TenMinute}
                            checked={sampleRate === SampleRate.TenMinute}
                            label={intl.formatMessage({id: "label.TenMinutes"})}
                            onChange={onSampleRateChanged}
                        />
                    </div>
                </div>
                <div style={{marginTop: '30px', width: '100%', display: "flex", justifyContent: "center"}}>
                    <Button icon='exportFile' disabled={isFetchingData} onClick={() => exportDataSet()}>
                        <FormattedMessage id='label.ExportDataset'/>
                    </Button>
                </div>
            </div>
            {isFetchingData &&
                <Dialog title={intl.formatMessage({id: 'label.LoadingDataPleaseWait'})} closeIcon={false}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <ScaleLoader
                            height={50}
                            width={10}
                            radius={4}
                            margin={2}
                            color={"#123abc"}
                            loading={isFetchingData}
                        />
                    </div>
                </Dialog>}
        </>
    )
}

