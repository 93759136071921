import React, {useRef, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {withTheme} from 'styled-components'
import {Button, Checkbox, ContentCard, Dropdown, Modal, TextField} from '@liquid-design/liquid-design-react'
import useMountEffect from '../../utils/useMountEffect'
import {apiProfile} from '../../models/Api/apiProfile'
import {
    Chart,
    ChartArea,
    ChartCategoryAxis,
    ChartCategoryAxisCrosshair,
    ChartCategoryAxisCrosshairTooltip,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesItemTooltip,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem
} from '@progress/kendo-react-charts'
import moment from 'moment'
import _ from 'lodash'
import {
    createNoteVisual,
    FormDateTimePicker, FormInput,
    FormTextArea,
    generateEventsNotes,
    IEventTooltipInfo,
    TrendsUtils
} from './TrendsUtils'
import {FormattedMessage, useIntl} from 'react-intl'
import StoreTypes from "StoreTypes"
import {IConfigReducer} from '../../store/reducers/configReducer'
import {LegendItemClickEvent, PlotAreaClickEvent} from '@progress/kendo-react-charts/dist/npm/common/events'
import {configActions} from '../../store/actions'
import {IAnalogueSensor} from "../../models/PLC/IAnalogueSensor";
import {Popup} from '@progress/kendo-react-popup'
import {CategoryAxisNotes} from "@progress/kendo-react-charts/dist/npm/option-types/category-axis-item/notes.interface";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import {apiComments} from "../../models/Api/apiComments";
import {handleResponse} from "../../utils/responseUtils";
import {ELoggingState} from "../../models/PLC/ELoggingState";
import {IsNullOrUndefined} from "../../utils/plcUtils";
import {getDigitFormat, getSensorDefaultDeadBand} from "../../components/Diagram/ControlPanels/settingsHelpers";
import defaultChartDateFormats from "../../config/applicationParameters";
import {ControlModuleBase} from "../../models/PLC/ControlModuleBase";

export interface IProfile {
    id?: string
    name: string
    tags: string[]
}

export interface ICurrentProfile {
    Name: string
    Tags: string[]
}

export interface IChartData {
    date: Date
    value: number
}

export interface IChartSeriesData {
    data: Record<string, IChartData[]>
    sensor: IAnalogueSensor
    NodeID: string
    Cfg_sTag: string
    Unit: string
    DeadBand: number
}

export interface IChartValueData {
    name: string
    unit: string
}

export interface ITimeRange {
    name: string
    id: string
}

const styles: any = {
    pinListItem: {
        cursor: 'pointer',
        height: '60px',
        width: '100%',
        borderRadius: '10px',
        textAlign: 'center',
        lineHeight: '40px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
    }
}

/**
 * Getting the greatest date in the sensor's history
 * returns the current local date, if none found in histories
 * @param config
 */
function getGreatestHistoryDate(config: IConfigReducer) {
    let latestDates: Date[] = [];

    //Iterating through control modules to get the latest date in data values
    Object.keys(config.Instances.ControlModules).forEach(
        (CMName) => {
            //console.log(CMName, config.Instances.ControlModules[CMName].History)
            if (config.Instances.ControlModules[CMName].Cfg_bEnable && Object.keys(config.Instances.ControlModules[CMName].History).length > 0) {
                let valueKey = Object.keys(config.Instances.ControlModules[CMName].History)[0];
                latestDates.push(new Date(_.last(Object.keys(config.Instances.ControlModules[CMName].History[valueKey]))))
            }
        }
    )

    //Getting the greatest available date available
    return latestDates.length > 0 ? _.max(latestDates) : new Date();
}

function getGreatestHistoryDateUnzoned(config: IConfigReducer) {
    let latestDates: Date[] = [];

    //Iterating through control modules to get the latest date in data values
    Object.keys(config.Instances.ControlModules).forEach(
        (CMName) => {
            //console.log(CMName, config.Instances.ControlModules[CMName].History)
            if (config.Instances.ControlModules[CMName].Cfg_bEnable && Object.keys(config.Instances.ControlModules[CMName].History).length > 0) {
                let valueKey = Object.keys(config.Instances.ControlModules[CMName].History)[0];
                let lastCmDate = _.last(Object.keys(config.Instances.ControlModules[CMName].History[valueKey]));

                latestDates.push(new Date(lastCmDate.substring(0, lastCmDate.length - 1)));
            }
        }
    )

    //Getting the greatest available date available
    return latestDates.length > 0 ? _.max(latestDates) : new Date();
}

const LiveTrendsView = (props: any) => {
    const {colors} = props.theme
    const chart = useRef(null)
    const intl = useIntl()
    const dispatch = useDispatch();

    const TIME_RANGES: ITimeRange[] = [
        {name: intl.formatMessage({id: 'label.Dropdown5'}), id: '5'},
        {name: intl.formatMessage({id: 'label.Dropdown10'}), id: '10'},
    ]

    let chartSeries: IChartSeriesData[] = [];
    let chartValueAxis: IChartValueData[] = [];

    const notes: CategoryAxisNotes = {
        data: [],
        visual: createNoteVisual
    }

    const {config: currentConfig} = props
    const [isSensorDialogVisible, setIsSensorDialogVisible] = useState(false)
    const [isProfileDialogVisible, setIsProfileDialogVisible] = useState(false)
    const [isCommentDialogVisible, setIsCommentDialogVisible] = useState(false)
    const [currentProfile, setCurrentProfile] = useState<ICurrentProfile | null>(null)
    const [profileList, setProfileList] = useState<IProfile[]>([])
    const [newProfileName, setNewProfileName] = useState('')
    const [showChartParts, setChartPartsVisibility] = useState(false);
    const [currentTimeRange, setCurrentTimeRange] = useState(TIME_RANGES[0])
    const [chartPauseDate, setChartPauseDate] = useState(moment(new Date()))
    const [pausedConfig, setPausedConfig] = useState<IConfigReducer>(currentConfig)

    //Event tooltip state
    const [tooltipParams, _setTooltipParams] = useState<IEventTooltipInfo>({
        showEventTooltip: false,
        eventTooltipContent: '',
        offset: {left: 150, top: 50}
    })
    const myStateRef = React.useRef(tooltipParams);
    const setTooltipParams = (data: IEventTooltipInfo) => {
        myStateRef.current = data;
        _setTooltipParams(data);
    };
    const [commentEventData, setCommentEventData] = useState<PlotAreaClickEvent>(undefined);

    const config = currentConfig.didPauseLive ? pausedConfig : currentConfig
    const controlModules = config.NodeId ? config.Instances.ControlModules : {}

    useMountEffect(() => {
        fetch(`${config.APIBaseUrl}/Settings/api/Settings/v1/TrendProfiles`, {
            method: 'GET'
        })
            .then((resp) => resp.json())
            .then((resp) => {
                setCurrentProfile(resp.CurrentProfile)
                setProfileList(resp.Profiles.map((profile: apiProfile, i: number) => ({
                    id: i.toString(),
                    name: profile.Name,
                    tags: profile.Tags
                })));
                setChartPartsVisibility(true);
            });

        //Handlers for popup on events
        chart.current.chartInstance.surface.bind("mouseenter", handleMouseEnter);
        chart.current.chartInstance.surface.bind("mouseleave", handleMouseLeave);
    })

    /**
     * MouseEnter event on chart surface : opens event tooltip if suitable
     * @param e
     */
    function handleMouseEnter(e: any) {
        if (e.element.parent.tooltip === undefined) {
            return;
        }

        if (e.originalEvent.target.nodeName === "circle") {
            let elementRect = e.originalEvent.target.getBoundingClientRect()
            if (!myStateRef.current.showEventTooltip) {

                setTooltipParams({
                    showEventTooltip: true,
                    eventTooltipContent: e.element.parent.tooltip,
                    offset: {left: elementRect.left, top: elementRect.top + elementRect.height}
                })
            }
        }
    }

    /**
     * MouseEnter event on chart surface : closes
     * @param e
     */
    function handleMouseLeave(e: any) {
        if (e.element.parent.tooltip === undefined) {
            return;
        }

        if (myStateRef.current.showEventTooltip) {
            setTooltipParams({
                showEventTooltip: false,
                eventTooltipContent: '',
                offset: {left: 0, top: 0}
            })
        }
    }

    /**
     * Generic function updating profile (PUT) with provided body
     * @param body Element/s to update
     */
    function PUTProfileAPI(body: object) {
        return fetch(`${config.APIBaseUrl}/Settings/api/Settings/v1/TrendProfiles`, {
            method: 'PUT',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(body)
        })
    }

    /**
     * Creates a new empty profile
     */
    function createProfile() {
        const newProfile: IProfile = {
            name: newProfileName,
            tags: []
        }
        PUTProfileAPI({
            Profiles: [
                ...profileList.map((p) => {
                    return {
                        Name: p.name,
                        Tags: p.tags
                    }
                }),
                {
                    Name: newProfile.name,
                    Tags: newProfile.tags
                }
            ],
            CurrentProfile: {
                Name: newProfile.name,
                Tags: newProfile.tags
            }
        })
            .then((resp) => resp.json())
            .then((resp) => {
                setNewProfileName('')
                setCurrentProfile(resp.CurrentProfile)
                setProfileList(resp.Profiles.map((profile: apiProfile, i: number) => ({
                    id: i.toString(),
                    name: profile.Name,
                    tags: profile.Tags
                })))
                setIsProfileDialogVisible(false)
            })
    }

    /**
     * Removes selected profile
     */
    function deleteProfile() {
        PUTProfileAPI({
            Profiles: profileList.filter((p) => (p.name !== currentProfile.Name)),
            CurrentProfile: null
        })
            .then((resp) => resp.json())
            .then((resp) => {
                setCurrentProfile(resp.CurrentProfile)
                setProfileList(resp.Profiles.map((profile: apiProfile, i: number) => ({
                    id: i.toString(),
                    name: profile.Name,
                    tags: profile.Tags
                })))
                setIsProfileDialogVisible(false)
            })
    }

    /**
     * Changes profile with dropdown
     * @param profile New profile to use
     */
    function changeProfile(profile: IProfile) {
        let index = -1;

        if (profile !== null) {
            index = profileList.findIndex((p) => p.name === profile.name)
        }

        PUTProfileAPI({
            Profiles: profileList.map((p) => ({
                Name: p.name,
                Tags: p.tags
            })),
            CurrentProfile: index !== -1 ? {
                Name: profileList[index].name,
                Tags: profileList[index].tags
            } : null
        })
            .then((resp) => resp.json())
            .then((resp) => {
                setCurrentProfile(resp.CurrentProfile)
                setProfileList(resp.Profiles.map((profile: apiProfile, i: number) => ({
                    id: i.toString(),
                    name: profile.Name,
                    tags: profile.Tags
                })))
            });
    }

    /**
     * Pin / Un-pin selected sensor
     * @param tag Sensor to pin/un-pin
     */
    function togglePin(tag: string) {
        const index = currentProfile.Tags.findIndex((t) => t === tag)
        const newCurrentProfile = {
            Name: currentProfile.Name,
            Tags: index === -1 ? [
                ...currentProfile.Tags,
                tag
            ] : currentProfile.Tags.filter((t, i) => i !== index)
        }
        PUTProfileAPI({
            Profiles: profileList.map((p) => (p.name === currentProfile.Name ? newCurrentProfile : {
                Name: p.name,
                Tags: p.tags
            })),
            CurrentProfile: newCurrentProfile
        })
            .then((resp) => resp.json())
            .then((resp) => {
                setCurrentProfile(resp.CurrentProfile)
                setProfileList(resp.Profiles.map((profile: apiProfile, i: number) => ({
                    id: i.toString(),
                    name: profile.Name,
                    tags: profile.Tags
                })))
            })
    }

    /**
     * Toggles pause mode
     */
    function togglePause() {

        //Get the latest history date as t0 point
        let refDate = getGreatestHistoryDate(config);

        //Save Pause date and config (data)
        let theDate = moment(refDate);
        setChartPauseDate(theDate);
        setPausedConfig(config);

        //Update store with the "paused" status
        dispatch(configActions.setDidPauseLive(!currentConfig.didPauseLive))
    }

    /**
     * Generate data for the chart : sensor values series and corresponding unit axis.
     * Generates chart notes too
     */
    function generateChartSeries() {

        notes.data = generateEventsNotes(config);

        //Ignoring PSH and WARN from live trends
        const CMs = Object.keys(config.Instances.ControlModules).filter(item => !item.startsWith("PSH") && !item.startsWith("WARN"))

        for (let i = 0; i < CMs.length; i++) {
            const data: Record<string, IChartData[]> = {}
            const CMTag = CMs[i]
            const CM = config.Instances.ControlModules[CMTag]

            if (CM.Sts_bEnable && (CM.ModuleNature === 2 || CM.ModuleNature === 4)) {
                if (chartValueAxis.findIndex((i: IChartValueData) => i.name === config.Instances.ControlModules[CMTag].Values[0].Cfg_suSensorUnit.Name) === -1) {
                    //Show Unit from pinned sensors (or all if no profile is available)
                    if (!currentProfile || (currentProfile && currentProfile.Tags.includes(CM.Cfg_sTag))) {
                        const CMValues = config.Instances.ControlModules[CMTag].Values
                        for (let j = 0; j < CMValues.length; j++) {
                            const item = CMValues[j]
                            let newItem = {
                                name: item.Cfg_suSensorUnit.Name,
                                unit: item.Cfg_suSensorUnit.Unit
                            }
                            if (!chartValueAxis.includes(newItem)) {
                                chartValueAxis = [
                                    ...chartValueAxis,
                                    newItem
                                ]
                            }
                        }
                    }
                }

                //using greatest history date as ref date, to avoid local clock "difference" problem (#2307)
                let refDateLocal = getGreatestHistoryDate(config);
                let now = moment(new Date(refDateLocal));

                const CMHistory = Object.keys(CM.History)
                for (let j = 0; j < CMHistory.length; j++) {
                    const NodeID = CMHistory[j]
                    data[NodeID] = []
                    const NodeIDHistory = Object.keys(CM.History[NodeID])

                    for (let k = 0; k < NodeIDHistory.length; k++) {
                        const d = NodeIDHistory[k]
                        //Deleting zone info to squeeze Date() time zone conversion
                        const unZonedDate = new Date(NodeIDHistory[k].substring(0, NodeIDHistory[k].length - 1));

                        //Diff will be made on the "local time" values (diff is 5 o 10 minutes, so hours does not matter)
                        let past = moment(new Date(d))

                        //Comparing local time dates, as we just wnt a 5 or 10 minutes slots, so time zone does not matter, as soon as we use the same for both dates
                        if ((currentConfig.didPauseLive ? chartPauseDate : now).diff(past, 'minutes') < parseInt(currentTimeRange.id)) {
                            data[NodeID].push({
                                date: new Date(unZonedDate),
                                value: CM.History[NodeID][d]
                            })
                        }
                    }
                }
            }
            if (!currentProfile || (currentProfile && currentProfile.Tags.includes(CM.Cfg_sTag))) {
                Object.keys(data).forEach((NodeID) => {
                    const sensor = config.Instances.ControlModules[
                        _.findKey(config.tagToF_,
                            (o) => o === NodeID.split('..')[1].split('.')[3])
                        ] as IAnalogueSensor

                    if (sensor.Values.length > 1) {
                        let valueInfo = sensor.Values.find(value => {
                            return value.Name === NodeID.split('..')[1].split('.').pop()
                        });

                        if (valueInfo) {
                            chartSeries = [
                                ...chartSeries,
                                {
                                    data: data,
                                    sensor: sensor,
                                    NodeID: NodeID,
                                    Cfg_sTag: valueInfo.Cfg_sTag,
                                    Unit: valueInfo.Cfg_suSensorUnit.Unit,
                                    DeadBand: getSensorDefaultDeadBand(valueInfo.Cfg_dDeadBands)
                                }
                            ]
                        }
                    } else {
                        chartSeries = [
                            ...chartSeries,
                            {
                                data: data,
                                sensor: sensor,
                                NodeID: NodeID,
                                Cfg_sTag: sensor.Cfg_sTag,
                                Unit: sensor.Values[0].Cfg_suSensorUnit.Unit,
                                DeadBand: getSensorDefaultDeadBand(sensor.Values[0].Cfg_dDeadBands)
                            }
                        ]
                    }
                })
            }
        }
    }

    const sensors: ControlModuleBase[] = []
    Object.keys(controlModules).forEach((tag) => {
        const controlModule = controlModules[tag]
        if (controlModule.Sts_bEnable && (controlModule.ModuleNature === 2 || controlModule.ModuleNature === 4)) {
            sensors.push(controlModule)
        }
    })
    const rows = Math.ceil(sensors.length / 4);
    let sensorRows: Array<Array<ControlModuleBase>> = [];

    for (let i = 0; i < rows; i++) {
        sensorRows.push(sensors.slice(i * 4, i * 4 + 4))
    }

    const sensorSelectionList = sensorRows.map((row, idx) => {
        return (
            <div className='row' style={{marginBottom: '2.5%'}} key={idx}>
                {row.map((sens: any) =>
                    <div key={sens.Cfg_sTag} className='col-3 justify-content-center'
                         onClick={() => togglePin(sens.Cfg_sTag)}>
                        <div
                            style={{
                                ...styles.pinListItem,
                                backgroundColor: (currentProfile && currentProfile.Tags.indexOf(sens.Cfg_sTag) > -1) ? colors.primary.base : colors.white.base,
                                color: (currentProfile && currentProfile.Tags.indexOf(sens.Cfg_sTag) > -1) ? colors.white.base : colors.primary.base
                            }}
                            className='frame'>
                            <b>{sens.Cfg_sTag}</b>
                        </div>
                    </div>
                )}
            </div>
        )
    })

    /**
     * Storing hidden/shown trends when clicking on the chart legend
     * @param i
     */
    function switchMaskedSensor(i: LegendItemClickEvent) {
        props.switchSensorMasking(i.text)
    }

    /**
     *  Comment Form validator function for text area
     * @param value
     */
    const requiredValidator = (value: string) => value ? "" : intl.formatMessage({id: 'label.FieldRequired'});

    function padLeft(num: number, size: number): string {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    function formatDate(theDate: Date) {
        let mm = theDate.getMonth() + 1; // getMonth() is zero-based
        let dd = theDate.getDate();

        let HH = theDate.getHours();
        let MM = theDate.getMinutes();
        let ss = theDate.getSeconds();
        let sss = theDate.getMilliseconds();

        let datePart = [theDate.getFullYear(),
            (mm > 9 ? '' : '0') + mm,
            (dd > 9 ? '' : '0') + dd
        ].join('-')

        let timepart = [(HH > 9 ? '' : '0') + HH,
            (MM > 9 ? '' : '0') + MM,
            (ss > 9 ? '' : '0') + ss].join(':') + '.' + padLeft(sss, 3);

        return datePart + ' ' + timepart;
    }

    /**
     * Creates a comment and send it to the database
     */
    function createCommentEventHandler(formData: any) {

        let newComment: apiComments = new apiComments();
        newComment.Content = formData.comments;
        newComment.Start = new Date(formatDate(formData.startDate) + " +00:00");

        fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/Comments/AddOrUpdate`, {
            method: 'PUT',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify(newComment)
        }).then(handleResponse)
            .then((resp) => {
                //Add comment to the comments list in the store and close the window
                dispatch(configActions.addComment(resp))
                setIsCommentDialogVisible(false)
            }).catch((error: any) => {
            //TODO:Add proper error handling !
            console.log(error)
        })
    }

    function toggleSelectedSensor(name: string) {
        let modified = currentConfig.selectedEvents;
        modified[name] = !modified[name];

        dispatch(configActions.setSelectedEvents(modified))
    }

    generateChartSeries()

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <div style={{display: "flex", justifyContent: 'center'}}>
                <Dropdown
                    label='Select time range'
                    options={TIME_RANGES}
                    defaultValue={TIME_RANGES[0]}
                    value={currentTimeRange ? currentTimeRange.name : TIME_RANGES[0].name}
                    onSubmit={(t: ITimeRange) => setCurrentTimeRange(t)}
                />
                <Button style={{marginRight: '10px', marginLeft: '10px'}} icon='testTube'
                        onClick={() => setIsProfileDialogVisible(!isProfileDialogVisible)}><FormattedMessage
                    id='label.SelectProfile'/></Button>
                <Button appearance='secondary' disabled={!currentProfile} icon='reaction'
                        style={{marginRight: '10px'}}
                        onClick={() => setIsSensorDialogVisible(!isSensorDialogVisible)}><FormattedMessage
                    id='label.EditProfile'/></Button>

                <Button appearance='primary' icon={currentConfig.didPauseLive ? 'mediaPlay' : 'mediaPause'}
                        style={{marginRight: '10px'}}
                        onClick={() => togglePause()}>{currentConfig.didPauseLive ?
                    <FormattedMessage id='label.Resume'/> :
                    <FormattedMessage id='label.Pause'/>}</Button>
                <Button appearance='primary'
                        icon='chatboxSquare'
                        disabled={currentConfig.DataLoggingState !== ELoggingState.Running || isCommentDialogVisible}
                        onClick={() => {
                            if (currentConfig.DataLoggingState === ELoggingState.Running) {
                                setCommentEventData(undefined);
                                setIsCommentDialogVisible(true);
                            }
                        }}>
                    <FormattedMessage id='label.AddComment'/></Button>

            </div>
            <div style={{height: '100%'}}>
                <Chart ref={ctx => chart.current = ctx}
                       transitions={false}
                       onLegendItemClick={switchMaskedSensor}
                       onPlotAreaClick={(event: PlotAreaClickEvent) => {

                           //If recording is running and ctrl + left click opens the comment creation dialog
                           if (event.nativeEvent.ctrlKey && currentConfig.DataLoggingState === ELoggingState.Running) {
                               setCommentEventData(event);
                               setIsCommentDialogVisible(true);
                           }
                       }}
                       style={{height: '100%'}}>
                    <ChartArea margin={10}/>
                    <ChartLegend position='bottom' visible={showChartParts}/>
                    <ChartTooltip/>
                    <ChartSeries>
                        {chartSeries.map((cS) => {
                            return <ChartSeriesItem
                                key={cS.NodeID}
                                name={cS.Cfg_sTag}
                                type="line"
                                style="smooth"
                                field="value"
                                categoryField="date"
                                data={cS.data[cS.NodeID]}
                                axis={cS.Unit}
                                categoryAxis="Timeline"
                                markers={{visible: false}}
                                visible={!currentConfig.maskedSensors.includes(cS.Cfg_sTag) && showChartParts}>
                                <ChartSeriesItemTooltip render={TrendsUtils.nestedTooltipRender}
                                                        format={'0' + getDigitFormat(1, cS.DeadBand)}/>
                            </ChartSeriesItem>
                        }) || []}
                    </ChartSeries>
                    <ChartValueAxis>
                        {chartValueAxis.map((cV) => (
                            <ChartValueAxisItem
                                key={cV.name}
                                name={cV.unit}
                                title={{text: `${cV.unit}`}}
                                visible={showChartParts}
                            />
                        )) || []}
                    </ChartValueAxis>
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            name='Timeline'
                            notes={notes || undefined}
                            visible={showChartParts}
                            maxDivisions={25}
                            majorGridLines={{visible: false}}
                            justified={false}
                            labels={{rotation: 45, dateFormats: defaultChartDateFormats}}>
                            <ChartCategoryAxisCrosshair>
                                <ChartCategoryAxisCrosshairTooltip/>
                            </ChartCategoryAxisCrosshair>
                        </ChartCategoryAxisItem>
                    </ChartCategoryAxis>
                </Chart>
            </div>
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Checkbox style={{paddingRight: '15px'}}
                          label={intl.formatMessage({id: 'label.ProcessEvents'})}
                          isChecked={currentConfig.selectedEvents.processEvents}
                          onChange={() => toggleSelectedSensor('processEvents')}/>
                <Checkbox style={{paddingRight: '15px'}}
                          label={intl.formatMessage({id: 'label.UserEvents'})}
                          isChecked={currentConfig.selectedEvents.userEvents}
                          onChange={() => toggleSelectedSensor('userEvents')}/>
                <Checkbox style={{paddingRight: '15px'}}
                          label={intl.formatMessage({id: 'label.AlarmEvents'})}
                          isChecked={currentConfig.selectedEvents.alarmEvents}
                          onChange={() => toggleSelectedSensor('alarmEvents')}/>
                <Checkbox style={{paddingRight: '15px'}}
                          label={intl.formatMessage({id: 'label.SequenceEvents'})}
                          isChecked={currentConfig.selectedEvents.sequenceEvents}
                          onChange={() => toggleSelectedSensor('sequenceEvents')}/>
                <Checkbox label={intl.formatMessage({id: 'label.Comments'})}
                          isChecked={currentConfig.selectedEvents.comments}
                          onChange={() => toggleSelectedSensor('comments')}/>
            </div>
            <Popup
                show={tooltipParams.showEventTooltip}
                popupClass='even-popup-content'
                animate={false}
                offset={tooltipParams.offset}>
                {tooltipParams.eventTooltipContent}
            </Popup>
            {isSensorDialogVisible && (
                <Modal label={intl.formatMessage({id: 'label.SelectPinnedSensors'})} open={isSensorDialogVisible}
                       onClose={() => setIsSensorDialogVisible(!isSensorDialogVisible)}>
                    <div className='container'>
                        {sensorSelectionList}
                    </div>
                </Modal>
            )}
            {isProfileDialogVisible && (
                <Modal
                    label={intl.formatMessage({id: 'label.SelectProfile'})}
                    open={isProfileDialogVisible}
                    onClose={() => setIsProfileDialogVisible(!isProfileDialogVisible)}
                >
                    <div className='container-fluid text-center'>
                        <Dropdown
                            label={intl.formatMessage({id: 'label.SelectProfileToDisplay'})}
                            options={profileList || []}
                            onSubmit={(v: IProfile) => changeProfile(v)}
                            defaultValue={
                                (profileList && currentProfile) ?
                                    {
                                        id: profileList.findIndex((p) => currentProfile.Name === p.name).toString(),
                                        name: currentProfile.Name
                                    } :
                                    null
                            }
                        />
                        <ContentCard
                            title={intl.formatMessage({id: 'label.SelectedProfile'})}
                            active={true}
                            imagePath='https://payload.cargocollective.com/1/22/721705/12853186/Merck_V2_2500_c.jpg'
                            // badge={{
                            //     children: `${currentProfile ? currentProfile.Tags.length > 0 ? currentProfile.Tags.length : 'No' : 'No'} sensor${currentProfile && currentProfile.Tags.length > 1 ? 's' : ''} attached to profile`,
                            //     iconRight: 'microscope'
                            // }}
                            featured={currentProfile ? currentProfile.Name : intl.formatMessage({id: 'label.NoSelectedProfile'})}
                        />
                        <br/>
                        <br/>
                        <Button appearance='primary' disabled={!currentProfile}
                                onClick={() => deleteProfile()}><FormattedMessage
                            id='label.DeleteProfile'/></Button>
                        <br/>
                        <hr/>
                        <div style={{marginTop: '50px'}}>
                            <TextField
                                placeholder={intl.formatMessage({id: 'label.EnterProfileName'})}
                                label={intl.formatMessage({id: 'label.ProfileName'})}
                                value={newProfileName}
                                onChange={(v: string) => setNewProfileName(v)}
                                grey
                            />
                            <br/>
                            <Button appearance='primary' disabled={!newProfileName}
                                    onClick={() => createProfile()}><FormattedMessage
                                id='label.CreateProfile'/></Button>
                        </div>
                    </div>
                </Modal>)}
            {isCommentDialogVisible && (
                <Modal label={intl.formatMessage({id: 'label.AddCommentDialogTitle'})} open={isCommentDialogVisible}
                       onClose={() => setIsCommentDialogVisible(!isCommentDialogVisible)}>
                    <Form
                        onSubmit={createCommentEventHandler}
                        initialValues={{
                            'startDate': commentEventData ? commentEventData.category : getGreatestHistoryDateUnzoned(config),
                            'dateFromSeries': !IsNullOrUndefined(commentEventData)
                        }}
                        render={(formRenderProps: any) => (
                            <FormElement style={{width: 400}}>
                                <fieldset className={'k-form-fieldset'}>
                                    <Field name={'dateFromSeries'} component={FormInput} type={'hidden'}></Field>
                                    <Field
                                        id={'startDate'}
                                        name={'startDate'}
                                        label={intl.formatMessage({id: 'label.CommentStartDate'})}
                                        // hint={'Hint: Should be greater than today'}
                                        component={FormDateTimePicker}
                                        disabled={!IsNullOrUndefined(commentEventData)}
                                        format={{
                                            day: "numeric",
                                            month: "numeric",
                                            year: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                            second: "numeric"
                                        }}
                                    />
                                    <Field
                                        id={'comments'}
                                        name={'comments'}
                                        label={intl.formatMessage({id: 'label.Comments'})}
                                        validator={requiredValidator}
                                        component={FormTextArea}
                                    />
                                    <span className={'k-form-separator'}/>
                                    <div className="k-form-buttons">
                                        <Button
                                            primary={true}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit}>
                                            <FormattedMessage id='label.AddComment'/>
                                        </Button>
                                        <Button onClick={formRenderProps.onFormReset}>
                                            <FormattedMessage id='label.Clear'/>
                                        </Button>
                                    </div>
                                </fieldset>
                            </FormElement>
                        )}
                    />
                </Modal>
            )}
        </div>
    )
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

const mapDispatchToProps = (dispatch: any) => ({
    switchSensorMasking: (sensor: string) => dispatch(configActions.switchSensorMasking(sensor))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    React.memo(withTheme(LiveTrendsView), (prevProps, nextProps) => {
        return (
            prevProps.config.theme === nextProps.config.theme &&
            prevProps.config.APIBaseUrl === nextProps.config.APIBaseUrl &&
            prevProps.config.NodeId === nextProps.config.NodeId &&
            (nextProps.config.didPauseLive || prevProps.config.Instances === nextProps.config.Instances) && //Ignore sensors value changes when live is paused (to prevent UI refresh on each sensor update)
            prevProps.config.tagToF_ === nextProps.config.tagToF_ &&
            prevProps.config.UserEvents === nextProps.config.UserEvents &&
            prevProps.config.ProcessEvents === nextProps.config.ProcessEvents &&
            prevProps.config.SequenceEvents === nextProps.config.SequenceEvents &&
            prevProps.config.maskedSensors === nextProps.config.maskedSensors && //Used for showimg / hiding sensor via chart legend
            prevProps.config.didPauseLive === nextProps.config.didPauseLive &&
            prevProps.config.DataLoggingState === nextProps.config.DataLoggingState &&
            prevProps.selectedEvents === nextProps.selectedEvents
        )
    })
)