import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {Container, Row, Col} from 'reactstrap';
import {Theme, Dropdown, Button} from '@liquid-design/liquid-design-react'
import {apiOpcValue} from "../../../models/Api/apiOpcValue";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {FloatingLabel} from '@progress/kendo-react-labels';
import {IConfigReducer} from "../../../store/reducers/configReducer";
import {useDispatch, useSelector} from "react-redux";
import StoreTypes from 'StoreTypes';
import {IUserEventInfo, setPlcProperty} from "../../../utils/plcUtils";
import {
    getDigitFormat,
    getDigitNumber,
    getEnumTextCode,
    getSensorDeadBand,
    IRegulationMode,
    rampRateInfo
} from "./settingsHelpers";
import {Agitator} from "../../../models/PLC/Agitator";
import {Tank} from "../../../models/PLC/Tank";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {AnalogueSensorWired} from "../../../models/PLC/AnalogueSensorWired";
import CommonSwitches from "./CommonSwitches";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import {useIntl} from 'react-intl'
import {getPumpStateOptions} from './StateOptions'

export interface ITankControlPanelProps {
    nodeId: string
}

export default function TankControlPanel({nodeId}: ITankControlPanelProps) {

    const dispatch = useDispatch();
    const intl = useIntl();

    //Getting information from store
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)

    //Hard coded regulation modes for Agitator
    let regulationModes: IRegulationMode[] = [
        {name: intl.formatMessage({id: "label.SpeedRegulation"}), id: '1', textCode: 'label.SpeedRegulation'},

    ];

    //For 800 and 6000 size, add weight regulation (not available on 150)
    if (config.Unit.Set.iConfiguredBTPSize !== 1) {
        regulationModes.push({
            name: intl.formatMessage({id: "label.WeightRegulation"}),
            id: '3',
            textCode: 'label.WeightRegulation'
        })
    }

    //Get the pump associated ControlModule
    const agitator: Agitator = _.find(config.Instances.ControlModules, (item: ControlModuleBase) => {
        return item.NodeId === nodeId;
    }) as Agitator;

    useEffect(() => {
        setCurrentRegulationMode(agitator.Set_iControlStyle)
    }, [agitator.Set_iControlStyle])

    //Get the EquipmentModule that contains the ControlModule
    const equipmentModule: Tank = _.find(config.Instances.EquipmentModules, (em: any) => {
        return _.find(em.ControlModules, (cm: ControlModuleBase) => {
            return cm.NodeId === nodeId;
        });
    }) as Tank;

    //Get the weight sensor used for tank weight regulation
    let weightSensor = config.Instances.ControlModules[equipmentModule.WeightSensor.Cfg_sTag] as AnalogueSensorWired;
    let weightUnit = weightSensor.Values[0].Cfg_suSensorUnit.Unit;

    //Getting initial ControlStyle
    const [currentRegulationMode, setCurrentRegulationMode] = useState(agitator.Set_iControlStyle);

    /**
     Applies new regulation mode to the PLC
     **/
    const applyRegulationModeChange = () => {

        let controlStyle: apiOpcValue = new apiOpcValue();
        controlStyle.NodeId = agitator.NodeId + '.Set_iControlStyle';
        controlStyle.Value = currentRegulationMode;

        //Pushing information to the PLC
        setPlcProperty(config, controlStyle, {
            userLabel: 'label.event.RegulationModeChanged', unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            enumValue: getEnumTextCode(regulationModes, currentRegulationMode)
        }, dispatch);
    };

    /**
     * Changes local value of the control style
     * @param selectedOption
     */
    const localRegulationModeChange = (selectedOption: any) => {
        setCurrentRegulationMode(parseInt(selectedOption.id));
    };

    /**
     Switching mode between Start <-> Stop
     **/
    const onStateChange = (newValue: any) => {
        let state: apiOpcValue = new apiOpcValue();
        state.NodeId = agitator.NodeId + '.Inp_bCommandManual';
        state.Value = newValue;

        let eventInfo: IUserEventInfo = {
            userLabel: newValue ? 'label.event.Started' : 'label.event.Stopped',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }

        setPlcProperty(config, state, eventInfo, dispatch);
    };

    /**
     Switching mode between Automatic <-> Manual
     **/
    const onModeChange = (newValue: any) => {
        let mode: apiOpcValue = new apiOpcValue();
        mode.NodeId = nodeId + '.Set_bMode';
        mode.Value = !newValue;

        let eventInfo: IUserEventInfo = {
            userLabel: mode.Value ? 'label.event.ManualModeSet' : 'label.event.AutoModeSet',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }
        setPlcProperty(config, mode, eventInfo, dispatch);
    };

    /**
     * Rendering speed regulation parameters
     **/
    function renderRegulationParameters() {

        switch (currentRegulationMode.toString()) {
            case '1' :
                //Speed regulation params
                return renderSpeedRegulation();
            case '3' :
                //Weight regulation params
                return renderWeightRegulation();
            default:
                return <>
                    <p>
                        <h3>{intl.formatMessage({id: "No regulation mode selected"})}</h3>
                    </p>
                </>;
        }
    }

    function renderSpeedRegulation() {
        //Set point is different in Auto or manual mode (False means automatic !!)
        let agitatorSpeedSetPoint = agitator.Set_rSpeedSetpointAuto;
        if (agitator.Set_bMode) {
            agitatorSpeedSetPoint = agitator.Set_rSpeedSetpointManual;
        }
        let speedDeadBand = getSensorDeadBand(agitator, 'Sts_rSpeedFeedback', agitator.Sts_rSpeedFeedback)
        let weightDeadBand = getSensorDeadBand(weightSensor, 'Out_rCurrentValue', weightSensor.Out_rCurrentValue)

        return <Container>
            <Row>
                <Col>
                    <FloatingLabel label={intl.formatMessage({id: "label.CurrentSpeed"})}
                                   editorId='CurrentSpeed'
                                   editorValue={agitator.Sts_rSpeedFeedback + ' %'}
                                   style={{width: '100%'}}>
                        <div id='CurrentSpeed' style={{fontWeight: 'bold', fontSize: '16px'}}>
                            {agitator.Sts_rSpeedFeedback.toFixed(getDigitNumber(agitator.Sts_rSpeedFeedback, speedDeadBand)) + ' %'}
                        </div>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FloatingLabel label={intl.formatMessage({id: "label.SetPoint"})} editorId='SpeedSetPoint'
                                   editorValue={agitatorSpeedSetPoint.toString()}>
                        <NumericTextBox
                            id='SpeedSetPoint'
                            format={"p" + getDigitNumber(agitatorSpeedSetPoint, speedDeadBand)}
                            disabled={!agitator.Set_bMode}
                            min={0}
                            max={1}
                            step={0.1}
                            spinners={false}
                            value={agitatorSpeedSetPoint / 100}
                            onChange={(e) => {
                                let mode: apiOpcValue = new apiOpcValue();
                                mode.NodeId = !agitator.Set_bMode ? agitator.NodeId + '.Set_rSpeedSetpointAuto' : agitator.NodeId + '.Set_rSpeedSetpointManual';
                                mode.Value = e.value * 100;

                                setPlcProperty(config, mode, {
                                    userLabel: 'label.event.SpeedSetPointSet',
                                    unit: '%',
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }}
                        />
                    </FloatingLabel>
                    <br/>
                    <FloatingLabel label={intl.formatMessage({id: "label.RampRate"})} editorId='RampRate'
                                   editorValue={agitator.Set_rRampRate.toString()}>
                        <NumericTextBox
                            id='RampRate'
                            format={rampRateInfo.DigitFormat + ' ' + rampRateInfo.Unit}
                            disabled={!agitator.Set_bMode}
                            min={0.5}
                            max={100}
                            step={0.5}
                            spinners={false}
                            value={agitator.Set_rRampRate}
                            onChange={(e) => {
                                let mode: apiOpcValue = new apiOpcValue();
                                mode.NodeId = agitator.NodeId + '.Set_rRampRate';
                                mode.Value = e.value;

                                setPlcProperty(config, mode, {
                                    userLabel: 'label.event.RampRateSet',
                                    unit: '',
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }}
                        />
                    </FloatingLabel>
                    {config.Unit.Set.iConfiguredBTPSize !== 1 && <><br/>
                        <FloatingLabel label={intl.formatMessage({id: "label.MinWeight"})} editorId='MinWeight'
                                       editorValue={equipmentModule.Set_rMinLevel.toString()}>
                            <NumericTextBox
                                id='MinWeight'
                                format={"0" + getDigitFormat(equipmentModule.Set_rMinLevel, weightDeadBand) + " " + weightUnit}
                                disabled={!agitator.Set_bMode}
                                min={weightSensor.Cfg_rEGUMinimum}
                                max={weightSensor.Cfg_rEGUMaximum}
                                step={1}
                                spinners={false}
                                value={equipmentModule.Set_rMinLevel}
                                onChange={(e) => {
                                    let mode: apiOpcValue = new apiOpcValue();
                                    mode.NodeId = equipmentModule.NodeId + '.Set_rMinLevel';
                                    mode.Value = e.value;

                                    setPlcProperty(config, mode, {
                                        sourceTag: agitator.Cfg_sTag,
                                        userLabel: 'label.event.MinWeightSet',
                                        unit: weightUnit,
                                        eventTriggering: UserEventTriggering.BeforeAction
                                    }, dispatch);
                                }}
                            />
                        </FloatingLabel>
                    </>}
                </Col>
            </Row>
        </Container>
    }

    function renderWeightRegulation() {
        let speedDeadBand = getSensorDeadBand(agitator, 'Sts_rSpeedFeedback', agitator.Sts_rSpeedFeedback)
        let weightDeadBand = getSensorDeadBand(weightSensor, 'Out_rCurrentValue', weightSensor.Out_rCurrentValue)
        let currentWeight = weightSensor.Out_rCurrentValue.toFixed(getDigitNumber(weightSensor.Out_rCurrentValue, weightDeadBand)) + " " + weightUnit;


        return <Container>
            <Row>
                <Col>
                    <FloatingLabel label={intl.formatMessage({id: "label.CurrentSpeed"})}
                                   editorId='CurrentSpeed'
                                   editorValue={agitator.Sts_rSpeedFeedback + ' %'}
                                   style={{width: '100%'}}>
                        <div id='CurrentSpeed' style={{fontWeight: 'bold', fontSize: '16px'}}>
                            {agitator.Sts_rSpeedFeedback.toFixed(getDigitNumber(agitator.Sts_rSpeedFeedback, speedDeadBand)) + ' %'}
                        </div>
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label={intl.formatMessage({id: "label.CurrentWeight"})}
                                   editorId='CurrentWeight'
                                   editorValue={currentWeight}
                                   style={{width: '100%'}}>
                        <div id='CurrentWeight' style={{fontWeight: 'bold', fontSize: '16px'}}>
                            {currentWeight}
                        </div>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FloatingLabel label={intl.formatMessage({id: "label.MinSpeed"})} editorId='MinSpeed'
                                   editorValue={equipmentModule.Set_rMinSpeed.toString()}>
                        <NumericTextBox
                            id='MinSpeed'
                            format={"p" + getDigitNumber(equipmentModule.Set_rMinSpeed, speedDeadBand)}
                            disabled={!agitator.Set_bMode}
                            min={0}
                            max={equipmentModule.Set_rMaxSpeed / 100}
                            step={0.1}
                            spinners={false}
                            value={equipmentModule.Set_rMinSpeed / 100}
                            onChange={(e) => {
                                //Propagate change on Enter button press
                                if (e.nativeEvent.key === "Enter") {
                                    let mode: apiOpcValue = new apiOpcValue();
                                    mode.NodeId = equipmentModule.NodeId + '.Set_rMinSpeed';
                                    mode.Value = e.value * 100;

                                    setPlcProperty(config, mode, {
                                        sourceTag: agitator.Cfg_sTag,
                                        userLabel: 'label.event.MinSpeedSet',
                                        unit: weightUnit,
                                        eventTriggering: UserEventTriggering.BeforeAction
                                    }, dispatch);
                                }
                            }}
                        />
                    </FloatingLabel>
                    <br/>
                    <FloatingLabel label={intl.formatMessage({id: "label.MaxSpeed"})} editorId='MaxSpeed'
                                   editorValue={equipmentModule.Set_rMaxSpeed.toString()}>
                        <NumericTextBox
                            id='MinSpeed'
                            format={"p" + getDigitNumber(equipmentModule.Set_rMaxSpeed, speedDeadBand)}
                            disabled={!agitator.Set_bMode}
                            min={equipmentModule.Set_rMinSpeed / 100}
                            max={1}
                            step={0.1}
                            spinners={false}
                            value={equipmentModule.Set_rMaxSpeed / 100}
                            onChange={(e) => {
                                //Propagate change on Enter button press
                                if (e.nativeEvent.key === "Enter") {
                                    let mode: apiOpcValue = new apiOpcValue();
                                    mode.NodeId = equipmentModule.NodeId + '.Set_rMaxSpeed';
                                    mode.Value = e.value * 100;

                                    setPlcProperty(config, mode, {
                                        sourceTag: agitator.Cfg_sTag,
                                        userLabel: 'label.event.MaxSpeedSet',
                                        unit: weightUnit,
                                        eventTriggering: UserEventTriggering.BeforeAction
                                    }, dispatch);
                                }
                            }}
                        />
                    </FloatingLabel>
                    <br/>
                    <FloatingLabel label={intl.formatMessage({id: "label.RampRate"})} editorId='RampRate'
                                   editorValue={agitator.Set_rRampRate.toString()}>
                        <NumericTextBox
                            id='RampRate'
                            format={"n" + getDigitNumber(agitator.Set_rRampRate, 0)}
                            disabled={!agitator.Set_bMode}
                            min={0.5}
                            max={100}
                            step={0.5}
                            spinners={false}
                            value={agitator.Set_rRampRate}
                            onChange={(e) => {
                                //Propagate change on Enter button press
                                if (e.nativeEvent.key === "Enter") {
                                    let mode: apiOpcValue = new apiOpcValue();
                                    mode.NodeId = agitator.NodeId + '.Set_rRampRate';
                                    mode.Value = e.value;

                                    setPlcProperty(config, mode, {
                                        userLabel: 'label.event.RampRateSet',
                                        unit: '',
                                        eventTriggering: UserEventTriggering.BeforeAction
                                    }, dispatch);
                                }
                            }}
                        />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel label={intl.formatMessage({id: "label.MinWeight"})} editorId='MinWeight'
                                   editorValue={equipmentModule.Set_rMinLevel.toString()}>
                        <NumericTextBox
                            id='MinWeight'
                            format={"0" + getDigitFormat(equipmentModule.Set_rMinLevel, weightDeadBand) + " " + weightUnit}
                            disabled={!agitator.Set_bMode}
                            min={weightSensor.Cfg_rEGUMinimum}
                            max={equipmentModule.Set_rMaxLevel}
                            step={1}
                            spinners={false}
                            value={equipmentModule.Set_rMinLevel}
                            onChange={(e) => {
                                //Propagate change on Enter button press
                                if (e.nativeEvent.key === "Enter") {
                                    let mode: apiOpcValue = new apiOpcValue();
                                    mode.NodeId = equipmentModule.NodeId + '.Set_rMinLevel';
                                    mode.Value = e.value;

                                    setPlcProperty(config, mode, {
                                        sourceTag: agitator.Cfg_sTag,
                                        userLabel: 'label.event.MinWeightSet',
                                        unit: weightUnit,
                                        eventTriggering: UserEventTriggering.BeforeAction
                                    }, dispatch);
                                }
                            }}
                        />
                    </FloatingLabel>
                    <br/>
                    <FloatingLabel label={intl.formatMessage({id: "label.MaxWeight"})} editorId='MaxWeight'
                                   editorValue={equipmentModule.Set_rMinLevel.toString()}>
                        <NumericTextBox
                            id='MaxWeight'
                            format={"0" + getDigitFormat(equipmentModule.Set_rMaxLevel, weightDeadBand) + " " + weightUnit}
                            disabled={!agitator.Set_bMode}
                            min={equipmentModule.Set_rMinLevel}
                            max={weightSensor.Cfg_rEGUMaximum}
                            step={1}
                            spinners={false}
                            value={equipmentModule.Set_rMaxLevel}
                            onChange={(e) => {
                                //Propagate change on Enter button press
                                if (e.nativeEvent.key === "Enter") {
                                    let mode: apiOpcValue = new apiOpcValue();
                                    mode.NodeId = equipmentModule.NodeId + '.Set_rMaxLevel';
                                    mode.Value = e.value;

                                    setPlcProperty(config, mode, {
                                        sourceTag: agitator.Cfg_sTag,
                                        userLabel: 'label.event.MaxWeightSet',
                                        unit: weightUnit,
                                        eventTriggering: UserEventTriggering.BeforeAction
                                    }, dispatch);
                                }
                            }}
                        />
                    </FloatingLabel>
                </Col>
            </Row>
        </Container>
    }

    //Agitator status
    let agitatorStatus: boolean = (agitator.Set_bMode && agitator.Inp_bCommandManual) || (!agitator.Set_bMode && agitator.Inp_bCommandAuto);

    return (
        <Theme themeName={config.theme}>
            <Container>
                <CommonSwitches stateOptions={getPumpStateOptions(intl)} isAutoMode={!agitator.Set_bMode}
                                isStarted={agitatorStatus} onModeChange={onModeChange} onStateChange={onStateChange}
                                startStopDisabled={false}
                                autoManuDisabled={true}/>
                <Row style={{padding: '5px'}}>
                    <Col xs="6">
                        <FloatingLabel label={intl.formatMessage({id: "label.RegulationMode"})}
                                       style={{width: '90%'}}
                                       editorId='RegulationMode'
                                       editorDisabled={!agitator.Set_bMode}
                                       editorValue={currentRegulationMode.toString()}>
                            <Dropdown
                                id='RegulationMode'
                                options={regulationModes}
                                value={currentRegulationMode.toString()}
                                onSubmit={localRegulationModeChange}
                                disabled={!agitator.Set_bMode}
                            />
                        </FloatingLabel>
                        <br/>
                        <br/>
                        <div style={{width: '100%'}}>
                            <Button style={{float: 'right'}}
                                    disabled={currentRegulationMode === agitator.Set_iControlStyle}
                                    onClick={applyRegulationModeChange}>{intl.formatMessage({id: "label.ApplyRegulationMode"})}</Button>
                        </div>
                    </Col>
                    <Col xs="6">
                        {renderRegulationParameters()}
                    </Col>
                </Row>
            </Container>
        </Theme>
    )
}