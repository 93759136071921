import React from 'react'
import {PortWidget} from '@projectstorm/react-diagrams'

import {PumpArtifactModel} from './PumpArtifactModel'
import {
    BaseArtifactWidgetProps,
    BaseArtifactWidgetState,
    BaseArtifactWidget
} from '../../BaseArtifact/BaseArtifactWidget'

import PumpVisual from './PumpVisual'

import * as DefaultPort from '../../Port'
import {connect} from 'react-redux'
import * as StoreTypes from 'StoreTypes'
import {IConfigReducer} from '../../../../store/reducers/configReducer'
import {DriveControlPID} from '../../../../models/PLC/DriveControlPID'
import AlarmSettingsPanel from '../../ControlPanels/AlarmSettingsPanel'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import PumpControlPanel from '../../ControlPanels/PumpControlPanel'
import _ from "lodash";
import {ControlModuleBase} from "../../../../models/PLC/ControlModuleBase";
import TransferPumpControlPanel from "../../ControlPanels/TransferPumpControlPanel";
import {Tank} from '../../../../models/PLC/Tank'
import {injectIntl, IntlShape} from "react-intl";
import {getAnalogSensorDeadBand, getDigitNumber, getSensorDeadBand} from "../../ControlPanels/settingsHelpers";

export interface PumpNodeWidgetProps extends BaseArtifactWidgetProps<PumpArtifactModel> {
    size: number
    node: any
    config: IConfigReducer
    intl: IntlShape
}

export interface PumpNodeWidgetState extends BaseArtifactWidgetState<PumpArtifactModel> {
}

class PumpArtifactWidget extends BaseArtifactWidget<PumpNodeWidgetProps, PumpNodeWidgetState> {


    constructor(props: PumpNodeWidgetProps) {
        super(props)
        this.state = {controlPanel: true, selected: 0, show: false, virtualSelected: 0}

        //Flag that indicates if the SPTFF mode is activated (neede for regulation Interlock)
        const isSptffModeActivated = (this.props.config.Unit.Set.iUserOptionWeightUser || this.props.config.Unit.Set.iFactOptionWeight) && this.props.config.Unit.Set.bVisibilityWE101;

        const equipmentModule: any = _.find(this.props.config.Instances.EquipmentModules, (em: any) => {
            return _.find(em.ControlModules, (cm: ControlModuleBase) => {
                return cm.NodeId === this.props.node.nodeId;
            });
        });

        if (isSptffModeActivated && equipmentModule.Cfg_sTag.includes("FEED")) {
            this.controlPanelTitleAddon = this.props.intl.formatMessage({id: "label.SPTFFModeActivated"});
        }
    }

    calculateLabelsPosition = () => {
        if (this.labelRef.current) {
            const labelDimensions = {
                width: this.labelRef.current.offsetWidth,
                height: this.labelRef.current.offsetHeight
            }

            const labelCoordinates = {
                x: this.props.size / 2 - labelDimensions.width / 2,
                y: this.props.size
            }

            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`
        }
    };

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

    renderControlPanelContent() {

        const equipmentModule: any = _.find(this.props.config.Instances.EquipmentModules, (em: any) => {
            return _.find(em.ControlModules, (cm: ControlModuleBase) => {
                return cm.NodeId === this.props.node.nodeId;
            });
        });

        const sensor: any = _.find(this.props.config.Instances.ControlModules, (item: ControlModuleBase) => {
            return item.NodeId === this.props.node.nodeId;
        });

        let hasCustomDAP = false

        for (let property in sensor) {
            // noinspection JSUnfilteredForInLoop
            if (property.startsWith("DAP") && sensor[property].Cfg_bAvailable) {
                // noinspection JSUnfilteredForInLoop
                hasCustomDAP = true;
            }
        }

        return (<TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
            <TabStripTab title='Control'>
                {equipmentModule.Cfg_sTag.includes("FEED") ? <PumpControlPanel nodeId={this.props.node.nodeId}/> :
                    <TransferPumpControlPanel nodeId={this.props.node.nodeId}/>}
            </TabStripTab>
            {(sensor.AAP || hasCustomDAP) && <TabStripTab title='Alarm'>
                <AlarmSettingsPanel nodeId={this.props.node.nodeId}/>
            </TabStripTab>}
        </TabStrip>);
    }

    render() {
        const sensor = this.props.config.Instances.ControlModules[this.props.node.displayName] as DriveControlPID

        let alarmState = 0
        if (sensor.Sts_bAlarm) {
            if (sensor.Sts_bAlarmSystem || sensor.Sts_bAlarmSafety || sensor.Sts_bAlarmCriticalProcess) {
                alarmState = 1
            } else {
                alarmState = 2
            }
        }

        return (
            <>
                <p style={{
                    fontFamily: 'Lato',
                    fontWeight: 'bold',
                    borderRadius: '5px',
                    color: '#494953',
                    fontSize: '20px',
                    border: 0,
                    margin: 0,
                    cursor: this.state.controlPanel ? 'pointer' : 'auto'
                }}
                   onClick={() => {
                       //TODO: Check later if it should be activated or not (no alarms on pumps)
                       if (!this.state.show)
                           super.showPopup(undefined);
                   }}
                >{this.props.node.displayName}
                    <br/>
                    <span style={{
                        fontFamily: 'Lato',
                        fontWeight: 1000,
                        color: '#494953',
                        fontSize: '28px',
                    }}>{sensor.Sts_rSpeedFeedback.toFixed(getDigitNumber(sensor.Sts_rSpeedFeedback, getSensorDeadBand(sensor, 'Sts_rSpeedFeedback', sensor.Sts_rSpeedFeedback))) + '%'}
                    </span>
                </p>
                <div
                    className='PumpArtifact-node'
                    style={{
                        position: 'relative',
                        width: this.props.size,
                        height: this.props.size,
                        cursor: this.state.controlPanel ? 'pointer' : 'auto'
                    }}
                >
                    <svg
                        width={this.props.size}
                        height={this.props.size}
                        viewBox='0 0 39 39'
                        preserveAspectRatio='xMidYMid'
                        dangerouslySetInnerHTML={{
                            __html: PumpVisual.build(sensor.Set_bMode, sensor.Sts_bRunning, sensor.Cfg_sTag == 'P401/SIC401' ? (this.props.config.Instances.EquipmentModules.TANK001 as Tank).Set_iControlStyle : sensor.Set_iControlStyle, this.props.node.displayName, alarmState, sensor.Sts_bInterlocked)
                        }}
                        onClick={(e) => {
                            if (!this.state.show)
                                super.showPopup(e)
                        }}
                    />
                    <PortWidget
                        port={this.props.node.getPort('left')} engine={this.props.engine}
                        style={{
                            position: 'absolute',
                            top: this.props.size / 2 - 8,
                            left: 0
                        }}
                    >
                        <DefaultPort.Port/>
                    </PortWidget>
                    <PortWidget
                        port={this.props.node.getPort('right')} engine={this.props.engine}
                        style={{
                            position: 'absolute',
                            top: this.props.size / 2 - 8,
                            right: 0
                        }}
                    >
                        <DefaultPort.Port/>
                    </PortWidget>
                    {super.render()}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(injectIntl(PumpArtifactWidget))
