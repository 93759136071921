import {PortModelAlignment} from '@projectstorm/react-diagrams'

export const Ports0Deg = [
    {
        name: 'in',
        alignment: PortModelAlignment.TOP
    },
    {
        name: 'out',
        alignment: PortModelAlignment.RIGHT
    }
]

export const Ports0DegSwitched = [
    {
        name: 'in',
        alignment: PortModelAlignment.RIGHT
    },
    {
        name: 'out',
        alignment: PortModelAlignment.TOP
    }
]
