import {NodeModelGenerics, PortModelOptions} from '@projectstorm/react-diagrams'

import {BaseArtifactModel} from '../../BaseArtifact/BaseArtifactModel'
import {PumpPortArtifactModel} from './PumpPortModel'
import PumpPorts from './PumpPortsConfig'

export interface PumpNodeModelGenerics {
    PORT: PumpArtifactModel;
}

export class PumpArtifactModel extends BaseArtifactModel<NodeModelGenerics & PumpNodeModelGenerics> {
    constructor(code: string, name: string, nodeId: string, orientation: string = 'horizontal') {
        super(code, name, nodeId, orientation, {type: 'Pump'})
        PumpPorts.forEach((port: PortModelOptions) => this.addPort(
            new PumpPortArtifactModel(port.name, port.alignment)
        ))
    }
}
