import React from 'react'
import ElbowNodeWidget from './ElbowArtifactWidget'
import { ElbowArtifactModel } from './ElbowArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class ElbowArtifactFactory extends AbstractReactFactory<ElbowArtifactModel, DiagramEngine> {
  constructor () {
    super('Elbow')
  }

  generateReactWidget (event: any): JSX.Element {
    return <ElbowNodeWidget engine={this.engine} size={38.4} node={event.model} />
  }

  generateModel (event: any) {
    return new ElbowArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
