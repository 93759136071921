/**
 * Dependencies
 */
import React from 'react'

/**
 * Routes import
 */
import DiagramView from './views/DiagramView'
import SettingsView from './views/SettingsView'
import AlarmsView from './views/AlarmsView'
import RecipesView from './views/RecipesView'
import TrendsView from "./views/TrendsView";

/**
 * Interfaces
 */
export interface IRoute {
  path: string,
  title: string,
  icon: string,
  exact?: boolean,
  main: () => any
}

/**
 * Content
 */
const routes: IRoute[] = [
  {
    path: '/',
    title: 'label.Pid',
    icon: 'bottle',
    exact: true,
    main: () => <DiagramView />
  },
  {
    path: '/trends',
    title: 'label.Trends',
    icon: 'finance',
    exact: true,
    main: () => <TrendsView />
  },
  {
    path: '/recipes',
    title: 'label.Recipes',
    icon: 'document',
    exact: true,
    main: () => <RecipesView />
  },
  {
    path: '/alarms',
    title: 'label.Alarms',
    icon: 'alarm',
    exact: true,
    main: () => <AlarmsView />
  },
  {
    path: '/settings',
    title: 'label.Settings',
    icon: 'tools',
    exact: true,
    main: () => <SettingsView />
  }
]

export default routes
