import React, {useEffect, useMemo, useState} from 'react'
import * as StoreTypes from 'StoreTypes'
import {connect, useDispatch} from 'react-redux'
import {
    Dropdown,
    Accordion,
    Button,
    Paragraph,
    Toggle,
    Headline,
    TextField,
    Modal
} from '@liquid-design/liquid-design-react'
import {configActions} from '../store/actions'
import {FormattedMessage, useIntl} from 'react-intl'
import AlarmSettingsPanel from '../components/Diagram/ControlPanels/AlarmSettingsPanel'
import {Row} from 'reactstrap'
import {FB_AAP} from '../models/PLC/FB_AAP'
import {FB_DAP} from '../models/PLC/FB_DAP'
import {Input, InputChangeEvent, NumericTextBox} from '@progress/kendo-react-inputs'
import {setPlcProperty} from '../utils/plcUtils'
import {apiOpcValue} from '../models/Api/apiOpcValue'
import {UserEventTriggering} from '../models/PLC/UserEventTriggering'
import {FloatingLabel, Label} from "@progress/kendo-react-labels";
import {withTheme} from "styled-components"
import {generatePasswordModal} from '../components/Diagram/ControlPanels/settingsHelpers'
import moment from 'moment'
import {downloadBlob} from "download.js";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import {ScaleLoader} from "react-spinners";
import {ELoggingState} from '../models/PLC/ELoggingState'
import {DriveControlPID} from '../models/PLC/DriveControlPID'
import {CustomRange, labSizesHoldupVolumes} from "../config/applicationParameters";
import useMountEffect from "../utils/useMountEffect";
import {Badge, BadgeContainer} from "@progress/kendo-react-indicators";
import {SvgIcon} from "@progress/kendo-react-common";
import {checkIcon, xIcon} from "@progress/kendo-svg-icons";

export enum Themes {
    richBlue = 'richBlue',
    richPurple = 'richPurple',
    vibrantCyan = 'vibrantCyan',
    vibrantMagenta = 'vibrantMagenta'
}

export interface IDropdownTheme {
    id: string
    name: string
    code: Themes
}

export interface ILanguage {
    id: string
    name: string
    code: string
}

export interface IDropdownOptionsPanel {
    id: string
    name: string
}

enum GrantedActions {
    UPDATER_REDIRECT = 'UPDATER_REDIRECT',
    ENABLE_DIGITAL_SIGNATURE = 'ENABLE_DIGITAL_SIGNATURE',
    DISABLE_DIGITAL_SIGNATURE = 'DISABLE_DIGITAL_SIGNATURE'
}

const SettingsView = (props: any) => {
    const {config} = props
    const intl = useIntl()
    const dispatch = useDispatch()
    const [accessGranted, setAccessGranted] = useState(false)
    const [accessRefuse, setAccessRefused] = useState(false)
    const [passwordModalOpen, setPasswordModalOpen] = useState(false)
    const [passwordValue, setPasswordValue] = useState('')
    const [updaterPasswordModalOpen, setUpdaterPasswordModalOpen] = useState(false)
    const [updaterPasswordValue, setUpdaterPasswordValue] = useState('')
    const workingTimeDay = config.Unit.Sts.iTotalWorkingTimeHour / 24
    const currentDateTime = moment().format('YYYY-MM-D HH:mm:ss')
    const [isFetchingData, setFetchingData] = useState(false);
    const [systemThumbprint, setSystemThumbprint] = useState("");
    const [verifiedThumbprint, setVerifiedThumbprint] = useState("");
    const [isVerifiedThumbprintValid, setIsVerifiedThumbprintValid] = useState(false);
    const [isSigningActivated, setIsSigningActivated] = useState(false);
    const [grantedAction, setGrantedAction] = useState<GrantedActions>(GrantedActions.UPDATER_REDIRECT);
    const [isDigitalSignatureConfirmationDialogOpen, setIsDigitalSignatureConfirmationDialogOpen] = useState(false);

    const isUpdaterButtonEnabled: boolean = useMemo(() => {
        // Recording needs to be either stopped or paused
        const isRecordingDisabled = config.DataLoggingState !== ELoggingState.Running
        // Recipe should be disabled
        const isRecipeDisabled = config.Recipe.OperationCtrl.iState === 0 || config.Recipe.OperationCtrl.iState === 3
        // All pumps should be disabled
        const arePumpsDisabled = !Object.keys(config.Instances.ControlModules).some((k) => (config.Instances.ControlModules[k] as DriveControlPID)?.Sts_bRunning === true)
        return isRecordingDisabled && isRecipeDisabled && arePumpsDisabled
    }, [config])

    const themes: IDropdownTheme[] = [
        {
            id: '0',
            name: intl.formatMessage({id: `label.ThemeRichBlue`}),
            code: Themes.richBlue
        },
        {
            id: '1',
            name: intl.formatMessage({id: `label.ThemeRichPurple`}),
            code: Themes.richPurple
        },
        {
            id: '2',
            name: intl.formatMessage({id: `label.ThemeVibrantCyan`}),
            code: Themes.vibrantCyan
        },
        {
            id: '3',
            name: intl.formatMessage({id: `label.ThemeVibrantMagenta`}),
            code: Themes.vibrantMagenta
        }
    ]

    const languages: ILanguage[] = [
        {
            id: '0',
            name: 'English',
            code: 'en'
        },
        {
            id: '2',
            name: 'French',
            code: 'fr'
        },
        {
            id: '1',
            name: 'German',
            code: 'de'
        },
        {
            id: '3',
            name: 'Italian',
            code: 'it'
        },
        {
            id: '4',
            name: 'Spanish',
            code: 'es'
        },
        {
            id: '6',
            name: 'Japanese',
            code: 'ja'
        },
        {
            id: '7',
            name: 'Chinese',
            code: 'zh'
        }
    ]

    const panels: IDropdownOptionsPanel[] = [
        {
            id: '0',
            name: intl.formatMessage({id: 'label.DropdownSystemSettings'}),
        },
        {
            id: '1',
            name: intl.formatMessage({id: 'label.DropdownControlModules'}),
        }
    ]

    const [optionPanel, setOptionPanel] = useState(panels[0])
    const defaultTheme = themes[themes.findIndex((t) => t.code === config.theme)]
    const defaultLanguage = languages[languages.findIndex((l) => l.code === config.locale)]

    useMountEffect(() => {
        //Getting system thumbprint
        fetch(`${config.APIBaseUrl}/Settings/api/Settings/v1/SystemThumbprint`, {
            method: 'GET'
        })
            .then((resp) => resp.text())
            .then((thumbprint) => {
                setSystemThumbprint(thumbprint.replaceAll("\"", ""));
            }).catch((err) => {
            console.error(err);
        });
    });

    // Reset settings panels when intl locale changes preventing blank window display
    useEffect(() => {
        setOptionPanel(panels[parseInt(optionPanel.id)])
    }, [intl.locale])

    useEffect(() => {
        //Gett siging configuration from API
        fetch(`${config.APIBaseUrl}/Settings/api/Settings/v1/SigningConfiguration`, {
            method: 'GET'
        })
            .then((resp) => resp.json())
            .then((setting) => {
                setIsSigningActivated(setting?.SignExports ?? false);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [isSigningActivated])

    async function setSigningConfiguration(signingActivated: boolean) {
        try {
            await fetch(`${config.APIBaseUrl}/Settings/api/Settings/v1/SigningConfiguration`, {
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    SignExports: signingActivated
                })
            });
        } catch
            (err) {
            console.error(err)
        }
    }

    /**
     * Set selected theme
     * @param theme Theme to apply
     */
    function setTheme(theme: IDropdownTheme) {
        dispatch(configActions.setTheme(theme !== null ? theme.code : Themes.richBlue))
    }

    /**
     * Set selected theme
     * @param lang Language to use
     */
    async function setLanguage(lang: ILanguage) {
        try {
            await fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/Language/SetCurrent/${lang.code}`, {
                method: 'PUT'
            })
            const JSONTranslations = await fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/Language/LocalizedStrings`)
            const translations = await JSONTranslations.json()

            dispatch(configActions.setLanguage(lang.code))
            dispatch(configActions.updateTranslations(translations))
        } catch (err) {
            console.error(err)
        }
    }

    function exportLogFiles() {
        let url = `${config.UpdaterBaseUrl}/Updater/api/Logs/v1/GetZippedLogFiles`
        setFetchingData(true);

        fetch(url, {
            method: 'GET'
        })
            .then((resp) => resp.blob())
            .then((blob) => {
                downloadBlob(`LogFiles${moment().format("YYYY-MM-DDTHH-mm-ss")}.zip`, blob)
                setFetchingData(false);
            }).catch(() => {
            setFetchingData(false)
        })
    }

    function exportPublicCertificate() {
        let url = `${config.APIBaseUrl}/Settings/api/Settings/v1/SystemPublicCertificate`
        setFetchingData(true);

        fetch(url, {
            method: 'GET'
        })
            .then((resp) => resp.blob())
            .then((blob) => {
                downloadBlob(`CogentCertificate.cer`, blob)
                setFetchingData(false);
            }).catch(() => {
            setFetchingData(false)
        })
    }

    function renderSettingsAlarms() {
        const accordeon: any = []
        Object.keys(config.Instances.ControlModules).forEach((sensorName, i) => {
            //Get the AAP for the given controlModule
            const sensor: any = config.Instances.ControlModules[sensorName]
            let AAP: FB_AAP = sensor.AAP;
            let DAPList: FB_DAP[] = [];

            for (let property in sensor) {
                // noinspection JSUnfilteredForInLoop
                if (property.startsWith("DAP") && sensor[property].Cfg_bAvailable) {
                    // noinspection JSUnfilteredForInLoop
                    DAPList.push(sensor[property]);
                }
            }
            //If AAP is null, don't push anything
            if ((AAP != undefined || DAPList.length > 0) && sensor.Sts_bEnable === true)
                accordeon.push((
                    <div key={i}>
                        <Accordion title={sensorName}>
                            <AlarmSettingsPanel nodeId={config.Instances.ControlModules[sensorName].NodeId}/>
                        </Accordion>
                    </div>
                ))
        })
        return accordeon
    }

    function updateConfigSetValue(e: any, tag: string, label: string, unit: string) {
        //Propagate change on Enter button press
        if (e.nativeEvent.key === "Enter") {
            let configValue: apiOpcValue = new apiOpcValue();
            configValue.NodeId = config.Unit.Set.NodeId + tag;
            configValue.Value = e.value;

            setPlcProperty(config, configValue, {
                userLabel: label,
                unit: unit,
                eventTriggering: UserEventTriggering.BeforeAction
            }, dispatch);
        }
    }

    function onclickResetTimeButton() {
        let resetTime: apiOpcValue = new apiOpcValue();
        resetTime.NodeId = config.Unit.Inp.NodeId + '.bResetWorkingTime'
        resetTime.Value = true
        console.log(resetTime)
        setPlcProperty(config, resetTime, {
            userLabel: 'label.event.ResetWorkingTime',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction
        }, dispatch);
    }

    /**
     * Opens a new tab redirecting on Updater client
     */
    function updaterRedirect() {
        fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/SavePlcRemanentVariables`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(
                {
                    PlcVersion: config.Unit.Sts.sProgrammVersion
                }
            )
        })
            .then(() => {
                // Redirecting to Updater UI
                window.location.replace(config.UpdaterClientBaseUrl);
                setUpdaterPasswordModalOpen(false);
            });
    }

    function onclickSetTimeButton() {
        fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/SetSystemClock?newDateTime=${currentDateTime}`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(
                null
            )
        })
            .then(() => {
                console.log("bonjour")
            })
    }

    /**
     Sets pasted thumbprint value and compares it to the system thumbprint
     */
    function verifyThumbprint(e: InputChangeEvent) {
        setVerifiedThumbprint(e.value);
        setIsVerifiedThumbprintValid(systemThumbprint.toUpperCase() === e.value.toUpperCase());
    }

    function toggleSignature(state: boolean) {
        setSigningConfiguration(state).then(() => setIsSigningActivated(state));
    }

    function renderSettingsPanel() {

        //Getting HoldupVolume range from hard coded configuration
        let holdupVolumeRange: CustomRange;
        switch (config.Unit.Set.iConfiguredBTPSize) {
            case 1 : {
                holdupVolumeRange = labSizesHoldupVolumes.Lab150;
                break;
            }
            case 2 : {
                holdupVolumeRange = labSizesHoldupVolumes.Lab800;
                break;
            }
            case 3 : {
                holdupVolumeRange = labSizesHoldupVolumes.Lab6000;
                break;
            }
        }

        switch (optionPanel.name) {
            case panels[1].name: {
                return (
                    <>
                        <div>
                            {renderSettingsAlarms()}
                        </div>
                    </>
                )
            }
            case panels[0].name: {
                return (
                    <>
                        <h5>{intl.formatMessage({id: 'label.DigitalSignature'})}</h5>
                        <br/>
                        <Row>
                            <div className="col-3" style={{minWidth: '400px'}}>
                                <FloatingLabel
                                    label={intl.formatMessage({id: "label.CertificateThumbprint"})}
                                    editorId='SystemThumbprint'
                                    editorValue={systemThumbprint}
                                    style={{width: '400px'}}>
                                    <div id='SystemThumbprint' style={{fontWeight: 'bold', fontSize: '16px', width: '400px'}}>
                                        {systemThumbprint}
                                    </div>
                                </FloatingLabel>
                            </div>
                            <div className="col-3">
                                <Button
                                    size='small'
                                    onClick={() => exportPublicCertificate()}>
                                    <FormattedMessage id='label.ExportPublicCertificate'/>
                                </Button>
                            </div>
                        </Row>
                        <br/>
                        <Row>
                            <div className="col-3" style={{minWidth: '400px'}}>
                                <div className={'k-form-field-wrap'}>
                                    <BadgeContainer style={{width: '400px'}}>
                                        <FloatingLabel
                                            label={intl.formatMessage({id: "label.CheckThumbprint"})}
                                            editorId='thumbprintVerification'
                                            editorValue="none"
                                            style={{width: '400px'}}>
                                            <Input
                                                id="thumbprintVerification"
                                                value={verifiedThumbprint}
                                                maxLength={40}
                                                onChange={verifyThumbprint}
                                                placeholder={intl.formatMessage({id: 'label.PasteThumbprint'})}
                                                style={{width: '400px'}}
                                                valid={isVerifiedThumbprintValid || verifiedThumbprint === ""}
                                            />
                                        </FloatingLabel>

                                        {verifiedThumbprint !== "" && (<Badge
                                            size="large"
                                            align={{vertical: "bottom", horizontal: "end"}}
                                            themeColor={isVerifiedThumbprintValid ? "success" : "error"}
                                            cutoutBorder={true}
                                        >
                                            <SvgIcon icon={isVerifiedThumbprintValid ? checkIcon : xIcon}/>
                                        </Badge>)}
                                    </BadgeContainer>
                                </div>
                            </div>
                        </Row>
                        <br/>
                        <Row>
                            <div className="col-3">
                                <FloatingLabel
                                    label={intl.formatMessage({id: "label.SignDatasetExports"})}
                                    editorValue="none">
                                </FloatingLabel>
                                <Toggle id="SignDatasetExports"
                                        isActive={isSigningActivated}
                                        onClick={() => {
                                            if (isSigningActivated) {
                                                setGrantedAction(GrantedActions.DISABLE_DIGITAL_SIGNATURE)
                                            } else {
                                                setGrantedAction(GrantedActions.ENABLE_DIGITAL_SIGNATURE)
                                            }
                                            setUpdaterPasswordModalOpen(true)
                                        }}/>
                            </div>
                        </Row>
                        <hr/>
                        <h5>{intl.formatMessage({id: 'label.ProductSettings'})}</h5>
                        <br/>
                        <Row>
                            <div className="col-3">
                                <NumericTextBox
                                    label={intl.formatMessage({id: 'label.ProductDensity'})}
                                    spinners={false}
                                    format={`0.000`}
                                    min={0.5}
                                    max={1.5}
                                    value={config.Unit.Set.rDensity}
                                    onChange={(e) => updateConfigSetValue(e, '.rDensity', 'label.event.DensitySet', '')}
                                />
                            </div>
                            <div className="col-3">
                                <NumericTextBox
                                    label={intl.formatMessage({id: 'label.ProductViscosity'})}
                                    spinners={false}
                                    format={`0.000` + ' cP'}
                                    min={0.1}
                                    max={99.9}
                                    value={config.Unit.Set.rViscosity}
                                    onChange={(e) => updateConfigSetValue(e, '.rViscosity', 'label.event.ViscositySet', ' cP')}
                                />
                            </div>
                        </Row>
                        <hr/>
                        <h5>{intl.formatMessage({id: 'label.System'})}</h5>
                        <br/>
                        <Row>
                            <div className="col-3">
                                <NumericTextBox
                                    label={intl.formatMessage({id: 'label.SystemMembraneSurface'})}
                                    spinners={false}
                                    format={`0` + ' cm²'}
                                    min={50}
                                    max={34200}
                                    value={config.Unit.Set.rMembraneSurface}
                                    onChange={(e) => updateConfigSetValue(e, '.rMembraneSurface', 'label.event.MembraneSurfaceSet', ' cm²')}
                                />
                            </div>
                            <div className="col-3">
                                <Row>
                                    <NumericTextBox
                                        label={intl.formatMessage({id: 'label.SystemVariableHoldupVolume'})}
                                        spinners={false}
                                        format={`0.000 L`}
                                        min={holdupVolumeRange.RangeMin}
                                        max={holdupVolumeRange.RangeMax}
                                        value={config.Unit.Set.rVariableHoldupVolume}
                                        onChange={(e) => updateConfigSetValue(e, '.rVariableHoldupVolume', 'label.event.VariableHoldupVolumeSet', ' L')}
                                    />
                                </Row>
                                <Row>
                                    <FloatingLabel
                                        label={intl.formatMessage({id: "label.SystemSystemHoldupVolume"})}
                                        editorId='systemHoldupVolume'
                                        editorValue={config.Unit.Set.rSystemHoldupVolume.toString()}
                                        style={{width: '100%'}}>
                                        <div id='systemHoldupVolume' style={{fontWeight: 'bold', fontSize: '16px'}}>
                                            {config.Unit.Set.rSystemHoldupVolume} L
                                        </div>
                                    </FloatingLabel>
                                </Row>
                                <Row>
                                    <FloatingLabel
                                        label={intl.formatMessage({id: "label.SystemTotalHoldupVolume"})}
                                        editorId='totalHoldupVolume'
                                        editorValue={config.Unit.Sts.rTotalHoldupVolume.toString()}
                                        style={{width: '100%'}}>
                                        <div id='totalHoldupVolume' style={{fontWeight: 'bold', fontSize: '16px'}}>
                                            {config.Unit.Sts.rTotalHoldupVolume} L
                                        </div>
                                    </FloatingLabel>
                                </Row>
                            </div>
                        </Row>
                        <hr/>
                        <h5>{intl.formatMessage({id: 'label.Maintenance'})}</h5>
                        <br/>
                        <Row>
                            <div className="col-3">
                                <FloatingLabel
                                    label={intl.formatMessage({id: "label.WorkingTimeElapsed"})}
                                    editorId='WorkingTimeElapsed'
                                    editorValue={workingTimeDay.toFixed(0) + ' ' + intl.formatMessage({id: "label.UnitDays"})}
                                    style={{width: '100%'}}>
                                    <div id='WorkingTimeElapsed' style={{fontWeight: 'bold', fontSize: '16px'}}>
                                        {workingTimeDay.toFixed(0) + ' ' + intl.formatMessage({id: "label.UnitDays"})}
                                    </div>
                                </FloatingLabel>
                            </div>
                            <div className="col-3">
                                <Button
                                    size='small'
                                    onClick={() => {
                                        setPasswordModalOpen(true)
                                    }}
                                >
                                    <FormattedMessage id='label.ResetTime'/>
                                </Button>
                            </div>
                        </Row>
                        <br/>
                        <Row>
                            <div className="col-3">
                                <FloatingLabel
                                    label={intl.formatMessage({id: "label.ActualTime"})}
                                    editorId='ActualTime'
                                    editorValue={currentDateTime}
                                    style={{width: '100%'}}>
                                    <div id='ActualTime' style={{fontWeight: 'bold', fontSize: '16px'}}>
                                        {currentDateTime}
                                    </div>
                                </FloatingLabel>
                            </div>
                            <div className="col-3">
                                <Button
                                    size='small'
                                    onClick={() => {
                                        onclickSetTimeButton()
                                    }}
                                >
                                    <FormattedMessage id='label.SetTime'/>
                                </Button>
                            </div>
                        </Row>
                        <br/>
                        {isFetchingData &&
                            <Dialog title={intl.formatMessage({id: 'label.LoadingDataPleaseWait'})} closeIcon={false}>
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <ScaleLoader
                                        height={50}
                                        width={10}
                                        radius={4}
                                        margin={2}
                                        color={"#123abc"}
                                        loading={isFetchingData}
                                    />
                                </div>
                            </Dialog>}
                        <hr/>
                        <h5>{intl.formatMessage({id: 'label.LogFiles'})}</h5>
                        <br/>
                        <Row>
                            <div className="col-6">
                                <Button style={{marginRight: '5px'}} icon='exportFile' onClick={() => exportLogFiles()}>
                                    <FormattedMessage id='label.ExportLogFiles'/>
                                </Button>
                            </div>
                        </Row>
                        {passwordModalOpen &&
                            generatePasswordModal(intl, accessGranted, () => onclickResetTimeButton(), passwordModalOpen, setPasswordModalOpen, passwordValue, setPasswordValue, accessRefuse, setAccessRefused, false)
                        }
                        <hr/>
                        <h5>{intl.formatMessage({id: 'label.DropdownUiPreferences'})}</h5>
                        <br/>
                        <Row>
                            <div className="col-3">
                                <FloatingLabel label={intl.formatMessage({id: "label.SelectLanguage"})}
                                               editorId="languageDropdown"
                                               editorValue={defaultLanguage.name}>
                                    <Dropdown
                                        id="languageDropdown"
                                        label={intl.formatMessage({id: 'label.SelectLanguage'})}
                                        options={languages}
                                        defaultValue={defaultLanguage}
                                        onSubmit={(lang: ILanguage) => setLanguage(lang)}
                                    />
                                </FloatingLabel>
                            </div>
                            <div className="col-3">
                                <FloatingLabel label={intl.formatMessage({id: "label.SelectColorTheme"})}
                                               editorId="themeDropdown"
                                               editorValue={defaultTheme.name}>
                                    <Dropdown
                                        id="themeDropdown"
                                        label={intl.formatMessage({id: 'label.SelectColorTheme'})}
                                        options={themes}
                                        defaultValue={defaultTheme}
                                        onSubmit={(theme: IDropdownTheme) => setTheme(theme)}
                                    />
                                </FloatingLabel>
                            </div>
                        </Row>
                        <hr/>
                        <h5>{intl.formatMessage({id: 'label.Updater'})}</h5>
                        <br/>
                        <Paragraph
                            style={{color: '#E61E50'}}>{intl.formatMessage({id: 'label.UpdaterPrerequisites'})}</Paragraph>
                        <Row>
                            <div className="col-6" style={{height: '90px'}}>
                                <Button
                                  disabled={!isUpdaterButtonEnabled}
                                  appearance='highlight'
                                  style={{marginRight: '5px'}}
                                  onClick={() => {
                                      setGrantedAction(GrantedActions.UPDATER_REDIRECT)
                                      setUpdaterPasswordModalOpen(true)
                                  }}>
                                    <FormattedMessage id='label.UpdaterOpen'/>
                                </Button>
                            </div>
                        </Row>
                        {updaterPasswordModalOpen &&
                            generatePasswordModal(intl, accessGranted, () => {
                                setUpdaterPasswordValue("")
                                switch (grantedAction) {
                                    case GrantedActions.ENABLE_DIGITAL_SIGNATURE:
                                        setIsDigitalSignatureConfirmationDialogOpen(true)
                                        break
                                    case GrantedActions.DISABLE_DIGITAL_SIGNATURE:
                                        toggleSignature(false)
                                        break
                                    case GrantedActions.UPDATER_REDIRECT:
                                        updaterRedirect()
                                        break
                                    default:
                                        console.error('Unhandled switch case in generatePasswordModal')
                                        updaterRedirect()
                                }
                            }, updaterPasswordModalOpen, setUpdaterPasswordModalOpen, updaterPasswordValue, setUpdaterPasswordValue, accessRefuse, setAccessRefused, grantedAction !== GrantedActions.DISABLE_DIGITAL_SIGNATURE)
                        }
                        <Modal
                          label={intl.formatMessage({id: 'label.EnableDigitalSignatureTitle'})}
                          open={isDigitalSignatureConfirmationDialogOpen}
                          onClose={() => {
                              setIsDigitalSignatureConfirmationDialogOpen(false)
                          }}
                        >
                            <div style={{textAlign: 'center'}}>
                                <Headline type='H3'>
                                    {intl.formatMessage({id: 'label.EnableDigitalSignature'})}
                                </Headline>
                                <br/>
                                <p style={{ textAlign: "left", fontSize: 20, whiteSpace: "break-spaces"}}>
                                    {intl.formatMessage({id: 'label.EnableDigitalSignatureWarning'})}
                                </p>
                                <br/>

                                <div style={{ display: "flex", gap: "16px", justifyContent: "center", alignItems: "center" }}>
                                    <Button size='big'
                                            appearance='secondary'
                                            onClick={() => setIsDigitalSignatureConfirmationDialogOpen(false)}
                                            style={{ width: '100%' }}>
                                        {intl.formatMessage({id: 'label.Cancel'})}
                                    </Button>
                                    <Button size='big'
                                            appearance='highlight'
                                            onClick={() => {
                                                setIsDigitalSignatureConfirmationDialogOpen(false)
                                                toggleSignature(true)
                                            }}
                                            style={{ width: '100%' }}>
                                        {intl.formatMessage({id: 'label.EnableDigitalSignature'})}
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    </>
                )
            }
        }
    }

    return (
        <>
            <Dropdown
                label='Select an options panel'
                options={panels}
                defaultValue={panels[0]}
                value={optionPanel.name}
                onSubmit={(panel: IDropdownOptionsPanel) => setOptionPanel(panel)}
            />
            <div className="ScrollBar" style={{height: '100%', paddingLeft: '10px', paddingTop: '10px'}}>
                {renderSettingsPanel()}
            </div>
        </>
    )
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(
    React.memo(withTheme(SettingsView), (prevProps, nextProps) => {
        return (
            prevProps.config.theme === nextProps.config.theme &&
            prevProps.config.locale === nextProps.config.locale &&
            prevProps.config.APIBaseUrl === nextProps.config.APIBaseUrl &&
            prevProps.config.NodeId === nextProps.config.NodeId &&
            prevProps.config.Instances === nextProps.config.Instances &&
            prevProps.config.Unit === nextProps.config.Unit
        )
    })
)
