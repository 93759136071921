import { apiSequenceMacroSteps } from '../../../../models/Api/apiSequenceMacroSteps'

function merge(settings: any, macroStep: apiSequenceMacroSteps) {
  Object.keys(settings).forEach((key) => {
    switch (key) {
      case 'isTransfertOptionWired': {
        if (!settings.isTransfertOptionWired) {
          macroStep.SequenceSteps[2].ActionIModType = 0
          macroStep.SequenceSteps[2].ActionIModId = 0
          macroStep.SequenceSteps[2].ActionICode = 0
          macroStep.SequenceSteps[2].ActionRValue = 0
        }
        break
      }
      case 'isFiltratePCVOptionWired': {
        if (!settings.isFiltratePCVOptionWired) {
          macroStep.SequenceSteps[3].ActionIModType = 0
          macroStep.SequenceSteps[3].ActionIModId = 0
          macroStep.SequenceSteps[3].ActionICode = 0
          macroStep.SequenceSteps[3].ActionRValue = 0
        }
        break
      }
    }
  })
}

export default merge