import React from 'react'
import {PortWidget} from '@projectstorm/react-diagrams'

import {VerticalValveArtifactModel} from './VerticalValveArtifactModel'
import {
    BaseArtifactWidgetProps,
    BaseArtifactWidgetState,
    BaseArtifactWidget
} from '../../BaseArtifact/BaseArtifactWidget'

import VerticalValveVisual from './VerticalValveVisual'

import * as DefaultPort from '../../Port'
import * as StoreTypes from 'StoreTypes'
import {connect} from 'react-redux'
import {IConfigReducer} from '../../../../store/reducers/configReducer'

export interface VerticalValveNodeWidgetProps extends BaseArtifactWidgetProps<VerticalValveArtifactModel> {
    size: number
    config: IConfigReducer
}

export interface VerticalValveNodeWidgetState extends BaseArtifactWidgetState<VerticalValveArtifactModel> {
}

class VerticalValveArtifactWidget extends BaseArtifactWidget<VerticalValveNodeWidgetProps, VerticalValveNodeWidgetState> {
    constructor(props: VerticalValveNodeWidgetProps) {
        super(props)
        this.state = {controlPanel: false, selected: 0, show: false, virtualSelected: 0}
    }

    calculateLabelsPosition = () => {
        if (this.labelRef.current) {
            const labelDimensions = {
                width: this.labelRef.current.offsetWidth,
                height: this.labelRef.current.offsetHeight
            }

            const labelCoordinates = {
                x: this.props.size - 10, /// 2 - labelDimensions.width / 2,
                y: this.props.size / 2 - labelDimensions.height / 2
            }

            if(this.props.node.orientation === 'left') {
                labelCoordinates.x = 25 - labelDimensions.width
            }

            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`

        }
    };

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

    render() {
        return (
            <div
                className='VerticalValveArtifact-node'
                style={{
                    position: 'relative',
                    width: this.props.size,
                    height: this.props.size
                }}
                onContextMenu={(e) => {
                    e.preventDefault();
                    super.showPopup(e)
                }}
            >
                <svg style={{transform: 'rotate(90deg)'}}
                     width={this.props.size}
                     height={this.props.size}
                     viewBox='0 0 32 26'
                     preserveAspectRatio='xMidYMid'
                     dangerouslySetInnerHTML={{
                         __html: VerticalValveVisual.build(BaseArtifactWidget.getConditionsStatus(this.props.node.getConditions(), this.props.config))
                     }}
                />
                <PortWidget
                    port={this.props.node.getPort('left')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: this.props.size / 2 - 7,
                        //backgroundColor: '#64fd06'
                    }}>
                    <DefaultPort.Port/>
                </PortWidget>
                <PortWidget
                    port={this.props.node.getPort('right')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: this.props.size - 18,
                        left: this.props.size / 2 - 7,
                        //backgroundColor: '#fa0404'
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                {super.render()}
            </div>
        )
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(VerticalValveArtifactWidget)
