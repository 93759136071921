import {Col, Container, Row} from "reactstrap";
import React from "react";
import {useIntl} from "react-intl";
import {Button, Glyph} from '@liquid-design/liquid-design-react'
import {useDispatch, useSelector} from "react-redux";
import {IConfigReducer} from "../../../store/reducers/configReducer";
import StoreTypes from "StoreTypes";
import _ from "lodash";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {setPlcProperty, CreateOpcValue} from "../../../utils/plcUtils";
import {Label} from "@progress/kendo-react-labels";
import {StepMotorValve} from "../../../models/PLC/StepMotorValve";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import {getDigitNumber, getSensorDeadBand} from "../ControlPanels/settingsHelpers";

export interface IStepMotorValveCalibrationProps {
    nodeId: string
}

/**
 * StepMotorValveCalibration component
 * @param nodeId
 * @constructor
 */
export default function StepMotorValveCalibration({nodeId}: IStepMotorValveCalibrationProps) {

    const intl = useIntl();
    const dispatch = useDispatch();

    //Getting information from store
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config);

    //Get reference to the valve
    const valve: StepMotorValve = _.find(config.Instances.ControlModules, (item: ControlModuleBase) => {
        return item.NodeId === nodeId;
    }) as StepMotorValve;

    /**
     * Sending ZeroPoint information to Step motor valve
     */
    function onClick() {
        let opcValue = CreateOpcValue(valve.NodeId + '.Inp_bStartCalib', true);
        setPlcProperty(config, opcValue, {
            userLabel: 'label.event.ValveCalibrationStarted', unit: '',
            eventTriggering: UserEventTriggering.BeforeAction
        }, dispatch);
    }

    //Get the valve mode
    let valveMode = valve.Cfg_bValveType ? intl.formatMessage({id: 'label.Closed'}) : intl.formatMessage({id: 'label.Opened'});

    //Get valve current currentStep, with unit
    let valvePosition = valve.Sts_rValvePositionFeedback.toFixed(getDigitNumber(valve.Sts_rValvePositionFeedback, getSensorDeadBand(valve, 'Sts_rValvePositionFeedback', valve.Sts_rValvePositionFeedback))).toString() + " % " + valveMode;

    return <>
        <Container>
            <Row style={{paddingTop: '5px'}}>
                <Col>
                    <Container>
                        <Row>
                            <Col style={{padding: '0px'}}>
                                {valve.Sts_bCalibrationInProgress && <div>
                                    <Glyph name="dot" color="#0567b7"/><span
                                    style={{
                                        fontSize: '19px',
                                        fontWeight: 'bold',
                                        verticalAlign: 'text-bottom'
                                    }}>&nbsp;{intl.formatMessage({id: 'label.CalibrationInProgress'})}</span>
                                </div>}
                                <Button
                                    style={{width: '100%'}}
                                    disabled={valve.Sts_bCalibrationInProgress || !valve.Set_bMode || valve.Set_iControlStyle !== 1}
                                    onClick={onClick}>{intl.formatMessage({id: 'label.Calibrate'})}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col>
                    <div style={{margin: '0 auto', width: 'fit-content', paddingBottom: '15px'}}>
                        <Label
                            editorId='CurrentSensorValue'>{intl.formatMessage({id: 'label.CurrentSensorValue'})}</Label>
                        <div id='CurrentSensorValue' style={{fontWeight: 'bold', fontSize: '26px'}}>
                            {valvePosition}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </>;
}