/**
 * Visual builder for ControlledValve Artifact
 */
export default class ControlledValveVisual {
  /**
   * Builds up svg
   * @returns SVG as a string
   * @param isManual
   * @param alarmState
   * @param regulationMode
   * @param isRunning
   */
  static build(isManual: boolean, alarmState: number, regulationMode: number, isRunning: boolean): string {
    let fill = isRunning ? '#0F69AF' : 'rgb(73,73,83)'
    switch (alarmState) {
      case 1: {
        fill = '#E61E50'
        break;
      }
      case 2: {
        fill = '#FFC832'
        break;
      }
    }
    let regulationInitials = ''
    switch (regulationMode) {
      case 1:
        //Speed regulation params
        regulationInitials = 'Po'
        break
      case 2:
        // Pressure regulation params
        regulationInitials = 'Dp'
        break
      case 3:
        //Flow PID regulation params
        regulationInitials = 'Fl'
        break
      case 4:
        //Delta P regulation params
        regulationInitials = 'Tp'
        break
    }
    return `
      <g transform="matrix(1,0,0,1,-2.34107e-06,-1.77636e-15)">
        <g transform="matrix(1,0,0,1,-2.00644,-1.99782)">
            <g transform="matrix(1,0,0,1,-8.06039,-7.51718)">
                <g>
                    <g transform="matrix(0.398419,0,0,0.39567,9.8568,9.515)">
                        <g transform="matrix(1,0,0,1,0.126172,0)">
                            <path d="M15,0C12.239,0 10,2.239 10,5C10,5.552 10.448,6 11,6L15,6L15,18L17,18L17,6L21,6C21.552,6 22,5.552 22,5C22,2.239 19.761,0 17,0L15,0Z" style="fill:rgb(73,73,83);"/>
                        </g>
                        <g transform="matrix(1,0,0,1,0.308172,0)">
                            <path d="M25.984,30.189L24.117,32.538L25.984,30.189ZM25.984,11.523L24.117,9.175L25.984,11.523ZM14.885,18.862L13.018,21.211L14.885,18.862ZM16.752,18.862L18.618,21.211L16.752,18.862ZM14.885,22.85L16.752,25.199L14.885,22.85ZM13.018,20.502L3.786,27.841L7.52,32.538L16.752,25.199L13.018,20.502ZM6.219,29.015L6.219,12.697L0.219,12.697L0.219,29.015L6.219,29.015ZM3.786,13.871L13.018,21.211L16.752,16.514L7.52,9.175L3.786,13.871ZM18.618,21.211L27.851,13.871L24.117,9.175L14.885,16.514L18.618,21.211ZM25.417,12.697L25.417,29.015L31.417,29.015L31.417,12.697L25.417,12.697ZM27.851,27.841L18.619,20.502L14.885,25.199L24.117,32.538L27.851,27.841ZM25.417,29.015C25.417,27.76 26.868,27.06 27.851,27.841L24.117,32.538C27.065,34.882 31.417,32.782 31.417,29.015L25.417,29.015ZM27.851,13.871C26.868,14.653 25.417,13.953 25.417,12.697L31.417,12.697C31.417,8.93 27.065,6.831 24.117,9.175L27.851,13.871ZM13.018,21.211C14.657,22.514 16.979,22.514 18.618,21.211L14.885,16.514C15.431,16.079 16.205,16.079 16.752,16.514L13.018,21.211ZM6.219,12.697C6.219,13.953 4.769,14.653 3.786,13.871L7.52,9.175C4.571,6.831 0.219,8.93 0.219,12.697L6.219,12.697ZM3.786,27.841C4.769,27.06 6.219,27.76 6.219,29.015L0.219,29.015C0.219,32.782 4.571,34.882 7.52,32.538L3.786,27.841ZM16.752,25.199C16.205,25.633 15.431,25.633 14.885,25.199L18.619,20.502C16.979,19.199 14.657,19.199 13.018,20.502L16.752,25.199Z" style="fill:rgb(213,213,217);fill-rule:nonzero;stroke:${isManual ? 'rgb(34,34,34)' : 'invisible'};stroke-width:1.2px;stroke-dasharray:2.6,2.6;"/>
                        </g>
                        <g transform="matrix(1,0,0,1,0.308172,0)">  
                            <path d="M25.984,30.189L25.05,31.364L25.984,30.189ZM25.984,11.523L25.05,10.349L25.984,11.523ZM14.885,18.862L13.951,20.036L14.885,18.862ZM16.752,18.862L17.685,20.036L16.752,18.862ZM14.885,22.85L15.818,24.024L14.885,22.85ZM13.951,21.676L4.719,29.015L6.586,31.364L15.818,24.024L13.951,21.676ZM4.719,29.015L4.719,12.697L1.719,12.697L1.719,29.015L4.719,29.015ZM4.719,12.697L13.951,20.036L15.818,17.688L6.586,10.349L4.719,12.697ZM17.685,20.036L26.917,12.697L25.05,10.349L15.818,17.688L17.685,20.036ZM26.917,12.697L26.917,29.015L29.917,29.015L29.917,12.697L26.917,12.697ZM26.917,29.015L17.685,21.676L15.818,24.024L25.05,31.364L26.917,29.015ZM26.917,29.015L25.05,31.364C27.016,32.926 29.917,31.526 29.917,29.015L26.917,29.015ZM26.917,12.697L29.917,12.697C29.917,10.186 27.016,8.786 25.05,10.349L26.917,12.697ZM13.951,20.036C15.044,20.905 16.592,20.905 17.685,20.036L15.818,17.688L13.951,20.036ZM4.719,12.697L6.586,10.349C4.62,8.786 1.719,10.186 1.719,12.697L4.719,12.697ZM4.719,29.015L1.719,29.015C1.719,31.526 4.62,32.926 6.586,31.364L4.719,29.015ZM15.818,24.024L17.685,21.676C16.592,20.807 15.044,20.807 13.951,21.676L15.818,24.024Z" style="fill:white;fill-rule:nonzero;"/>
                        </g>
                        <g transform="matrix(1,0,0,1,0.308172,0)">
                            <path d="M16.752,22.85C16.205,22.416 15.431,22.416 14.885,22.85L5.653,30.189C4.67,30.971 3.219,30.271 3.219,29.015L3.219,12.697C3.219,11.442 4.67,10.742 5.653,11.523L14.885,18.862C15.431,19.297 16.205,19.297 16.752,18.862L25.984,11.523C26.967,10.742 28.417,11.442 28.417,12.697L28.417,29.015C28.417,30.271 26.967,30.971 25.984,30.189L16.752,22.85Z" style="fill:${fill};"/>
                        </g>
                    </g>
                    <g transform="matrix(1.62183,0,0,1.62183,-14.1134,-14.6513)">
                        <g transform="matrix(0.216702,0,0,0.216702,19.2075,20.7564)">
                            <path d="M17.75,7C17.75,3.826 15.174,1.25 12,1.25L7,1.25C3.826,1.25 1.25,3.826 1.25,7C1.25,10.174 3.826,12.75 7,12.75L12,12.75C15.174,12.75 17.75,10.174 17.75,7Z" style="fill:rgb(213,213,217);stroke:rgb(217,213,213);stroke-width:1.5px;"/>
                        </g>
                        <g transform="matrix(0.350964,0,0,0.350964,20.0867,22.9014)">
                            <text x="0px" y="0px" style="font-family:'Lato', sans-serif;font-size:6px;font-weight:bold;fill:rgb(34,34,34);">${regulationInitials}</text>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>`
  }
}
