import React from 'react'
import * as StoreTypes from 'StoreTypes'
import { IConfigReducer } from '../store/reducers/configReducer'
import { useSelector } from 'react-redux'
import { Bowl, Headline, Paragraph } from '@liquid-design/liquid-design-react'

const ErrorView = () => {
  const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)

  return (
    <div style={{
      height: '100vh',
      width: '100vw',
      backgroundColor: '#DDDDDD',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <div>
        <Bowl animationDuration={1000} percent={0} width={300} />
      </div>
      <div>
        <Headline>
          An error occurred while using CogentLab WebApplication.
        </Headline>
        <Paragraph>
          { config.error.status !== 0 ? (
            <>Error status: {config.error.status}, message: {config.error.message}</>
          ) : (
            <>Message: {config.error.message}</>
          )}
        </Paragraph>
      </div>
    </div>
  )
}

export default ErrorView
