import React, { useEffect, useRef, useState } from 'react'
import {useSelector} from 'react-redux'
import StoreTypes from 'StoreTypes'
import {IConfigReducer} from '../../../store/reducers/configReducer'
import {Col, Row} from 'reactstrap'
import {useIntl} from 'react-intl'
import {NumericTextBox} from '@progress/kendo-react-inputs'
import {Accordion, Dropdown, RadioButton} from '@liquid-design/liquid-design-react'

import useMountEffect from '../../../utils/useMountEffect'

import {AnalogueSensorVirtual} from '../../../models/PLC/AnalogueSensorVirtual'
import {Feed} from '../../../models/PLC/Feed'
import {Retentate} from '../../../models/PLC/Retentate'
import {Membrane} from '../../../models/PLC/Membrane'
import {Filtrate} from '../../../models/PLC/Filtrate'
import {StepMotorValve} from '../../../models/PLC/StepMotorValve'
import {DeadBandList} from '../../../models/PLC/DeadBand'

import {updateControlValue, updateDropdownValue} from './Helpers/UpdateHelpers'
import {getDigitFormat, getDigitNumber} from '../../Diagram/ControlPanels/settingsHelpers'
import MacroStep, {IMacroStep} from './MacroStep'

const TotalRecirculation = (props: Partial<IMacroStep>) => {

    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const intl = useIntl()
    const areValuesLoadedRef = useRef(false)
    const isTransfertOptionWired = config.Unit.Set.iUserOptionTransfert
    const isFiltratePCVOptionWired = config.Unit.Set.iFactOptionFiltratePCV
    const isFlowmeterOptionWired = config.Unit.Set.iFactOptionFlowmeters
    let SIC001_P001DropdownValues: any[] = [];

    if (!isFlowmeterOptionWired) {
        SIC001_P001DropdownValues = [
            {name: intl.formatMessage({id: 'label.SpeedRegulation'}), id: '1', dbValue: 7},
            {name: intl.formatMessage({id: 'label.DeltaPRegulation'}), id: '2', dbValue: 6}
        ]
    } else {
        SIC001_P001DropdownValues = [
            {name: intl.formatMessage({id: 'label.SpeedRegulation'}), id: '1', dbValue: 7},
            {name: intl.formatMessage({id: 'label.DeltaPRegulation'}), id: '2', dbValue: 6},
            {name: intl.formatMessage({id: 'label.FlowRegulation'}), id: '3', dbValue: 5}
        ]
    }

    const PCV101DropdownValues = [
        {name: intl.formatMessage({id: 'label.Position'}), id: '1', dbValue: 2},
        {name: intl.formatMessage({id: 'label.Pressure'}), id: '2', dbValue: 3},
        {name: intl.formatMessage({id: 'label.TMPRegulation'}), id: '3', dbValue: 5}
    ]

    const PCV201DropdownValues = [
        {name: intl.formatMessage({id: 'label.Position'}), id: '1', dbValue: 2},
        {name: intl.formatMessage({id: 'label.Pressure'}), id: '2', dbValue: 3},
        {name: intl.formatMessage({id: 'label.TMPRegulation'}), id: '3', dbValue: 5}
    ]

    //Add flow option if flowmeter are activated
    if (isFlowmeterOptionWired) {
        PCV201DropdownValues.push({name: intl.formatMessage({id: 'label.FlowRegulation'}), id: '4', dbValue: 4})
    }

    useMountEffect(() => {
        if (props.loadedValues) {
            // CONCENTRATION CONFIG
            setIsAutoModeOn(
                props.loadedValues[0].ActionICode !== 6
            )
            setMemorization(
                props.loadedValues[28].ActionICode === 10
            )
            // P001/SIC001
            const INITSIC001_P001Dropdown = props.loadedValues[14].ActionICode
            setSIC001_P001DropdownSelected(
                SIC001_P001DropdownValues[SIC001_P001DropdownValues.findIndex((val) => val.dbValue === INITSIC001_P001Dropdown)]
            )
            const INITSIC001_P001SetPoint = props.loadedValues[14].ActionRValue
            setSIC001_P001SetPointSelected(
                INITSIC001_P001SetPoint
            )
            // PCV101
            const INITPCV101Dropdown = props.loadedValues[17].ActionICode
            setPCV101DropdownSelected(
                PCV101DropdownValues[PCV101DropdownValues.findIndex((val) => val.dbValue === INITPCV101Dropdown)]
            )
            const INITPCV101SetPoint = props.loadedValues[17].ActionRValue
            setPCV101SetPointSelected(
                INITPCV101SetPoint
            )
            // PCV201
            const INITPCV201Dropdown = props.loadedValues[24].ActionICode
            setPCV201DropdownSelected(
                PCV201DropdownValues[PCV201DropdownValues.findIndex((val) => val.dbValue === INITPCV201Dropdown)]
            )
            const INITPCV201SetPoint = props.loadedValues[24].ActionRValue
            setPCV201SetPointSelected(
                INITPCV201SetPoint
            )
            // END CRITERIA
            setTimeCriteria(
                props.loadedValues[27].SequenceCriterias[0].RValue
            )
            setTimeCriteriaHour(
                Math.floor(props.loadedValues[27].SequenceCriterias[0].RValue / 3600)
            )
            setTimeCriteriaMinute(
                Math.floor((props.loadedValues[27].SequenceCriterias[0].RValue % 3600) / 60)
            )
            setTimeCriteriaSecond(props.loadedValues[27].SequenceCriterias[0].RValue % 60
            )
        }
        areValuesLoadedRef.current = true
    })

    useEffect(() => {
        if (areValuesLoadedRef.current && props.onSave) {
            validConfiguration(false)
        }
    }, [props.onSave, validConfiguration])

    const [isAutoModeOn, setIsAutoModeOn] = useState(false)
    const [memorization, setMemorization] = useState(false)

    const [SIC001_P001DropdownSelected, setSIC001_P001DropdownSelected] = useState(SIC001_P001DropdownValues[0])
    const [SIC001_P001SetPointSelected, setSIC001_P001SetPointSelected] = useState(0)

    const [PCV101DropdownSelected, setPCV101DropdownSelected] = useState(PCV101DropdownValues[0])
    const [PCV101SetPointSelected, setPCV101SetPointSelected] = useState(0)

    const [PCV201DropdownSelected, setPCV201DropdownSelected] = useState(PCV201DropdownValues[0])
    const [PCV201SetPointSelected, setPCV201SetPointSelected] = useState(0)

    const [timeCriteria, setTimeCriteria] = useState(0)
    const [timeCriteriaHour, setTimeCriteriaHour] = useState(0)
    const [timeCriteriaMinute, setTimeCriteriaMinute] = useState(0)
    const [timeCriteriaSeconds, setTimeCriteriaSecond] = useState(0)


    function updateTimeCriteria(valToModif: number, value: number) {
        let temptimeCriteria: number
        switch (valToModif) {
            case 1 : {
                setTimeCriteriaHour(value)
                temptimeCriteria = value * 3600 + timeCriteriaMinute * 60 + timeCriteriaSeconds
                break
            }
            case 2 : {
                setTimeCriteriaMinute(value)
                temptimeCriteria = timeCriteriaHour * 3600 + value * 60 + timeCriteriaSeconds
                break
            }
            case 3 : {
                setTimeCriteriaSecond(value)
                temptimeCriteria = timeCriteriaHour * 3600 + timeCriteriaMinute * 60 + value
                break
            }

        }
        updateControlValue(false, setTimeCriteria, temptimeCriteria)
    }

    function validConfiguration(triggerChanges = true) {
        props.onSave(triggerChanges, {
            isAutoModeOn,
            memorization,
            SIC001_P001DropdownSelected,
            SIC001_P001SetPointSelected,
            PCV101DropdownSelected,
            PCV101SetPointSelected,
            PCV201DropdownSelected,
            PCV201SetPointSelected,
            timeCriteria,
            isTransfertOptionWired,
            isFiltratePCVOptionWired
        })
    }

    function generateSIC001_P001Input() {
        const EM = config.Instances.EquipmentModules['FEED001'] as Feed
        switch (SIC001_P001DropdownSelected ? SIC001_P001DropdownSelected.id : '1') {
            case '1': {
                // Speed
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={0}
                        max={1}
                        value={SIC001_P001SetPointSelected / 100}
                        format={`p${getDigitNumber(SIC001_P001SetPointSelected, DeadBandList.getDeadBandFor(config.Instances.ControlModules['P001/SIC001'].Values[0].Cfg_dDeadBands, SIC001_P001SetPointSelected))}`}
                        onChange={(val) => updateControlValue(true, setSIC001_P001SetPointSelected, val.value)}
                    />
                )
            }
            case '2': {
                // Delta P
                const control = config.Instances.ControlModules['DP001'] as AnalogueSensorVirtual
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.PressurePID
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={SIC001_P001SetPointSelected}
                        format={`0${getDigitFormat(SIC001_P001SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, SIC001_P001SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setSIC001_P001SetPointSelected, val.value)}
                    />
                )
            }
            case '3': {
                // Flow
                const control = EM.FeedFlow
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.FlowPID
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={SIC001_P001SetPointSelected}
                        format={`0${getDigitFormat(SIC001_P001SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, SIC001_P001SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setSIC001_P001SetPointSelected, val.value)}
                    />
                )
            }
        }
    }

    function generatePCV101Input() {
        const EM = config.Instances.EquipmentModules['RETENTATE001'] as Retentate
        const valve = config.Instances.ControlModules['PCV101'] as StepMotorValve
        let valveMode = valve.Cfg_bValveType ? intl.formatMessage({id: 'label.Closed'}) : intl.formatMessage({id: 'label.Opened'})

        switch (PCV101DropdownSelected ? PCV101DropdownSelected.id : '1') {
            case '1': {
                // Position
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={0}
                        max={1}
                        value={PCV101SetPointSelected / 100}
                        format={`0${getDigitFormat(PCV101SetPointSelected, DeadBandList.getDeadBandFor(valve.Values[0].Cfg_dDeadBands, PCV101SetPointSelected))} % ${valveMode}`}
                        onChange={(val) => updateControlValue(true, setPCV101SetPointSelected, val.value)}
                    />
                )
            }
            case '2': {
                // Pressure
                const control = config.Instances.ControlModules['PI101'] as AnalogueSensorVirtual
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.PressureRegul
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={PCV101SetPointSelected}
                        format={`0${getDigitFormat(PCV101SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, PCV101SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setPCV101SetPointSelected, val.value)}
                    />
                )
            }
            case '3': {
                // TMP
                const control = (config.Instances.EquipmentModules['MEMBRANE001'] as Membrane).TMP
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.TMPRegul
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={PCV101SetPointSelected}
                        format={`0${getDigitFormat(PCV101SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, PCV101SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setPCV101SetPointSelected, val.value)}
                    />
                )
            }
        }
    }

    function generatePCV201Input() {
        const EM = config.Instances.EquipmentModules['FILTRATE001'] as Filtrate
        const valve = config.Instances.ControlModules['PCV201'] as StepMotorValve
        let valveMode = valve.Cfg_bValveType ? intl.formatMessage({id: 'label.Closed'}) : intl.formatMessage({id: 'label.Opened'})
        switch (PCV201DropdownSelected ? PCV201DropdownSelected.id : '1') {
            case '1': {
                // Position
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={0}
                        max={1}
                        value={PCV201SetPointSelected / 100}
                        format={`0${getDigitFormat(PCV201SetPointSelected, DeadBandList.getDeadBandFor(valve.Values[0].Cfg_dDeadBands, PCV201SetPointSelected))} % ${valveMode}`}
                        onChange={(val) => updateControlValue(true, setPCV201SetPointSelected, val.value)}
                    />
                )
            }
            case '2': {
                // Pressure
                const control = EM.FiltratePressureSensor
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.PressureRegul
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={PCV201SetPointSelected}
                        format={`0${getDigitFormat(PCV201SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, PCV201SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setPCV201SetPointSelected, val.value)}
                    />
                )
            }
            case '3': {
                // TMP
                const control = (config.Instances.EquipmentModules['MEMBRANE001'] as Membrane).TMP
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.TMPRegul
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={PCV201SetPointSelected}
                        format={`0${getDigitFormat(PCV201SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, PCV201SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setPCV201SetPointSelected, val.value)}
                    />
                )
            }
            case '4': {
                // Flow
                const control = EM.FiltrateFlowSensor
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.FlowRegul
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={PCV201SetPointSelected}
                        format={`0${getDigitFormat(PCV201SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, PCV201SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setPCV201SetPointSelected, val.value)}
                    />
                )
            }
        }
    }

    return (
        <>
            <MacroStep
                id={props.id}
                macroStepName={'label.TotalRecirculation'}
                lightBoxLabel={'label.TotalRecirculationOptions'}
                sequenceID={props.sequenceID}
                stepNumber={props.stepNumber}
                isDraggable={props.isDraggable}
                isModel={props.isModel}
                onDelete={props.onDelete}
                onSave={validConfiguration}
            >
                <Accordion title={intl.formatMessage({id: 'label.TotalRecirculationOptions'})}>
                    <div style={{minHeight: '200px'}}>
                        <Row>
                            <Col>
                                <br/>
                                <RadioButton
                                    isSelected={isAutoModeOn}
                                    onClick={() => setIsAutoModeOn(true)}
                                    label={intl.formatMessage({id: 'label.AutoModeOn'})}
                                />
                                <br/>
                                <RadioButton
                                    isSelected={!isAutoModeOn}
                                    onClick={() => setIsAutoModeOn(false)}
                                    label={intl.formatMessage({id: 'label.AutoModeOff'})}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <br/>
                                <RadioButton
                                    isSelected={memorization}
                                    onClick={() => setMemorization(true)}
                                    label={intl.formatMessage({id: 'label.Memorize'})}
                                />
                                <br/>
                                <RadioButton
                                    isSelected={!memorization}
                                    onClick={() => setMemorization(false)}
                                    label={intl.formatMessage({id: 'label.NoMemorize'})}
                                />
                            </Col>
                        </Row>
                    </div>
                </Accordion>
                <Accordion title="P001/SIC001">
                    <div style={{minHeight: '200px'}}>
                        <Row>
                            <Col>
                                <Dropdown
                                    label={intl.formatMessage({id: 'label.RegulationMode'})}
                                    options={SIC001_P001DropdownValues}
                                    value={SIC001_P001DropdownSelected.id}
                                    onSubmit={(val: any) => updateDropdownValue(setSIC001_P001DropdownSelected, val, [setSIC001_P001SetPointSelected])}
                                />
                            </Col>
                            <Col>
                                {generateSIC001_P001Input()}
                            </Col>
                        </Row>
                    </div>
                </Accordion>
                <Accordion title="PCV101">
                    <div style={{minHeight: '200px'}}>
                        <Row>
                            <Col>
                                <Dropdown
                                    label={intl.formatMessage({id: 'label.RegulationMode'})}
                                    options={PCV101DropdownValues}
                                    value={PCV101DropdownSelected.id}
                                    onSubmit={(val: any) => updateDropdownValue(setPCV101DropdownSelected, val, [setPCV101SetPointSelected])}
                                />
                            </Col>
                            <Col>
                                {generatePCV101Input()}
                            </Col>
                        </Row>
                    </div>
                </Accordion>
                {isFiltratePCVOptionWired && (
                    <Accordion title="PCV201">
                        <div style={{minHeight: '200px'}}>
                            <Row>
                                <Col>
                                    <Dropdown
                                        label={intl.formatMessage({id: 'label.RegulationMode'})}
                                        options={PCV201DropdownValues}
                                        value={PCV201DropdownSelected.id}
                                        onSubmit={(val: any) => updateDropdownValue(setPCV201DropdownSelected, val, [setPCV201SetPointSelected])}
                                    />
                                </Col>
                                <Col>
                                    {generatePCV201Input()}
                                </Col>
                            </Row>
                        </div>
                    </Accordion>
                )}
                <Accordion title={intl.formatMessage({id: 'label.EndCriteria'})}>
                    <div style={{minHeight: '200px'}}>
                        <Row>
                            <Col>
                                <NumericTextBox
                                    label={intl.formatMessage({id: 'label.TimeHour'})}
                                    format={'0 h'}
                                    spinners={false}
                                    min={0}
                                    value={timeCriteriaHour}
                                    onChange={(val) => updateTimeCriteria(1, val.value)}
                                />
                            </Col>
                            <Col>
                                <NumericTextBox
                                    label={intl.formatMessage({id: 'label.TimeMinute'})}
                                    format={'0 m'}
                                    spinners={false}
                                    min={0}
                                    max={59}
                                    value={timeCriteriaMinute}
                                    onChange={(val) => updateTimeCriteria(2, val.value)}
                                />
                            </Col>
                            <Col>
                                <NumericTextBox
                                    label={intl.formatMessage({id: 'label.TimeSecond'})}
                                    format={'0 s'}
                                    spinners={false}
                                    min={0}
                                    max={59}
                                    value={timeCriteriaSeconds}
                                    onChange={(val) => updateTimeCriteria(3, val.value)}
                                />
                            </Col>
                        </Row>
                    </div>
                </Accordion>
                <br/>
            </MacroStep>
        </>
    )
}

export default TotalRecirculation
