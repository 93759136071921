import {NodeModelGenerics, PortModelOptions} from '@projectstorm/react-diagrams'

import {BaseArtifactModel} from '../../BaseArtifact/BaseArtifactModel'
import {ThreeWayValvePortArtifactModel} from './ThreeWayValvePortModel'
import ThreeWayValvePorts from './ThreeWayValvePortsConfig'
import RotatedThreeWayValvePortsConfig from './RotatedThreeWayValvePortsConfig'
import { IConditions } from '../../CustomLinks/PipeLink/PipeLinkModel'

export interface ThreeWayValveNodeModelGenerics {
    PORT: ThreeWayValveArtifactModel;
}

export class ThreeWayValveArtifactModel extends BaseArtifactModel<NodeModelGenerics & ThreeWayValveNodeModelGenerics> {
    constructor(code: string, name: string, nodeId: string, orientation: string = 'horizontal', conditions: IConditions[] = []) {
        super(code, name, nodeId, orientation, {type: 'ThreeWayValve'}, conditions);

        (code.startsWith('90-') ? RotatedThreeWayValvePortsConfig : ThreeWayValvePorts).forEach((port: PortModelOptions) => this.addPort(
          new ThreeWayValvePortArtifactModel(port.name, port.alignment)
        ))
    }
}
