import { apiSequenceMacroSteps } from '../../../../models/Api/apiSequenceMacroSteps'

function merge(settings: any, macroStep: apiSequenceMacroSteps) {
  Object.keys(settings).forEach((key) => {
    switch (key) {
      case 'EndCriteriaDropdownSelected': {
        switch (settings.EndCriteriaDropdownSelected.id) {
          case '1': {
            // Time
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 1002
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 4
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.timeCriteria

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = 0

            macroStep.SequenceSteps[27].IBooleanOperator = 0
            break
          }
          case '2': {
            // Flush volume
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 200
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 7
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = 1

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = 0

            macroStep.SequenceSteps[27].IBooleanOperator = 0

            macroStep.SequenceSteps[2].ActionRValue = settings.flushVolume
            break
          }
          case '3': {
            // Weight sum threshold
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 117
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.weightSumThreshold

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = 0

            macroStep.SequenceSteps[27].IBooleanOperator = 0
            break
          }
          case '4': {
            // WI001 threshold
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 2
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 9
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 2
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.superiorWI001Threshold

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = 0

            macroStep.SequenceSteps[27].IBooleanOperator = 0
            break
          }
          case '5': {
            // FI201 threshold
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 16
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 3
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.inferiorFI201Threshold

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = 0

            macroStep.SequenceSteps[27].IBooleanOperator = 0
            break
          }
          case '6': {
            // AI201 threshold
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 17
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 2
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.inferiorAI201Threshold

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 17
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 2
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 2
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = settings.superiorAI201Threshold

            macroStep.SequenceSteps[27].IBooleanOperator = 2
            break
          }
          case '7': {
            // AI202 threshold
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 17
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.inferiorAI202Threshold

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 17
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 1
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 2
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = settings.superiorAI202Threshold

            macroStep.SequenceSteps[27].IBooleanOperator = 2
            break
          }
        }
        break
      }
    }
  })
}

export default merge