import { PortModelAlignment } from '@projectstorm/react-diagrams'

export default [
  {
    name: 'top',
    alignment: PortModelAlignment.TOP
  },
  {
    name: 'left',
    alignment: PortModelAlignment.LEFT
  }
]
