import React, {Component} from "react";
import moment from "moment";
import {FormattedDate, FormattedMessage, FormattedTime} from "react-intl";

/**
 * This is the alarm notification content component.
 *
 * @class NotificationContent
 * @extends {Component}
 */
class NotificationContent extends Component {
    constructor(props) {
        super(props);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick() {
        this.props.handleOnClick(this.props.content);
    }

    /**
     * Render function of the component
     *
     * @returns the HTML of the alarm notification content component.
     * @memberof NotificationContent
     */
    render() {
        const {content} = this.props;

        let theDate = content.Acknowledged || content.Created;
        let unzonedate = theDate.toString();

        if (unzonedate.endsWith('Z')) {
            unzonedate = unzonedate.substring(0, unzonedate.length - 1)
        }

        let notificationContent;
        if(content.UserLabel.startsWith('label.')) {
           notificationContent =  <FormattedMessage id={content.UserLabel} />
        }
        else {
            notificationContent =<>{content.UserLabel}</>
        }

        return (
            <div>
                <div className="orch-notification-details-left">
                    <div className="orch-notification-title alarm-truncateText">{content.SensorTag}</div>
                    <div className="orch-notification-description alarm-truncateText">{notificationContent}</div>
                    <div className="orch-notification-time-block">
                        <div className="orch-notification-time">
                            <FormattedDate value={unzonedate}/>&nbsp;<FormattedTime hour={'numeric'} minute={'numeric'} second={'numeric'} value={unzonedate}/>
                        </div>
                    </div>
                </div>
                <div className="orch-notification-view-right">
                    <div className="orch-notification-view" onClick={this.handleOnClick}>
                        <span className="orch-view-text"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotificationContent;