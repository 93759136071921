import * as React from 'react'
import _ from 'lodash'
import styled from '@emotion/styled'

import './BaseArtifact.css'

import {DiagramEngine} from '@projectstorm/react-diagrams'
import {BaseArtifactModel} from './BaseArtifactModel'
import PidDiagramModel from '../PIDDiagramModel'
import './BaseArtifact.css'
import {Window} from "@progress/kendo-react-dialogs";
import CustomLabelWidget from './CustomLabelWidget'
import AlarmSettingsPanel from "../ControlPanels/AlarmSettingsPanel";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout'
import WiredSensorCalibration from "../CalibrationPanels/WiredSensorCalibration";
import {AnalogueSensorWired} from "../../../models/PLC/AnalogueSensorWired";
import {AnalogueSensorEndress} from "../../../models/PLC/AnalogueSensorEndress";
import EndressSensorCalibration from "../CalibrationPanels/EndressSensorCalibration";
import {AnalogueSensorLevitronics} from "../../../models/PLC/AnalogueSensorLevitronics";
import LevitronicsSensorCalibration from "../CalibrationPanels/LevitronicsSensorCalibration";
import HamiltonSensorCalibration from "../CalibrationPanels/HamiltonSensorCalibration";
import {IConfigReducer} from "../../../store/reducers/configReducer";
import HeatExchangerControlPanel from '../ControlPanels/HeatExchangerControlPanel'
import TankControlPanel from '../ControlPanels/TankControlPanel'

export const NodeLabel = styled.div`
  display: inline-block;
  position: absolute;
  min-width:90px;
  `

export interface BaseArtifactWidgetProps<TCustomNodeModel extends BaseArtifactModel> {
    engine: DiagramEngine;
    node: TCustomNodeModel;
}

export interface BaseArtifactWidgetState<TCustomNodeModel extends BaseArtifactModel> {
    controlPanel: boolean;
    selected: number;
    show?: boolean;
    showVirtualSensorPopup?: boolean;
    virtualSensorPanel?: boolean;
    virtualSelected: number;
}


export abstract class BaseArtifactWidget<TProps extends BaseArtifactWidgetProps<BaseArtifactModel>, TState extends BaseArtifactWidgetState<BaseArtifactModel>> extends React.Component<TProps, TState> {
    protected labelRef: React.RefObject<HTMLDivElement>
    protected tooltipRef: React.RefObject<HTMLDivElement>
    anchor: any;
    virtualNodeId: string;
    virtualSensorTag: string;
    virtualSensorIndex: number;
    protected controlPanelTitleAddon: string = '';

    protected constructor(props: TProps) {
        super(props)
        this.labelRef = React.createRef()
        this.tooltipRef = React.createRef()
        this.handleSelect = this.handleSelect.bind(this)
        this.handleVirtualSelect = this.handleVirtualSelect.bind(this)
        this.showPopup = this.showPopup.bind(this)
        this.showVirtualSensorPopup = this.showVirtualSensorPopup.bind(this)
    }

    /**
     * Evaluates the condition against the current property value
     * @param config
     * @param tag
     * @param param
     * @param awaitedValue
     * @private
     */
    private static evaluateCondition(config: any, tag: string, param: string, awaitedValue: boolean) {

        //If tag is UNIT, it's a relative object path to an object (store root is the root path)
        if (tag === 'UNIT') {
            //Use lodash to get the right information
            let currentValue = _.get(config, param)

            return currentValue === awaitedValue;
        }

        //if Tag is not UNIT, the tag is a CM Tag
        return config.Instances.ControlModules[tag][param] === awaitedValue;
    }

    public static getConditionsStatus(opts: any, config: IConfigReducer): boolean {
        let isEnabled = true

        if (opts.isEnabled) {
            const flag = opts.flag
            const conditionsResults: boolean[] = []

            opts.isEnabled.forEach((enableCondition: any) => {
                //Multiple sub condition array
                if (Array.isArray(enableCondition.condition)) {

                    let arrayCondition = true
                    const subConditionsResults: boolean[] = [];

                    //Parsing all sub conditions
                    enableCondition.condition.forEach((cond: string, i: number) => {
                        const [tag, param] = cond.split('|')

                        arrayCondition = BaseArtifactWidget.evaluateCondition(config, tag, param, enableCondition.value[i]);

                        //If we have an operator, store each value in a separate table
                        if (enableCondition.operator) {
                            subConditionsResults.push(arrayCondition)
                        } else {
                            conditionsResults.push(arrayCondition)
                        }
                    })

                    //If an operator is defined, go trough all sub condition result and apply the operator to compute an overall status
                    if (enableCondition.operator) {
                        let computedCondition = true;

                        if (enableCondition.operator === 'OR') {
                            if (!subConditionsResults.some((e) => e === true)) {
                                computedCondition = false
                            }
                        } else {
                            if (!subConditionsResults.every((e) => e === true)) {
                                computedCondition = false
                            }
                        }

                        //Add computed condition result to the main result table
                        conditionsResults.push(computedCondition)
                    }
                } else {
                    const [tag, param] = enableCondition.condition.split('|')
                    conditionsResults.push(BaseArtifactWidget.evaluateCondition(config, tag, param, enableCondition.value))
                }
            })

            if (flag && flag === 'OR') {
                if (!conditionsResults.some((e) => e === true)) {
                    isEnabled = false
                }
            } else {
                if (!conditionsResults.every((e) => e === true)) {
                    isEnabled = false
                }
            }
            return isEnabled
        }
    }

    public getOrientation() {
        return this.props.node.orientation;
    }

    protected getRandomInt(max: number): number {
        return Math.floor(Math.random() * Math.floor(max));
    }

    public getModel(): PidDiagramModel {
        return this.props.engine.getModel() as PidDiagramModel
    }

    componentDidUpdate() {
        window.requestAnimationFrame(this.calculateLabelsPosition)
    }

    componentDidMount() {
        window.requestAnimationFrame(this.calculateLabelsPosition)
    }

    abstract calculateLabelsPosition(): void;

    abstract performAction(actionName: string, actionProps: any): void;

    protected renderNodeLabel() {

        return (
            <>
                <NodeLabel
                    ref={this.labelRef} style={{
                    position: 'absolute',
                    display: 'inline-block',
                    top: 0,
                    left: 0,
                    width: 'max-content'
                }}
                >
                    {
                        _.map(this.props.node.getLabels(), (labelModel, index) => {
                            return (
                                <div key={labelModel.getID()} style={{paddingBottom: '5px'}}
                                     onClick={(e) => {
                                         const labelId = labelModel.getID()
                                         const labelOptions = labelModel.getOptions();
                                         if (!labelId.startsWith("TEXT") && labelOptions.extras.hasPopup) {
                                             const [nodeId, tag] = labelId.split('\\');
                                             this.showVirtualSensorPopup(nodeId, tag, this.getRandomInt(100));
                                         }
                                     }}>
                                    <CustomLabelWidget model={labelModel}/>
                                </div>
                            )
                        })
                    }
                </NodeLabel>
                {this.state.virtualSensorPanel != false ? this.renderVirtualSensorControlPanel() : ''}
            </>
        )
    }

    /**
     * Renders the calibration panel associated to the current sensor/actuator
     * @param nodeId
     * @protected
     */
    protected renderCalibrationContent(nodeId: string) {

        if (nodeId.includes("AnalogueSensorWired"))
            return <WiredSensorCalibration nodeId={nodeId}/>

        if (nodeId.includes("AnalogueSensorEndress"))
            return <EndressSensorCalibration nodeId={nodeId}/>

        if (nodeId.includes("AnalogueSensorLevitronics"))
            return <LevitronicsSensorCalibration nodeId={nodeId}/>

        if (nodeId.includes("AnalogueSensorHam"))
            return <HamiltonSensorCalibration nodeId={nodeId}/>

        return null
    }

    public renderVirtualSensorPanel(nodeId: string, index: number) {
        if (nodeId.includes('F_AnalogueSensorTCU')) {
            return (
              <TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
                  <TabStripTab title='Control'>
                      <HeatExchangerControlPanel nodeId={this.props.node.nodeId}/>
                  </TabStripTab>
                  <TabStripTab title='Alarm'>
                      <AlarmSettingsPanel nodeId={this.props.node.nodeId}/>
                  </TabStripTab>
              </TabStrip>
            )
        } else if (nodeId.includes('F_Agitator')) {
            return (<TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
                <TabStripTab title='Control'>
                    <TankControlPanel nodeId={this.props.node.nodeId}/>
                </TabStripTab>
            </TabStrip>);
        } else {
            return <>
                <TabStrip selected={this.state.virtualSelected} onSelect={this.handleVirtualSelect}>
                    <TabStripTab title='Alarm'>
                        <AlarmSettingsPanel nodeId={nodeId}/>
                    </TabStripTab>
                    {!nodeId.includes("SensorVirtual") && <TabStripTab title='Calibration'>
                        {this.renderCalibrationContent(nodeId)}
                    </TabStripTab>}
                </TabStrip>
            </>;
        }
    }

    handleSelect(selection: TabStripSelectEventArguments) {
        this.setState({selected: selection.selected})
    }

    handleVirtualSelect(selection: any) {
        this.setState({virtualSelected: selection.selected})
    }

    showPopup(e: any) {
        this.anchor = this.tooltipRef.current
        this.setState({show: !this.state.show})
    }

    showVirtualSensorPopup(nodeId?: string, tag: string = '', index: number = 0) {
        // Only toggle virtual sensor popup when providing undefined instead of parameters
        if (nodeId !== undefined) {
            // Clear selected state whenever the sensor changes
            if (nodeId !== this.virtualNodeId || tag !== this.virtualSensorTag) this.setState({ virtualSelected: 0 })
            this.virtualNodeId = nodeId;
            this.virtualSensorTag = tag;
            this.virtualSensorIndex = index;
        }
        this.setState({showVirtualSensorPopup: !this.state.showVirtualSensorPopup})
    }

    private renderControlPanel() {
        let allNodes = []
        let nodesRect = undefined
        let zoomFactor = 0
        let deZoomFactor = 1
        let scale = 1

        if (this.state.show) {
            allNodes = this.props.engine.getModel().getNodes()
            // get nodes bounding box with margin
            nodesRect = this.props.engine.getBoundingNodesRect(allNodes, undefined);
            zoomFactor = this.props.engine.getModel().getZoomLevel() / 100 //xFactor < yFactor ? xFactor : yFactor;
            deZoomFactor = 1 + zoomFactor
            scale = 1 + (1 - zoomFactor)
        }

        return (
            <>
                {this.state.show &&
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    position: 'fixed',
                    top: 0, //y
                    left: 0, //x
                    width: nodesRect.getWidth() * deZoomFactor,
                    height: nodesRect.getHeight() * deZoomFactor,
                    zIndex: 10001,
                    // borderColor: 'red',
                    // borderWidth: '2px',
                    // borderStyle: 'dashed'
                }}>
                    <Window
                        title={this.controlPanelTitleAddon === '' ? (<>{this.props.node.displayName}</>) : (<>{this.props.node.displayName}&nbsp;
                            <div style={{
                                color: 'rgb(230, 30, 80)',
                                fontWeight: 'bold'
                            }}>{this.controlPanelTitleAddon}</div>
                        </>)}
                        initialLeft={((nodesRect.getWidth() * deZoomFactor) / 2) - (1100 * deZoomFactor) / 2}
                        initialTop={((nodesRect.getHeight() * deZoomFactor) / 2) - (560 * deZoomFactor) / 2}
                        width={1100}
                        height={560}
                        draggable={true}
                        resizable={false}
                        onClose={event => this.showPopup(undefined)}
                        maximizeButton={() => null}
                        style={{transform: 'scale(' + scale + ')'}}
                    >
                        {this.renderControlPanelContent()}
                    </Window>
                </div>
                }
            </>
        )
    }

    protected renderVirtualSensorControlPanel() {
        let allNodes = []
        let nodesRect = undefined
        let zoomFactor = 0
        let deZoomFactor = 1
        let scale = 1

        if (this.state.showVirtualSensorPopup) {
            allNodes = this.props.engine.getModel().getNodes()
            // get nodes bounding box with margin
            nodesRect = this.props.engine.getBoundingNodesRect(allNodes, undefined);
            zoomFactor = this.props.engine.getModel().getZoomLevel() / 100 //xFactor < yFactor ? xFactor : yFactor;
            deZoomFactor = 1 + zoomFactor
            scale = 1 + (1 - zoomFactor)
        }
        return (
            <>
                {this.state.showVirtualSensorPopup &&
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 10001
                }}>
                    <Window
                        title={this.virtualSensorTag}
                        initialLeft={((nodesRect.getWidth() * deZoomFactor) / 2) - (1100 * deZoomFactor) / 2}
                        initialTop={((nodesRect.getHeight() * deZoomFactor) / 2) - (540 * deZoomFactor) / 2}
                        width={1100}
                        height={540}
                        draggable={true}
                        resizable={false}
                        onClose={event => this.showVirtualSensorPopup(undefined)}
                        maximizeButton={() => null}
                        style={{transform: 'scale(' + scale + ')'}}
                    >
                        {this.renderVirtualSensorPanel(this.virtualNodeId, this.virtualSensorIndex)}
                    </Window>
                </div>
                }
            </>
        )
    }

    public renderControlPanelContent() {
        return <></>
    }

    render() {
        return (
            <div>
                {this.state.controlPanel ? this.renderControlPanel() : ''}
                <div>
                    {this.renderNodeLabel()}
                </div>
            </div>
        )
    }
}
