import React from 'react'
import EmptyNodeWidget from './EmptyArtifactWidget'
import { EmptyArtifactModel } from './EmptyArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class EmptyArtifactFactory extends AbstractReactFactory<EmptyArtifactModel, DiagramEngine> {
  constructor () {
    super('Empty')
  }

  generateReactWidget (event: any): JSX.Element {
    return <EmptyNodeWidget engine={this.engine} size={83.30} node={event.model} />
  }

  generateModel (event: any) {
    return new EmptyArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
