import React from 'react'
import {PortWidget, DefaultLabelModel} from '@projectstorm/react-diagrams'

import {MembraneArtifactModel} from './MembraneArtifactModel'
import {
    BaseArtifactWidgetProps,
    BaseArtifactWidgetState,
    BaseArtifactWidget, NodeLabel
} from '../../BaseArtifact/BaseArtifactWidget'

import MembraneVisual from './MembraneVisual'

import * as DefaultPort from '../../Port'
import _ from "lodash";
import CustomLabelWidget from "../../BaseArtifact/CustomLabelWidget";
import StoreTypes from "StoreTypes";
import {IConfigReducer} from "../../../../store/reducers/configReducer";
import {connect} from "react-redux";
import { DriveControlPID } from '../../../../models/PLC/DriveControlPID'

export interface MembraneNodeWidgetProps extends BaseArtifactWidgetProps<MembraneArtifactModel> {
    height: number;
    width: number;
    config: IConfigReducer;
}

export interface MembraneNodeWidgetState extends BaseArtifactWidgetState<MembraneArtifactModel> {
}

class MembraneArtifactWidget extends BaseArtifactWidget<MembraneNodeWidgetProps, MembraneNodeWidgetState> {
    protected rightFlowLabelRef: React.RefObject<HTMLDivElement>
    protected rightPermeabilityLabelRef: React.RefObject<HTMLDivElement>

    //protected rightLabelRef: React.RefObject<HTMLDivElement>

    constructor(props: MembraneNodeWidgetProps) {
        super(props)
        this.rightFlowLabelRef = React.createRef()
        this.rightPermeabilityLabelRef = React.createRef()

        this.state = {
            controlPanel: false,
            selected: 0,
            virtualSelected: 0
        }
    }

    calculateLabelsPosition = () => {

        if (this.labelRef.current) {
            const labelDimensions = {
                width: this.labelRef.current.offsetWidth,
                height: this.labelRef.current.offsetHeight
            }

            const labelCoordinates = {
                x: -labelDimensions.width - 20,
                y: 10
            }

            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`
        }

        if (this.rightFlowLabelRef.current) {
            const flowLabelCoordinates = {
                x: this.props.width,
                y: 10
            }
            this.rightFlowLabelRef.current.style.transform = `translate(${flowLabelCoordinates.x}px, ${flowLabelCoordinates.y}px)`
        }

        if (this.rightPermeabilityLabelRef.current) {
            const permeabilityLabelCoordinates = {
                x: this.props.width,
                y: 10
            }
            if (this.rightFlowLabelRef.current) {
                permeabilityLabelCoordinates.x = this.props.width + this.rightFlowLabelRef.current.offsetWidth;
                permeabilityLabelCoordinates.y = 10;
            }
            this.rightPermeabilityLabelRef.current.style.transform = `translate(${permeabilityLabelCoordinates.x}px, ${permeabilityLabelCoordinates.y}px)`
        }

    };

    public renderNodeLabel() {

        let leftLabels: DefaultLabelModel[] = [];
        let rightFlowLabels: DefaultLabelModel[] = [];
        let rightPermeabilityLabels: DefaultLabelModel[] = [];


        _.forEach(this.props.node.getLabels(), (labelModel) => {
            //Get the sensor tag
            const tag = labelModel.getID().split('\\')[1]

            if (tag.includes("PER")) {
                rightPermeabilityLabels.push(labelModel);
            } else {
                if (tag.includes("FLU")) {
                    rightFlowLabels.push(labelModel);
                } else {
                    leftLabels.push(labelModel);
                }
            }
        });

        return (
            <>
                <NodeLabel
                    ref={this.labelRef} style={{
                    position: 'absolute',
                    display: 'inline-block',
                    top: 0,
                    left: 0,
                    width: 'max-content',
                    cursor: 'pointer'
                }}
                >
                    {
                        _.map(leftLabels, (labelModel, index) => {
                            return (
                                <div key={labelModel.getID()} className="VirtualSensorNodeLabel"
                                     onClick={(e) => {
                                         const [nodeId, tag] = labelModel.getID().split('\\');
                                         this.showVirtualSensorPopup(nodeId, tag, super.getRandomInt(100));
                                     }}>
                                    <CustomLabelWidget model={labelModel}/>
                                </div>
                            )
                        })
                    }
                </NodeLabel>
                <NodeLabel
                    ref={this.rightFlowLabelRef} style={{
                    position: 'absolute',
                    display: 'inline-block',
                    top: 0,
                    left: 0,
                    width: 'max-content',
                    cursor: 'pointer'
                }}
                >
                    {
                        _.map(rightFlowLabels, (labelModel, index) => {
                            return (
                                <div key={labelModel.getID()} className="VirtualSensorNodeLabel"
                                     onClick={(e) => {
                                         const [nodeId, tag] = labelModel.getID().split('\\');
                                         this.showVirtualSensorPopup(nodeId, tag, super.getRandomInt(100));
                                     }}>
                                    <CustomLabelWidget model={labelModel}/>
                                </div>
                            )
                        })
                    }
                </NodeLabel>
                <NodeLabel
                    ref={this.rightPermeabilityLabelRef} style={{
                    position: 'absolute',
                    display: 'inline-block',
                    top: 0,
                    left: 0,
                    width: 'max-content',
                    cursor: 'pointer'
                }}
                >
                    {
                        _.map(rightPermeabilityLabels, (labelModel, index) => {
                            return (
                                <div key={labelModel.getID()} className="VirtualSensorNodeLabel"
                                     onClick={(e) => {
                                         const [nodeId, tag] = labelModel.getID().split('\\');
                                         this.showVirtualSensorPopup(nodeId, tag, super.getRandomInt(100));
                                     }}>
                                    <CustomLabelWidget model={labelModel}/>
                                </div>
                            )
                        })
                    }
                </NodeLabel>
                {this.state.virtualSensorPanel !== false ? super.renderVirtualSensorControlPanel() : ''}
            </>
        )
    }

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

    render() {
      const isSIC001_P001Running = (this.props.config.Instances.ControlModules['P001/SIC001'] as DriveControlPID).Sts_bRunning
        return (
            <div
                className='MembraneArtifact-node'
                style={{
                    position: 'relative',
                    width: this.props.width,
                    height: this.props.height
                }}
            >
                <svg
                    width={this.props.width}
                    height={this.props.height}
                    viewBox='0 0 71 174'
                    preserveAspectRatio='xMidYMid'
                    dangerouslySetInnerHTML={{
                        __html: MembraneVisual.build(isSIC001_P001Running)
                    }}
                    onClick={(e) => {
                        if (!this.state.show)
                            super.showPopup(e)
                    }}
                />
                <PortWidget
                    port={this.props.node.getPort('retentate')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 28
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                <PortWidget
                    port={this.props.node.getPort('feed')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: this.props.height - 16,
                        left: 26
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                <PortWidget
                    port={this.props.node.getPort('filtrate')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        right: 28,
                        top: 0
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                <PortWidget
                    port={this.props.node.getPort('drain')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        right: 24,
                        top: this.props.height - 16
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                {super.render()}
            </div>
        )
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(MembraneArtifactWidget)
