import React from 'react'
import ValveNodeWidget from './ValveArtifactWidget'
import { ValveArtifactModel } from './ValveArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class ValveArtifactFactory extends AbstractReactFactory<ValveArtifactModel, DiagramEngine> {
  constructor () {
    super('Valve')
  }

  generateReactWidget (event: any): JSX.Element {
    return <ValveNodeWidget engine={this.engine} size={60} node={event.model} />
  }

  generateModel (event: any) {
    return new ValveArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
