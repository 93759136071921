import _ from 'lodash'
import React from 'react'
import {Col, Container, Row} from 'reactstrap';
import {Theme, Toggle} from '@liquid-design/liquid-design-react'
import {apiOpcValue} from "../../../models/Api/apiOpcValue";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {Label} from '@progress/kendo-react-labels';
import {IConfigReducer} from "../../../store/reducers/configReducer";
import {useDispatch, useSelector} from "react-redux";
import StoreTypes from 'StoreTypes';
import {setPlcProperty} from "../../../utils/plcUtils";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {FB_AAP} from "../../../models/PLC/FB_AAP";
import {FB_DAP} from "../../../models/PLC/FB_DAP";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import {IntlShape, useIntl} from 'react-intl'
import {getDigitFormat, getDigitNumber, getSensorDeadBand, getSensorUnit} from "./settingsHelpers";

export interface IAlarmSettingsPanelProps {
    nodeId: string
}

/**
 * Render Criticality Toggle for DAP (when suitable)
 * @param showCriticalityToggle
 * @param DAP
 * @param config
 * @param dispatchFunc
 * @param intl
 * @param isReadOnly
 */
function generateCriticalityColumn(showCriticalityToggle: boolean, DAP: FB_DAP, config: IConfigReducer, dispatchFunc: any, intl: IntlShape, isReadOnly: boolean = false) {
    if (showCriticalityToggle) {
        return <Col>
            <Label
                editorId={DAP.Cfg_sTag + "Criticality"}>{intl.formatMessage({id: 'label.AlarmCritical'})}</Label>
            <br/>
            <Toggle
                id={DAP.Cfg_sTag + "Criticality"}
                disabled={(DAP.Set_iPriority > 0 && DAP.Set_iPriority < 10) || isReadOnly}
                isActive={DAP.Set_iPriority <= 101}
                onClick={() => {
                    let currentPriority: number;

                    if (DAP.Set_iPriority === 251 || DAP.Set_iPriority === 0)
                        currentPriority = 101;
                    else
                        currentPriority = 251;

                    let isCritical: apiOpcValue = new apiOpcValue();
                    isCritical.NodeId = DAP.NodeId + '.Set_iPriority';
                    isCritical.Value = currentPriority;

                    setPlcProperty(config, isCritical, {
                        userLabel: 'label.event.CriticalityLevelSet',
                        unit: '',
                        eventTriggering: UserEventTriggering.BeforeAction
                    }, dispatchFunc);
                }}/>
        </Col>;
    }

    return <></>;
}

/**
 * Renders DAP Settings contained in columns
 * @param config
 * @param DAP
 * @param showCriticalityToggle
 * @param dispatchFunc
 * @param intl
 * @param fromAAP
 * @param isReadOnly
 */
function generateDAPSettingsColumns(config: IConfigReducer, DAP: FB_DAP, showCriticalityToggle: boolean, dispatchFunc: any, intl: IntlShape, fromAAP: boolean, isReadOnly: boolean = false) {
    let textCode = "label." + DAP.Cfg_sTag
    if (fromAAP) {
        textCode = "label.Delay"
    }

    return <>
        {generateCriticalityColumn(showCriticalityToggle, DAP, config, dispatchFunc, intl, isReadOnly)}
        <Col>
            <Label editorId={DAP.Cfg_sTag}>{intl.formatMessage({id: textCode})}</Label>
            <br/>
            <NumericTextBox
                id={DAP.Cfg_sTag}
                format="0 s"
                min={0}
                step={1}
                spinners={false}
                value={DAP.Set_iDelayActivation}
                disabled={(DAP.Set_iPriority > 0 && DAP.Set_iPriority < 10) || isReadOnly}
                onChange={(e) => {
                    //Propagate change on Enter button press
                    if (e.nativeEvent.key === "Enter") {
                        let delay: apiOpcValue = new apiOpcValue();
                        delay.NodeId = DAP.NodeId + '.Set_iDelayActivation';
                        delay.Value = e.value;

                        setPlcProperty(config, delay, {
                            userLabel: 'label.event.ActivationDelaySet',
                            unit: 's',
                            eventTriggering: UserEventTriggering.BeforeAction
                        }, dispatchFunc);
                    }
                }}
            />
        </Col>
        <Col>
            <Label
                editorId={DAP.Cfg_sTag + "Activation"}>{intl.formatMessage({id: 'label.AlarmEnabled'})}</Label>
            <br/>
            <Toggle
                id={DAP.Cfg_sTag + "Activation"}
                isActive={DAP.Set_bEnable}
                onClick={() => {
                    let isEnabled: apiOpcValue = new apiOpcValue();
                    isEnabled.NodeId = DAP.NodeId + '.Set_bEnable';
                    const newValue = !DAP.Set_bEnable;
                    isEnabled.Value = newValue;

                    setPlcProperty(config, isEnabled, {
                        userLabel: newValue ? 'label.event.AlarmEnabled' : 'label.event.AlarmDisabled',
                        unit: '',
                        eventTriggering: UserEventTriggering.BeforeAction
                    }, dispatchFunc);
                }}
                disabled={(DAP.Set_iPriority > 0 && DAP.Set_iPriority < 10) || isReadOnly}/>
        </Col>
    </>;
}

function generateDAPSettingsRow(config: IConfigReducer, DAP: FB_DAP, showCriticalityToggle: boolean, dispatchFunc: any, index: number, intl: IntlShape, isReadOnly: boolean = false) {
    return <Row key={DAP.Cfg_sTag + index.toString()} style={{padding: '5px'}}>
        {generateDAPSettingsColumns(config, DAP, showCriticalityToggle, dispatchFunc, intl, false, isReadOnly)}
    </Row>;
}

/**
 * Renders Out Of Range alarm in a Row container
 * @param AAP
 * @param config
 * @param dispatchFunc
 * @param intl
 */
function generateOutOfRange(AAP: FB_AAP, config: IConfigReducer, dispatchFunc: any, intl: IntlShape) {

    //If out of range alarm is not enabled, render empty content
    if (!AAP.DAPOutOfRange.Cfg_bAvailable)
        return <></>;

    //Rendering Out of range alarm
    return <Row id={AAP.Cfg_sTag + '5'} style={{padding: '5px'}} xs={3}>
        <Col>
            <Label editorId='OutOfRange'>{intl.formatMessage({id: "label.OutOfRange"})}</Label>
            <br/>
            <NumericTextBox
                id='OutOfRange'
                format={"p" + getDigitNumber(AAP.Set_iOutOfRangePercentLimit, 0)}
                min={0}
                max={1}
                step={0.1}
                spinners={false}
                value={AAP.Set_iOutOfRangePercentLimit / 100}
                onChange={(e) => {
                    //Propagate change on Enter button press
                    if (e.nativeEvent.key === "Enter") {
                        let threshold: apiOpcValue = new apiOpcValue();
                        threshold.NodeId = AAP.NodeId + '.Set_iOutOfRangePercentLimit';
                        threshold.Value = e.value * 100;

                        setPlcProperty(config, threshold, {
                            userLabel: 'label.event.OutOfRangePercentLimitSet',
                            unit: '%',
                            eventTriggering: UserEventTriggering.BeforeAction
                        }, dispatchFunc);
                    }
                }}
            />

        </Col>
        {generateDAPSettingsColumns(config, AAP.DAPOutOfRange, false, dispatchFunc, intl, true)}
    </Row>;
}

/**
 * Renders AAP settings i a Row container
 * @param config
 * @param sensor
 * @param dispatchFunc
 * @param intl
 */
function generateAAPSettingsLine(config: IConfigReducer, sensor: any, dispatchFunc: any, intl: IntlShape) {

    //Get the AAP for the given controlModule
    let AAP: FB_AAP = sensor.AAP;

    //If AAP is null, return empty element
    if (AAP === undefined)
        return <></>;
    let deadBand = getSensorDeadBand(sensor, 'Out_rCurrentValue', sensor.Out_rCurrentValue)
    let sensorUnit = getSensorUnit(sensor, 'Out_rCurrentValue')

    //Rendering AAP standard information
    return <>
        <Row id={AAP.Cfg_sTag + '1'} style={{padding: '5px'}} xs={3}>
            <Col>
                <Label editorId='ThresholdHiHi'>{intl.formatMessage({id: "label.ThresholdHiHi"})}</Label>
                <br/>
                <NumericTextBox
                    id='ThresholdHiHi'
                    format={"0" + getDigitFormat(AAP.Set_rCriticalProcessHiThreshold, deadBand) + " " + sensorUnit.Unit}
                    max={sensor.Cfg_rEGUMaximum}
                    min={AAP.Set_rProcessWarningHiThreshold}
                    step={0.1}
                    spinners={false}
                    value={AAP.Set_rCriticalProcessHiThreshold}
                    onChange={(e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            let threshold: apiOpcValue = new apiOpcValue();
                            threshold.NodeId = AAP.NodeId + '.Set_rCriticalProcessHiThreshold';
                            threshold.Value = e.value;

                            setPlcProperty(config, threshold, {
                                userLabel: 'label.event.AlarmHiHiThresholdSet',
                                unit: '',
                                eventTriggering: UserEventTriggering.BeforeAction
                            }, dispatchFunc);
                        }
                    }}
                />
            </Col>
            {generateDAPSettingsColumns(config, AAP.DAPCriticalProcessHi, false, dispatchFunc, intl, true)}
        </Row>
        <Row id={AAP.Cfg_sTag + '2'} style={{padding: '5px'}} xs={3}>
            <Col>
                <Label editorId='ThresholdHi'>{intl.formatMessage({id: "label.ThresholdHi"})}</Label>
                <br/>
                <NumericTextBox
                    id='ThresholdHi'
                    format={"0" + getDigitFormat(AAP.Set_rProcessWarningHiThreshold, deadBand) + " " + sensorUnit.Unit}
                    step={0.1}
                    spinners={false}
                    value={AAP.Set_rProcessWarningHiThreshold}
                    max={AAP.Set_rCriticalProcessHiThreshold}
                    min={AAP.Set_rProcessWarningLoThreshold}
                    onChange={(e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            let threshold: apiOpcValue = new apiOpcValue();
                            threshold.NodeId = AAP.NodeId + '.Set_rProcessWarningHiThreshold';
                            threshold.Value = e.value;

                            setPlcProperty(config, threshold, {
                                userLabel: 'label.event.AlarmHiThresholdSet',
                                unit: '',
                                eventTriggering: UserEventTriggering.BeforeAction
                            }, dispatchFunc);
                        }
                    }}
                />
            </Col>
            {generateDAPSettingsColumns(config, AAP.DAPProcessWarningHi, false, dispatchFunc, intl, true)}
        </Row>
        <Row id={AAP.Cfg_sTag + '3'} style={{padding: '5px'}} xs={3}>
            <Col>
                <Label editorId='ThresholdLo'>{intl.formatMessage({id: "label.ThresholdLo"})}</Label>
                <br/>
                <NumericTextBox
                    id='ThresholdLo'
                    format={"0" + getDigitFormat(AAP.Set_rProcessWarningLoThreshold, deadBand) + " " + sensorUnit.Unit}
                    step={0.1}
                    spinners={false}
                    value={AAP.Set_rProcessWarningLoThreshold}
                    max={AAP.Set_rProcessWarningHiThreshold}
                    min={AAP.Set_rCriticalProcessLoThreshold}
                    onChange={(e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            let threshold: apiOpcValue = new apiOpcValue();
                            threshold.NodeId = AAP.NodeId + '.Set_rProcessWarningLoThreshold';
                            threshold.Value = e.value;

                            setPlcProperty(config, threshold, {
                                userLabel: 'label.event.AlarmLoThresholdSet',
                                unit: '',
                                eventTriggering: UserEventTriggering.BeforeAction
                            }, dispatchFunc);
                        }
                    }}
                />
            </Col>
            {generateDAPSettingsColumns(config, AAP.DAPProcessWarningLo, false, dispatchFunc, intl, true)}
        </Row>
        <Row id={AAP.Cfg_sTag + '4'} style={{padding: '5px'}} xs={3}>
            <Col>
                <Label editorId='ThresholdLoLo'>{intl.formatMessage({id: "label.ThresholdLoLo"})}</Label>
                <br/>
                <NumericTextBox
                    id='ThresholdLoLo'
                    format={"0" + getDigitFormat(AAP.Set_rCriticalProcessLoThreshold, deadBand) + " " + sensorUnit.Unit}
                    max={AAP.Set_rProcessWarningLoThreshold}
                    min={sensor.Cfg_rEGUMinimum}
                    step={0.1}
                    spinners={false}
                    value={AAP.Set_rCriticalProcessLoThreshold}
                    onChange={(e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            let threshold: apiOpcValue = new apiOpcValue();
                            threshold.NodeId = AAP.NodeId + '.Set_rCriticalProcessLoThreshold';
                            threshold.Value = e.value;

                            setPlcProperty(config, threshold, {
                                userLabel: 'label.event.AlarmLoLoThresholdSet',
                                unit: '',
                                eventTriggering: UserEventTriggering.BeforeAction
                            }, dispatchFunc);
                        }
                    }}
                />
            </Col>
            {generateDAPSettingsColumns(config, AAP.DAPCriticalProcessLo, false, dispatchFunc, intl, true)}
        </Row>
        {generateOutOfRange(AAP, config, dispatchFunc, intl)}
    </>;
}

function generateCustomDAPRows(config: IConfigReducer, controlModule: any, dispatchFunc: any, intl: IntlShape, isReadOnly: boolean = false) {

    let DAPList: FB_DAP[] = [];

    for (let property in controlModule) {
        // noinspection JSUnfilteredForInLoop
        if (property.startsWith("DAP") && controlModule[property].Cfg_bAvailable) {
            // noinspection JSUnfilteredForInLoop
            DAPList.push(controlModule[property]);
        }
    }

    return <>
        {DAPList.map((theDAP, index) => {
            return generateDAPSettingsRow(config, theDAP, true, dispatchFunc, index, intl, isReadOnly);
        })}
    </>;
}

export default function AlarmSettingsPanel({nodeId}: IAlarmSettingsPanelProps) {

    const dispatch = useDispatch();
    const intl = useIntl();

    //Getting information from store
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config);

    //Get the pump associated ControlModule
    const sensor: ControlModuleBase = _.find(config.Instances.ControlModules, (item: ControlModuleBase) => {
        return item.NodeId === nodeId;
    });

    const isReadOnly = sensor.Cfg_sTag.includes('WARN')

    return (
        <Theme themeName={config.theme}>
            <Container>
                {generateAAPSettingsLine(config, sensor, dispatch, intl)}
                {generateCustomDAPRows(config, sensor, dispatch, intl, isReadOnly)}
            </Container>
        </Theme>
    )
}
