import React, { useEffect, useRef, useState } from 'react'
import {useSelector} from 'react-redux'
import StoreTypes from 'StoreTypes'
import {IConfigReducer} from '../../../store/reducers/configReducer'
import {Col, Row} from 'reactstrap'
import {useIntl} from 'react-intl'
import {NumericTextBox} from '@progress/kendo-react-inputs'
import { Accordion, Checkbox, Dropdown, RadioButton } from '@liquid-design/liquid-design-react'

import useMountEffect from '../../../utils/useMountEffect'

import {AnalogueSensorVirtual} from '../../../models/PLC/AnalogueSensorVirtual'
import {DeadBandList} from '../../../models/PLC/DeadBand'

import {updateControlValue, updateDropdownValue} from './Helpers/UpdateHelpers'
import {getDigitFormat, getDigitNumber} from '../../Diagram/ControlPanels/settingsHelpers'
import MacroStep, {IMacroStep} from './MacroStep'

const TankFilling = (props: Partial<IMacroStep>) => {

    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const intl = useIntl()
    const areValuesLoadedRef = useRef(false)

    const EndCriteriaDropdownValues = [
        {name: intl.formatMessage({id: 'label.Time'}), id: '1', dbValue: 1002},
    ]

    const isTransfertOptionWired = config.Unit.Set.iUserOptionTransfert
    const isFiltratePCVOptionWired = config.Unit.Set.iFactOptionFiltratePCV
    const isTankWeightWired = config.Unit.Set.iConfiguredBTPSize > 1

    if (isTankWeightWired) {
        EndCriteriaDropdownValues.push({name: intl.formatMessage({id: 'label.WT001Threshold'}), id: '2', dbValue: 2})
    }


    useMountEffect(() => {
        if (props.loadedValues) {
            // CONCENTRATION CONFIG
            setMemorization(
                props.loadedValues[28].ActionICode === 10
            )
            // P001/SIC001
            const INITSIC001_P001SetPoint = props.loadedValues[14].ActionRValue
            setSIC001_P001SetPointSelected(
                INITSIC001_P001SetPoint
            )

            if (props.loadedValues[25].SequenceCriterias[0].IModeType !== 0) {
                // With low speed end criteria
                setHasEndCriteriaLowSpeed(true)

                setEndCriteriaLowSpeedThreshold(props.loadedValues[25].SequenceCriterias[0].RValue)
                setEndCriteriaLowSpeedSetpoint(props.loadedValues[25].ActionRValue)
            }

            // END CRITERIA
            const INITEndCriteriaDropdown = props.loadedValues[27].SequenceCriterias[0].IModeType
            setEndCriteriaDropdownSelected(
                EndCriteriaDropdownValues[EndCriteriaDropdownValues.findIndex((val) => val.dbValue === INITEndCriteriaDropdown)]
            )
            switch (INITEndCriteriaDropdown) {
                case 1002: {
                    setTimeCriteria(
                        props.loadedValues[27].SequenceCriterias[0].RValue
                    )
                    setTimeCriteriaHour(
                        Math.floor(props.loadedValues[27].SequenceCriterias[0].RValue / 3600)
                    )
                    setTimeCriteriaMinute(
                        Math.floor((props.loadedValues[27].SequenceCriterias[0].RValue % 3600) / 60)
                    )
                    setTimeCriteriaSecond(props.loadedValues[27].SequenceCriterias[0].RValue % 60
                    )
                    break
                }
                case 2: {
                    setWeightSumThreshold(
                        props.loadedValues[27].SequenceCriterias[0].RValue
                    )
                    break
                }
            }
        }
        areValuesLoadedRef.current = true
    })

    useEffect(() => {
        if (areValuesLoadedRef.current && props.onSave) {
            validConfiguration(false)
        }
    }, [props.onSave, validConfiguration])

    const [memorization, setMemorization] = useState(false)

    const [SIC001_P001SetPointSelected, setSIC001_P001SetPointSelected] = useState(0)

    const [timeCriteria, setTimeCriteria] = useState(0)
    const [timeCriteriaHour, setTimeCriteriaHour] = useState(0)
    const [timeCriteriaMinute, setTimeCriteriaMinute] = useState(0)
    const [timeCriteriaSeconds, setTimeCriteriaSecond] = useState(0)
    const [weightSumThreshold, setWeightSumThreshold] = useState(0)
    const [EndCriteriaDropdownSelected, setEndCriteriaDropdownSelected] = useState(EndCriteriaDropdownValues[0])
    const [hasEndCriteriaLowSpeed, setHasEndCriteriaLowSpeed] = useState(false)
    const [endCriteriaLowSpeedThreshold, setEndCriteriaLowSpeedThreshold] = useState(0)
    const [endCriteriaLowSpeedSetpoint, setEndCriteriaLowSpeedSetpoint] = useState(0)

    const endCriteriaLowSpeedThresholdConsistencyCheck = endCriteriaLowSpeedThreshold > weightSumThreshold
    const endCriteriaLowSpeedSetpointConsistencyCheck = endCriteriaLowSpeedSetpoint < SIC001_P001SetPointSelected
    const endCriteriaFormError = hasEndCriteriaLowSpeed && (!endCriteriaLowSpeedThresholdConsistencyCheck || !endCriteriaLowSpeedSetpointConsistencyCheck)

    function validConfiguration(triggerChanges = true) {
        props.onSave(triggerChanges, {
            memorization,
            SIC001_P001SetPointSelected,
            timeCriteria,
            weightSumThreshold,
            EndCriteriaDropdownSelected,
            isTransfertOptionWired,
            isFiltratePCVOptionWired,
            hasEndCriteriaLowSpeed,
            endCriteriaLowSpeedThreshold,
            endCriteriaLowSpeedSetpoint
        })
    }

    function updateTimeCriteria(valToModif: number, value: number) {
        let temptimeCriteria: number
        switch (valToModif) {
            case 1 : {
                setTimeCriteriaHour(value)
                temptimeCriteria = value * 3600 + timeCriteriaMinute * 60 + timeCriteriaSeconds
                break
            }
            case 2 : {
                setTimeCriteriaMinute(value)
                temptimeCriteria = timeCriteriaHour * 3600 + value * 60 + timeCriteriaSeconds
                break
            }
            case 3 : {
                setTimeCriteriaSecond(value)
                temptimeCriteria = timeCriteriaHour * 3600 + timeCriteriaMinute * 60 + value
                break
            }

        }
        updateControlValue(false, setTimeCriteria, temptimeCriteria)
    }

    function generateEndCriteriaFields() {
        switch (EndCriteriaDropdownSelected.id) {
            case '1':
                // Time
                return (
                    <>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.TimeHour'})}
                            format={'0 h'}
                            spinners={false}
                            min={0}
                            value={timeCriteriaHour}
                            onChange={(val) => updateTimeCriteria(1, val.value)}
                        />
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.TimeMinute'})}
                            format={'0 m'}
                            spinners={false}
                            min={0}
                            max={59}
                            value={timeCriteriaMinute}
                            onChange={(val) => updateTimeCriteria(2, val.value)}
                        />
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.TimeSecond'})}
                            format={'0 s'}
                            spinners={false}
                            min={0}
                            max={59}
                            value={timeCriteriaSeconds}
                            onChange={(val) => updateTimeCriteria(3, val.value)}
                        />
                    </>
                )
            case '2':
                // WI001 Threshold
                const WI001 = config.Instances.ControlModules['WI001'] as AnalogueSensorVirtual
                const unit = WI001.Values[0].Cfg_suSensorUnit.Unit

                return (
                  <>
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.WT001Threshold'})}
                        spinners={false}
                        min={WI001.Cfg_rEGUMinimum}
                        max={WI001.Cfg_rEGUMaximum}
                        value={weightSumThreshold}
                        format={`0${getDigitFormat(weightSumThreshold, DeadBandList.getDeadBandFor(WI001.Values[0].Cfg_dDeadBands, weightSumThreshold))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setWeightSumThreshold, val.value)}
                    />
                      <br/>
                      <br/>
                      <Checkbox label={intl.formatMessage({ id: 'label.EndEmptyingLowSpeed' })} isChecked={hasEndCriteriaLowSpeed} onChange={() => setHasEndCriteriaLowSpeed((val) => !val)} />
                      { hasEndCriteriaLowSpeed && (
                        <>
                            <br/>
                            <NumericTextBox
                              width={300}
                              valid={endCriteriaLowSpeedThresholdConsistencyCheck}
                              label={intl.formatMessage({id: 'label.EndEmptyingLowSpeedThreshold'})}
                              spinners={false}
                              min={WI001.Cfg_rEGUMinimum}
                              max={WI001.Cfg_rEGUMaximum}
                              value={endCriteriaLowSpeedThreshold}
                              format={`0${getDigitFormat(endCriteriaLowSpeedThreshold, DeadBandList.getDeadBandFor(WI001.Values[0].Cfg_dDeadBands, endCriteriaLowSpeedThreshold))} ${unit}`}
                              onChange={(val) => updateControlValue(false, setEndCriteriaLowSpeedThreshold, val.value)}
                            />
                            <br/>
                            { !endCriteriaLowSpeedThresholdConsistencyCheck && (
                              <>
                                  <span style={ { color: 'red' } }>{ intl.formatMessage({ id: 'label.EndEmptyingLowSpeedThresholdError' }) }</span>
                                  <br/>
                              </>
                            ) }
                            <NumericTextBox
                              width={300}
                              valid={endCriteriaLowSpeedSetpointConsistencyCheck}
                              label={intl.formatMessage({ id: 'label.EndEmptyingLowSpeedSetpoint'})}
                              spinners={false}
                              min={0}
                              max={SIC001_P001SetPointSelected / 100}
                              value={endCriteriaLowSpeedSetpoint / 100}
                              format={`p${getDigitNumber(endCriteriaLowSpeedSetpoint, DeadBandList.getDeadBandFor(config.Instances.ControlModules['P001/SIC001'].Values[0].Cfg_dDeadBands, endCriteriaLowSpeedSetpoint))}`}
                              onChange={(val) => setEndCriteriaLowSpeedSetpoint(val.value * 100)}
                            />
                            <br/>
                            { !endCriteriaLowSpeedSetpointConsistencyCheck && (
                              <span
                                style={ { color: 'red' } }>{ intl.formatMessage({ id: 'label.EndEmptyingLowSpeedSetpointError' }) }</span>
                            ) }
                        </>
                      ) }
                  </>
                )
        }
    }

    return (
        <MacroStep
            id={props.id}
            macroStepName={'label.TankEmptying'}
            lightBoxLabel={'label.TankEmptyingOptions'}
            sequenceID={props.sequenceID}
            stepNumber={props.stepNumber}
            isDraggable={props.isDraggable}
            isModel={props.isModel}
            onDelete={props.onDelete}
            onSave={validConfiguration}
            disabled={endCriteriaFormError}
        >
            <Accordion title={intl.formatMessage({id: 'label.TankEmptyingOptions'})}>
                <div style={{minHeight: '200px'}}>
                    <Row>
                        <Col>
                            <br/>
                            <RadioButton
                                isSelected={memorization}
                                onClick={() => setMemorization(true)}
                                label={intl.formatMessage({id: 'label.Memorize'})}
                            />
                            <br/>
                            <RadioButton
                                isSelected={!memorization}
                                onClick={() => setMemorization(false)}
                                label={intl.formatMessage({id: 'label.NoMemorize'})}
                            />
                        </Col>
                    </Row>
                </div>
            </Accordion>
            <Accordion title="P001/SIC001">
                <div style={{minHeight: '200px'}}>
                    <Row>
                        <Col>
                            <NumericTextBox
                                label={intl.formatMessage({id: 'label.SetPoint'})}
                                spinners={false}
                                min={0}
                                max={1}
                                value={SIC001_P001SetPointSelected / 100}
                                format={`p${getDigitNumber(SIC001_P001SetPointSelected, DeadBandList.getDeadBandFor(config.Instances.ControlModules['P001/SIC001'].Values[0].Cfg_dDeadBands, SIC001_P001SetPointSelected))}`}
                                onChange={(val) => updateControlValue(true, setSIC001_P001SetPointSelected, val.value)}
                            />
                        </Col>
                    </Row>
                </div>
            </Accordion>
            <Accordion title={intl.formatMessage({id: 'label.EndCriteria'})}>
                <div style={{minHeight: '200px'}}>
                    <Row>
                        <Col>
                            <Dropdown
                                label={intl.formatMessage({id: 'label.EndCriteria'})}
                                options={EndCriteriaDropdownValues}
                                value={EndCriteriaDropdownSelected.id}
                                onSubmit={(val: any) => updateDropdownValue(setEndCriteriaDropdownSelected, val, [setTimeCriteria, setWeightSumThreshold])}
                            />
                        </Col>
                        <Col>
                            {generateEndCriteriaFields()}
                        </Col>
                    </Row>
                </div>
            </Accordion>
            <br/>
        </MacroStep>
    )
}

export default TankFilling
