/**
 * Visual builder for Tank Artifact
 */
export default class TankVisual {

    private static drawInterlock() {
        return `<g transform="matrix(1.67117,0,0,1.67117,-41.9254,-21.4289)">
                    <g id="circle30301" serif:id="circle3030" transform="matrix(0.0499242,0,0,0.0499242,56.5783,121.979)">
                        <circle cx="16" cy="4" r="171.304" style="fill:rgb(93,10,10);stroke:rgb(255,43,46);stroke-width:44.6px;"/>
                    </g>
                    <g id="path3818" transform="matrix(0.0499242,0,0,0.0499242,56.5783,121.979)">
                        <path d="M-116.99,106.245L-85.17,138.065L151.14,-98.245L119.321,-130.065L-116.99,106.245Z" style="fill:rgb(255,43,46);fill-rule:nonzero;"/>
                    </g>
                </g>`
    }

    private static drawAgitator(isAgitatorRunning: boolean, regulationInitials: string, isAutoMode: boolean) {
        return `
            <g id="circle3030" transform="matrix(0.083432,0,0,0.083432,52.6268,182.42)">
                <circle cx="16" cy="4" r="171.304" style="fill:none;stroke:${isAutoMode ? 'transparent' : 'rgb(255,255,255)'};stroke-width:16px;stroke-dasharray:38.33,38.33;"/>
            </g>
            <g transform="matrix(1,0,0,1,-2.90588,48.7537)" class="${isAgitatorRunning ? 'agitator-on' : ''}">
                <g transform="matrix(1,0,0,1,45.9656,129)">
                    <path d="M17,1L5,1C4.663,1 4.326,1.167 4.134,1.5L0.67,7.5C0.285,8.167 0.766,9 1.536,9L20.268,9C21.038,9 21.519,8.167 21.134,7.5L17.67,1.5C17.515,1.232 17.267,1.071 17,1.019L17,1Z" style="fill:rgb(213,213,217);stroke:rgb(49,185,255);stroke-width:0.04px;stroke-linejoin:round;stroke-miterlimit:2;"/>
                </g>
                <g transform="matrix(1,0,0,1,45.9656,129)">
                    <path d="M4.134,1.5C4.519,0.833 5.481,0.833 5.866,1.5L9.33,7.5C9.715,8.167 9.234,9 8.464,9L1.536,9C0.766,9 0.285,8.167 0.67,7.5L4.134,1.5Z" style="fill:white;fill-rule:nonzero;stroke:rgb(49,185,255);stroke-width:0.04px;stroke-linejoin:round;stroke-miterlimit:2;"/>
                </g>
            </g>
            <g transform="matrix(4.91967,0,0,4.91967,-50.6609,97.1118)">
                <g transform="matrix(0.216702,0,0,0.216702,19.2075,20.7564)">
                    <path d="M17.75,7C17.75,3.826 15.174,1.25 12,1.25L7,1.25C3.826,1.25 1.25,3.826 1.25,7C1.25,10.174 3.826,12.75 7,12.75L12,12.75C15.174,12.75 17.75,10.174 17.75,7Z" style="fill:rgb(213,213,217);stroke:rgb(217,213,213);stroke-width:1.5px;"/>
                </g>
                <g transform="matrix(0.350964,0,0,0.350964,20.0867,22.9014)">
                    <text x="0px" y="0px" style="font-family:'Lato', sans-serif;font-size:6px;font-weight:bold;fill:rgb(34,34,34);">${regulationInitials}</text>
                </g>
            </g>`
    }

    /**
     * Builds up svg
     * @returns SVG as a string
     * @param isAgitatorRunning
     */
    static build(isAgitatorRunning: boolean, regulationMode: number, isAgitatorEnabled: boolean, isAgitatorInterlocked: boolean, isAutoMode: boolean): string {
        let regulationInitials = ''
        switch (regulationMode) {
            case 1:
                //Speed regulation params
                regulationInitials = 'Sp'
                break
            case 3:
                //Weight regulation params
                regulationInitials = 'We'
                break
        }
        return `<g transform="matrix(1,0,0,1,2.03202e-06,2e-05)">
        <g transform="matrix(1,0,0,1,3.03828,-46.7173)">
            <g transform="matrix(1,0,0,1,-11.1344,9.71728)">
                <path d="M95.137,170.543L34.863,170.543L34.863,210.019C34.863,215.305 48.356,219.589 65,219.589C81.644,219.589 95.137,215.305 95.137,210.019L95.137,170.543Z" style="fill:rgb(213,213,217);"/>
                <path d="M34.863,170.543L34.863,168.543L32.863,168.543L32.863,170.543L34.863,170.543ZM95.137,170.543L97.137,170.543L97.137,168.543L95.137,168.543L95.137,170.543ZM34.863,172.543L95.137,172.543L95.137,168.543L34.863,168.543L34.863,172.543ZM36.863,210.019L36.863,170.543L32.863,170.543L32.863,210.019L36.863,210.019ZM65,217.589C56.825,217.589 49.505,216.534 44.295,214.88C41.679,214.049 39.707,213.102 38.432,212.145C37.122,211.162 36.863,210.423 36.863,210.019L32.863,210.019C32.863,212.259 34.29,214.037 36.03,215.344C37.805,216.676 40.247,217.791 43.085,218.693C48.783,220.502 56.531,221.589 65,221.589L65,217.589ZM93.137,210.019C93.137,210.423 92.878,211.162 91.568,212.145C90.293,213.102 88.321,214.049 85.705,214.88C80.496,216.534 73.175,217.589 65,217.589L65,221.589C73.47,221.589 81.218,220.502 86.916,218.693C89.753,217.791 92.195,216.676 93.97,215.344C95.71,214.037 97.137,212.259 97.137,210.019L93.137,210.019ZM93.137,170.543L93.137,210.019L97.137,210.019L97.137,170.543L93.137,170.543Z" style="fill:rgb(213,213,217);fill-rule:nonzero;"/>
                <path d="M95.275,170.543L35,170.543L35,210.019C35,215.305 48.493,219.589 65.137,219.589C81.782,219.589 95.275,215.305 95.275,210.019L95.275,170.543Z" style="fill:white;"/>
                <path d="M43,216.56L43,192L35,192L35,210.938C35.65,213.104 38.572,215.059 43,216.56Z" style="fill:rgb(248,248,252);"/>
                <path d="M95,210.939L95,192L87,192L87,216.56C91.428,215.059 94.35,213.104 95,210.939Z" style="fill:rgb(248,248,252);"/>
                <path d="M13.25,56.927C13.25,54.856 14.929,53.177 17,53.177L113,53.177C115.071,53.177 116.75,54.856 116.75,56.927L116.75,159.42C116.75,180.056 100.577,197.072 79.968,198.12L65,198.881L50.032,198.12C29.423,197.072 13.25,180.056 13.25,159.42L13.25,56.927Z" style="fill:rgb(237,237,243);fill-rule:nonzero;stroke:rgb(213,213,217);stroke-width:2.5px;"/>
                <path d="M118,49.795L118,56.192L117.958,56.192C116.846,63.306 93.561,68.987 65,68.987C36.439,68.987 13.154,63.306 12.042,56.192L12,56.192L12,49.795L17.393,49.795C26.02,45.374 44.097,42.331 65,42.331C85.903,42.331 103.98,45.374 112.607,49.795L118,49.795Z" style="fill:rgb(73,73,83);"/>
                <ellipse cx="65" cy="50.328" rx="53" ry="13.328" style="fill:rgb(118,118,128);"/>
                <path d="M18,116L112,116L112,157.751C112,177.326 96.358,193.314 76.787,193.742L65,194L53.213,193.742C33.642,193.314 18,177.326 18,157.751L18,116Z" style="fill:rgb(15,105,175);fill-rule:nonzero;"/>
                <ellipse cx="65" cy="116.434" rx="46.765" ry="13.328" style="fill:rgb(111,165,207);"/>
            </g>
            ` + (isAgitatorInterlocked ? this.drawInterlock() : '') + (isAgitatorEnabled ? this.drawAgitator(isAgitatorRunning && !isAgitatorInterlocked, regulationInitials, isAutoMode) : '') + `
        </g>
    </g>`
    }
}
