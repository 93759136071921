import React from 'react'
import {PortWidget} from '@projectstorm/react-diagrams'

import {BeakerArtifactModel} from './BeakerArtifactModel'
import {BaseArtifactWidgetProps, BaseArtifactWidgetState, BaseArtifactWidget} from '../../BaseArtifact/BaseArtifactWidget'

import BeakerVisual from './BeakerVisual'

import * as DefaultPort from '../../Port'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import AlarmSettingsPanel from '../../ControlPanels/AlarmSettingsPanel'

export interface BeakerNodeWidgetProps extends BaseArtifactWidgetProps<BeakerArtifactModel> {
    height: number,
    width: number
}

export interface BeakerNodeWidgetState extends BaseArtifactWidgetState<BeakerArtifactModel> {
}

class BeakerArtifactWidget extends BaseArtifactWidget<BeakerNodeWidgetProps, BeakerNodeWidgetState> {
    constructor(props: BeakerNodeWidgetProps) {
        super(props)
        this.state = {controlPanel: true, selected: 0, show: false, virtualSelected:0}
    }

    calculateLabelsPosition = () => {
        if (this.labelRef.current) {

            const labelCoordinates = {
                x: this.props.width,
                y: 0
            }

            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`
        }
    };

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

    renderControlPanelContent (): JSX.Element {
      let calibrationContent = super.renderCalibrationContent(this.props.node.nodeId);
      return (<TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
        <TabStripTab title='Alarm'>
          <AlarmSettingsPanel nodeId={this.props.node.nodeId}/>
        </TabStripTab>
        {calibrationContent && <TabStripTab title='Calibration'>
          {calibrationContent}
        </TabStripTab>}
      </TabStrip>);
    }

  render() {
        return (
            <div
                className='BeakerArtifact-node'
                style={{
                    position: 'relative',
                    width: this.props.width,
                    height: this.props.height,
                    cursor: 'pointer'
                }}
            >
                <svg
                    width={this.props.width}
                    height={this.props.height}
                    viewBox='0 0 33 47'
                    preserveAspectRatio='xMidYMid'
                    dangerouslySetInnerHTML={{
                        __html: BeakerVisual.build(this.props.node.isSelected() ? 'chocolate' : '#454545')
                    }}
                    onClick={(e) => {
                        if (!this.state.show)
                            super.showPopup(e)
                    }}
                />
                <PortWidget
                    port={this.props.node.getPort('top')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: this.props.width / 2 - 6
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                <PortWidget
                    port={this.props.node.getPort('left')} engine={this.props.engine}
                    style={{
                        position: 'absolute',
                        top: this.props.width / 2 - 8,
                        left: 0
                    }}
                >
                    <DefaultPort.Port/>
                </PortWidget>
                {super.render()}
            </div>
        )
    }
}

export default BeakerArtifactWidget
