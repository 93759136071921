import React, { Component } from 'react'

const NotificationIcon = (props: any) => {
  return (
    <div id="orch-notification_icon">
      <div id="topmenu-alarm"/>
      {props.count ? (
        <div className="orch-notification_count" style={{ color: '#FFF' }}>{props.count}</div>
      ) : (
        ''
      )}
    </div>
  )
}

export default NotificationIcon
