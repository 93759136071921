import * as _ from 'lodash'
import { DiagramModel, DiagramModelGenerics } from '@projectstorm/react-diagrams'
import { BaseArtifactModel } from './BaseArtifact/BaseArtifactModel'
import PipeLinkModel from './CustomLinks/PipeLink/PipeLinkModel'

export default class PIDDiagramModel<G extends DiagramModelGenerics = DiagramModelGenerics> extends DiagramModel<G> {
  // Get the first node with the specified code
  public getNodeByCode (code: string): BaseArtifactModel {
    const currentNodes = this.getActiveNodeLayer().getModels()

    return _.find(currentNodes, function (node) {
      return (node as BaseArtifactModel).code === code
    }) as BaseArtifactModel
  }

  // Get the first link with the specified code
  public getLinkByCode (code: string): PipeLinkModel {
    const currentLinks = this.getActiveLinkLayer().getModels()

    return _.find(currentLinks, function (node) {
      return (node as PipeLinkModel).code === code
    }) as PipeLinkModel
  }
}
