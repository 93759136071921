import * as React from 'react';
import {DefaultLabelModel} from '@projectstorm/react-diagrams';
import styled from '@emotion/styled';
import * as StoreTypes from 'StoreTypes'
import {connect} from 'react-redux'
import {injectIntl, IntlShape} from 'react-intl'
import {IConfigReducer} from '../../../store/reducers/configReducer'
import _ from "lodash";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {getDigitNumber} from "../ControlPanels/settingsHelpers";
import {ValueProperties} from "../../../models/PLC/ValueProperties";
import {DeadBandList} from "../../../models/PLC/DeadBand";

export interface DefaultLabelWidgetProps {
    model: DefaultLabelModel;
    config: IConfigReducer;
    intl: IntlShape;
}

const Label = styled.div`
  font-family: 'Lato',
  sans-serif;
  font-weight: bold;
  border-radius: 5px;
  color: #494953;
  font-size: 16px;
  padding: 0 8px;
  user-select: none;
`;

class CustomLabelWidget extends React.Component<DefaultLabelWidgetProps> {
    render() {
        const name = this.props.model.getID().split('\\')[0]

        if (name.startsWith("TEXT-")) {
            return (
                <>
                    <Label style={{
                        fontSize: '20px'
                    }}>
                        {this.props.model.getOptions().extras.title}
                    </Label>
                    <Label style={{
                        fontWeight: 1000,
                        fontSize: '28px'
                    }}>
                        {this.props.model.getOptions().extras.value}
                    </Label>
                </>
            );
        } else {
            const sensor: any = _.find(this.props.config.Instances.ControlModules, (item: ControlModuleBase) => {
                return item.NodeId === name;
            });

            let alarmColor = 'rgb(73,73,73)'

            const mainValueInfo: ValueProperties = sensor.Values.find((item: ValueProperties) => {
                return item.IsMainValue === true
            });

            let currentValue: any

            if (!mainValueInfo) {
                console.error("An error occurred while getting main value :")

                console.log("--- USED DATA ---")
                console.log("sensor.Cfg_sTag")
                console.log(sensor.Cfg_sTag)
                console.log("mainValueInfo")
                console.log(mainValueInfo)
                console.log("NodeID")
                console.log(name)
                console.log("Sensor object")
                console.log(sensor)

                console.log("--- ERRORS ---")
                console.error("- No mainValue info")
                currentValue = sensor[sensor.Values[0].Name]
                console.debug(currentValue)
                if (!currentValue) {
                    console.error("- No value in Values[]")
                    currentValue = sensor.Out_rCurrentValue
                    console.debug(currentValue)
                    if (!currentValue) {
                        console.error("- No Out_rCurrentValue")
                        currentValue = 0
                    }
                }
            } else {
                currentValue = sensor[mainValueInfo.Name]
            }

            if(!currentValue.toFixed) {
                console.error("ERROR: VALUE IS NOT A NUMBER")
                console.log("sensor.Cfg_sTag")
                console.log(sensor.Cfg_sTag)
                console.log("NodeID")
                console.log(name)
                console.log("Sensor object")
                console.log(sensor)
            }

            if (sensor.Sts_bAlarm) {
                if (sensor.Sts_bAlarmSystem || sensor.Sts_bAlarmSafety || sensor.Sts_bAlarmCriticalProcess) {
                    alarmColor = '#E61E50'
                } else {
                    alarmColor = '#FFC832'
                }
            }

            return (
                <>
                    <Label style={{
                        fontSize: '22px',
                        color: alarmColor
                    }}>
                        {!this.props.model.getOptions().extras.isSensor ? sensor.Cfg_sTag : this.props.intl.formatMessage({id: sensor.Values[0].Cfg_suSensorUnit.Category})}
                    </Label>
                    <Label style={{
                        fontWeight: 1000,
                        fontSize: '28px',
                        color: alarmColor
                    }}>
                        {`${currentValue.toFixed ? currentValue.toFixed(getDigitNumber(currentValue, DeadBandList.getDeadBandFor(mainValueInfo.Cfg_dDeadBands, currentValue))) : currentValue} `}
                        <br/>
                        <div style={{fontSize: "22px"}}>
                            {`${sensor.Values[0].Cfg_suSensorUnit.Unit !== 'none' ? sensor.Values[0].Cfg_suSensorUnit.Unit : ''}`}
                        </div>
                    </Label>
                </>
            );
        }
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(injectIntl(CustomLabelWidget))