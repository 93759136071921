import React, {useEffect, useState} from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavbarBrand,
    Row, Col,
} from 'reactstrap'
import './topmenu.css'
import Notification from '../AlarmNotification/Notification'
import {withTheme} from 'styled-components'
import {Logo} from '@liquid-design/liquid-design-react'
import {FormattedMessage, useIntl} from "react-intl";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {applicationVersions, licenseInformation} from "../../../../config/applicationVersions";
import {Editor} from "@progress/kendo-react-editor";
import StoreTypes from "StoreTypes";
import {connect} from "react-redux";
import plcIcon from "../../../../assets/images/plc-icon.png";

interface Package {
    PackageVersion: string
}

interface UpdaterStatusVersion {
    UpdaterVersion: string
    CurrentPackage: Package
}

const TopMenuBar = (props: any) => {

    const {config, theme} = props
    const [isExpanded, setIsExpanded] = useState(false)
    const [isVersionOpen, setIsVersionOpen] = useState(false)
    const [updaterVersion, setUpdaterVersion] = useState('')
    const [packageVersion, setPackageVersion] = useState('')
    const intl = useIntl()

    /**
     * Toggles the menubar in mobile view.
     * @memberof TopMenuBar
     */
    function toggle() {
        setIsExpanded(!isExpanded)
    }

    useEffect(() => {
        fetch(config.UpdaterBaseUrl + '/Updater/api/Updater/v1/Status')
            .then((resp) => resp.json())
            .then((data: UpdaterStatusVersion) => {
                setUpdaterVersion(data.UpdaterVersion)
                setPackageVersion(data.CurrentPackage.PackageVersion)
            })
            .catch(console.error)
    }, [])

    return (
        <>
            <div className="navWrapper" style={{backgroundColor: theme.colors.primary.base}}>
                <Navbar expand="md" style={{padding: 0}}>
                    <NavbarBrand>
                        <div style={{marginLeft: '20px'}}>
                            <Logo size={42} color={'rgb(255,255,255)'}/>
                        </div>
                    </NavbarBrand>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        color: '#d93b3b',
                        textAlign: 'center',
                        fontSize: '26px',
                        width: '100%',
                        fontWeight: 'bold',
                        textShadow: '-1px -1px 0 #FFFFFF, 1px -1px 0 #FFFFFF, -1px 1px 0 #FFFFFF, 1px 1px 0 #FFFFFF'
                    }}>
                        {!config.AH.Sts.Alarm.bPlcConnectionActive &&
                            <div><span><img src={plcIcon} className="plcError"/></span>
                                <span><FormattedMessage id="label.PlcConnectionBroken"/></span></div>}
                        {config.AH.Sts.Alarm.bDiskWarning &&
                            <div><FormattedMessage id="label.DiskUsageAboveLimit"/></div>}
                    </div>
                    <NavbarToggler onClick={() => toggle()} className="mr-2"/>
                    <Collapse isOpen={isExpanded} navbar>
                        <Nav className="ml-auto" navbar>
                            <div className="vl"/>
                            <div className="alarmIcon">
                                <Notification/>
                            </div>
                            <div className="vl"/>
                            <div className="userIcon" style={{marginRight: '15px', cursor: "pointer"}}
                                 onClick={() => setIsVersionOpen(true)}>
                                <div className="user-name-text">
                                    {props.cogentSize}
                                </div>
                            </div>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
            {isVersionOpen &&
                <Dialog title={intl.formatMessage({id: 'label.AboutBoxTitle'}) + ` ${packageVersion}`}
                        onClose={() => setIsVersionOpen(false)}
                        width={800}>
                    <Row>
                        <Col>
                            <FormattedMessage id='label.UpdaterVersion'/>
                        </Col>
                        <Col>
                            {updaterVersion}
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <h4>
                                <FormattedMessage id='label.LicenceInformations'/>
                            </h4>
                            <Editor
                                contentStyle={{height: '320px'}}
                                defaultContent={licenseInformation}
                                onExecute={e => {
                                    return !e.transaction.docChanged;
                                }}
                            />
                        </Col>
                    </Row>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => setIsVersionOpen(false)}>
                            <FormattedMessage id='label.Ok'/>
                        </button>
                    </DialogActionsBar>
                </Dialog>}
        </>
    )
}
const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(
    React.memo(withTheme(TopMenuBar), (prevProps, nextProps) => {
        return (prevProps.config.AH === nextProps.config.AH
            && prevProps.config.OPC === nextProps.config.OPC)
    })
)
