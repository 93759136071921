import React, {useState} from 'react'
import {Checkbox, RadioButton} from '@liquid-design/liquid-design-react'
import {IConfigReducer} from '../../../store/reducers/configReducer'
import {useSelector} from 'react-redux'
import StoreTypes from "StoreTypes"
import {setPlcPropertiesAsync, setPlcPropertyAsync} from "../../../utils/plcUtils";
import {apiOpcValue} from "../../../models/Api/apiOpcValue";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'

export const Lab6000ConfigPanel = () => {

    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const intl: IntlShape = useIntl()

    const isOptionEnabled: Record<string, boolean> = {}

    config.UserOptions.forEach((opt) => {
        isOptionEnabled[opt.Code] = !!(config.Unit.Set as any)[(opt as any).NsID]
    })

    const [tankRadio, setTankRadio] = useState(isOptionEnabled.Bag)
    const [rangePression, setRangePression] = useState(!!isOptionEnabled.RangePression)
    const [weightWI101, setWeightWI101] = useState(isOptionEnabled.Weight && config.Unit.Set.bVisibilityWE101)
    const [weightWI201, setWeightWI201] = useState(isOptionEnabled.Weight && config.Unit.Set.bVisibilityWE201)

    async function toggleUserOption(option: string, value?: any) {
        const decomposed = option.split('-')
        const UO = config.UserOptions[config.UserOptions.findIndex((opt) => opt.Code === decomposed[0])]

        if (decomposed.length > 1) {
            const visibilityEqual: Record<string, string> = {
                WI101: 'bVisibilityWE101',
                WI201: 'bVisibilityWE201'
            }

            let properties: apiOpcValue[] = [
                {
                    NodeId: UO.NodeId,
                    Value: true
                },
                {
                    NodeId: `${config.Unit.Set.NodeId}.${visibilityEqual[decomposed[1] as string]}`,
                    Value: value
                }
            ];

            let infoTag = option + ': '
            if (value)
                infoTag += 'ON'
            else
                infoTag += 'OFF'

            await setPlcPropertiesAsync(config, properties, {
                sourceTag: infoTag,
                userLabel: 'label.event.UserOptionSet',
                unit: '',
                eventTriggering: UserEventTriggering.BeforeAction,
                showValue: false
            }, undefined);

            switch (decomposed[1]) {
                case 'WI101':
                    setWeightWI101(value)
                    break
                case 'WI201':
                    setWeightWI201(value)
                    break
            }
        } else {
            const UO = config.UserOptions[config.UserOptions.findIndex((opt) => opt.Code === option)]

            let property: apiOpcValue = {
                NodeId: UO.NodeId,
                Value: value === undefined ? !isOptionEnabled[option] : value
            }

            let infoTag = option + ': ';

            if (option === 'RangePression') {
                if (property.Value === 1)
                    infoTag += 'High'
                else
                    infoTag += 'Low'
            } else {
                if (property.Value)
                    infoTag += 'ON'
                else
                    infoTag += 'OFF'
            }

            await setPlcPropertyAsync(config, property, {
                sourceTag: infoTag,
                userLabel: 'label.event.UserOptionSet',
                unit: '',
                eventTriggering: UserEventTriggering.BeforeAction,
                showValue: false
            }, undefined);

            switch (option) {
                case 'Bag':
                    setTankRadio(value)
                    break;
                case 'RangePression':
                    setRangePression(!!value)
                    break;
            }
        }
    }

    return (
        <>
            <h5>
              <FormattedMessage id='label.Tank'/>
            </h5>
            <br/>
            <RadioButton onClick={() => toggleUserOption('Bag', false)} isSelected={!tankRadio} label={intl.formatMessage({ id: 'label.UseSystemTank' })}/>
            <br/>
            <RadioButton onClick={() => toggleUserOption('Bag', true)} isSelected={tankRadio} label={intl.formatMessage({ id: 'label.UseExternalBag' })}/>
            <br/>
            <Checkbox onChange={() => toggleUserOption('transfert')} isChecked={isOptionEnabled.transfert} label={intl.formatMessage({ id: 'label.UseTransfertPump' })}/>
            <hr/>

            <h5>
              <FormattedMessage id='label.Feed'/>
            </h5>
            <br/>
            <Checkbox onChange={() => toggleUserOption('TempCtrl')} isChecked={isOptionEnabled.TempCtrl} label={intl.formatMessage({ id: 'label.UseTCU' })}/>
            <br/>
            <Checkbox onChange={() => toggleUserOption('FeedPH')} isChecked={isOptionEnabled.FeedPH} label={intl.formatMessage({ id: 'label.UsePHAndConductivity' })}/>
            <hr/>

            <h5>
              <FormattedMessage id='label.Retentate'/>
            </h5>
            <br/>
            <RadioButton onClick={() => toggleUserOption('Weight-WI101', false)} isSelected={!weightWI101} label={intl.formatMessage({ id: 'label.RetentateRedirectsToTank' })}/>
            <br/>
            <RadioButton onClick={() => toggleUserOption('Weight-WI101', true)} isSelected={weightWI101} label={intl.formatMessage({ id: 'label.RetentateToWeightScale' })}/>
            <hr/>

            <h5>
              <FormattedMessage id='label.Filtrate'/>
            </h5>
            <br/>
            <RadioButton onClick={() => toggleUserOption('Weight-WI201', false)} isSelected={!weightWI201} label={intl.formatMessage({ id: 'label.FiltrateToOutlet' })}/>
            <br/>
            <RadioButton onClick={() => toggleUserOption('Weight-WI201', true)} isSelected={weightWI201} label={intl.formatMessage({ id: 'label.FiltrateToWeightScaleWT201' })}/>
            <br/>
            <Checkbox onChange={() => toggleUserOption('UV')} isChecked={isOptionEnabled.UV} label={intl.formatMessage({ id: 'label.UseFiltrateUV' })} />
            <br/>
            <Checkbox onChange={() => toggleUserOption('FiltratePH')} isChecked={isOptionEnabled.FiltratePH} label={intl.formatMessage({ id: 'label.UseFiltratePHAndConductivity' })}/>
            <hr/>

            <h5>
              <FormattedMessage id='label.PressureSensors'/>
            </h5>
            <br/>
            <RadioButton onClick={() => toggleUserOption('RangePression', 0)} isSelected={!rangePression} label={intl.formatMessage({ id: 'label.UseStandardRangePressure' })}/>
            <br/>
            <RadioButton onClick={() => toggleUserOption('RangePression', 1)} isSelected={rangePression} label={intl.formatMessage({ id: 'label.UseLowRangePressure' })}/>
        </>
    )
}