import React from 'react'
import TankNodeWidget from './TankArtifactWidget'
import { TankArtifactModel } from './TankArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class TankArtifactFactory extends AbstractReactFactory<TankArtifactModel, DiagramEngine> {
  constructor () {
    super('Tank')
  }

  generateReactWidget (event: any): JSX.Element {
    return <TankNodeWidget engine={this.engine} height={300} width={180} node={event.model} />
  }

  generateModel (event: any) {
    return new TankArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
