import * as React from 'react'
import {PipeLinkFactory} from './PipeLinkFactory'
import {DiagramEngine} from '@projectstorm/react-diagrams-core'
import PipeLinkModel from './PipeLinkModel'
import * as StoreTypes from 'StoreTypes'
import {connect} from 'react-redux'
import {BaseArtifactWidget} from "../../BaseArtifact/BaseArtifactWidget";

export interface PipeLinkSegmentWidgetProps {
    path: string;
    link: PipeLinkModel;
    config: any;
    selected: boolean;
    forwardRef: React.RefObject<SVGPathElement>;
    factory: PipeLinkFactory;
    diagramEngine: DiagramEngine;
    onSelection: (selected: boolean) => any;
    extras: object;
}

class PipeLinkSegmentWidget extends React.Component<PipeLinkSegmentWidgetProps> {
    render() {
        let isEnabled = BaseArtifactWidget.getConditionsStatus(this.props.link.getOptions(), this.props.config);

        this.props.link.setColor(isEnabled ? '#0F69AF' : '#767680')

        const Bottom = React.cloneElement(
            this.props.factory.generateLinkSegment(
                this.props.link,
                this.props.selected || this.props.link.isSelected(),
                this.props.path
            ),
            {
                ref: this.props.forwardRef
            },
        )

        const Top = React.cloneElement(Bottom, {
            strokeLinecap: 'round',
            onMouseLeave: () => {
                this.props.onSelection(false)
            },
            onMouseEnter: () => {
                this.props.onSelection(true)
            },
            ...this.props.extras,
            ref: null,
            'data-linkid': this.props.link.getID(),
            strokeOpacity: this.props.selected ? 0.1 : 0,
            strokeWidth: 20,
            fill: 'none',
            onContextMenu: (event: any) => {
                if (!this.props.link.isLocked()) {
                    event.preventDefault()
                    this.props.link.remove()
                }
            }
        })

        return (
            <g>
                {Bottom}
                {Top}
            </g>
        )
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(PipeLinkSegmentWidget)