import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import * as StoreTypes from 'StoreTypes'
import { IConfigReducer } from '../store/reducers/configReducer'

import { Col, Row } from 'reactstrap'
import { Button, Card, Checkbox, Dropdown, Headline, Modal, TextField } from '@liquid-design/liquid-design-react'

import useMountEffect from '../utils/useMountEffect'

import { apiSequences } from '../models/Api/apiSequences'
import { apiModelMacroSteps } from '../models/Api/apiModelMacroSteps'
import { apiModelCriterias } from '../models/Api/apiModelCriterias'
import { apiSequenceCriterias } from '../models/Api/apiSequenceCriterias'
import { apiModelSteps } from '../models/Api/apiModelSteps'

import {
    Diafiltration,
    SPTFFConcentration,
    Concentration,
    TankFilling,
    TankEmptying,
    TotalRecirculation,
    SinglePathFlush,
    StopEquipment
} from '../components/Recipes/MacroSteps'

import {
    DiafiltrationDataProcess,
    SPTFFConcentrationDataProcess,
    ConcentrationDataProcess,
    TankFillingDataProcess,
    TankEmptyingDataProcess,
    TotalRecirculationDataProcess,
    SinglePathFlushDataProcess,
    StopEquipmentDataProcess
} from '../components/Recipes/MacroSteps/DataProcess'

import { Dialog } from '@progress/kendo-react-dialogs'
import { ScaleLoader } from 'react-spinners'
import { IsNullOrUndefined, setPlcProperty } from '../utils/plcUtils'
import { apiOpcValue } from '../models/Api/apiOpcValue'
import { UserEventTriggering } from '../models/PLC/UserEventTriggering'
import { apiSequenceMacroSteps } from '../models/Api/apiSequenceMacroSteps'
import MacroStepDataProcess from '../components/Recipes/MacroSteps/DataProcess/MacroStepDataProcess'
import { Sortable, SortableItemUIProps } from '@progress/kendo-react-sortable'

export type Modify<T, R> = Omit<T, keyof R> & R;

export interface IDropdownSequence {
    id: string
    name: string
    crc: number
}

export enum ERecipeModels {
    DIAFILTRATION,
    SPTFF_CONCENTRATION,
    CONCENTRATION,
    TANK_FILLING,
    TANK_EMPTYING,
    TOTAL_RECIRCULATION,
    SINGLE_PATH_FLUSH,
    STOP_EQUIPMENT,
}

// Macrosteps limit / recipe.
// Set to 9 due to limited PLC memory
const MACROSTEPS_LIMIT = 9

// Modified the two interface below to add the UniqueId key for drag&drop
interface apiSequenceMacroStepsUnique extends apiSequenceMacroSteps {
    UniqueId: string
}
interface apiSequencesUnique extends Omit<apiSequences, 'SequenceMacroSteps'> {
    SequenceMacroSteps: apiSequenceMacroStepsUnique[]
}

const RecipesView = () => {
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const intl = useIntl()
    const dispatch = useDispatch()
    const [currentSequence, setCurrentSequence] = useState<apiSequencesUnique>({
        Id: null,
        Name: '',
        SequenceMacroSteps: [],
        SequenceCrc: (config.Unit.Sts as any).iCurrentSequenceCRC
    })
    const [newSequenceName, setNewSequenceName] = useState('')
    const [duplicatedSequenceName, setDuplicatedSequenceName] = useState('')

    const [showAllSequences, setShowAllSequences] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [isSequenceSent, setIsSequenceSent] = useState(false)
    const [isSendingSequence, setIsSendingSequence] = useState(false)
    const [changesDidHappen, setChangesDidHappen] = useState(false)
    const [isNewSequence, setIsNewSequence] = useState(false)
    const [isValidatorOpen, setIsValidatorOpen] = useState(false)
    const [waitingSequence, setWaitingSequence] = useState<IDropdownSequence>(null)
    const [isCRCModalOpen, setIsCRCModalOpen] = useState(false)
    const [isDuplicateSequenceModalOpen, setIsDuplicateSequenceModalOpen] = useState(false)
    const [isDeleteSequenceModalOpen, setIsDeleteSequenceModalOpen] = useState(false)
    const [isConfirmExitModalOpen, setIsConfirmExitModalOpen] = useState(false)
    const [isNoStopEquipmentModalOpen, setIsNoStopEquipmentModalOpen] = useState(false)
    const ModelMacroStep = useRef<apiModelMacroSteps[]>([])
    const sequenceList = useRef<Modify<apiSequencesUnique, {
        Id: string
    }>[]>([])
    const initialSequence = useRef<apiSequencesUnique>(null)
    const [sequenceListDropdown, setSequenceListDropdown] = useState([])
    const [isDragging, setIsDragging] = useState(false)

    const isTransfertOptionWired = config.Unit.Set.iUserOptionTransfert
    const isFiltratePCVOptionWired = config.Unit.Set.iFactOptionFiltratePCV

    useMountEffect(() => {
        refreshDropDownList()
        refreshModelMacroStep()
    })

    function editSequence(seq: IDropdownSequence) {
        if (seq.crc !== (config.Unit.Sts as any).iCurrentSequenceCRC) {
            setWaitingSequence(seq)
            setIsCRCModalOpen(true)
        } else {
            const index = sequenceList.current.findIndex((s) => s.Name === seq.name)
            fetch(`${config.APIBaseUrl}/Recipe/api/Recipes/v1/Sequence/get/${sequenceList.current[index].Id}`)
                .then((resp) => resp.json())
                .then((sequence: apiSequences) => {
                    const uniqueSequence: apiSequencesUnique = {
                        ...sequence,
                        SequenceMacroSteps: sequence.SequenceMacroSteps.map((sequenceMacroStep) => ({
                            ...sequenceMacroStep,
                            UniqueId: _.uniqueId() // Generate unique ID for drag&drop
                        }))
                    }
                    initialSequence.current = uniqueSequence
                    setIsNewSequence(false)
                    setCurrentSequence(uniqueSequence)
                    setIsSequenceSent(false)
                    setIsEditing(true)
                    setNewSequenceName('')
                })
                .catch((err) => console.error(`Error while opening sequence: ${err}`))
        }
    }

    function confirmCRCModification() {
        setIsCRCModalOpen(false)

        const index = sequenceList.current.findIndex((s) => s.Name === waitingSequence.name)
        fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/PropertyValue/Set?pushToPlc=true`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                NodeId: config.Recipe.OperationCtrl.NodeId + '.bClearRecipe',
                Value: true
            })
        })
            .then(() => {
                return fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/PropertyValue/Set?pushToPlc=true`, {
                    method: 'PUT',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        NodeId: config.Unit.Set.NodeId + '.iNewSequenceCRC',
                        Value: waitingSequence.crc
                    })
                })
            })
            .then(() => {
                clearRecipe()
            })
            .then(() => {
                return fetch(`${config.APIBaseUrl}/Recipe/api/Recipes/v1/Sequence/get/${sequenceList.current[index].Id}`)
                    .then((resp) => resp.json())
                    .then((sequence: apiSequences) => {
                        const uniqueSequence: apiSequencesUnique = {
                            ...sequence,
                            SequenceMacroSteps: sequence.SequenceMacroSteps.map((sequenceMacroStep) => ({
                                ...sequenceMacroStep,
                                UniqueId: _.uniqueId() // Generate unique ID for drag&drop
                            }))
                        }
                        initialSequence.current = uniqueSequence
                        setCurrentSequence(uniqueSequence)
                        setIsEditing(true)
                    })
                    .catch((err) => console.error(`Error while opening sequence: ${err}`))
            })
            .catch(console.error)
    }

    function createSequence() {
        const Name = newSequenceName.trim()
        if (Name) {
            setCurrentSequence({
                Id: 0,
                Name,
                SequenceMacroSteps: [],
                SequenceCrc: (config.Unit.Sts as any).iCurrentSequenceCRC
            })
            setIsNewSequence(true)
            setIsSequenceSent(false)
            setIsEditing(true)
            setNewSequenceName('')
        }
    }

    function duplicateSequence() {
        const Name = duplicatedSequenceName.trim()
        if (Name) {
            setIsDuplicateSequenceModalOpen(false)
            setDuplicatedSequenceName('')
            let SequenceMacroSteps = resetIds(initialSequence.current.SequenceMacroSteps)
            setCurrentSequence({
                Id: 0,
                Name: duplicatedSequenceName,
                SequenceMacroSteps,
                SequenceCrc: initialSequence.current.SequenceCrc
            })
            setIsNewSequence(true)
            setIsSequenceSent(false)
            setChangesDidHappen(true)
            setIsEditing(true)
        }
    }

    /**
     * Reset all IDs to create new objects
     */
    function resetIds(Sms: apiSequenceMacroStepsUnique[]): apiSequenceMacroStepsUnique[] {
        Sms.forEach(ms => {
            ms.Id = 0
            ms.SequenceId = 0
            ms.SequenceSteps.forEach(ss => {
                ss.Id = 0
                ss.SequenceMacroStepId = 0
                ss.SequenceCriterias.forEach(sc => {
                    sc.Id = 0
                    sc.SequenceStepsId = 0
                })
            })
        })
        return Sms
    }

    function returnToSequenceSelection() {
        if (changesDidHappen) {
            setIsConfirmExitModalOpen(true)
        } else {
            confirmExit()
        }
    }

    function confirmExit() {
        setIsConfirmExitModalOpen(false)
        refreshDropDownList()
        setIsEditing(false)
        setNewSequenceName('')
        setCurrentSequence(null)
        setChangesDidHappen(false)
    }

    function refreshDropDownList() {
        fetch(`${config.APIBaseUrl}/Recipe/api/Recipes/v1/Sequences/Get`)
            .then((resp) => resp.json())
            .then((resp: apiSequences[]) => {
                sequenceList.current = resp.map((item, i) => ({
                    ...item,
                    Id: item.Id.toString(),
                    SequenceMacroSteps: item.SequenceMacroSteps.map((sequenceMacroStep) => ({
                        ...sequenceMacroStep,
                        UniqueId: _.uniqueId() // Generate unique ID for drag&drop
                    }))
                }))
            })
            .then(() => setSequenceListDropdown(getSequenceList()))
    }

    function refreshModelMacroStep() {
        fetch(`${config.APIBaseUrl}/Recipe/api/Recipes/v1/ModelMacroSteps/Get`)
            .then((resp) => resp.json())
            .then((resp: apiModelMacroSteps[]) => {
                ModelMacroStep.current = resp
            })
    }

    function cancelChanges() {
        if (IsNullOrUndefined(initialSequence.current)) {
            let unsavedSequence = {
                ..._.cloneDeep(currentSequence)
            }
            unsavedSequence.SequenceMacroSteps = []

            setCurrentSequence(unsavedSequence)
        } else {
            setCurrentSequence({
                ..._.cloneDeep(initialSequence.current)
            })
        }

        setChangesDidHappen(false)
    }

    function saveChanges() {
        setIsValidatorOpen(true)
    }

    function closeValidator() {
        setIsValidatorOpen(false)
    }

    function saveNewSequenceName() {
        fetch(`${config.APIBaseUrl}/Recipe/api/Recipes/v1/Sequence/AddOrUpdate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Id: currentSequence.Id,
                Name: newSequenceName,
                SequenceCrc: currentSequence.SequenceCrc,
                SequenceMacroSteps: currentSequence.SequenceMacroSteps
            })
        })
            .then((resp) => resp.json())
            .then((seq) => {
                setCurrentSequence({
                    ...currentSequence,
                    Name: seq.Name
                })
                setNewSequenceName(null)
            })
            .catch((err) => console.error(err))
    }

    function saveSequence(ackNoStopEquipment = false) {
        /* Check whether the last sequence MacroStep is "Stop Equipments" or not.
         * If it's not a "Stop Equipments" MacroStep, display a warning modal to the user before proceeding.
         */
        if (currentSequence.SequenceMacroSteps[currentSequence.SequenceMacroSteps.length - 1].ModelMacroStepId - 1 !== ERecipeModels.STOP_EQUIPMENT && ackNoStopEquipment === false) {
            setIsValidatorOpen(false)
            setIsNoStopEquipmentModalOpen(true)
            return;
        }
        for (const macroStep of currentSequence.SequenceMacroSteps) {
            if (macroStep.ModelMacroStepId - 1 === ERecipeModels.STOP_EQUIPMENT) {
                StopEquipmentDataProcess({
                    isTransfertOptionWired,
                    isFiltratePCVOptionWired
                }, macroStep)
            }
        }
        const savingSequence = {
            ...currentSequence,
            SequenceMacroSteps: currentSequence.SequenceMacroSteps.map((sequenceMacroStep) => {
                const { UniqueId, ...rest } = sequenceMacroStep // Extract UniqueId from objects since they don't have to be pushed to the database
                return rest
            })
        }
        fetch(`${config.APIBaseUrl}/Recipe/api/Recipes/v1/Sequence/AddOrUpdate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(savingSequence)
        })
            .then((resp) => resp.json())
            .then((sequence: apiSequences) => {
                const uniqueSequence: apiSequencesUnique = {
                    ...sequence,
                    SequenceMacroSteps: sequence.SequenceMacroSteps.map((sequenceMacroStep) => ({
                        ...sequenceMacroStep,
                        UniqueId: _.uniqueId() // Generate unique ID for drag&drop
                    }))
                }
                resetSequenceName(true)
                setIsValidatorOpen(false)
                setIsNoStopEquipmentModalOpen(false)
                setIsNewSequence(false)
                setChangesDidHappen(false)
                setIsSequenceSent(false)
                setCurrentSequence(uniqueSequence)
                initialSequence.current = uniqueSequence
            })
            .catch((err) => console.error(err))
    }

    function deleteSequence() {
        fetch(`${config.APIBaseUrl}/Recipe/api/Recipes/v1/Sequence/${currentSequence.Id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: null
        })
            .then(() => setIsDeleteSequenceModalOpen(false))
            .then(() => confirmExit())
            .catch(console.error)
    }

    function sendToPLC() {
        fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/PropertyValue/Set?pushToPlc=true`, {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                NodeId: config.Recipe.OperationCtrl.NodeId + '.bClearRecipe',
                Value: true
            })
        })
          .then(() => {
              setIsSendingSequence(true)
              return fetch(`${config.APIBaseUrl}/Recipe/api/Recipes/v1/Sequence/Upload/${currentSequence.Id}`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(null)
              })
          })
          .then(() => {
              setIsSequenceSent(true)
              setTimeout(() => setIsSendingSequence(false), 2000)
          })
          .catch((err) => console.error(err))
    }

    function saveSettings(type: ERecipeModels, settings: any, id: number, triggerChanges: boolean) {
        const macroStep = currentSequence.SequenceMacroSteps[id]
        // Generic data process first
        if (type !== ERecipeModels.STOP_EQUIPMENT) MacroStepDataProcess(settings, macroStep)
        // Specific data process
        switch (type) {
            case ERecipeModels.DIAFILTRATION: {
                DiafiltrationDataProcess(settings, macroStep)
                break;
            }
            case ERecipeModels.SPTFF_CONCENTRATION: {
                SPTFFConcentrationDataProcess(settings, macroStep)
                break;
            }
            case ERecipeModels.CONCENTRATION: {
                ConcentrationDataProcess(settings, macroStep)
                break;
            }
            case ERecipeModels.TANK_EMPTYING: {
                TankEmptyingDataProcess(settings, macroStep)
                break;
            }
            case ERecipeModels.TANK_FILLING: {
                TankFillingDataProcess(settings, macroStep)
                break;
            }
            case ERecipeModels.TOTAL_RECIRCULATION: {
                TotalRecirculationDataProcess(settings, macroStep)
                break;
            }
            case ERecipeModels.SINGLE_PATH_FLUSH: {
                SinglePathFlushDataProcess(settings, macroStep)
                break;
            }
            case ERecipeModels.STOP_EQUIPMENT: {
                StopEquipmentDataProcess(settings, macroStep)
                break;
            }
        }
        if (triggerChanges) {
            setChangesDidHappen(true)
        }
    }

    function removeMacroStep(id: number) {
        let SequenceMacroSteps = [...currentSequence.SequenceMacroSteps]
        SequenceMacroSteps.splice(id, 1)

        //Re computing order to prevent "holes" in the Order
        for (let i = 0; i < SequenceMacroSteps.length; i++) {
            SequenceMacroSteps[i].Order = i + 1;
        }

        setCurrentSequence({
            ...currentSequence,
            SequenceMacroSteps
        })
        setChangesDidHappen(true)
        if (currentSequence.Id !== 0) {
            fetch(`${config.APIBaseUrl}/Recipe/api/Recipes/v1/Sequence/${currentSequence.Id}/SequenceMacroStep/${currentSequence.SequenceMacroSteps[id].Id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(null)
            })
                .then((resp) => resp.json())
                .catch((err) => console.error(err))
        }
    }

    function onDrop(e: React.DragEvent<HTMLSpanElement>) {
        e.preventDefault()
        if (currentSequence.SequenceMacroSteps.length < MACROSTEPS_LIMIT) {
            let tempCriteria: apiSequenceCriterias[][] = []
            let tempCriteria2: apiSequenceCriterias[] = []
            const Ms_id = e.dataTransfer.getData('Ms_id')
            const index = ModelMacroStep.current.findIndex((model) => model.Id === parseInt(Ms_id))
            const macroStepId = currentSequence.SequenceMacroSteps.length + 1

            const modelStepArray: apiModelSteps[] = [
                ...ModelMacroStep.current[index].ModelSteps
            ]

            const tempModelCriteria: apiModelCriterias[][] = modelStepArray.map((model) => model.ModelCriterias)

            tempModelCriteria.forEach((model) => {
                model.forEach((tab, i) => {
                    const {IModeType, IModId, ICode, IOperator, RValue} = tab
                    tempCriteria2.push({
                        Id: 0,
                        IModeType,
                        IModId,
                        ICode,
                        IOperator,
                        RValue,
                        Rank: i,
                        SequenceStepsId: 0
                    })
                })
                tempCriteria.push(tempCriteria2)
                tempCriteria2 = []
            })

            let tempStep: any = modelStepArray.map((item, i) => ({
                ActionICode: item.ActionICode,
                Id: 0,
                StepRank: i,
                SequenceMacroStepId: 0,
                SequenceCriterias: tempCriteria[i],
                ActionIModId: item.ActionIModId,
                ActionIModType: item.ActionIModType,
                ActionRValue: item.ActionRValue,
                IBooleanOperator: item.IBooleanOperator
            }))
            setCurrentSequence({
                ...currentSequence,
                SequenceMacroSteps: [
                    ...currentSequence.SequenceMacroSteps,
                    {
                        Id: 0,
                        ModelMacroStepId: parseInt(Ms_id),
                        SequenceId: currentSequence.Id,
                        Order: macroStepId,
                        SequenceSteps: tempStep,
                        UniqueId: _.uniqueId() // Generate unique ID for drag&drop
                    }
                ]
            })
            setChangesDidHappen(true)
        }
    }

    function getSequenceList() {
        if (showAllSequences) {
            return sequenceList.current.map((seq) => ({name: seq.Name, id: seq.Id, crc: seq.SequenceCrc}))
        } else {
            const list: any = []
            sequenceList.current.forEach((seq) => {
                if (seq.SequenceCrc === (config.Unit.Sts as any).iCurrentSequenceCRC) {
                    list.push({name: seq.Name, id: seq.Id, crc: seq.SequenceCrc})
                }
            })
            return list
        }
    }

    function clearRecipe() {
        let clearcmd: apiOpcValue = new apiOpcValue();
        let userLabel: string = "recipe.label.clearrecipe"
        clearcmd.Value = true
        clearcmd.NodeId = config.Recipe.OperationCtrl.NodeId + ".bClearRecipe"
        setPlcProperty(config, clearcmd, {
            userLabel,
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction
        }, dispatch)
    }

    /**
     * On input blur, reset the sequence name if already used
     */
    function resetSequenceName(force: boolean) {
        if (sequenceNameAlreadyUsed || force) {
            setNewSequenceName(currentSequence.Name)
        }
    }

    const sequenceNameAlreadyUsed = sequenceList.current.findIndex((s) => s.Name === newSequenceName) !== -1

    return (
        <div className='container-fluid align-items-center' style={{padding: '15px', height: '100%'}}>
            {isEditing && (
                <div className='row justify-content-center text-center'>
                    <div className='col-12'>
                        <Row>
                            <Button style={{marginRight: '15px', marginLeft: '15px', float: 'left', marginTop: '15px'}}
                                    appearance='secondary'
                                    icon='arrowLeft'
                                    onClick={() => returnToSequenceSelection()}>
                                <FormattedMessage id='label.ReturnToSequenceSelection'/>
                            </Button>
                            <Col sm={2}>
                                <TextField
                                    style={{float: 'left', width: '100%'}}
                                    value={newSequenceName || currentSequence.Name}
                                    label={intl.formatMessage({id: 'label.SequenceName'})}
                                    onChange={(name: string) => setNewSequenceName(name)}
                                    onBlur={() => resetSequenceName(false)}
                                    grey
                                />
                            </Col>
                            <Col sm={2}>
                                <Button style={{float: 'left', marginTop: '26px', marginLeft: '5px', width: '100%'}}
                                        size='small'
                                        appearance='highlight'
                                        disabled={!newSequenceName || currentSequence.Id === 0 || sequenceNameAlreadyUsed}
                                        icon='pencil'
                                        isIconFilled={true} onClick={() => saveNewSequenceName()}
                                >
                                    <FormattedMessage id='label.RenameSequence'/>
                                </Button>
                            </Col>
                            <Col sm={2}>
                                <Button style={{float: 'left', marginTop: '26px', marginLeft: '5px', width: '100%'}}
                                        size='small'
                                        appearance='highlight' disabled={isNewSequence} icon='bin' isIconFilled={true}
                                        onClick={() => setIsDeleteSequenceModalOpen(true)}>
                                    <FormattedMessage id='label.DeleteSequence'/>
                                </Button>
                            </Col>
                            {!isNewSequence && (
                                <Col sm={2}>
                                    <Button style={{float: 'left', marginTop: '26px', marginLeft: '5px', width: '100%'}}
                                            appearance='highlight'
                                            icon='layer'
                                            onClick={() => setIsDuplicateSequenceModalOpen(true)}>
                                        <FormattedMessage id='label.DuplicateSequence'/>
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </div>
                </div>
            )}
            <div className='row justify-content-center text-center'>
                {isEditing ? (
                    <>
                        <Col>
                            <br/>
                            <br/>
                            <Row>
                                <Col>
                                    <Headline type='H4'>
                                        <FormattedMessage id='label.MacroStepListing'/>
                                    </Headline>
                                    <br/>
                                    <div className="sequenceList scrollbar">
                                        {ModelMacroStep.current.map((model, i) => {
                                            switch (model.Id - 1) {
                                                case ERecipeModels.DIAFILTRATION:
                                                    return (
                                                        <Diafiltration
                                                            key={i}
                                                            id={model.Id}
                                                            isDraggable={!config.Unit.Sts.bSTFFMode}
                                                            isModel={true}
                                                        />
                                                    )
                                                case ERecipeModels.SPTFF_CONCENTRATION:
                                                    return (
                                                        <SPTFFConcentration
                                                            key={i}
                                                            id={model.Id}
                                                            isDraggable={config.Unit.Sts.bSTFFMode}
                                                            isModel={true}
                                                        />
                                                    )
                                                case ERecipeModels.CONCENTRATION:
                                                    return (
                                                        <Concentration
                                                            key={i}
                                                            id={model.Id}
                                                            isDraggable={!config.Unit.Sts.bSTFFMode}
                                                            isModel={true}
                                                        />
                                                    )
                                                case ERecipeModels.TANK_FILLING:
                                                    return (
                                                        <TankFilling
                                                            key={i}
                                                            id={model.Id}
                                                            isDraggable={config.Unit.Set.iUserOptionTransfert && !config.Unit.Sts.bSTFFMode}
                                                            isModel={true}
                                                        />
                                                    )
                                                case ERecipeModels.TANK_EMPTYING:
                                                    return (
                                                        <TankEmptying
                                                            key={i}
                                                            id={model.Id}
                                                            isDraggable={!config.Unit.Sts.bSTFFMode}
                                                            isModel={true}
                                                        />
                                                    )
                                                case ERecipeModels.TOTAL_RECIRCULATION:
                                                    return (
                                                        <TotalRecirculation
                                                            key={i}
                                                            id={model.Id}
                                                            isDraggable={!config.Unit.Sts.bSTFFMode}
                                                            isModel={true}
                                                        />
                                                    )
                                                case ERecipeModels.SINGLE_PATH_FLUSH:
                                                    return (
                                                        <SinglePathFlush
                                                            key={i}
                                                            id={model.Id}
                                                            isDraggable={!config.Unit.Sts.bSTFFMode}
                                                            isModel={true}
                                                        />
                                                    )
                                                case ERecipeModels.STOP_EQUIPMENT:
                                                    return (
                                                        <StopEquipment
                                                            key={i}
                                                            id={model.Id}
                                                            isDraggable={true}
                                                            isModel={true}
                                                        />
                                                    )
                                                default:
                                                    return (
                                                        <React.Fragment key={i}/>
                                                    )
                                            }
                                        })}
                                    </div>
                                </Col>
                                <Col>
                                    <Headline type='H4'>
                                        <FormattedMessage id='label.CurrentSequenceMacroStepListing'/>
                                    </Headline>
                                    <br/>
                                    <div className="sequenceList scrollbar"
                                         style={{
                                             backgroundColor: '#F3F3F7',
                                             borderRadius: '8px',
                                             padding: '15px 0 15px 0'
                                         }}>
                                        { currentSequence.SequenceMacroSteps.length ? (
                                          <Sortable
                                            idField={"UniqueId"} // Unique key used to remap cards, Step ID is not unique until submitted to backend, neither is MacroStep ID.
                                            data={currentSequence.SequenceMacroSteps.map((sequenceMacroStep) => ({
                                                ...sequenceMacroStep,
                                                currentSequenceId: currentSequence.Id,
                                                removeMacroStep, // Pass down function to MacroStepCustomUI children
                                                saveSettings // Pass down function to MacroStepCustomUI children
                                            }))}
                                            itemUI={MacroStepCustomUI}
                                            onDragOver={(event) => setCurrentSequence((prev) => {
                                                setChangesDidHappen(true)
                                                return {
                                                    ...prev,
                                                    SequenceMacroSteps: (event.newState as apiSequenceMacroStepsUnique[]).map((macroStep, i) => ({
                                                        ...macroStep, Order: i+1 // Reorder cards
                                                    }))
                                                }
                                            })}
                                          />
                                        ) : null}
                                        { currentSequence.SequenceMacroSteps.length < MACROSTEPS_LIMIT && (
                                          <Card
                                            className="noHover"
                                            style={{
                                                border: isDragging ? '2px solid rgb(15, 105, 175)' : '2px dashed rgb(15, 105, 175)',
                                                color: isDragging ? 'rgb(15, 105, 175)' : 'inherit',
                                                transition: 'none'
                                            }}
                                            onDragEnter={() => setIsDragging(true)}
                                            onDropCapture={() => setIsDragging(false)}
                                            onDragLeave={() => setIsDragging(false)}
                                            onDrop={(e: any) => onDrop(e)}
                                            onDragOver={(e: any) => e.preventDefault()}
                                          >
                                              <Headline type='H5' style={{ pointerEvents: 'none' }}>
                                                  <FormattedMessage id='label.DragHere' />
                                              </Headline>
                                          </Card>
                                        ) }
                                        <br/>
                                        <Button appearance='ghost' icon='close' disabled={!changesDidHappen}
                                                onClick={() => cancelChanges()}>
                                            <FormattedMessage id='label.CancelChanges'/>
                                        </Button>
                                        <br/>
                                        <Button icon='progressBarCheckMark' disabled={!changesDidHappen}
                                                onClick={() => saveChanges()}>
                                            <FormattedMessage id='label.ValidChanges'/>
                                        </Button>
                                        <br/>
                                        <br/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={2}>
                            <Button style={{marginTop: '100%', width: '100%'}}
                                    disabled={(changesDidHappen || config.Recipe.Phase[0].iState === 1 || currentSequence.SequenceMacroSteps.length < 1)}
                                    size='big' appearance='highlight' icon='upload' onClick={() => sendToPLC()}>
                                <FormattedMessage id='label.TransferToPlc'/>
                            </Button>
                            <br/>
                            <br/>
                            <Button style={{width: '100%'}}
                                    disabled={(config.Recipe.OperationCtrl.iOperationId === 0 || config.Recipe.Phase[0].iState === 1)}
                                    size='big' appearance='highlight' icon='placeholder' onClick={() => clearRecipe()}>
                                <FormattedMessage id='label.ClearRecipe'/>
                            </Button>
                        </Col>
                    </>
                ) : (
                    <Col>
                        <Card active={true} style={{maxWidth: '860px'}}>
                            <FormattedMessage id='label.PleaseChooseSequence'/>
                            <br/>
                            <Dropdown
                                label={intl.formatMessage({id: 'label.ChooseSequence'})}
                                options={getSequenceList()}
                                style={{ width: 580, marginTop: 8, maxWidth: 'none' }}
                                onSubmit={(seq: IDropdownSequence) => editSequence(seq)}
                            />
                            <br/>
                            <Checkbox style={{marginLeft: 5, marginTop: 8}}
                                      disabled={config.Recipe.OperationCtrl.iState !== 0 && config.Recipe.OperationCtrl.iState !== 3}
                                      label={intl.formatMessage({id: 'label.ShowAllSequence'})}
                                      isChecked={showAllSequences} onChange={() => setShowAllSequences(s => !s)}/>
                            <br/>
                            <br/>
                            <FormattedMessage id='label.SelectOrCreate'/>
                            <br/>
                            <br/>
                            <TextField
                                placeholder={intl.formatMessage({id: 'label.SequenceName'})}
                                label={intl.formatMessage({id: 'label.CreateASequence'})}
                                onChange={(name: string) => setNewSequenceName(name)}
                                style={{ width: 480 }}
                                grey
                            />
                            <br/>
                            <Button icon='progressBarCheckMark'
                                    disabled={sequenceList.current.findIndex((seq) => seq.Name === newSequenceName) !== -1}
                                    onClick={() => createSequence()}><FormattedMessage
                                id='label.CreateSequence'/></Button>
                        </Card>
                    </Col>
                )}
            </div>
            <Modal
                label='Valid modifications?'
                open={isValidatorOpen}
                onClose={() => closeValidator()}
            >
                <div style={{textAlign: 'center'}}>
                    <Headline type='H3'>
                        <FormattedMessage id='label.ConfirmModifications'/>
                    </Headline>
                    <br/>
                    <div style={{margin: '15px'}}>
                        <Button
                            size='big'
                            onClick={() => saveSequence()}
                            style={{margin: '5px'}}
                        >
                            <FormattedMessage id='label.SaveToDatabase'/>
                        </Button>
                        <Button
                            size='big'
                            appearance='secondary'
                            onClick={() => closeValidator()}
                            style={{margin: '5px'}}
                        >
                            <FormattedMessage id='label.CancelModifications'/>
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                label={intl.formatMessage({id: 'label.ConfirmReturnToSelection'})}
                open={isConfirmExitModalOpen}
                onClose={() => setIsConfirmExitModalOpen(false)}
            >
                <div style={{textAlign: 'center'}}>
                    <Headline type='H3'>
                        <FormattedMessage id='label.ConfirmReturnToSelection'/>
                    </Headline>
                    <br/>
                    <div style={{margin: '15px'}}>
                        <Button
                            size='big'
                            onClick={() => confirmExit()}
                            style={{margin: '5px'}}
                        >
                            <FormattedMessage id='label.ReturnToSequenceSelection'/>
                        </Button>
                        <Button
                            size='big'
                            appearance='secondary'
                            onClick={() => setIsConfirmExitModalOpen(false)}
                            style={{margin: '5px'}}
                        >
                            <FormattedMessage id='label.KeepEditing'/>
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                label={intl.formatMessage({id: 'label.ConfirmCRCModification'})}
                open={isCRCModalOpen}
                onClose={() => setIsCRCModalOpen(false)}
            >
                <div style={{textAlign: 'center'}}>
                    <Headline type='H3'>
                        <FormattedMessage id='label.ConfirmCRCModification'/>
                    </Headline>
                    <br/>
                    <div style={{margin: '15px'}}>
                        <Button
                            size='big'
                            onClick={() => confirmCRCModification()}
                            style={{margin: '5px'}}
                        >
                            <FormattedMessage id='label.ConfirmCRC'/>
                        </Button>
                        <Button
                            size='big'
                            appearance='secondary'
                            onClick={() => setIsCRCModalOpen(false)}
                            style={{margin: '5px'}}
                        >
                            <FormattedMessage id='label.No'/>
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                label={intl.formatMessage({id: 'label.DuplicateSequence'})}
                open={isDuplicateSequenceModalOpen}
                onClose={() => setIsDuplicateSequenceModalOpen(false)}
            >
                <div style={{textAlign: 'center'}}>
                    <Headline type='H3'>
                        <FormattedMessage id='label.SequenceName'/>
                    </Headline>
                    <br/>
                    <TextField
                        value={duplicatedSequenceName}
                        onChange={(name: string) => setDuplicatedSequenceName(name)}
                        grey
                    />
                    <br/>
                    <br/>
                    <Button size='big'
                            appearance='highlight' disabled={!duplicatedSequenceName} icon='arrowCheck'
                            isIconFilled={true} onClick={() => duplicateSequence()}>
                        <FormattedMessage id='label.ConfirmDuplication'/>
                    </Button>
                </div>
            </Modal>
            <Modal
                label={intl.formatMessage({id: 'label.DeleteSequence'})}
                open={isDeleteSequenceModalOpen}
                onClose={() => setIsDeleteSequenceModalOpen(false)}
            >
                <div style={{textAlign: 'center'}}>
                    <Headline type='H3'>
                        <FormattedMessage id='label.WarningDelete'/>
                    </Headline>
                    <br/>
                    <Button size='big'
                            appearance='highlight' icon='arrowCheck'
                            isIconFilled={true} onClick={() => deleteSequence()}>
                        <FormattedMessage id='label.ConfirmDelete'/>
                    </Button>
                </div>
            </Modal>
            <Modal
              label={intl.formatMessage({id: 'label.WarningNoStopEquipmentMacrostepTitle'})}
              open={isNoStopEquipmentModalOpen}
              onClose={() => setIsNoStopEquipmentModalOpen(false)}
            >
                <div style={{textAlign: 'center'}}>
                    <Headline type='H3'>
                        <FormattedMessage id='label.WarningNoStopEquipmentMacrostepDescription'/>
                    </Headline>
                    <br/>
                    <div style={{margin: '15px'}}>
                        <Button
                          size='big'
                          appearance='secondary'
                          onClick={() => setIsNoStopEquipmentModalOpen(false)}
                          style={{margin: '5px'}}
                        >
                            <FormattedMessage id='label.KeepEditing'/>
                        </Button>
                        <Button
                          size='big'
                          appearance='highlight'
                          onClick={() => saveSequence(true)}
                          style={{margin: '5px'}}>
                            <FormattedMessage id='label.ProceedWithCaution'/>
                        </Button>
                    </div>
                </div>
            </Modal>
            {isSendingSequence && (
                <Dialog title={intl.formatMessage({id: 'label.SendingSequence'})} closeIcon={false}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <ScaleLoader
                            height={50}
                            width={10}
                            radius={4}
                            margin={2}
                            color={"#123abc"}
                            loading={isSendingSequence}
                        />
                    </div>
                </Dialog>
            )}
        </div>
    )
}

/**
 * MacroStepCustomUI styles based on grabbing state
 * @param isActive True if card is currently grabbed
 */
const getBaseItemStyle = (isActive: boolean) => ({
    fontSize: "16px",
    textAlign: "center",
    outline: "none",
    cursor: "move",
    display: "block",
    opacity: isActive ? 0.5 : 1
});

/**
 * Custom Drag & Drop card
 */
const MacroStepCustomUI = (props: SortableItemUIProps) => {
    const { attributes, forwardRef, isActive, style } = props
    // Type-safe dataItem
    const macroStep = props.dataItem as apiSequenceMacroStepsUnique & { currentSequenceId: number, removeMacroStep: (id: number) => void, saveSettings: (type: ERecipeModels, settings: any, id: number, triggerChanges: boolean) => void}

    let macroStepComponent = <br/>

    switch (macroStep.ModelMacroStepId - 1) {
        case ERecipeModels.DIAFILTRATION:
            macroStepComponent = <Diafiltration
              id={macroStep.ModelMacroStepId}
              loadedValues={macroStep.SequenceSteps}
              stepNumber={macroStep.Order}
              isDraggable={false}
              isModel={false}
              onDelete={() => macroStep.removeMacroStep(macroStep.Order - 1)}
              onSave={(triggerChanges, settings) => macroStep.saveSettings(ERecipeModels.DIAFILTRATION, settings, macroStep.Order - 1, triggerChanges)}
              sequenceID={macroStep.currentSequenceId}
            />
            break
        case ERecipeModels.SPTFF_CONCENTRATION:
            macroStepComponent = <SPTFFConcentration
              id={macroStep.ModelMacroStepId}
              loadedValues={macroStep.SequenceSteps}
              stepNumber={macroStep.Order}
              isDraggable={false}
              isModel={false}
              onDelete={() => macroStep.removeMacroStep(macroStep.Order - 1)}
              onSave={(triggerChanges, settings) => macroStep.saveSettings(ERecipeModels.SPTFF_CONCENTRATION, settings, macroStep.Order - 1, triggerChanges)}
              sequenceID={macroStep.currentSequenceId}
            />
            break
        case ERecipeModels.CONCENTRATION:
            macroStepComponent = <Concentration
              id={macroStep.ModelMacroStepId}
              loadedValues={macroStep.SequenceSteps}
              stepNumber={macroStep.Order}
              isDraggable={false}
              isModel={false}
              onDelete={() => macroStep.removeMacroStep(macroStep.Order - 1)}
              onSave={(triggerChanges, settings) => macroStep.saveSettings(ERecipeModels.CONCENTRATION, settings, macroStep.Order - 1, triggerChanges)}
              sequenceID={macroStep.currentSequenceId}
            />
            break
        case ERecipeModels.TANK_FILLING:
            macroStepComponent = <TankFilling
              id={macroStep.ModelMacroStepId}
              loadedValues={macroStep.SequenceSteps}
              stepNumber={macroStep.Order}
              isDraggable={false}
              isModel={false}
              onDelete={() => macroStep.removeMacroStep(macroStep.Order - 1)}
              onSave={(triggerChanges, settings) => macroStep.saveSettings(ERecipeModels.TANK_FILLING, settings, macroStep.Order - 1, triggerChanges)}
              sequenceID={macroStep.currentSequenceId}
            />
            break
        case ERecipeModels.TANK_EMPTYING:
            macroStepComponent = <TankEmptying
              id={macroStep.ModelMacroStepId}
              loadedValues={macroStep.SequenceSteps}
              stepNumber={macroStep.Order}
              isDraggable={false}
              isModel={false}
              onDelete={() => macroStep.removeMacroStep(macroStep.Order - 1)}
              onSave={(triggerChanges, settings) => macroStep.saveSettings(ERecipeModels.TANK_EMPTYING, settings, macroStep.Order - 1, triggerChanges)}
              sequenceID={macroStep.currentSequenceId}
            />
            break
        case ERecipeModels.TOTAL_RECIRCULATION:
            macroStepComponent = <TotalRecirculation
              id={macroStep.ModelMacroStepId}
              loadedValues={macroStep.SequenceSteps}
              stepNumber={macroStep.Order}
              isDraggable={false}
              isModel={false}
              onDelete={() => macroStep.removeMacroStep(macroStep.Order - 1)}
              onSave={(triggerChanges, settings) => macroStep.saveSettings(ERecipeModels.TOTAL_RECIRCULATION, settings, macroStep.Order - 1, triggerChanges)}
              sequenceID={macroStep.currentSequenceId}
            />
            break
        case ERecipeModels.SINGLE_PATH_FLUSH:
            macroStepComponent = <SinglePathFlush
              id={macroStep.ModelMacroStepId}
              loadedValues={macroStep.SequenceSteps}
              stepNumber={macroStep.Order}
              isDraggable={false}
              isModel={false}
              onDelete={() => macroStep.removeMacroStep(macroStep.Order - 1)}
              onSave={(triggerChanges, settings) => macroStep.saveSettings(ERecipeModels.SINGLE_PATH_FLUSH, settings, macroStep.Order - 1, triggerChanges)}
              sequenceID={macroStep.currentSequenceId}
            />
            break
        case ERecipeModels.STOP_EQUIPMENT:
            macroStepComponent = <StopEquipment
              id={macroStep.ModelMacroStepId}
              loadedValues={macroStep.SequenceSteps}
              stepNumber={macroStep.Order}
              isDraggable={false}
              isModel={false}
              onDelete={() => macroStep.removeMacroStep(macroStep.Order - 1)}
              onSave={(triggerChanges, settings) => macroStep.saveSettings(ERecipeModels.STOP_EQUIPMENT, settings, macroStep.Order - 1, triggerChanges)}
              sequenceID={macroStep.currentSequenceId}
            />
            break
    }

    return (
      <div style={{
          ...getBaseItemStyle(attributes['aria-grabbed']), // Change styles if card is grabbed (-> lower opacity)
          ...Object.keys(attributes).length ? style : { display: 'none' } // Don't display ghost card on grab
      }} ref={forwardRef} { ...attributes }>
          {macroStepComponent}
          <br/>
      </div>
    )
}

export default RecipesView
