import { apiSequenceMacroSteps } from '../../../../models/Api/apiSequenceMacroSteps'

function merge(settings: any, macroStep: apiSequenceMacroSteps) {
  Object.keys(settings).forEach((key) => {
    switch (key) {
      case 'initialDiafVolMode': {
        if (settings.initialDiafVolMode) {
          macroStep.SequenceSteps[2].ActionIModType = 0
          macroStep.SequenceSteps[2].ActionIModId = 0
          macroStep.SequenceSteps[2].ActionICode = 0
          macroStep.SequenceSteps[2].ActionRValue = 0
          macroStep.SequenceSteps[3].ActionIModType = 200
          macroStep.SequenceSteps[3].ActionIModId = 1
          macroStep.SequenceSteps[3].ActionICode = 17
          macroStep.SequenceSteps[3].ActionRValue = 1 // Mode with weight = 1
        } else {
          macroStep.SequenceSteps[2].ActionIModType = 200
          macroStep.SequenceSteps[2].ActionIModId = 1
          macroStep.SequenceSteps[2].ActionICode = 16
          macroStep.SequenceSteps[2].ActionRValue = settings.initialDiafVolume
          macroStep.SequenceSteps[3].ActionIModType = 200
          macroStep.SequenceSteps[3].ActionIModId = 1
          macroStep.SequenceSteps[3].ActionICode = 17
          macroStep.SequenceSteps[3].ActionRValue = 0 // Mode without weight = 0
        }
        break
      }
      case 'EndCriteriaDropdownSelected': {
        switch (settings.EndCriteriaDropdownSelected.id) {
          case '1': {
            // Time
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 1002
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 4
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.timeCriteria

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = 0
            break
          }
          case '2': {
            // Number of diafiltrations
            macroStep.SequenceSteps[4].ActionIModType = 200
            macroStep.SequenceSteps[4].ActionIModId = 1
            macroStep.SequenceSteps[4].ActionICode = 11
            macroStep.SequenceSteps[4].ActionRValue = settings.diafiltrationsNumberCriteria

            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 200
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 5
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = 1

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = 0
            break
          }
          case '3': {
            // AI201 threshold
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 17
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 2
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.inferiorAI201Threshold

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 17
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 2
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 2
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = settings.superiorAI201Threshold
            macroStep.SequenceSteps[27].IBooleanOperator = 2
            break
          }
          case '4': {
            // AI202 threshold
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 17
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.inferiorAI202Threshold

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 17
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 1
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 2
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = settings.superiorAI202Threshold
            macroStep.SequenceSteps[27].IBooleanOperator = 2
            break
          }
          case '5': {
            // AI203 threshold
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 18
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.inferiorAI203Threshold

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 18
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 1
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 2
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = settings.superiorAI203Threshold
            macroStep.SequenceSteps[27].IBooleanOperator = 2
            break
          }
          case '6': {
            // FI201 threshold
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 16
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 3
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.inferiorFI201Threshold

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = 0
            break
          }
          case '7': {
            // Weight sum threshold
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 117
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.weightSumThreshold

            macroStep.SequenceSteps[27].SequenceCriterias[1].IModeType = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].IOperator = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].ICode = 0
            macroStep.SequenceSteps[27].SequenceCriterias[1].RValue = 0
            break
          }
        }
        break
      }
    }
  })
}

export default merge