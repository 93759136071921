import {IConfigReducer} from "../store/reducers/configReducer";
import {apiOpcValue} from "../models/Api/apiOpcValue";
import {handleResponse} from "./responseUtils";
import {configActions} from "../store/actions";
import {UserEventTriggering} from "../models/PLC/UserEventTriggering";

export function IsNullOrUndefined(value: any) {

    return value === undefined || value === null;
}

export function IsEmptyOrNullOrUndefined(value: string) {

    return value === undefined || value === null || value === '';
}

/**
 * Creat OpcValue to send data to the PLC
 * @param nodeId
 * @param value
 * @constructor
 */
export function CreateOpcValue(nodeId: string, value: any) {

    let opcValue: apiOpcValue = new apiOpcValue();
    opcValue.NodeId = nodeId;
    opcValue.Value = value;

    return opcValue;
}

/**
 * User Event information
 */
export interface IUserEventInfo {
    userLabel: string,                      //User label for the event
    unit: string,                           //Unit associated with the value (°C, ml, etc...)
    eventTriggering: UserEventTriggering,   //Indicate if the event should be triggered before or after the main action (PLC var set)
    showValue?: boolean,                    //Indicates if the value has to be shown
    enumValue?: string,                      //String textCode for enum values
    sourceTag?: string                       //Tag of the actuator the events come from : used for regulations because they are hosted by the EM and not the CM. In simple case, Tag is calculated from the variable NodeId
}

/**
 * Building request Header based on the event IUserEventInfo
 * @param eventInfo
 */
function buildHeaders(eventInfo: IUserEventInfo) {

    let customHeaders: any = {
        'Content-Type': 'application/json'
    }

    if (!IsNullOrUndefined(eventInfo)) {
        customHeaders = {
            'Content-Type': 'application/json',
            'UserEvents': eventInfo.userLabel,
            'EventUnit': encodeURI(eventInfo.unit),
            'EventTriggering': eventInfo.eventTriggering,
            'EventShowValue': IsNullOrUndefined(eventInfo.showValue) ? true : eventInfo.showValue,
            'EventEnumValue': IsNullOrUndefined(eventInfo.enumValue) ? '' : eventInfo.enumValue,
            'EventSourceTag': IsNullOrUndefined(eventInfo.sourceTag) ? '' : eventInfo.sourceTag
        }
    }
    return customHeaders;
}

/**
 * Setting PLC property value
 * @param config
 * @param propertyValue
 * @param eventInfo
 * @param dispatchFunc
 */
export function setPlcProperty(config: IConfigReducer, propertyValue: apiOpcValue, eventInfo: IUserEventInfo, dispatchFunc: any) {

    if (!IsNullOrUndefined(propertyValue) && !IsNullOrUndefined(propertyValue.Value)) {

        let customHeaders = buildHeaders(eventInfo);

        fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/PropertyValue/Set`, {
            method: 'PUT',
            headers: customHeaders,
            body: JSON.stringify(propertyValue)
        })
            .then(handleResponse)
            .then(json => {
                if (dispatchFunc !== undefined) {
                    dispatchFunc(configActions.updateDefault(propertyValue))
                }
            }).catch(error => {
            //TODO: Proper Error handling !
            console.error(error);
        });
    }
}

export async function setPlcPropertyAsync(config: IConfigReducer, propertyValue: apiOpcValue, eventInfo: IUserEventInfo, dispatchFunc: any) {

    if (!IsNullOrUndefined(propertyValue) && !IsNullOrUndefined(propertyValue.Value)) {

        let customHeaders = buildHeaders(eventInfo);

        let response = await fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/PropertyValue/Set`, {
            method: 'PUT',
            headers: customHeaders,
            body: JSON.stringify(propertyValue)
        });

        if (response.ok) {
            if (dispatchFunc !== undefined) {
                dispatchFunc(configActions.updateDefault(propertyValue))
            }
        } else {
            let body = await response.json();
            throw new Error(body.message);
        }
    }
}

export async function setPlcPropertiesAsync(config: IConfigReducer, propertyValues: apiOpcValue[], eventInfo: IUserEventInfo, dispatchFunc: any) {

    if (!IsNullOrUndefined(propertyValues) && !IsNullOrUndefined(propertyValues.length === 0)) {

        let customHeaders = buildHeaders(eventInfo);

        let response = await fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/PropertyValues/Set`, {
            method: 'PUT',
            headers: customHeaders,
            body: JSON.stringify(propertyValues)
        });

        if (response.ok) {
            if (dispatchFunc !== undefined) {
                propertyValues.forEach((propertyValue) => {
                    dispatchFunc(configActions.updateDefault(propertyValue))
                })
            }
        } else {
            let body = await response.json();
            throw new Error(body.message);
        }
    }
}