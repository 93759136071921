import React, { useState } from 'react'
import NotificationHeader from './NotificationHeader'
import AlarmNotification from './AlarmNotification'

const Notification = () => {

  const [isFreshData, setIsFreshData] = useState(true)
  const [headerData, setHeaderData] = useState(null)

  function handleShowMessage(data: any) {
    setIsFreshData(true)
    setHeaderData(data)
    // Close header after 30s
    setTimeout(() => {
      setIsFreshData(false)
      setHeaderData(null)
    }, 6000)
  }

  return (
    <div>
      {!isFreshData &&
      <NotificationHeader
          content={headerData}
      />
      }
      <AlarmNotification handleShowMessage={handleShowMessage}/>
    </div>
  )
}

export default Notification