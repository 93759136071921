import React from 'react'
import HeatExchangerNodeWidget from './HeatExchangerArtifactWidget'
import { HeatExchangerArtifactModel } from './HeatExchangerArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class HeatExchangerArtifactFactory extends AbstractReactFactory<HeatExchangerArtifactModel, DiagramEngine> {
  constructor () {
    super('HeatExchanger')
  }

  generateReactWidget (event: any): JSX.Element {
    return <HeatExchangerNodeWidget engine={this.engine} height={60} width={120} node={event.model} />
  }

  generateModel (event: any) {
    return new HeatExchangerArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
