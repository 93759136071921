/**
 * Dependencies
 */
import React from 'react'
// import * as StoreTypes from 'StoreTypes'
// import { useSelector } from 'react-redux'

import TopMenuBar from './Navbar/components/TopMenuBar/TopMenuBar'
import {useSelector} from 'react-redux'
import * as StoreTypes from 'StoreTypes'
import {IConfigReducer} from '../store/reducers/configReducer'

/**
 * Content
 */
const CustomHeader = () => {
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)

    return (
        <TopMenuBar cogentSize={config.applicationName}/>
    )
}

export default CustomHeader
