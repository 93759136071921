import React, { SetStateAction } from 'react'

/**
 * Reset dropdown values on change
 * @param updateDropdownFunc Function which set selected dropdown
 * @param val New dropdown selected value
 * @param updateValuesFuncs Functions which set actuator values
 */
export function updateDropdownValue (updateDropdownFunc: React.Dispatch<any>, val: any, updateValuesFuncs: React.Dispatch<any>[] = []) {
  updateValuesFuncs.forEach((f) => f(0))
  updateDropdownFunc(val)
}

/**
 * Update actuator value
 * @param isSpeed Value to set is *100 if this is a speed control (percentage)
 * @param updateFunc Function which set actuator values
 * @param value New value to set
 */
export function updateControlValue (isSpeed: boolean, updateFunc: React.Dispatch<SetStateAction<number>>, value: number) {
  updateFunc(isSpeed ? value * 100 : value)
}