import {NodeModelGenerics, PortModelOptions} from '@projectstorm/react-diagrams'

import {BaseArtifactModel} from '../../BaseArtifact/BaseArtifactModel'
import {TankPortArtifactModel} from './TankPortModel'
import TankPorts from './TankPortsConfig'

export interface TankNodeModelGenerics {
    PORT: TankArtifactModel;
}

export class TankArtifactModel extends BaseArtifactModel<NodeModelGenerics & TankNodeModelGenerics> {
    constructor(code: string, name: string, nodeId: string, orientation: string = 'horizontal') {
        super(code, name, nodeId, orientation, {type: 'Tank'})
        TankPorts.forEach((port: PortModelOptions) => this.addPort(
            new TankPortArtifactModel(port.name, port.alignment)
        ))
    }
}
