import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {Container, Row, Col} from 'reactstrap';
import {Theme, Dropdown, Button} from '@liquid-design/liquid-design-react'
import {apiOpcValue} from "../../../models/Api/apiOpcValue";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {DriveControlPID} from "../../../models/PLC/DriveControlPID";
import {FloatingLabel} from '@progress/kendo-react-labels';
import {IConfigReducer} from "../../../store/reducers/configReducer";
import {useDispatch, useSelector} from "react-redux";
import StoreTypes from "StoreTypes";
import {IUserEventInfo, setPlcProperty} from "../../../utils/plcUtils";
import {
    getDigitFormat,
    getDigitNumber,
    getEnumTextCode, getSensorDeadBand,
    IRegulationMode,
    renderSpeedRegulationParams
} from "./settingsHelpers";
import {Tank} from "../../../models/PLC/Tank";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {AnalogueSensorWired} from "../../../models/PLC/AnalogueSensorWired";
import CommonSwitches from "./CommonSwitches";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import {useIntl} from 'react-intl'
import {getPumpStateOptions} from './StateOptions'


export interface ITransferPumpControlPanelProps {
    nodeId: string
}

export default function TransferPumpControlPanel({nodeId}: ITransferPumpControlPanelProps) {

    const dispatch = useDispatch();
    const intl = useIntl()

    //Hard coded regulation modes for DriveControlPID
    const regulationModes: IRegulationMode[] = [
        {name: intl.formatMessage({id: "label.SpeedRegulation"}), id: '1', textCode: 'label.SpeedRegulation'},
        {
            name: intl.formatMessage({id: "label.WeightRegulation"}),
            id: '2',
            textCode: 'label.WeightRegulation'
        },
    ];

    //Getting information from store
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)

    //Get the pump associated ControlModule
    const pumpModule: DriveControlPID = _.find(config.Instances.ControlModules, (item: ControlModuleBase) => {
        return item.NodeId === nodeId;
    }) as DriveControlPID;

    //Get the EquipmentModule that contains the ControlModule
    const equipmentModule: Tank = _.find(config.Instances.EquipmentModules, (em: any) => {
        return _.find(em.ControlModules, (cm: ControlModuleBase) => {
            return cm.NodeId === nodeId;
        });
    }) as Tank;

    useEffect(() => {
        setCurrentRegulationMode(equipmentModule.Set_iControlStyle)
    }, [equipmentModule.Set_iControlStyle])

    //Get the weight sensor used for tank weight regulation
    let weightSensor = config.Instances.ControlModules[equipmentModule.WeightSensor.Cfg_sTag] as AnalogueSensorWired;
    let deadBand = getSensorDeadBand(weightSensor, weightSensor.Values[0].Name, weightSensor.Out_rCurrentValue)
    let weightUnit = weightSensor.Values[0].Cfg_suSensorUnit.Unit;


    //Getting initial ControlStyle
    const [currentRegulationMode, setCurrentRegulationMode] = useState(equipmentModule.Set_iControlStyle);

    /**
     Applies new regulation mode to the PLC
     **/
    const applyRegulationModeChange = () => {

        let controlStyle: apiOpcValue = new apiOpcValue();
        controlStyle.NodeId = equipmentModule.NodeId + '.Set_iControlStyle';
        controlStyle.Value = currentRegulationMode;

        //Pushing information to the PLC
        setPlcProperty(config, controlStyle, {
            sourceTag: pumpModule.Cfg_sTag,
            userLabel: 'label.event.RegulationModeChanged', unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            enumValue: getEnumTextCode(regulationModes, currentRegulationMode)
        }, dispatch);
    };

    /**
     * Changes local value of the control style
     * @param selectedOption
     */
    const localRegulationModeChange = (selectedOption: any) => {
        setCurrentRegulationMode(parseInt(selectedOption.id));
    };

    /**
     Switching mode between Start <-> Stop
     **/
    const onStateChange = (newValue: any) => {

        let state: apiOpcValue = new apiOpcValue();
        state.NodeId = nodeId + '.Inp_bCommandManual';
        state.Value = newValue;

        let eventInfo: IUserEventInfo = {
            userLabel: newValue ? 'label.event.Started' : 'label.event.Stopped',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }

        setPlcProperty(config, state, eventInfo, dispatch);
    };

    /**
     Switching mode between Automatic <-> Manual
     **/
    const onModeChange = (newValue: any) => {

        let mode: apiOpcValue = new apiOpcValue();
        mode.NodeId = nodeId + '.Set_bMode';
        mode.Value = !newValue;

        let eventInfo: IUserEventInfo = {
            userLabel: mode.Value ? 'label.event.ManualModeSet' : 'label.event.AutoModeSet',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }
        setPlcProperty(config, mode, eventInfo, dispatch);
    };

    /**
     * Rendering speed regulation parameters
     **/
    function renderRegulationParameters() {

        switch (currentRegulationMode.toString()) {
            case '1' :
                //Speed regulation params
                return renderSpeedRegulationParams(config, !pumpModule.Set_bMode, pumpModule, dispatch, intl);
            case '2':
                //Flow PID regulation params
                return renderSpeedRegulationParams(config, !pumpModule.Set_bMode, pumpModule, dispatch, intl, renderAdditionalCol, renderAdditionalValueCol);
            default:
                return <>
                    <h3>{intl.formatMessage({id: "No regulation mode selected"})}</h3>
                </>;
        }
    }

    function renderAdditionalValueCol() {
        let deadBand = getSensorDeadBand(weightSensor, 'Out_rCurrentValue', weightSensor.Out_rCurrentValue)
        let currentWeight = weightSensor.Out_rCurrentValue.toFixed(getDigitNumber(weightSensor.Out_rCurrentValue, deadBand)) + " " + weightUnit;

        return <Col>
            <FloatingLabel label={intl.formatMessage({id: "label.CurrentWeight"})}
                           editorId='CurrentWeight'
                           editorValue={currentWeight}
                           style={{width: '100%'}}>
                <div id='CurrentWeight' style={{fontWeight: 'bold', fontSize: '16px'}}>
                    {currentWeight}
                </div>
            </FloatingLabel>
        </Col>;
    }

    function renderAdditionalCol() {

        return <Col>
            <FloatingLabel label={intl.formatMessage({id: "label.MinWeight"})} editorId='MinWeight'
                           editorValue={equipmentModule.Set_rLevelStartPump.toString()}>
                <NumericTextBox
                    id='MinWeight'
                    format={"0" + getDigitFormat(equipmentModule.Set_rLevelStartPump, deadBand) + " " + weightUnit}
                    disabled={!pumpModule.Set_bMode}
                    min={weightSensor.Cfg_rEGUMinimum}
                    max={equipmentModule.Set_rLevelStopPump}
                    step={1}
                    value={equipmentModule.Set_rLevelStartPump}
                    onChange={(e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            let mode: apiOpcValue = new apiOpcValue();
                            mode.NodeId = equipmentModule.NodeId + '.Set_rLevelStartPump';
                            mode.Value = e.value;

                            setPlcProperty(config, mode, {
                                sourceTag: pumpModule.Cfg_sTag,
                                userLabel: 'label.event.MinWeightSet',
                                unit: weightUnit,
                                eventTriggering: UserEventTriggering.BeforeAction
                            }, dispatch);
                        }
                    }}
                />
            </FloatingLabel>
            <br/>
            <FloatingLabel label={intl.formatMessage({id: "label.MaxWeight"})} editorId='MaxWeight'
                           editorValue={equipmentModule.Set_rLevelStopPump.toString()}>
                <NumericTextBox
                    id='MaxWeight'
                    format={"0" + getDigitFormat(equipmentModule.Set_rLevelStopPump, deadBand) + " " + weightUnit}
                    disabled={!pumpModule.Set_bMode}
                    min={equipmentModule.Set_rLevelStartPump}
                    max={weightSensor.Cfg_rEGUMaximum}
                    step={1}
                    value={equipmentModule.Set_rLevelStopPump}
                    onChange={(e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            let mode: apiOpcValue = new apiOpcValue();
                            mode.NodeId = equipmentModule.NodeId + '.Set_rLevelStopPump';
                            mode.Value = e.value;

                            setPlcProperty(config, mode, {
                                sourceTag: pumpModule.Cfg_sTag,
                                userLabel: 'label.event.MaxWeightSet',
                                unit: weightUnit,
                                eventTriggering: UserEventTriggering.BeforeAction
                            }, dispatch);
                        }
                    }}
                />
            </FloatingLabel>
        </Col>;
    }

    return (
        <Theme themeName={config.theme}>
            <Container>
                <CommonSwitches stateOptions={getPumpStateOptions(intl)} isAutoMode={!pumpModule.Set_bMode}
                                isStarted={pumpModule.Set_bMode ? pumpModule.Inp_bCommandManual : pumpModule.Inp_bCommandAuto}
                                onModeChange={onModeChange}
                                onStateChange={onStateChange}
                                startStopDisabled={equipmentModule.Set_iControlStyle.toString() === '2'}/>
                <Row style={{padding: '5px'}}>
                    <Col xs="6">
                        <FloatingLabel label={intl.formatMessage({id: "label.RegulationMode"})}
                                       style={{width: '90%'}}
                                       editorId='RegulationMode'
                                       editorDisabled={!pumpModule.Set_bMode}
                                       editorValue={currentRegulationMode.toString()}>
                            <Dropdown
                                id='RegulationMode'
                                options={regulationModes}
                                value={currentRegulationMode.toString()}
                                onSubmit={localRegulationModeChange}
                                disabled={!pumpModule.Set_bMode}
                            />
                        </FloatingLabel>
                        <br/>
                        <br/>
                        <div style={{width: '100%'}}>
                            <Button style={{float: 'right'}}
                                    disabled={currentRegulationMode === equipmentModule.Set_iControlStyle}
                                    onClick={applyRegulationModeChange}>{intl.formatMessage({id: "label.ApplyRegulationMode"})}</Button>
                        </div>
                    </Col>
                    <Col xs="6">
                        {renderRegulationParameters()}
                    </Col>
                </Row>
            </Container>
        </Theme>
    )
}