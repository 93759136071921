/**
 * Visual builder for VerticalValve Artifact
 */
export default class VerticalValveVisual {
  /**
   * Builds up svg
   * @returns SVG as a string
   * @param isValveOpen
   */
  static build(isValveOpen: boolean): string {
    return `<g transform="matrix(1,0,0,1,-0.219,-8.18901)">
        <g transform="matrix(1,0,0,1,0.219,8.18901)">
            <path d="M13.758,13.467C14.849,12.618 16.382,12.625 17.466,13.487L26.698,20.826L26.698,4.508L17.466,11.847L17.44,11.867C16.349,12.716 14.816,12.709 13.732,11.847L4.5,4.508L4.5,20.826L13.732,13.487L13.758,13.467ZM24.877,2.124C26.842,0.619 29.698,2.017 29.698,4.508L29.698,20.826L29.697,20.885C29.653,23.359 26.782,24.725 24.831,23.175L15.599,15.835L6.367,23.175L6.321,23.211C4.356,24.715 1.5,23.317 1.5,20.826L1.5,4.508L1.501,4.449C1.545,1.975 4.416,0.609 6.367,2.16L15.599,9.499L24.877,2.124Z" style="fill:white;fill-rule:nonzero;stroke:rgb(213,213,217);stroke-width:2.76px;"/>
        </g>
        <g transform="matrix(1,0,0,1,0.219,8.18901)">
            <path d="M24.877,2.124C26.842,0.619 29.698,2.017 29.698,4.508L29.698,20.826L29.697,20.885C29.653,23.359 26.782,24.725 24.831,23.175L15.599,15.835L6.367,23.175L6.321,23.211C4.356,24.715 1.5,23.317 1.5,20.826L1.5,4.508L1.501,4.449C1.545,1.975 4.416,0.609 6.367,2.16L15.599,9.499L24.877,2.124Z" style="fill:white;fill-rule:nonzero;"/>
        </g>
        <path d="M16.752,22.85C16.205,22.416 15.431,22.416 14.885,22.85L5.653,30.189C4.67,30.971 3.219,30.271 3.219,29.015L3.219,12.697C3.219,11.442 4.67,10.742 5.653,11.523L14.885,18.862C15.431,19.297 16.205,19.297 16.752,18.862L25.984,11.523C26.967,10.742 28.417,11.442 28.417,12.697L28.417,29.015C28.417,30.271 26.967,30.971 25.984,30.189L16.752,22.85Z" style="fill:${isValveOpen ? 'rgb(15,105,175)' : '#494953'};"/>
    </g>`
  }
}
