import { NodeModelGenerics, PortModelOptions } from '@projectstorm/react-diagrams'

import { BaseArtifactModel } from '../../BaseArtifact/BaseArtifactModel'
import { ControlledValvePortArtifactModel } from './ControlledValvePortModel'
import ControlledValvePorts from './ControlledValvePortsConfig'

export interface ControlledValveNodeModelGenerics {
  PORT: ControlledValveArtifactModel;
}

export class ControlledValveArtifactModel extends BaseArtifactModel<NodeModelGenerics & ControlledValveNodeModelGenerics> {
  constructor (code: string, name: string, nodeId: string, orientation:string = 'horizontal') {
    super(code, name, nodeId, orientation,{ type: 'ControlledValve' })
    ControlledValvePorts.forEach((port: PortModelOptions) => this.addPort(
      new ControlledValvePortArtifactModel(port.name, port.alignment)
    ))
  }
}
