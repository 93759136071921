import React, {useState} from 'react'
import {RadioButton} from '@liquid-design/liquid-design-react'
import {IConfigReducer} from '../../../store/reducers/configReducer'
import {useSelector} from 'react-redux'
import StoreTypes from "StoreTypes"
import {setPlcPropertiesAsync, setPlcPropertyAsync} from "../../../utils/plcUtils";
import {apiOpcValue} from "../../../models/Api/apiOpcValue";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import {FormattedMessage, IntlShape, useIntl} from 'react-intl'

export const Lab150ConfigPanel = () => {

    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const intl: IntlShape = useIntl()

    const isOptionEnabled: Record<string, boolean> = {}

    config.UserOptions.forEach((opt) => {
        isOptionEnabled[opt.Code] = !!(config.Unit.Set as any)[(opt as any).NsID]
    })

    const [tankRadio, setTankRadio] = useState(isOptionEnabled.Bag)
    const [rangePression, setRangePression] = useState(!!isOptionEnabled.RangePression)
    const [weightWI101, setWeightWI101] = useState(config.Unit.Set.iFactOptionWeight &&config.Unit.Set.bVisibilityWE101)
    const [weightWI201, setWeightWI201] = useState(config.Unit.Set.iFactOptionWeight &&config.Unit.Set.bVisibilityWE201 && !config.Unit.Set.bVisibilityWE202 && !config.Unit.Set.bVisibilityWE203)
    const [weightWI201202, setWeightWI201202] = useState(config.Unit.Set.iFactOptionWeight &&config.Unit.Set.bVisibilityWE201 && config.Unit.Set.bVisibilityWE202 && !config.Unit.Set.bVisibilityWE203)
    const [weightWI201202203, setWeightWI201202203] = useState(config.Unit.Set.iFactOptionWeight &&config.Unit.Set.bVisibilityWE201 && config.Unit.Set.bVisibilityWE202 && config.Unit.Set.bVisibilityWE203)
    const visibilityEqual: Record<string, string> = {
        WI101: 'bVisibilityWE101',
        WI201: 'bVisibilityWE201',
        WI202: 'bVisibilityWE202',
        WI203: 'bVisibilityWE203'
    }
    const [isWeightStandardRange, setIsWeightStandardRange] = useState(config.Unit.Set.iUserOptionWeightSize)

    async function toggleUserOption(option: string, value?: any) {
        const decomposed = option.split('-')

        if (decomposed.length > 1) {
            let properties: apiOpcValue[] = []

            let infoTag = option + ': '

            if (decomposed[1] === 'WI101') {
                if (value)
                    infoTag += 'ON'
                else
                    infoTag += 'OFF'

                properties.push({
                    NodeId: `${config.Unit.Set.NodeId}.${visibilityEqual[decomposed[1] as string]}`,
                    Value: value
                })
            } else {
                infoTag += 'ON'

                properties.push({
                    NodeId: `${config.Unit.Set.NodeId}.${visibilityEqual['WI203']}`,
                    Value: decomposed[1] === "WI201WI202WI203"
                })
                properties.push({
                    NodeId: `${config.Unit.Set.NodeId}.${visibilityEqual['WI202']}`,
                    Value: decomposed[1] === "WI201WI202" || decomposed[1] === "WI201WI202WI203"
                })
                properties.push({
                    NodeId: `${config.Unit.Set.NodeId}.${visibilityEqual['WI201']}`,
                    Value: decomposed[1] === "WI201" || decomposed[1] === "WI201WI202" || decomposed[1] === "WI201WI202WI203"
                })
            }

            await setPlcPropertiesAsync(config, properties, {
                sourceTag: infoTag,
                userLabel: 'label.event.UserOptionSet',
                unit: '',
                eventTriggering: UserEventTriggering.BeforeAction,
                showValue: false
            }, undefined);

            switch (decomposed[1]) {
                case 'WI101':
                    setWeightWI101(value)
                    break
                case 'OUTLET':
                    setWeightWI201(false)
                    setWeightWI201202(false)
                    setWeightWI201202203(false)
                    break
                case 'WI201':
                    setWeightWI201(true)
                    setWeightWI201202(false)
                    setWeightWI201202203(false)
                    break
                case 'WI201WI202':
                    setWeightWI201(false)
                    setWeightWI201202(true)
                    setWeightWI201202203(false)
                    break
                case 'WI201WI202WI203':
                    setWeightWI201(false)
                    setWeightWI201202(false)
                    setWeightWI201202203(true)
                    break
            }
        } else {
            const UO = config.UserOptions[config.UserOptions.findIndex((opt) => opt.Code === option)]

            let property: apiOpcValue = {
                NodeId: UO.NodeId,
                Value: value === undefined ? !isOptionEnabled[option] : value
            }

            let infoTag = option + ': ';

            if (option === 'RangePression') {
                if (property.Value === 1)
                    infoTag += 'High'
                else
                    infoTag += 'Low'
            } else {
                if (property.Value)
                    infoTag += 'ON'
                else
                    infoTag += 'OFF'
            }

            await setPlcPropertyAsync(config, property, {
                sourceTag: infoTag,
                userLabel: 'label.event.UserOptionSet',
                unit: '',
                eventTriggering: UserEventTriggering.BeforeAction,
                showValue: false
            }, undefined);

            switch (option) {
                case 'Bag':
                    setTankRadio(value)
                    break;
                case 'RangePression':
                    setRangePression(!!value)
                    break;
            }
        }
    }

    /**
     * Toggles the weight standard range setting on a PLC.
     *
     * /!\ This method is special, it has been implemented for version 1.3.6 which would have required the configurator.json to be upgraded.
     * As this was not possible, we play with this variable manually, as it is available for all Lab150 systems.
     *
     * @param {number} range - The range value to set. If 0, sets to 'Standard'; if 1, sets to 'Extended'.
     * @return {Promise<void>} - A promise that resolves when the operation is complete.
     */
    async function toggleWeightStandardRange(range: 0 | 1): Promise<void> {
        let property: apiOpcValue = {
            NodeId: config.NodeId + `.Unit.Set.iUserOptionWeightSize`,
            Value: range
        }
        await setPlcPropertyAsync(config, property, {
            sourceTag: `WeightSize-${range === 0 ? 'Standard' : 'Extended'}`,
            userLabel: 'label.event.UserOptionSet',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }, undefined);
        setIsWeightStandardRange(range)
    }

    return (
        <>
            <h5>
                <FormattedMessage id='label.Tank'/>
            </h5>
            <br/>
            <RadioButton onClick={() => toggleUserOption('Bag', false)} isSelected={!tankRadio}
                         label={intl.formatMessage({id: 'label.UseSystemTank'})}/>
            <br/>
            <RadioButton onClick={() => toggleUserOption('Bag', true)} isSelected={tankRadio}
                         label={intl.formatMessage({id: 'label.UseExternalBag'})}/>

            {config.Unit.Set.iFactOptionWeight &&
              <>
                  <hr/>
                  <h5>
                      <FormattedMessage id="label.Retentate"/>
                  </h5>
                  <br/>
                  <RadioButton onClick={ () => toggleUserOption('Weight-WI101', false) } isSelected={ !weightWI101 }
                               label={ intl.formatMessage({ id: 'label.RetentateRedirectsToTank' }) }/>
                  <br/>
                  <RadioButton onClick={ () => toggleUserOption('Weight-WI101', true) } isSelected={ weightWI101 }
                               label={ intl.formatMessage({ id: 'label.RetentateToWeightScale' }) }/>
                  <hr/>

                  <h5>
                      <FormattedMessage id="label.Filtrate"/>
                  </h5>
                  <br/>
                  <RadioButton onClick={ () => toggleUserOption('Weight-OUTLET', true) }
                               isSelected={ !weightWI201 && !weightWI201202 && !weightWI201202203 }
                               label={ intl.formatMessage({ id: 'label.FiltrateToOutlet' }) }/>
                  <br/>
                  <RadioButton onClick={ () => toggleUserOption('Weight-WI201', true) }
                               isSelected={ weightWI201 && !weightWI201202 && !weightWI201202203 }
                               label={ intl.formatMessage({ id: 'label.FiltrateToWeightScaleWT201' }) }/>
                  <br/>
                  <RadioButton onClick={ () => toggleUserOption('Weight-WI201WI202', true) }
                               isSelected={ !weightWI201 && weightWI201202 && !weightWI201202203 }
                               label={ intl.formatMessage({ id: 'label.FiltrateToWeightScaleWT201202' }) }/>
                  <br/>
                  <RadioButton onClick={ () => toggleUserOption('Weight-WI201WI202WI203', true) }
                               isSelected={ !weightWI201 && !weightWI201202 && weightWI201202203 }
                               label={ intl.formatMessage({ id: 'label.FiltrateToWeightScaleWT201202203' }) }/>
                  <br/>
                  <br/>
                  <h6>
                      <FormattedMessage id="label.FiltrateWeightRange"/>
                  </h6>
                  <br/>
                  <RadioButton onClick={ () => toggleWeightStandardRange(0) }
                               isSelected={isWeightStandardRange === 0}
                               label={ intl.formatMessage({ id: 'label.FiltrateWeightStandardRange' }) }/>
                  <br/>
                  <RadioButton onClick={ () => toggleWeightStandardRange(1) }
                               isSelected={isWeightStandardRange === 1}
                               label={ intl.formatMessage({ id: 'label.FiltrateWeightExtendedRange' }) }/>
              </> }
            <hr/>

            <h5>
                <FormattedMessage id="label.PressureSensors"/>
            </h5>
            <br/>
            <RadioButton onClick={ () => toggleUserOption('RangePression', 0) } isSelected={ !rangePression }
                         label={ intl.formatMessage({ id: 'label.UseStandardRangePressure'})}/>
            <br/>
            <RadioButton onClick={() => toggleUserOption('RangePression', 1)} isSelected={rangePression}
                         label={intl.formatMessage({id: 'label.UseLowRangePressure'})}/>
        </>
    )
}
