import React from 'react'
import BeakerNodeWidget from './BeakerArtifactWidget'
import { BeakerArtifactModel } from './BeakerArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class BeakerArtifactFactory extends AbstractReactFactory<BeakerArtifactModel, DiagramEngine> {
  constructor () {
    super('Beaker')
  }

  generateReactWidget (event: any): JSX.Element {
    return <BeakerNodeWidget engine={this.engine} height={100} width={80} node={event.model} />
  }

  generateModel (event: any) {
    return new BeakerArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
