import {BaseArtifactModel} from '../BaseArtifact/BaseArtifactModel'
import {diagramArtifacts, IControlConfig, labSizesConfig} from '../../../config/diagram.config'
import PipeLinkModel from '../CustomLinks/PipeLink/PipeLinkModel'
import _ from 'lodash'
import {DefaultGeneration} from './DefaultGeneration'
import {IConfigReducer} from '../../../store/reducers/configReducer'
import {IAnalogueSensor} from "../../../models/PLC/IAnalogueSensor";

export class Lab150Generation extends DefaultGeneration {

    generateLinks(config: IConfigReducer, mappedArtifacts: any): BaseArtifactModel[] {
        const artifacts = []

        const TankToFeed: any = new PipeLinkModel('TankToFeed', 'TankToFeed', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        TankToFeed.setSourcePort(mappedArtifacts['HV001'].getPort('right'))
        TankToFeed.setTargetPort(mappedArtifacts['P001/SIC001'].getPort('left'))
        artifacts.push(TankToFeed)

        // TODO: Add weight user option check for atomicity
        if (!config.Unit.Set.bVisibilityWE101) {
            const retentateToTank: any = new PipeLinkModel('retentateToTank', 'retentateToTank', {
                    isEnabled: [
                        {
                            condition: 'P001/SIC001|Sts_bRunning',
                            value: true
                        }
                    ]
                }
            )

            retentateToTank.setSourcePort(mappedArtifacts['E3'].getPort('out'))
            retentateToTank.setTargetPort(mappedArtifacts['HV401'].getPort('top'))
            artifacts.push(retentateToTank)
        }

        const FeedToMembrane: any = new PipeLinkModel('FeedToMembrane', 'FeedToMembrane', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        FeedToMembrane.setSourcePort(mappedArtifacts['HV701'].getPort('bottom'))
        FeedToMembrane.setTargetPort(mappedArtifacts['ME1'].getPort('in'))
        artifacts.push(FeedToMembrane)

        const MembraneToRetentate: any = new PipeLinkModel('MembraneToRetentate', 'MembraneToRetentate', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        MembraneToRetentate.setSourcePort(mappedArtifacts['ME2'].getPort('out'))
        MembraneToRetentate.setTargetPort(mappedArtifacts['PI101'].getPort('right'))
        artifacts.push(MembraneToRetentate)

        const MembraneToFiltrate: any = new PipeLinkModel('MembraneToFiltrate', 'MembraneToFiltrate', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        MembraneToFiltrate.setSourcePort(mappedArtifacts['ME3'].getPort('out'))
        MembraneToFiltrate.setTargetPort(mappedArtifacts['Filtrate'].getPort('left'))
        artifacts.push(MembraneToFiltrate)

        return artifacts
    }

    generateTankArtifacts(): BaseArtifactModel[] {

        const EMLoaded = this.config.Instances.EquipmentModules.TANK001
        const EMConfig = labSizesConfig.Lab150['TANK']

        const tankArtifacts: BaseArtifactModel[] & PipeLinkModel[] = []

        const [mappedControls, mappedConfig] = [
            this.getMappedControls(EMLoaded),
            this.getMappedConfig(EMConfig)
        ]

        const tank = new (EMConfig.main.model)('Tank', 'AG002/SIC002', this.config.Instances.ControlModules['AG002/SIC002'].NodeId)
        tank.setPosition(EMConfig.position[0], EMConfig.position[1])
        // Add virtual sensors
        EMConfig.virtualSensors.forEach((virtualSensor: string) => {
            const virtualControl = mappedControls[virtualSensor]
            if (virtualControl.Sts_bEnable) {
                this.addLabels(tank, virtualControl, false, true)
            }
        })
        tankArtifacts.push(tank)

        const IN2 = new (diagramArtifacts.Inlet.model)('IN2', this.intl.formatMessage({id: 'label.Inlet'}), '', 'horizontal')
        IN2.setPosition(mappedConfig['IN2'].position[0], mappedConfig['IN2'].position[1])
        tankArtifacts.push(IN2)

        const HV002 = new (diagramArtifacts.VerticalValve.model)('90-HV002', 'HV002', '', 'vertical')
        HV002.setPosition(mappedConfig['HV002'].position[0], mappedConfig['HV002'].position[1])
        this.addCustomLabel(HV002, 'HV002', '')
        tankArtifacts.push(HV002)


        const TankToHV002 = new PipeLinkModel('TankToHV002', 'TankToHV002')
        TankToHV002.setSourcePort(tank.getPort('HV002-out'))
        TankToHV002.setTargetPort(HV002.getPort('right'))
        tankArtifacts.push(TankToHV002)

        const HV001 = new (diagramArtifacts.Valve.model)('HV001', 'HV001', '', 'horizontal')

        HV001.setPosition(mappedConfig['HV001'].position[0], mappedConfig['HV001'].position[1])
        this.addCustomLabel(HV001, 'HV001', '')
        tankArtifacts.push(HV001)

        const IN2ToTank = new PipeLinkModel('IN2ToTank', 'IN2ToTank')
        IN2ToTank.setSourcePort(IN2.getPort('right'))
        IN2ToTank.setTargetPort(tank.getPort('left'))
        tankArtifacts.push(IN2ToTank)

        const TE1 = new (diagramArtifacts.Elbow.model)('0-TE1', 'TE1', '', '', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        TE1.setPosition(mappedConfig['TE1'].position[0], mappedConfig['TE1'].position[1])
        tankArtifacts.push(TE1)

        const TankToTE1 = new PipeLinkModel('TankToTE1', 'TankToTE1', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        TankToTE1.setSourcePort(tank.getPort('bottom'))
        TankToTE1.setTargetPort(TE1.getPort('in'))
        tankArtifacts.push(TankToTE1)

        const TE1ToHV001 = new PipeLinkModel('TE1ToHV001', 'TE1ToHV001', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        TE1ToHV001.setSourcePort(TE1.getPort('out'))
        TE1ToHV001.setTargetPort(HV001.getPort('left'))
        tankArtifacts.push(TE1ToHV001)

        const TE2 = new (diagramArtifacts.Elbow.model)('360-TE2', 'TE2', '', '', {
            isEnabled: [
                {
                    condition: ['P001/SIC001|Sts_bRunning', 'UNIT|Unit.Sts.bSTFFMode'],
                    value: [true, false],
                    operator: 'AND'
                }
            ]
        })
        TE2.setPosition(mappedConfig['TE2'].position[0], mappedConfig['TE2'].position[1])
        tankArtifacts.push(TE2)

        const TankToTE2 = new PipeLinkModel('TankToTE2', 'TankToTE2', {
            isEnabled: [
                {
                    condition: ['P001/SIC001|Sts_bRunning', 'UNIT|Unit.Sts.bSTFFMode'],
                    value: [true, false],
                    operator: 'AND'
                }
            ]
        })
        TankToTE2.setSourcePort(tank.getPort('right'))
        TankToTE2.setTargetPort(TE2.getPort('in'))
        tankArtifacts.push(TankToTE2)

        const HV401 = new (diagramArtifacts.ThreeWayValve.model)('HV401', 'HV401', '', 'none')

        HV401.setPosition(mappedConfig['HV401'].position[0], mappedConfig['HV401'].position[1])
        this.addCustomLabel(HV401, 'HV401', '')
        tankArtifacts.push(HV401)

        const TE2ToHV401 = new PipeLinkModel('TE2ToHV401', 'TE2ToHV401', {
            isEnabled: [
                {
                    condition: ['P001/SIC001|Sts_bRunning', 'UNIT|Unit.Sts.bSTFFMode'],
                    value: [true, false],
                    operator: 'AND'
                }
            ]
        })
        TE2ToHV401.setSourcePort(TE2.getPort('out'))
        TE2ToHV401.setTargetPort(HV401.getPort('bottom'))
        tankArtifacts.push(TE2ToHV401)

        return tankArtifacts
    }

    generateTCUArtifacts(): BaseArtifactModel[] {
        const EMLoaded = this.config.Instances.EquipmentModules.TCU002
        const EMConfig = labSizesConfig.Lab150['TCU']

        const TCUArtifacts: BaseArtifactModel[] & PipeLinkModel[] = []

        const [mappedControls, mappedConfig] = [
            this.getMappedControls(EMLoaded),
            this.getMappedConfig(EMConfig)
        ]

        return TCUArtifacts
    }

    generateFeedArtifacts(): BaseArtifactModel[] {
        const EMLoaded = this.config.Instances.EquipmentModules.FEED001
        const EMConfig = labSizesConfig.Lab150['FEED']

        const feedArtifacts: BaseArtifactModel[] & PipeLinkModel[] = []

        const [mappedControls, mappedConfig] = [
            this.getMappedControls(EMLoaded),
            this.getMappedConfig(EMConfig)
        ]

        const feed = new (diagramArtifacts.Pump.model)('P001/SIC001', 'P001/SIC001', this.config.Instances.ControlModules['P001/SIC001'].NodeId)
        // Add virtual sensors
        EMConfig.virtualSensors.forEach((virtualSensor: string) => {
            const virtualControl = mappedControls[virtualSensor]
            if (virtualControl.Sts_bEnable) {
                this.addLabels(feed, virtualControl, false)
            }
        })
        feed.setPosition(EMConfig.position[0], EMConfig.position[1])
        feedArtifacts.push(feed)

        const PI001 = new (diagramArtifacts.Sensor.model)('PI001', 'PI001', this.config.Instances.ControlModules['PI001'].NodeId)
        this.addLabels(PI001, mappedControls['PI001'], true)
        PI001.setPosition(mappedConfig['PI001'].position[0], mappedConfig['PI001'].position[1])
        feedArtifacts.push(PI001)

        const PSH001 = new (diagramArtifacts.Sensor.model)('PSH001', 'PSH001', this.config.Instances.ControlModules['PSH001'].NodeId)
        PSH001.setPosition(mappedConfig['PSH001'].position[0], mappedConfig['PSH001'].position[1])
        feedArtifacts.push(PSH001)

        const FE1 = new (diagramArtifacts.Elbow.model)('0-FE1', 'FE1', '', '')
        FE1.setPosition(mappedConfig['FE1'].position[0], mappedConfig['FE1'].position[1])
        feedArtifacts.push(FE1)

        const DR1 = new (diagramArtifacts.Outlet.model)('DR1', this.intl.formatMessage({id: 'label.Outlet'}), '', 'horizontal')
        DR1.setPosition(mappedConfig['DR1'].position[0], mappedConfig['DR1'].position[1])
        feedArtifacts.push(DR1)

        const HV701 = new (diagramArtifacts.ThreeWayValve.model)('90-HV701', 'HV701', '', 'vertical')
        HV701.setPosition(mappedConfig['HV701'].position[0], mappedConfig['HV701'].position[1])
        this.addCustomLabel(HV701, 'HV701', '')
        feedArtifacts.push(HV701)

        if (this.config.Unit.Set.iFactOptionFlowmeters) {
            const TI001 = new (diagramArtifacts.Sensor.model)('TI001', 'TI001', this.config.Instances.ControlModules['TI001'].NodeId)
            this.addLabels(TI001, mappedControls['TI001'], true)
            TI001.setPosition(mappedConfig['TI001'].position[0], mappedConfig['TI001'].position[1])
            feedArtifacts.push(TI001)

            const SIC001_P001ToTI001 = new PipeLinkModel('P001/SIC001ToTI001', 'P001/SIC001ToTI001', {
                isEnabled: [
                    {
                        condition: 'P001/SIC001|Sts_bRunning',
                        value: true
                    }
                ]
            })
            SIC001_P001ToTI001.setSourcePort(feed.getPort('right'))
            SIC001_P001ToTI001.setTargetPort(TI001.getPort('left'))
            feedArtifacts.push(SIC001_P001ToTI001)

            const TI001ToPI001 = new PipeLinkModel('TI001ToPI001', 'TI001ToPI001', {
                isEnabled: [
                    {
                        condition: 'P001/SIC001|Sts_bRunning',
                        value: true
                    }
                ]
            })
            TI001ToPI001.setSourcePort(TI001.getPort('right'))
            TI001ToPI001.setTargetPort(PI001.getPort('left'))
            feedArtifacts.push(TI001ToPI001)
        } else {
            const SIC001_P001ToPI001 = new PipeLinkModel('P001/SIC001ToPI001', 'P001/SIC001ToPI001', {
                isEnabled: [
                    {
                        condition: 'P001/SIC001|Sts_bRunning',
                        value: true
                    }
                ]
            })
            SIC001_P001ToPI001.setSourcePort(feed.getPort('right'))
            SIC001_P001ToPI001.setTargetPort(PI001.getPort('left'))
            feedArtifacts.push(SIC001_P001ToPI001)
        }

        const PI001ToPSH001 = new PipeLinkModel('PI001ToPSH001', 'PI001ToPSH001', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        PI001ToPSH001.setSourcePort(PI001.getPort('right'))
        PI001ToPSH001.setTargetPort(PSH001.getPort('left'))
        feedArtifacts.push(PI001ToPSH001)

        const PSH001ToT2ToHV701 = new PipeLinkModel('PSH001ToT2', 'PSH001ToT2', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        PSH001ToT2ToHV701.setSourcePort(PSH001.getPort('right'))
        PSH001ToT2ToHV701.setTargetPort(HV701.getPort('top'))
        feedArtifacts.push(PSH001ToT2ToHV701)

        const HV701ToFE1 = new PipeLinkModel('HV701ToFE1', 'HV701ToFE1')
        HV701ToFE1.setSourcePort(HV701.getPort('left'))
        HV701ToFE1.setTargetPort(FE1.getPort('in'))
        feedArtifacts.push(HV701ToFE1)

        const FE1ToDR1 = new PipeLinkModel('FE1ToDR1', 'FE1ToDR1')
        FE1ToDR1.setSourcePort(FE1.getPort('out'))
        FE1ToDR1.setTargetPort(DR1.getPort('left'))
        feedArtifacts.push(FE1ToDR1)

        return feedArtifacts
    }

    generateMembraneArtifacts(): BaseArtifactModel[] {
        const EMLoaded = this.config.Instances.EquipmentModules.MEMBRANE001
        const EMConfig = labSizesConfig.Lab150['MEMBRANE']

        const membraneArtifacts: BaseArtifactModel[] & PipeLinkModel[] = []

        const [mappedControls, mappedConfig] = [
            this.getMappedControls(EMLoaded),
            this.getMappedConfig(EMConfig)
        ]

        const membrane = new (diagramArtifacts.Membrane.model)('Membrane', 'Membrane', EMLoaded.NodeId)
        // Add virtual sensors
        EMConfig.virtualSensors.forEach((virtualSensor: string) => {
            const virtualControl = mappedControls[virtualSensor]
            if (virtualControl.Sts_bEnable) {
                this.addLabels(membrane, virtualControl, false)
            }
        })
        membrane.setPosition(EMConfig.position[0], EMConfig.position[1])
        membraneArtifacts.push(membrane)

        const ME2 = new (diagramArtifacts.Elbow.model)('180-ME2', 'ME2', '', '', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        ME2.setPosition(mappedConfig['ME2'].position[0], mappedConfig['ME2'].position[1])
        membraneArtifacts.push(ME2)

        const MembraneToME2 = new PipeLinkModel('MembraneToME2', 'MembraneToME2', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        MembraneToME2.setSourcePort(membrane.getPort('retentate'))
        MembraneToME2.setTargetPort(ME2.getPort('in'))
        membraneArtifacts.push(MembraneToME2)

        const ME3 = new (diagramArtifacts.Elbow.model)('90-ME3-SP', 'ME3', '', '', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        ME3.setPosition(mappedConfig['ME3'].position[0], mappedConfig['ME3'].position[1])
        membraneArtifacts.push(ME3)

        const MembraneToME3 = new PipeLinkModel('MembraneToME3', 'MembraneToME3', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        MembraneToME3.setSourcePort(membrane.getPort('filtrate'))
        MembraneToME3.setTargetPort(ME3.getPort('in'))
        membraneArtifacts.push(MembraneToME3)

        const ME1 = new (diagramArtifacts.Elbow.model)('360-ME1', 'ME1', '', '', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        ME1.setPosition(mappedConfig['ME1'].position[0], mappedConfig['ME1'].position[1])
        membraneArtifacts.push(ME1)

        const ME1ToMembrane = new PipeLinkModel('ME1ToMembrane', 'ME1ToMembrane', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        ME1ToMembrane.setSourcePort(ME1.getPort('out'))
        ME1ToMembrane.setTargetPort(membrane.getPort('feed'))
        membraneArtifacts.push(ME1ToMembrane)

        const HV702 = new (diagramArtifacts.VerticalValve.model)('HV702', 'HV702', '', 'vertical')
        HV702.setPosition(mappedConfig['HV702'].position[0], mappedConfig['HV702'].position[1])
        this.addCustomLabel(HV702, 'HV702', '')
        membraneArtifacts.push(HV702)

        const DR3 = new (diagramArtifacts.Outlet.model)('DR3', this.intl.formatMessage({id: 'label.Drain'}), '', 'horizontal')
        DR3.setPosition(mappedConfig['DR3'].position[0], mappedConfig['DR3'].position[1])
        membraneArtifacts.push(DR3)

        const ME4 = new (diagramArtifacts.Elbow.model)('0-ME4', 'ME4', '', '')
        ME4.setPosition(mappedConfig['ME4'].position[0], mappedConfig['ME4'].position[1])
        membraneArtifacts.push(ME4)

        const HV702ToME4 = new PipeLinkModel('HV702ToME4', 'HV702ToME4')

        HV702ToME4.setSourcePort(HV702.getPort('right'))
        HV702ToME4.setTargetPort(ME4.getPort('in'))
        membraneArtifacts.push(HV702ToME4)

        const ME4ToDR3 = new PipeLinkModel('ME4ToDR3', 'ME4ToDR3')

        ME4ToDR3.setSourcePort(ME4.getPort('out'))
        ME4ToDR3.setTargetPort(DR3.getPort('left'))
        membraneArtifacts.push(ME4ToDR3)

        const MembraneToHV702 = new PipeLinkModel('MembraneToHV702', 'MembraneToHV702')
        MembraneToHV702.setSourcePort(membrane.getPort('drain'))
        MembraneToHV702.setTargetPort(HV702.getPort('left'))
        membraneArtifacts.push(MembraneToHV702)

        return membraneArtifacts
    }

    generateRetentateArtifacts(): BaseArtifactModel[] {
        const EMLoaded = this.config.Instances.EquipmentModules.RETENTATE001
        const EMConfig = labSizesConfig.Lab150['RETENTATE']

        const retentateArtifacts: BaseArtifactModel[] & PipeLinkModel[] = []

        const [mappedControls, mappedConfig] = [
            this.getMappedControls(EMLoaded),
            this.getMappedConfig(EMConfig)
        ]

        const retentate = new (diagramArtifacts.ControlledValve.model)('PCV101', 'PCV101', this.config.Instances.ControlModules['PCV101'].NodeId)

        // Add virtual sensors
        EMConfig.virtualSensors.forEach((virtualSensor: string) => {
            const virtualControl = mappedControls[virtualSensor]
            if (virtualControl.Sts_bEnable) {
                this.addLabels(retentate, virtualControl, false)
            }
        })
        retentate.setPosition(EMConfig.position[0], EMConfig.position[1])
        retentateArtifacts.push(retentate)

        const PI101 = new (diagramArtifacts.Sensor.model)('PI101', 'PI101', this.config.Instances.ControlModules['PI101'].NodeId)
        this.addLabels(PI101, mappedControls['PI101'], true)
        PI101.setPosition(mappedConfig['PI101'].position[0], mappedConfig['PI101'].position[1])
        retentateArtifacts.push(PI101)

        const PI101ToRetentate = new PipeLinkModel('PI101ToRetentate', 'PI101ToRetentate', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        PI101ToRetentate.setSourcePort(PI101.getPort('left'))
        PI101ToRetentate.setTargetPort(retentate.getPort('right'))
        retentateArtifacts.push(PI101ToRetentate)

        if (this.config.Unit.Set.iFactOptionFlowmeters) {
            const FI101 = new (diagramArtifacts.Sensor.model)('FI101', 'FI101', this.config.Instances.ControlModules['FI101'].NodeId)
            this.addLabels(FI101, mappedControls['FI101'], true)
            FI101.setPosition(mappedConfig['FI101'].position[0], mappedConfig['FI101'].position[1])
            retentateArtifacts.push(FI101)

            const retentateToFI101 = new PipeLinkModel('retentateToFI101', 'retentateToFI101', {
                isEnabled: [
                    {
                        condition: 'P001/SIC001|Sts_bRunning',
                        value: true
                    }
                ]
            })
            retentateToFI101.setSourcePort(retentate.getPort('left'))
            retentateToFI101.setTargetPort(FI101.getPort('right'))
            retentateArtifacts.push(retentateToFI101)

            if (this.config.Unit.Set.iFactOptionWeight && this.config.Unit.Set.bVisibilityWE101) {
                const WI101 = new (diagramArtifacts.Beaker.model)('WI101', 'WI101', this.config.Instances.ControlModules['WI101'].NodeId)
                this.addLabels(WI101, mappedControls['WI101'], false)
                this.addLabels(WI101, mappedControls['FLO101'], false)
                WI101.setPosition(mappedConfig['WI101'].position[0], mappedConfig['WI101'].position[1])
                retentateArtifacts.push(WI101)

                const E2 = new (diagramArtifacts.Elbow.model)('90-E2', 'E2', '', '', {
                    isEnabled: [
                        {
                            condition: 'P001/SIC001|Sts_bRunning',
                            value: true
                        }
                    ]
                })
                E2.setPosition(mappedConfig['E2'].position[0], mappedConfig['E2'].position[1])
                retentateArtifacts.push(E2)

                const FI101ToE2 = new PipeLinkModel('FI101ToE2', 'FI101ToE2', {
                    isEnabled: [
                        {
                            condition: 'P001/SIC001|Sts_bRunning',
                            value: true
                        }
                    ]
                })
                FI101ToE2.setSourcePort(FI101.getPort('left'))
                FI101ToE2.setTargetPort(E2.getPort('in'))
                retentateArtifacts.push(FI101ToE2)

                const E2ToWI101 = new PipeLinkModel('E2ToWI101', 'E2ToWI101', {
                    isEnabled: [
                        {
                            condition: 'P001/SIC001|Sts_bRunning',
                            value: true
                        }
                    ]
                })
                E2ToWI101.setSourcePort(E2.getPort('out'))
                E2ToWI101.setTargetPort(WI101.getPort('top'))
                retentateArtifacts.push(E2ToWI101)
            } else {
                const E3 = new (diagramArtifacts.Elbow.model)('90-E3', 'E3', '', '', {
                    isEnabled: [
                        {
                            condition: 'P001/SIC001|Sts_bRunning',
                            value: true
                        }
                    ]
                })

                E3.setPosition(mappedConfig['E3'].position[0], mappedConfig['E3'].position[1])
                retentateArtifacts.push(E3)

                const sensorToE3: any = new PipeLinkModel('sensorToE3', 'sensorToE3', {
                        isEnabled: [
                            {
                                condition: 'P001/SIC001|Sts_bRunning',
                                value: true
                            }
                        ]
                    }
                )

                sensorToE3.setSourcePort(FI101.getPort('left'))
                sensorToE3.setTargetPort(E3.getPort('in'))
                retentateArtifacts.push(sensorToE3)
            }
        } else {
            if (this.config.Unit.Set.iFactOptionWeight && this.config.Unit.Set.bVisibilityWE101) {
                const WI101 = new (diagramArtifacts.Beaker.model)('WI101', 'WI101', this.config.Instances.ControlModules['WI101'].NodeId)
                this.addLabels(WI101, mappedControls['WI101'], false)
                WI101.setPosition(mappedConfig['WI101'].position[0], mappedConfig['WI101'].position[1])
                retentateArtifacts.push(WI101)

                const E2 = new (diagramArtifacts.Elbow.model)('90-E2', 'E2', '', '', {
                    isEnabled: [
                        {
                            condition: 'P001/SIC001|Sts_bRunning',
                            value: true
                        }
                    ]
                })
                E2.setPosition(mappedConfig['E2'].position[0], mappedConfig['E2'].position[1])
                retentateArtifacts.push(E2)

                const retentateToE2 = new PipeLinkModel('retentateToE2', 'retentateToE2', {
                    isEnabled: [
                        {
                            condition: 'P001/SIC001|Sts_bRunning',
                            value: true
                        }
                    ]
                })
                retentateToE2.setSourcePort(retentate.getPort('left'))
                retentateToE2.setTargetPort(E2.getPort('in'))
                retentateArtifacts.push(retentateToE2)

                const E2ToWI101 = new PipeLinkModel('E2ToWI101', 'E2ToWI101', {
                    isEnabled: [
                        {
                            condition: 'P001/SIC001|Sts_bRunning',
                            value: true
                        }
                    ]
                })
                E2ToWI101.setSourcePort(E2.getPort('out'))
                E2ToWI101.setTargetPort(WI101.getPort('top'))
                retentateArtifacts.push(E2ToWI101)
            } else {
                const E3 = new (diagramArtifacts.Elbow.model)('90-E3', 'E3', '', '', {
                    isEnabled: [
                        {
                            condition: 'P001/SIC001|Sts_bRunning',
                            value: true
                        }
                    ]
                })

                E3.setPosition(mappedConfig['E3'].position[0], mappedConfig['E3'].position[1])
                retentateArtifacts.push(E3)

                const sensorToE3: any = new PipeLinkModel('sensorToE3', 'sensorToE3', {
                        isEnabled: [
                            {
                                condition: 'P001/SIC001|Sts_bRunning',
                                value: true
                            }
                        ]
                    }
                )

                sensorToE3.setSourcePort(retentate.getPort('left'))
                sensorToE3.setTargetPort(E3.getPort('in'))
                retentateArtifacts.push(sensorToE3)
            }
        }

        return retentateArtifacts
    }

    generateFiltrateArtifacts(): BaseArtifactModel[] {
        const EMLoaded = this.config.Instances.EquipmentModules.FILTRATE001
        const EMConfig = labSizesConfig.Lab150['FILTRATE']

        const filtrateArtifacts: BaseArtifactModel[] & PipeLinkModel[] = []

        const [mappedControls, mappedConfig] = [
            this.getMappedControls(EMLoaded),
            this.getMappedConfig(EMConfig)
        ]

        const filtrate = new (diagramArtifacts.Empty.model)('Filtrate', 'Filtrate', '',  'horizontal', {
            isEnabled: [
                {
                    condition: 'P001/SIC001|Sts_bRunning',
                    value: true
                }
            ]
        })
        filtrate.setPosition(EMConfig.position[0], EMConfig.position[1])
        EMConfig.virtualSensors.forEach((virtualSensor: string) => {
            const virtualControl = mappedControls[virtualSensor]
            if (virtualControl.Sts_bEnable) {
                this.addLabels(filtrate, virtualControl, false)
            }
        })
        filtrateArtifacts.push(filtrate)

        const optionalArtifacts: {
            name: string
            artifact: BaseArtifactModel,
            portIn: string,
            portOut: string
        }[] = []

        if (this.config.Unit.Set.iFactOptionFiltratePCV) {

            const PI201 = new (diagramArtifacts.Sensor.model)('PI201', 'PI201', this.config.Instances.ControlModules['PI201'].NodeId)
            PI201.setPosition(mappedConfig['PI201'].position[0], mappedConfig['PI201'].position[1])
            this.addLabels(PI201, mappedControls['PI201'], true)

            optionalArtifacts.push({
                name: 'PI201',
                artifact: PI201,
                portIn: 'left',
                portOut: 'right'
            })
            filtrateArtifacts.push(PI201);

            const PCV201 = new (diagramArtifacts.ControlledValve.model)('PCV201', 'PCV201', this.config.Instances.ControlModules['PCV201'].NodeId)
            PCV201.setPosition(mappedConfig['PCV201'].position[0], mappedConfig['PCV201'].position[1])
            optionalArtifacts.push({
                name: 'PCV201',
                artifact: PCV201,
                portIn: 'left',
                portOut: 'right'
            })
            filtrateArtifacts.push(PCV201)
        }

        if (this.config.Unit.Set.iFactOptionFlowmeters) {
            const FI201 = new (diagramArtifacts.Sensor.model)('FI201', 'FI201', this.config.Instances.ControlModules['FI201'].NodeId)
            this.addLabels(FI201, mappedControls['FI201'], true)
            FI201.setPosition(mappedConfig['FI201'].position[0], mappedConfig['FI201'].position[1])
            optionalArtifacts.push({
                name: 'FI201',
                artifact: FI201,
                portIn: 'left',
                portOut: 'right'
            })
            filtrateArtifacts.push(FI201)
        }

        let beakerCount = 0
        if (!this.config.Unit.Set.iFactOptionWeight) {
            beakerCount = 0
        } else {
            if (this.config.Unit.Set.bVisibilityWE201)
                beakerCount++
            if (this.config.Unit.Set.bVisibilityWE202) {
                if (beakerCount === 0) {
                    beakerCount = 2
                } else {
                    beakerCount++
                }
            }

            if (this.config.Unit.Set.bVisibilityWE203)
                if (beakerCount === 0) {
                    beakerCount = 3
                } else {
                    beakerCount++
                }
        }

        if (this.config.Unit.Set.iFactOptionWeight) {
            let offset = 1.5

            if (this.config.Unit.Set.bVisibilityWE201) {
                if (beakerCount > 1) {
                    //Elbow
                    const FIE1 = new (diagramArtifacts.Elbow.model)('180-FIE1-SP', 'FIE1', '', '', {
                        isEnabled: [
                            {
                                condition: 'P001/SIC001|Sts_bRunning',
                                value: true
                            }
                        ]
                    })

                    FIE1.setPosition(mappedConfig['FIE1'].position[0], mappedConfig['FIE1'].position[1])

                    optionalArtifacts.push({
                        name: 'FIE1',
                        artifact: FIE1,
                        portIn: 'in',
                        portOut: 'out'
                    })
                    filtrateArtifacts.push(FIE1)

                    //And T
                    const FIT1 = new (diagramArtifacts.Y.model)('180-FIT1-SP', 'FIT1', '')
                    FIT1.setPosition(mappedConfig['FIT1'].position[0], mappedConfig['FIT1'].position[1])
                    optionalArtifacts.push({
                        name: 'FIT1',
                        artifact: FIT1,
                        portIn: 'in',
                        portOut: 'out'
                    })
                    filtrateArtifacts.push(FIT1)
                }

                let pcvOffset = 0
                if (beakerCount === 1 && _.last(filtrateArtifacts).displayName === 'PCV201') {
                    pcvOffset = 77
                }

                const WI201 = this.CreateBeakerArtifact('WI201', 'FLO201', mappedControls, filtrateArtifacts, offset + pcvOffset, mappedConfig);
                filtrateArtifacts.push(WI201)

                if (beakerCount === 1) {
                    optionalArtifacts.push({
                        name: 'WI201',
                        artifact: WI201,
                        portIn: 'left',
                        portOut: 'left'
                    })
                } else {
                    let FIT1ToWI201 = new PipeLinkModel('FIT1ToWI201', 'FIT1ToWI201', {
                        isEnabled: [
                            {
                                condition: 'P001/SIC001|Sts_bRunning',
                                value: true
                            }
                        ]
                    })

                    FIT1ToWI201.setSourcePort(filtrateArtifacts.find(item => item.displayName === 'FIT1').getPort('outangle'))
                    FIT1ToWI201.setTargetPort(WI201.getPort('left'))

                    filtrateArtifacts.push(FIT1ToWI201)
                }
            }
            if (this.config.Unit.Set.bVisibilityWE202) {

                if (beakerCount > 2) {
                    const FIT2 = new (diagramArtifacts.Y.model)('180-FIT2-SP', 'FIT2', '')
                    FIT2.setPosition(mappedConfig['FIT2'].position[0], mappedConfig['FIT2'].position[1])
                    optionalArtifacts.push({
                        name: 'FIT2',
                        artifact: FIT2,
                        portIn: 'in',
                        portOut: 'out'
                    })

                    filtrateArtifacts.push(FIT2)
                    offset = 1.5
                } else {
                    const FIE2 = new (diagramArtifacts.Elbow.model)('0-FIE2', 'FIE2', '', '', {
                        isEnabled: [
                            {
                                condition: 'P001/SIC001|Sts_bRunning',
                                value: true
                            }
                        ]
                    })

                    FIE2.setPosition(mappedConfig['FIE2'].position[0], mappedConfig['FIE2'].position[1] - 250)

                    optionalArtifacts.push({
                        name: 'FIE2',
                        artifact: FIE2,
                        portIn: 'in',
                        portOut: 'out'
                    })
                    filtrateArtifacts.push(FIE2)

                    offset = -15
                }

                const WI202 = this.CreateBeakerArtifact('WI202', 'FLO202', mappedControls, filtrateArtifacts, offset, mappedConfig);
                filtrateArtifacts.push(WI202)

                if (beakerCount === 2) {
                    optionalArtifacts.push({
                        name: 'WI202',
                        artifact: WI202,
                        portIn: 'left',
                        portOut: 'left'
                    })
                } else {
                    let FIT2ToWI202 = new PipeLinkModel('FIT2ToWI202', 'FIT2ToWI202', {
                        isEnabled: [
                            {
                                condition: 'P001/SIC001|Sts_bRunning',
                                value: true
                            }
                        ]
                    })

                    FIT2ToWI202.setSourcePort(filtrateArtifacts.find(item => item.displayName === 'FIT2').getPort('outangle'))
                    FIT2ToWI202.setTargetPort(WI202.getPort('left'))

                    filtrateArtifacts.push(FIT2ToWI202)
                }
            }
            if (this.config.Unit.Set.bVisibilityWE203) {

                const FIE2 = new (diagramArtifacts.Elbow.model)('0-FIE2', 'FIE2', '', '', {
                    isEnabled: [
                        {
                            condition: 'P001/SIC001|Sts_bRunning',
                            value: true
                        }
                    ]
                })

                FIE2.setPosition(mappedConfig['FIE2'].position[0], mappedConfig['FIE2'].position[1])

                optionalArtifacts.push({
                    name: 'FIE2',
                    artifact: FIE2,
                    portIn: 'in',
                    portOut: 'out'
                })
                filtrateArtifacts.push(FIE2)

                offset = -15
                const WI203 = this.CreateBeakerArtifact('WI203', 'FLO203', mappedControls, filtrateArtifacts, offset, mappedConfig);
                filtrateArtifacts.push(WI203)

                optionalArtifacts.push({
                    name: 'WI203',
                    artifact: WI203,
                    portIn: 'left',
                    portOut: 'left'
                })
            }
        }

        if (beakerCount === 0) {
            const DR2 = new (diagramArtifacts.Outlet.model)('DR2', this.intl.formatMessage({id: 'label.Outlet'}), '', 'horizontal', {
                isEnabled: [
                    {
                        condition: 'P001/SIC001|Sts_bRunning',
                        value: true
                    }
                ]
            })
            //Adjust outlet Top coordinate to the last control + an offset of 100 (if last artifact is vertical)
            let offset = -16;
            if (_.last(filtrateArtifacts).displayName === 'PCV201') {
                offset = 77
            } else if (_.last(filtrateArtifacts).orientation === 'horizontal') {
                offset = 0;
            }

            let outletTop = _.last(filtrateArtifacts).getPosition().y + offset;

            DR2.setPosition(mappedConfig['DR2'].position[0], outletTop)

            optionalArtifacts.push({
                name: 'DR2',
                artifact: DR2,
                portIn: 'left',
                portOut: ''
            })

            filtrateArtifacts.push(DR2)
        }

        const optionalLinks = optionalArtifacts.map((art, i) => {
            let link
            if (i === 0) {
                link = new PipeLinkModel(`filtrateTo${art.name}`, `filtrateTo${art.name}`, {
                    isEnabled: [
                        {
                            condition: 'P001/SIC001|Sts_bRunning',
                            value: true
                        },

                    ]
                })
                link.setSourcePort(filtrate.getPort('right'))
                link.setTargetPort(art.artifact.getPort(art.portIn))
            } else {
                link = new PipeLinkModel(`${optionalArtifacts[i - 1].name}To${art.name}`, `${optionalArtifacts[i - 1].name}To${art.name}`, {
                    isEnabled: [
                        {
                            condition: 'P001/SIC001|Sts_bRunning',
                            value: true
                        }
                    ]
                })

                link.setSourcePort(optionalArtifacts[i - 1].artifact.getPort(optionalArtifacts[i - 1].portOut))
                link.setTargetPort(art.artifact.getPort(art.portIn))
            }
            return link
        })

        filtrateArtifacts.push(...optionalLinks)

        return filtrateArtifacts
    }

    private CreateBeakerArtifact(beakerTag: string, flowTag: string, mappedControls: Record<string, IAnalogueSensor>, filtrateArtifacts: BaseArtifactModel[] & PipeLinkModel[], offset: number, mappedConfig: Record<string, IControlConfig>) {
        const WTArtifact = new (diagramArtifacts.Beaker.model)(beakerTag, beakerTag, this.config.Instances.ControlModules[beakerTag].NodeId)
        this.addLabels(WTArtifact, mappedControls[beakerTag], false)
        this.addLabels(WTArtifact, mappedControls[flowTag], false)

        //Adjust beaker Top coordinate to the last control
        let weightTop = _.last(filtrateArtifacts).getPosition().y + offset;

        WTArtifact.setPosition(mappedConfig[beakerTag].position[0], weightTop)

        return WTArtifact
    }
}