import {PortModelAlignment} from '@projectstorm/react-diagrams'

export const Ports90Deg = [
    {
        name: 'in',
        alignment: PortModelAlignment.RIGHT
    },
    {
        name: 'out',
        alignment: PortModelAlignment.BOTTOM
    }
]

export const Ports90DegSwitched = [
    {
        name: 'in',
        alignment: PortModelAlignment.BOTTOM
    },
    {
        name: 'out',
        alignment: PortModelAlignment.RIGHT
    }
]
