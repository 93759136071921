import React from 'react'
import NotificationList from './NotificationList'
import './alarmNotification.css'
import * as StoreTypes from 'StoreTypes'
import { connect } from 'react-redux'
import { apiAlarmStatus } from '../../../../models/Api/apiAlarmStatus'

const AlarmNotification = (props: any) => {

  const { config } = props

  // function handleOnClick(data) {
  //     this.props.removeOrchAlarmNotification(data);
  //     this.props.handleShowMessage([data]);
  // }

  function clearAlarms() {
    fetch(`${config.APIBaseUrl}/Unit/api/Unit/v1/PropertyValue/Set?pushToPlc=true`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        Value: true,
        NodeId: config.EO.NodeId + ".Inp.bAlarmsAck"
      })
    })
      .catch((err) => console.error(err))
  }

  const alarms: any = []
  config.AH.Sts.Alarm.ActiveAlarms.forEach((alarm: apiAlarmStatus) => {
    if (!alarm.Acknowledged) {
      (alarm as any).Date = new Date(alarm.Created)
      alarms.push(alarm)
    }
  })

  return (
    <div>
      <div className="orch-notification_icon_container">
        <NotificationList
          key={0}
          alarmList={alarms}
          count={alarms.length}
          handleOnClick={() => {}}
          clearAllNotification={() => clearAlarms()}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
  config: state.config
})

export default connect(mapStateToProps)(
  React.memo(AlarmNotification, (prevProps, nextProps) => {
    return (prevProps.config.AH === nextProps.config.AH)
  })
)
