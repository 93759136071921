import * as React from 'react';
import {
    GridColumnMenuFilter,
    GridColumnMenuCheckboxFilter
} from '@progress/kendo-react-grid';
import {GridColumnMenuFilterProps} from "@progress/kendo-react-grid/dist/es/columnMenu/GridColumnMenuFilter";

export class ColumnMenu extends React.Component<GridColumnMenuFilterProps> {
    render() {
        return (
            <div>
                <GridColumnMenuFilter {...this.props} expanded={true}/>
            </div>
        );
    }
}

