const defaultChartDateFormats =  {
        seconds: "HH:mm:ss",
        minutes: "dd MMM HH:mm",
        hours: "dd MMM HH:mm",
        days: "dd MMM",
        weeks:"dd MMM",
        months: "MMM yyyy",
        years: "yyyy" }

export default defaultChartDateFormats;

export interface CustomRange {
        RangeMin: number;
        RangeMax: number;
}

export const labSizesHoldupVolumes: any = {
        Lab150: <CustomRange>{
                RangeMin: 0,
                RangeMax: 0.1,
        },
        Lab800:<CustomRange>{
                RangeMin: 0,
                RangeMax: 0.5,
        },
        Lab6000:<CustomRange>{
                RangeMin: 0,
                RangeMax: 2,
        }
}