import {PortModelAlignment} from '@projectstorm/react-diagrams'

export const Ports180Deg = [
    {
        name: 'in',
        alignment: PortModelAlignment.BOTTOM
    },
    {
        name: 'out',
        alignment: PortModelAlignment.LEFT
    }
]

export const Ports180DegSwitched = [
    {
        name: 'in',
        alignment: PortModelAlignment.LEFT
    },
    {
        name: 'out',
        alignment: PortModelAlignment.BOTTOM
    }
]
