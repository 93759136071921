import React, { useEffect, useRef, useState } from 'react'
import {useSelector} from 'react-redux'
import StoreTypes from 'StoreTypes'
import {IConfigReducer} from '../../../store/reducers/configReducer'
import {Col, Row} from 'reactstrap'
import {useIntl} from 'react-intl'
import {NumericTextBox} from '@progress/kendo-react-inputs'
import {Accordion, Dropdown, RadioButton} from '@liquid-design/liquid-design-react'

import useMountEffect from '../../../utils/useMountEffect'

import {AnalogueSensorVirtual} from '../../../models/PLC/AnalogueSensorVirtual'
import {Retentate} from '../../../models/PLC/Retentate'
import {Tank} from '../../../models/PLC/Tank'
import {AnalogueSensorWired} from '../../../models/PLC/AnalogueSensorWired'
import {StepMotorValve} from '../../../models/PLC/StepMotorValve'
import {DeadBandList} from '../../../models/PLC/DeadBand'

import {updateControlValue, updateDropdownValue} from './Helpers/UpdateHelpers'
import {getDigitFormat, getDigitNumber} from '../../Diagram/ControlPanels/settingsHelpers'
import MacroStep, {IMacroStep} from './MacroStep'
import {DriveControlPID} from "../../../models/PLC/DriveControlPID";

const SPTFFConcentration = (props: Partial<IMacroStep>) => {

    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const intl = useIntl()
    const areValuesLoadedRef = useRef(true)

    const PCV101DropdownValues = [
        {name: intl.formatMessage({id: 'label.Position'}), id: '1', dbValue: 2},
        {name: intl.formatMessage({id: 'label.Pressure'}), id: '2', dbValue: 3}
    ]

    const SIC401_P401DropdownValues = [
        {name: intl.formatMessage({id: 'label.SpeedRegulation'}), id: '1', dbValue: 1},
        {name: intl.formatMessage({id: 'label.DeltaLevelRegulation'}), id: '2', dbValue: 2}
    ]

    const EndCriteriaDropdownValues = [
        {name: intl.formatMessage({id: 'label.Time'}), id: '1', dbValue: 1002},
    ]

    const isTankWeightWired = config.Unit.Set.iConfiguredBTPSize > 1

    if (isTankWeightWired) {
        EndCriteriaDropdownValues.push({name: intl.formatMessage({id: 'label.WT001Threshold'}), id: '2', dbValue: 2})
    }

    const isTransfertOptionWired = config.Unit.Set.iUserOptionTransfert
    const isFiltratePCVOptionWired = config.Unit.Set.iFactOptionFiltratePCV
    const isFlowmetersOptionWired = config.Unit.Set.iFactOptionFlowmeters

    useMountEffect(() => {
        if (props.loadedValues) {
            // CONCENTRATION CONFIG
            setIsAutoModeOn(
                props.loadedValues[0].ActionICode !== 6
            )
            setMemorization(
                props.loadedValues[1].ActionICode === 10
            )
            // P001/SIC001
            const INITSIC001_P001SetPoint = props.loadedValues[14].ActionRValue
            setSIC001_P001SetPointSelected(
                INITSIC001_P001SetPoint
            )
            // PCV101
            const INITPCV101Dropdown = props.loadedValues[17].ActionICode
            setPCV101DropdownSelected(
                PCV101DropdownValues[PCV101DropdownValues.findIndex((val) => val.dbValue === INITPCV101Dropdown)]
            )
            const INITPCV101SetPoint = props.loadedValues[17].ActionRValue
            setPCV101SetPointSelected(
                INITPCV101SetPoint
            )
            // P401/SIC401
            const INITSIC401_P401Dropdown = props.loadedValues[20].ActionRValue
            const INITSIC401_P401Value = SIC401_P401DropdownValues.findIndex((val) => val.dbValue === INITSIC401_P401Dropdown)
            setSIC401_P401DropdownSelected(
                SIC401_P401DropdownValues[INITSIC401_P401Value !== -1 ? INITSIC401_P401Value : 0]
            )
            const INITSIC401_P401SetPoint = props.loadedValues[21].ActionRValue
            setSIC401_P401SetPointSelected(
                INITSIC401_P401SetPoint
            )
            const INITSIC401_P401LevelStart = props.loadedValues[18].ActionRValue
            setSIC401_P401LevelStart(
                INITSIC401_P401LevelStart
            )
            const INITSIC401_P401LevelStop = props.loadedValues[19].ActionRValue
            setSIC401_P401LevelStop(
                INITSIC401_P401LevelStop
            )
            const INITSIC401_P401StartSelected = props.loadedValues[22].ActionRValue
            setSIC401_P401StartSelected(
                INITSIC401_P401StartSelected
            )
            // PCV201
            const INITPCV201SetPoint = props.loadedValues[23].ActionRValue
            setPCV201SetPointSelected(
                INITPCV201SetPoint
            )
            // END CRITERIA
            const INITEndCriteriaDropdown = props.loadedValues[27].SequenceCriterias[0].IModeType
            setEndCriteriaDropdownSelected(
                EndCriteriaDropdownValues[EndCriteriaDropdownValues.findIndex((val) => val.dbValue === INITEndCriteriaDropdown)]
            )
            switch (INITEndCriteriaDropdown) {
                case 1002: {
                    setTimeCriteria(
                        props.loadedValues[27].SequenceCriterias[0].RValue
                    )
                    setTimeCriteriaHour(
                        Math.floor(props.loadedValues[27].SequenceCriterias[0].RValue / 3600)
                    )
                    setTimeCriteriaMinute(
                        Math.floor((props.loadedValues[27].SequenceCriterias[0].RValue % 3600) / 60)
                    )
                    setTimeCriteriaSecond(props.loadedValues[27].SequenceCriterias[0].RValue % 60
                    )
                    break
                }
                case 2: {
                    setsuperiorWI001Threshold(
                        props.loadedValues[27].SequenceCriterias[0].RValue
                    )
                    break
                }
            }
        }
        areValuesLoadedRef.current = true
    })

    useEffect(() => {
        if (areValuesLoadedRef.current && props.onSave) {
            validConfiguration(false)
        }
    }, [props.onSave, validConfiguration])

    const [isAutoModeOn, setIsAutoModeOn] = useState(false)
    const [memorization, setMemorization] = useState(false)

    const [SIC001_P001SetPointSelected, setSIC001_P001SetPointSelected] = useState(0)

    const [PCV101DropdownSelected, setPCV101DropdownSelected] = useState(PCV101DropdownValues[0])
    const [PCV101SetPointSelected, setPCV101SetPointSelected] = useState(0)

    const [SIC401_P401DropdownSelected, setSIC401_P401DropdownSelected] = useState(SIC401_P401DropdownValues[0])
    const [SIC401_P401SetPointSelected, setSIC401_P401SetPointSelected] = useState(0)
    const [SIC401_P401StartSelected, setSIC401_P401StartSelected] = useState(0)

    const [SIC401_P401LevelStart, setSIC401_P401LevelStart] = useState(0)
    const [SIC401_P401LevelStop, setSIC401_P401LevelStop] = useState(0)

    const [PCV201SetPointSelected, setPCV201SetPointSelected] = useState(0)

    const [timeCriteria, setTimeCriteria] = useState(0)
    const [timeCriteriaHour, setTimeCriteriaHour] = useState(0)
    const [timeCriteriaMinute, setTimeCriteriaMinute] = useState(0)
    const [timeCriteriaSeconds, setTimeCriteriaSecond] = useState(0)
    const [superiorWI001Threshold, setsuperiorWI001Threshold] = useState(0)
    const [EndCriteriaDropdownSelected, setEndCriteriaDropdownSelected] = useState(EndCriteriaDropdownValues[0])


    function validConfiguration(triggerChanges = true) {
        props.onSave(triggerChanges, {
            isAutoModeOn,
            memorization,
            SIC001_P001SetPointSelected,
            PCV101DropdownSelected,
            PCV101SetPointSelected,
            SIC401_P401DropdownSelected,
            SIC401_P401SetPointSelected,
            SIC401_P401LevelStart,
            SIC401_P401LevelStop,
            PCV201SetPointSelected,
            timeCriteria,
            superiorWI001Threshold,
            EndCriteriaDropdownSelected,
            isFiltratePCVOptionWired,
            isFlowmetersOptionWired,
            isTransfertOptionWired,
            SIC401_P401StartSelected,
        })
    }

    function updateTimeCriteria(valToModif: number, value: number) {
        let temptimeCriteria: number
        switch (valToModif) {
            case 1 : {
                setTimeCriteriaHour(value)
                temptimeCriteria = value * 3600 + timeCriteriaMinute * 60 + timeCriteriaSeconds
                break
            }
            case 2 : {
                setTimeCriteriaMinute(value)
                temptimeCriteria = timeCriteriaHour * 3600 + value * 60 + timeCriteriaSeconds
                break
            }
            case 3 : {
                setTimeCriteriaSecond(value)
                temptimeCriteria = timeCriteriaHour * 3600 + timeCriteriaMinute * 60 + value
                break
            }

        }
        updateControlValue(false, setTimeCriteria, temptimeCriteria)
    }

    function generateEndCriteriaFields() {
        switch (EndCriteriaDropdownSelected.id) {
            case '1':
                // Time
                return (
                    <>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.TimeHour'})}
                            format={'0 h'}
                            spinners={false}
                            min={0}
                            value={timeCriteriaHour}
                            onChange={(val) => updateTimeCriteria(1, val.value)}
                        />
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.TimeMinute'})}
                            format={'0 m'}
                            spinners={false}
                            min={0}
                            max={59}
                            value={timeCriteriaMinute}
                            onChange={(val) => updateTimeCriteria(2, val.value)}
                        />
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.TimeSecond'})}
                            format={'0 s'}
                            spinners={false}
                            min={0}
                            max={59}
                            value={timeCriteriaSeconds}
                            onChange={(val) => updateTimeCriteria(3, val.value)}
                        />
                    </>
                )
            case '2':
                // WI001 Threshold
                const sensor = config.Instances.ControlModules['WI001'] as AnalogueSensorWired
                const unit = sensor.Values[0].Cfg_suSensorUnit.Unit

                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.WT001Threshold'})}
                        spinners={false}
                        min={sensor.Cfg_rEGUMinimum}
                        max={sensor.Cfg_rEGUMaximum}
                        value={superiorWI001Threshold}
                        format={`0${getDigitFormat(superiorWI001Threshold, DeadBandList.getDeadBandFor(sensor.Values[0].Cfg_dDeadBands, superiorWI001Threshold))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setsuperiorWI001Threshold, val.value)}
                    />
                )
        }
    }

    function generatePCV101Input() {
        const EM = config.Instances.EquipmentModules['RETENTATE001'] as Retentate
        let valveMode = (config.Instances.ControlModules['PCV101'] as StepMotorValve).Cfg_bValveType ? intl.formatMessage({id: 'label.Closed'}) : intl.formatMessage({id: 'label.Opened'})

        switch (PCV101DropdownSelected ? PCV101DropdownSelected.id : '1') {
            case '1': {
                // Position
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={0}
                        max={1}
                        value={PCV101SetPointSelected / 100}
                        format={`0${getDigitFormat(PCV101SetPointSelected, DeadBandList.getDeadBandFor(config.Instances.ControlModules['PCV101'].Values[0].Cfg_dDeadBands, PCV101SetPointSelected))} % ${valveMode}`}
                        onChange={(val) => updateControlValue(true, setPCV101SetPointSelected, val.value)}
                    />
                )
            }
            case '2': {
                // Pressure
                const control = config.Instances.ControlModules['PI101'] as AnalogueSensorVirtual
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.PressureRegul

                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={PCV101SetPointSelected}
                        format={`0${getDigitFormat(PCV101SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, PCV101SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setPCV101SetPointSelected, val.value)}
                    />
                )
            }
        }
    }

    function generateSIC401_P401Input() {
        const EM = config.Instances.EquipmentModules['TANK001'] as Tank
        const weightSensor = config.Instances.ControlModules[EM.WeightSensor.Cfg_sTag] as AnalogueSensorWired
        const unit = weightSensor.Values[0].Cfg_suSensorUnit.Unit
        const transferPump = config.Instances.ControlModules['P401/SIC401'] as DriveControlPID;

        return (
            <>
                <NumericTextBox
                    label={intl.formatMessage({id: 'label.SetPoint'})}
                    spinners={false}
                    min={transferPump.Speed.SPMin / 100}
                    max={transferPump.Speed.SPMax / 100}
                    value={SIC401_P401SetPointSelected / 100}
                    format={`p${getDigitNumber(SIC401_P401SetPointSelected, DeadBandList.getDeadBandFor(config.Instances.ControlModules['P401/SIC401'].Values[0].Cfg_dDeadBands, SIC401_P401SetPointSelected))}`}
                    onChange={(val) => updateControlValue(true, setSIC401_P401SetPointSelected, val.value)}
                />
                {SIC401_P401DropdownSelected.id === '2' && (
                    <>
                        <br/>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.LevelStart'})}
                            spinners={false}
                            min={weightSensor.Cfg_rEGUMinimum}
                            max={SIC401_P401LevelStop}
                            value={SIC401_P401LevelStart}
                            format={`0${getDigitFormat(SIC401_P401LevelStart, DeadBandList.getDeadBandFor(weightSensor.Values[0].Cfg_dDeadBands, SIC401_P401LevelStart))} ${unit}`}
                            onChange={(val) => updateControlValue(false, setSIC401_P401LevelStart, val.value)}
                        />
                        <br/>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.LevelStop'})}
                            spinners={false}
                            min={SIC401_P401LevelStart}
                            max={weightSensor.Cfg_rEGUMaximum}
                            value={SIC401_P401LevelStop}
                            format={`0${getDigitFormat(SIC401_P401LevelStop, DeadBandList.getDeadBandFor(weightSensor.Values[0].Cfg_dDeadBands, SIC401_P401LevelStop))} ${unit}`}
                            onChange={(val) => updateControlValue(false, setSIC401_P401LevelStop, val.value)}
                        />
                    </>
                )}
            </>
        )
    }

    return (
        <MacroStep
            id={props.id}
            macroStepName={'label.SPTFFConcentration'}
            lightBoxLabel={'label.SPTFFConcentrationOptions'}
            sequenceID={props.sequenceID}
            stepNumber={props.stepNumber}
            isDraggable={props.isDraggable}
            isModel={props.isModel}
            onDelete={props.onDelete}
            onSave={validConfiguration}
        >
            <Accordion title={intl.formatMessage({id: 'label.SPTFFConcentrationOptions'})}>
                <div style={{minHeight: '200px'}}>
                    <Row>
                        <Col>
                            <br/>
                            <RadioButton
                                isSelected={isAutoModeOn}
                                onClick={() => setIsAutoModeOn(true)}
                                label={intl.formatMessage({id: 'label.AutoModeOn'})}
                            />
                            <br/>
                            <RadioButton
                                isSelected={!isAutoModeOn}
                                onClick={() => setIsAutoModeOn(false)}
                                label={intl.formatMessage({id: 'label.AutoModeOff'})}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br/>
                            <RadioButton
                                isSelected={memorization}
                                onClick={() => setMemorization(true)}
                                label={intl.formatMessage({id: 'label.Memorize'})}
                            />
                            <br/>
                            <RadioButton
                                isSelected={!memorization}
                                onClick={() => setMemorization(false)}
                                label={intl.formatMessage({id: 'label.NoMemorize'})}
                            />
                        </Col>
                    </Row>
                </div>
            </Accordion>
            <Accordion title="P001/SIC001">
                <div style={{minHeight: '200px'}}>
                    <Row>
                        <Col>
                            <NumericTextBox
                                label={intl.formatMessage({id: 'label.SetPoint'})}
                                spinners={false}
                                min={0}
                                max={1}
                                value={SIC001_P001SetPointSelected / 100}
                                format={`p${getDigitNumber(SIC001_P001SetPointSelected, DeadBandList.getDeadBandFor(config.Instances.ControlModules['P001/SIC001'].Values[0].Cfg_dDeadBands, SIC001_P001SetPointSelected))}`}
                                onChange={(val) => updateControlValue(true, setSIC001_P001SetPointSelected, val.value)}
                            />
                        </Col>
                    </Row>
                </div>
            </Accordion>
            <Accordion title="PCV101">
                <div style={{minHeight: '200px'}}>
                    <Row>
                        <Col>
                            <Dropdown
                                label={intl.formatMessage({id: 'label.RegulationMode'})}
                                options={PCV101DropdownValues}
                                value={PCV101DropdownSelected.id}
                                onSubmit={(val: any) => updateDropdownValue(setPCV101DropdownSelected, val, [setPCV101SetPointSelected])}
                            />
                        </Col>
                        <Col>
                            {generatePCV101Input()}
                        </Col>
                    </Row>
                </div>
            </Accordion>
            {isTransfertOptionWired && (
                <Accordion title="P401/SIC401">
                    <div style={{minHeight: '200px'}}>
                        <Row>
                            <Col>
                                <br/>
                                <RadioButton
                                    isSelected={SIC401_P401StartSelected === 0}
                                    onClick={() => setSIC401_P401StartSelected(0)}
                                    label={intl.formatMessage({id: 'label.Stoppump'})}
                                />
                                <br/>
                                <RadioButton
                                    isSelected={SIC401_P401StartSelected === 1}
                                    onClick={() => setSIC401_P401StartSelected(1)}
                                    label={intl.formatMessage({id: 'label.Startpump'})}
                                />
                            </Col>
                        </Row>
                        {SIC401_P401StartSelected === 1 && <Row>
                            <Col>
                                <Dropdown
                                    label={intl.formatMessage({id: 'label.RegulationMode'})}
                                    options={SIC401_P401DropdownValues}
                                    value={SIC401_P401DropdownSelected.id}
                                    onSubmit={(val: any) => updateDropdownValue(setSIC401_P401DropdownSelected, val, [setSIC401_P401SetPointSelected, setSIC401_P401LevelStart, setSIC401_P401LevelStop])}
                                />
                            </Col>
                            <Col>
                                {generateSIC401_P401Input()}
                            </Col>
                        </Row>}
                    </div>
                </Accordion>
            )}
            {isFiltratePCVOptionWired && (
                <Accordion title="PCV201">
                    <div style={{minHeight: '200px'}}>
                        <Row>
                            <Col>
                                <NumericTextBox
                                    label={intl.formatMessage({id: 'label.SetPoint'})}
                                    spinners={false}
                                    min={0}
                                    max={1}
                                    value={PCV201SetPointSelected / 100}
                                    format={`0${getDigitFormat(PCV201SetPointSelected, DeadBandList.getDeadBandFor(config.Instances.ControlModules['PCV201'].Values[0].Cfg_dDeadBands, PCV201SetPointSelected))} % ${(config.Instances.ControlModules['PCV201'] as StepMotorValve).Cfg_bValveType ? intl.formatMessage({id: 'label.Closed'}) : intl.formatMessage({id: 'label.Opened'})}`}
                                    onChange={(val) => updateControlValue(true, setPCV201SetPointSelected, val.value)}
                                />
                            </Col>
                        </Row>
                    </div>
                </Accordion>
            )}
            <Accordion title={intl.formatMessage({id: 'label.EndCriteria'})}>
                <div style={{minHeight: '200px'}}>
                    <Row>
                        <Col>
                            <Dropdown
                                label={intl.formatMessage({id: 'label.EndCriteria'})}
                                options={EndCriteriaDropdownValues}
                                value={EndCriteriaDropdownSelected.id}
                                onSubmit={(val: any) => updateDropdownValue(setEndCriteriaDropdownSelected, val, [setTimeCriteria, setsuperiorWI001Threshold])}
                            />
                        </Col>
                        <Col>
                            {generateEndCriteriaFields()}
                        </Col>
                    </Row>
                </div>
            </Accordion>
            <br/>
        </MacroStep>
    )
}

export default SPTFFConcentration
