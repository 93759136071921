import {Col, Container, Row} from "reactstrap";
import React, {useState} from "react";
import {IntlShape, useIntl} from "react-intl";
import {Button, Glyph, Dropdown, Checkbox, CircularProgressBar} from '@liquid-design/liquid-design-react'
import {useDispatch, useSelector} from "react-redux";
import {IConfigReducer} from "../../../store/reducers/configReducer";
import StoreTypes from "StoreTypes";
import _ from "lodash";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {AnalogueSensorHam} from "../../../models/PLC/AnalogueSensorHam";
import {
    CreateOpcValue,
    IsEmptyOrNullOrUndefined,
    IsNullOrUndefined,
    setPlcPropertyAsync
} from "../../../utils/plcUtils";
import {Label} from "@progress/kendo-react-labels";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Stepper} from "@progress/kendo-react-layout";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {apiOpcValue} from "../../../models/Api/apiOpcValue";
import {
    getDigitFormat,
    getDigitNumber,
    getSensorDeadBand,
    getSensorDefaultDeadBand,
    IPanelOptions
} from "../ControlPanels/settingsHelpers";
import SwitchSelector from "../../SwitchSelector";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";

export interface IHamiltonSensorCalibrationProps {
    nodeId: string
}

enum CalibrationType {
    None,
    Standard,
    Product
}

enum OverallStatus {
    ProductCalibrationActive,
    StandardCalibrationActive,
    RestoringProductCalibration,
    RestoringStandardCalibration,
    UpdatingCalibrationData
}

enum StandardCalibrationStatus {
    //Common statuses and step 1 status
    StandardCalibrationStarted = 1 << 0,
    StepTwoStatus = 1 << 2,
    StepThreeStatus = 1 << 3,
    StabilityTimerRunning = 1 << 4,
    ProductCalibrationRestored = 1 << 9,
    StandardCalibrationFailed = 1 << 11,
    RestoringProductCalibration = 1 << 12,
    UpdatingStandardSelection = 1 << 10,

    //Step 2 status
    StepTwoIncorrectMeasurementUnit = 1 << 13,
    StepTwoOutOfCalibrationRange = 1 << 14,
    StepTwoNoMatchingCalibrationStandard = 1 << 15,
    StepTwoActualTempReadingTooLow = 1 << 16,
    StepTwoActualTempReadingTooHigh = 1 << 17,
    StepTwoTempReadingNotStable = 1 << 18,
    StepTwoCellConstantTooLow = 1 << 19,
    StepTwoCellConstantTooHigh = 1 << 20,
    StepTwoPhReadingNotStable = 1 << 21,
    StepTwoSetPointCalibrationSuccessful = 1 << 7,
    StepTwoSetPointCalibrationInProgress = 1 << 5,

    //Step 3 status
    StepThreeIncorrectMeasurementUnit = 1 << 22,
    StepThreeOutOfCalibrationRange = 1 << 23,
    StepThreeNoMatchingCalibrationStandard = 1 << 24,
    StepThreeActualTempReadingTooLow = 1 << 25,
    StepThreeActualTempReadingTooHigh = 1 << 26,
    StepThreeTempReadingNotStable = 1 << 27,
    StepThreeCellConstantTooLow = 1 << 28,
    StepThreeCellConstantTooHigh = 1 << 29,
    StepThreePhReadingNotStable = 1 << 30,
    StepThreeSetPointCalibrationSuccessful = 1 << 8,
    StepThreeSetPointCalibrationInProgress = 1 << 6
}

enum ProductCalibrationStatus {
    //Common & Step-1 Status
    ProductCalibrationStarted = 1 << 0,
    StepTwoStatus = 1 << 2,
    StepThreeStatus = 1 << 3,
    ReadyForProductCalibration = 1 << 4,
    ProductCalibrationActive = 1 << 8,
    StandardCalibrationRestored = 1 << 9,
    ProductCalibrationFailed = 1 << 11,
    RestoringStandardCalibration = 1 << 12,
    ReadingInitialMeasurement = 1 << 5,
    InitialMeasurementSuccessful = 1 << 7,

    //Step-2 Status
    OutOfCalibrationRange = 1 << 26,
    OutOfRange = 1 << 27,
    ProductCalibrationInProgress = 1 << 6,
    ProductCalibrationSuccessful = 1 << 10,
    UpdatingCalibrationData = 1 << 25,
    CP6_Active = 1 << 28,
    CP6_InitialMeasurement = 1 << 29,
    CP6_Assigned = 1 << 30
}

function IsBitActivated(wordStatus: number, statusToTest: StandardCalibrationStatus | ProductCalibrationStatus) {
    if (IsNullOrUndefined(wordStatus))
        return false;

    if ((wordStatus & statusToTest) === statusToTest)
        return true;

    return false;
}

interface ICalibrationSetsDefinition {
    name: string,
    id: string
}

//Available calibration standard list for StandardCalibration (PH and Conductivity)
let pHCalibrationSets: ICalibrationSetsDefinition[] = [
    {name: 'Hamilton', id: '1'},
    {name: 'MerckTitrisol', id: '2'},
    {name: 'DIN19267', id: '4'},
    {name: 'NISTStandard', id: '8'},
    {name: 'MettlerToledo', id: '16'},
    {name: 'Radiometer', id: '32'},
];

let conductivityCalibrationSets: ICalibrationSetsDefinition[] = [
    {name: 'Hamilton', id: '1'},
    {name: 'Reagecon', id: '2'},
    {name: 'KCISolutions', id: '4'}
];

interface ICheckBoxDefinition {
    id: number,
    label: string
}

//pH Calibration available standards checkboxes
const pHCalibrationStandards: ICheckBoxDefinition[] = [
    {id: 1 << 0, label: 'label.PHCalibrationTemplate1'},
    {id: 1 << 1, label: 'label.PHCalibrationTemplate2'},
    {id: 1 << 2, label: 'label.PHCalibrationTemplate3'},
    {id: 1 << 3, label: 'label.PHCalibrationTemplate4'},
    {id: 1 << 4, label: 'label.PHCalibrationTemplate5'},
    {id: 1 << 5, label: 'label.PHCalibrationTemplate6'},
    {id: 1 << 6, label: 'label.PHCalibrationTemplate7'},
    {id: 1 << 7, label: 'label.PHCalibrationTemplate8'},
    {id: 1 << 8, label: 'label.PHCalibrationTemplate9'},
    {id: 1 << 9, label: 'label.PHCalibrationTemplate10'},
    {id: 1 << 10, label: 'label.PHCalibrationTemplate11'},
    {id: 1 << 11, label: 'label.PHCalibrationTemplate12'},
    {id: 1 << 16, label: 'label.PHCalibrationTemplate1'},
    {id: 1 << 17, label: 'label.PHCalibrationTemplate2'},
    {id: 1 << 18, label: 'label.PHCalibrationTemplate3'},
    {id: 1 << 19, label: 'label.PHCalibrationTemplate4'},
    {id: 1 << 20, label: 'label.PHCalibrationTemplate5'},
    {id: 1 << 21, label: 'label.PHCalibrationTemplate6'},
    {id: 1 << 22, label: 'label.PHCalibrationTemplate7'},
    {id: 1 << 23, label: 'label.PHCalibrationTemplate8'},
    {id: 1 << 24, label: 'label.PHCalibrationTemplate9'},
    {id: 1 << 25, label: 'label.PHCalibrationTemplate10'},
    {id: 1 << 26, label: 'label.PHCalibrationTemplate11'},
    {id: 1 << 27, label: 'label.PHCalibrationTemplate12'}
];

//Conductivity Calibration available standards checkboxes
const conductivityCalibrationStandards: ICheckBoxDefinition[] = [
    {id: 1 << 0, label: 'label.ConductivityCalibrationTemplate1'},
    {id: 1 << 1, label: 'label.ConductivityCalibrationTemplate2'},
    {id: 1 << 2, label: 'label.ConductivityCalibrationTemplate3'},
    {id: 1 << 3, label: 'label.ConductivityCalibrationTemplate4'},
    {id: 1 << 4, label: 'label.ConductivityCalibrationTemplate5'},
    {id: 1 << 5, label: 'label.ConductivityCalibrationTemplate6'},
    {id: 1 << 6, label: 'label.ConductivityCalibrationTemplate7'},
    {id: 1 << 7, label: 'label.ConductivityCalibrationTemplate8'},
    {id: 1 << 8, label: 'label.ConductivityCalibrationTemplate9'},
    {id: 1 << 9, label: 'label.ConductivityCalibrationTemplate10'},
    {id: 1 << 10, label: 'label.ConductivityCalibrationTemplate11'},
    {id: 1 << 11, label: 'label.ConductivityCalibrationTemplate12'},
    {id: 1 << 16, label: 'label.ConductivityCalibrationTemplate1'},
    {id: 1 << 17, label: 'label.ConductivityCalibrationTemplate2'},
    {id: 1 << 18, label: 'label.ConductivityCalibrationTemplate3'},
    {id: 1 << 19, label: 'label.ConductivityCalibrationTemplate4'},
    {id: 1 << 20, label: 'label.ConductivityCalibrationTemplate5'},
    {id: 1 << 21, label: 'label.ConductivityCalibrationTemplate6'},
    {id: 1 << 22, label: 'label.ConductivityCalibrationTemplate7'},
    {id: 1 << 23, label: 'label.ConductivityCalibrationTemplate8'},
    {id: 1 << 24, label: 'label.ConductivityCalibrationTemplate9'},
    {id: 1 << 25, label: 'label.ConductivityCalibrationTemplate10'},
    {id: 1 << 26, label: 'label.ConductivityCalibrationTemplate11'},
    {id: 1 << 27, label: 'label.ConductivityCalibrationTemplate12'}
];

/**
 * Get string associated with the current product calibration Status
 * @param sensor
 * @param stepId
 */
function getProductStatusText(sensor: AnalogueSensorHam, stepId: number) {
    let wordStatus = sensor.Sts_wCP6CalibrationStatus;

    if (stepId === 2 && IsBitActivated(wordStatus, ProductCalibrationStatus.ProductCalibrationFailed)) {
        return 'label.' + ProductCalibrationStatus[ProductCalibrationStatus.ProductCalibrationFailed]
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.ReadingInitialMeasurement)) {
        return 'label.' + ProductCalibrationStatus[ProductCalibrationStatus.ReadingInitialMeasurement]
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.InitialMeasurementSuccessful)) {
        return 'label.' + ProductCalibrationStatus[ProductCalibrationStatus.InitialMeasurementSuccessful]
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.ProductCalibrationInProgress)) {
        return 'label.' + ProductCalibrationStatus[ProductCalibrationStatus.ProductCalibrationInProgress]
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.OutOfRange)) {
        return 'label.' + ProductCalibrationStatus[ProductCalibrationStatus.OutOfRange]
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.OutOfCalibrationRange)) {
        return 'label.' + ProductCalibrationStatus[ProductCalibrationStatus.OutOfCalibrationRange]
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.ProductCalibrationFailed)) {
        return 'label.' + ProductCalibrationStatus[ProductCalibrationStatus.ProductCalibrationFailed]
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.ProductCalibrationSuccessful)) {
        return 'label.' + ProductCalibrationStatus[ProductCalibrationStatus.ProductCalibrationSuccessful]
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.ReadyForProductCalibration)) {
        return 'label.' + ProductCalibrationStatus[ProductCalibrationStatus.ReadyForProductCalibration]
    }

    return "UnknownStatus";
}

/**
 * Return dot color depending on the current product calibration status
 * @param sensor
 * @param stepId
 */
function getProductStatusColor(sensor: AnalogueSensorHam, stepId: number) {
    let wordStatus = sensor.Sts_wCP6CalibrationStatus;

    if (stepId === 2 && IsBitActivated(wordStatus, ProductCalibrationStatus.ProductCalibrationFailed)) {
        return '#ec3030'
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.ReadingInitialMeasurement)) {
        return '#0567b7'
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.InitialMeasurementSuccessful)) {
        return '#52c626'
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.ProductCalibrationInProgress)) {
        return '#0567b7'
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.OutOfRange)) {
        return '#ec3030'
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.OutOfCalibrationRange)) {
        return '#ec3030'
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.ProductCalibrationFailed)) {
        return '#ec3030'
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.ProductCalibrationSuccessful)) {
        return '#52c626'
    }

    if (IsBitActivated(wordStatus, ProductCalibrationStatus.ReadyForProductCalibration)) {
        return '#0567b7'
    }

    return '#0567b7'
}

/**
 * Get string associated with the current standard calibration Status
 * @param sensor
 * @param stepId
 */
function getStandardStatusText(sensor: AnalogueSensorHam, stepId: number) {

    let wordStatus = sensor.Sts_wSTDCalibrationStatus;
    // Standard Calibration Failed
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StandardCalibrationFailed)) {
        return 'label.' + StandardCalibrationStatus[StandardCalibrationStatus.StandardCalibrationFailed]
    }

    // Standard Selection In Progress
    if (wordStatus === 0 && sensor.Sts_bSTDSelectionInProgress) {
        return 'label.StandardSelectionInProgress'
    }

    // Setpoint-2 Calibration In Progress
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeSetPointCalibrationInProgress)) {
        return 'label.' + StandardCalibrationStatus[StandardCalibrationStatus.StepThreeSetPointCalibrationInProgress]
    }

    // Setpoint-1 Calibration In Progress
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoSetPointCalibrationInProgress)) {
        return 'label.' + StandardCalibrationStatus[StandardCalibrationStatus.StepTwoSetPointCalibrationInProgress]
    }

    // Updating Standard Selection
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.UpdatingStandardSelection)) {
        return 'label.' + StandardCalibrationStatus[StandardCalibrationStatus.UpdatingStandardSelection]
    }

    // Setpoint-2 Calibration Successful
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeSetPointCalibrationSuccessful)) {
        return 'label.' + StandardCalibrationStatus[StandardCalibrationStatus.StepThreeSetPointCalibrationSuccessful]
    }

    // Setpoint-1 Calibration Successful
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoSetPointCalibrationSuccessful)) {
        return 'label.' + StandardCalibrationStatus[StandardCalibrationStatus.StepTwoSetPointCalibrationSuccessful]
    }

    // Diff. between CP1 & CP2 < pH 1.0
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoOutOfCalibrationRange) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeOutOfCalibrationRange)) {
        return 'label.DiffBetweenCp1AndCP2TooLow'
    }

    // No Matching Calibration Standard
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoNoMatchingCalibrationStandard) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeNoMatchingCalibrationStandard)) {
        return 'label.NoMatchingCalibrationStandard'
    }

    // Actual Temp. Reading Is Too Low
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoActualTempReadingTooLow) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeActualTempReadingTooLow)) {
        return 'label.CurrentTempTooLow'
    }

    // Actual Temp. Reading Is Too High
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoActualTempReadingTooHigh) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeActualTempReadingTooHigh)) {
        return 'label.CurrentTempTooHigh'
    }

    // Temp. Reading Is Not Stable
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoTempReadingNotStable) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeTempReadingNotStable)) {
        return 'label.CurrentTempNotStable'
    }

    // Offset at pH 7 or Slope is Too Low
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoCellConstantTooLow) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeCellConstantTooLow)) {
        if (sensor.Values[0].Cfg_suSensorUnit.Name === "PH")
            return 'label.OffsetAtSevenOrSlopeTooLow'

        return 'label.CellConstantTooLow'
    }

    // Offset at pH 7 or Slope is Too High
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoCellConstantTooHigh) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeCellConstantTooHigh)) {

        if (sensor.Values[0].Cfg_suSensorUnit.Name === "PH")
            return 'label.OffsetAtSevenOrSlopeTooHigh'

        return 'label.CellConstantTooHigh'
    }

    // pH Reading Is Not Stable
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoPhReadingNotStable) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreePhReadingNotStable)) {
        if (sensor.Values[0].Cfg_suSensorUnit.Name === "PH")
            return 'label.PHReadingNotStable'

        return 'label.ConductivityReadingNotStable'
    }

    // Incorrect Measurement Unit
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoIncorrectMeasurementUnit) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeIncorrectMeasurementUnit)) {
        return 'label.IncorrectMeasurementUnit'
    }

    // Ready
    if (wordStatus === 0) {
        return 'label.ReadyForStandardCalibration'
    }

    return "UnknownStatus :" + wordStatus;
}

function getStandardStatusColor(sensor: AnalogueSensorHam, stepId: number) {

    let wordStatus = sensor.Sts_wSTDCalibrationStatus;
    // Standard Calibration Failed
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StandardCalibrationFailed)) {
        return '#ec3030'
    }

    // Standard Selection In Progress
    if (wordStatus === 0 && sensor.Sts_bSTDSelectionInProgress) {
        return '#0567b7'
    }

    // Setpoint-2 Calibration In Progress
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeSetPointCalibrationInProgress)) {
        return '#0567b7'
    }

    // Setpoint-1 Calibration In Progress
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoSetPointCalibrationInProgress)) {
        return '#0567b7'
    }

    // Updating Standard Selection
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.UpdatingStandardSelection)) {
        return '#0567b7'
    }

    // Setpoint-2 Calibration Successful
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeSetPointCalibrationSuccessful)) {
        return '#52c626'
    }

    // Setpoint-1 Calibration Successful
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoSetPointCalibrationSuccessful)) {
        return '#52c626'
    }

    // Diff. between CP1 & CP2 < pH 1.0
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoOutOfCalibrationRange) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeOutOfCalibrationRange)) {
        return '#ec3030'
    }

    // No Matching Calibration Standard
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoNoMatchingCalibrationStandard) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeNoMatchingCalibrationStandard)) {
        return '#ec3030'
    }

    // Actual Temp. Reading Is Too Low
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoActualTempReadingTooLow) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeActualTempReadingTooLow)) {
        return '#ec3030'
    }

    // Actual Temp. Reading Is Too High
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoActualTempReadingTooHigh) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeActualTempReadingTooHigh)) {
        return '#ec3030'
    }

    // Temp. Reading Is Not Stable
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoTempReadingNotStable) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeTempReadingNotStable)) {
        return '#ec3030'
    }

    // Offset at pH 7 or Slope is Too Low
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoCellConstantTooLow) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeCellConstantTooLow)) {

        return '#ec3030'
    }

    // Offset at pH 7 or Slope is Too High
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoCellConstantTooHigh) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeCellConstantTooHigh)) {

        return '#ec3030'
    }

    // pH Reading Is Not Stable
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoPhReadingNotStable) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreePhReadingNotStable)) {
        return '#ec3030'
    }

    // Incorrect Measurement Unit
    if (IsBitActivated(wordStatus, StandardCalibrationStatus.StepTwoIncorrectMeasurementUnit) || IsBitActivated(wordStatus, StandardCalibrationStatus.StepThreeIncorrectMeasurementUnit)) {
        return '#ec3030'
    }

    // Ready
    if (wordStatus === 0) {
        return '#0567b7'
    }

    return '#0567b7'
}

/**
 * Render calibration status Header
 * @param config
 * @param dispatch
 * @param intl
 * @param sensor
 * @param calibrationType
 * @param stepId
 */
function renderCalibrationStatus(config: IConfigReducer, dispatch: any, intl: IntlShape, sensor: AnalogueSensorHam, calibrationType: CalibrationType, stepId: number) {

    let statusString = "";
    let statusColor = "";

    switch (calibrationType) {
        case CalibrationType.Product:
            statusString = intl.formatMessage({id: getProductStatusText(sensor, stepId)});
            statusColor = getProductStatusColor(sensor, stepId);
            break;

        case CalibrationType.Standard:
            statusString = intl.formatMessage({id: getStandardStatusText(sensor, stepId)});
            statusColor = getStandardStatusColor(sensor, stepId)
            break;
    }

    return <>
        <Row style={{paddingTop: '5px'}}>
            <Col>
                <div style={{
                    fontSize: '16px',
                    fontWeight: 'bold'
                }}>
                    {intl.formatMessage({id: 'label.CalibrationStatus'}, {sensor: intl.formatMessage({id: sensor.Values[0].Cfg_suSensorUnit.Category})})}
                </div>
                <div>
                    <Glyph name="dot" color={statusColor}/><span
                    style={{
                        fontSize: '19px',
                        fontWeight: 'bold',
                        verticalAlign: 'text-bottom'
                    }}>&nbsp;{statusString}</span>
                </div>
            </Col>
        </Row>
    </>
}

/**
 * Renders Product calibration fields
 * @param config
 * @param dispatch
 * @param intl
 * @param sensor
 * @param showReadButton
 * @param disableMeasuredValue
 */
function renderProductCalibrationFields(config: IConfigReducer, dispatch: any, intl: IntlShape, sensor: AnalogueSensorHam, showReadButton: boolean, disableMeasuredValue: boolean) {
    let deadBand = getSensorDeadBand(sensor, 'Out_rCurrentValue', sensor.Out_rCurrentValue)
    return <>
        <Row style={{paddingTop: '15px'}}>
            <Col>
                <Label
                    editorId='CurrentSensorValue'>{intl.formatMessage({id: 'label.InitialValue'})}</Label>
                <div id='CurrentSensorValue' style={{fontWeight: 'bold', fontSize: '19px'}}>
                    {sensor.Out_rCP6Coefficient1.toFixed(getDigitNumber(sensor.Out_rCP6Coefficient1, deadBand)) + ' ' + sensor.Values[0].Cfg_suSensorUnit.Unit}
                </div>
            </Col>
            <Col>
                <Label
                    editorId='SensorMillivolt'>{intl.formatMessage({id: 'label.InitialValue'})}</Label>
                <div id='SensorMillivolt' style={{fontWeight: 'bold', fontSize: '19px'}}>
                    {sensor.Out_rCP6Coefficient2.toFixed(getDigitNumber(sensor.Out_rCP6Coefficient2, deadBand)) + ' mV'}
                </div>
            </Col>
        </Row>
        {!disableMeasuredValue && <Row style={{paddingTop: '15px'}}>
            <Col>
                <Label
                    editorId='measuredValue'>{intl.formatMessage({id: 'label.MeasuredValue'})}</Label>
                <br/>
                <NumericTextBox
                    id='measuredValue'
                    format={"0" + getDigitFormat(sensor.Set_rCP6CalibrationValue, deadBand) + " " + sensor.Values[0].Cfg_suSensorUnit.Unit}
                    min={sensor.Out_rPMC1Min}
                    max={sensor.Out_rPMC1Max}
                    step={1}
                    spinners={false}
                    value={sensor.Set_rCP6CalibrationValue}
                    onChange={async (e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            try {
                                let opcValue = CreateOpcValue(sensor.NodeId + '.Set_rCP6CalibrationValue', e.value);
                                await setPlcPropertyAsync(config, opcValue, {
                                    userLabel: 'label.event.ProductCalibrationValueSet',
                                    unit: sensor.Values[0].Cfg_suSensorUnit.Unit,
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            } catch (error) {
                                //TODO: Add better error handling !
                                alert(error.message);
                            }
                        }
                    }}
                />
                <Button style={{marginLeft: '15px'}}
                        disabled={IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.ProductCalibrationInProgress) || IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.ProductCalibrationFailed) || IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.ProductCalibrationSuccessful)}
                        onClick={async () => {
                            try {
                                //Sending data to PLC
                                let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bCP6CalibrationValue', true);
                                await setPlcPropertyAsync(config, opcValue, {
                                    userLabel: 'label.event.CalibrationValueSubmitted',
                                    unit: '',
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);

                            } catch (error) {
                                //TODO: Add better error handling !
                                alert(error.message);
                            }
                        }}>{intl.formatMessage({id: 'label.Submit'})}</Button>
            </Col>
        </Row>}
        {showReadButton && <Row style={{paddingTop: '15px'}}>
            <Col>
                <Button
                    disabled={IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.ReadingInitialMeasurement)}
                    onClick={async () => {
                        try {
                            //Sending data to PLC
                            let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bInitiateCP6Calibration', true);
                            await setPlcPropertyAsync(config, opcValue, undefined, dispatch);

                        } catch (error) {
                            //TODO: Add better error handling !
                            alert(error.message);
                        }
                    }}>{intl.formatMessage({id: 'label.Read'})}</Button>&nbsp;<span
                style={{fontSize: '16px'}}>{intl.formatDate(sensor.Sts_wCP6InitialMeasurementDT * 1000)} - {intl.formatTime(sensor.Sts_wCP6InitialMeasurementDT * 1000)}</span>
            </Col>
        </Row>}
    </>
}

/**
 * Render current calibration step content
 * @param config
 * @param dispatch
 * @param intl
 * @param sensor
 * @param stepId
 */
function renderCurrentStepContentForProductCalibration(config: IConfigReducer, dispatch: any, intl: IntlShape, sensor: AnalogueSensorHam, stepId: number) {

    switch (stepId) {
        case 0 :
            return <Container>
                {renderCalibrationStatus(config, dispatch, intl, sensor, CalibrationType.Product, stepId)}
                {renderProductCalibrationFields(config, dispatch, intl, sensor, true, true)}
            </Container>
        case 1 :
            return <Container>
                {renderCalibrationStatus(config, dispatch, intl, sensor, CalibrationType.Product, stepId)}
                {renderProductCalibrationFields(config, dispatch, intl, sensor, false, true)}
                <Row style={{paddingTop: '15px'}}>
                    <Col>
                        <div>
                            <Glyph name="dot"/><span
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                verticalAlign: 'text-bottom'
                            }}>&nbsp;{intl.formatMessage({id: 'Draw sample for lab analysis and press "Next"'})}</span>
                        </div>
                    </Col>
                </Row>
            </Container>
        case 2 :
            return <Container>
                {renderCalibrationStatus(config, dispatch, intl, sensor, CalibrationType.Product, stepId)}
                {renderProductCalibrationFields(config, dispatch, intl, sensor, false, false)}
            </Container>
    }

    return <></>
}

/**
 * Renders checkboxes lines for the selected configuration standard
 * @param config
 * @param dispatch
 * @param intl
 * @param sensor
 * @param calibrationStandards
 */
function renderCheckboxesLine(config: IConfigReducer, dispatch: any, intl: IntlShape, sensor: AnalogueSensorHam, calibrationStandards: ICheckBoxDefinition[]) {

    let lines = [];
    let startOffset = 0;
    let modePrefix = '';

    if (sensor.Set_bSTDManualCalibration) {
        startOffset = 0;
        modePrefix = 'Manual';
    } else {
        startOffset = 12;
        modePrefix = 'Auto'
    }

    let lineNumber = calibrationStandards.length / 4;

    for (let i = 0; i < lineNumber; i++) {

        let checkBoxValue1Name: string = 'Out_rCalibrationSetDef' + (i + 1) + modePrefix + 'NV';
        let checkBoxValue2Name: string = 'Out_rCalibrationSetDef' + (i + 1 + lineNumber) + modePrefix + 'NV';

        let checkBoxValue1 = sensor[checkBoxValue1Name as keyof AnalogueSensorHam];
        let checkBoxValue2 = sensor[checkBoxValue2Name as keyof AnalogueSensorHam];

        lines.push(<Row key={i}>
            <Col>
                {!IsEmptyOrNullOrUndefined(calibrationStandards[i + startOffset].label) &&
                <Checkbox key={i}
                          isChecked={(sensor.Set_wSelectedCalibrationSets & calibrationStandards[i + startOffset].id) === calibrationStandards[i + startOffset].id}
                          label={intl.formatMessage({id: calibrationStandards[i + startOffset].label},
                              {
                                  mode: sensor.Set_bSTDManualCalibration ? intl.formatMessage({id: 'label.Manual'}).toLowerCase() : intl.formatMessage({id: 'label.Automatic'}).toLowerCase(),
                                  value: checkBoxValue1
                              })}
                          onChange={async () => {
                              try {
                                  console.log("Val before:", sensor.Set_wSelectedCalibrationSets.toString(2));
                                  //Set the right bit in the array set
                                  let val = sensor.Set_wSelectedCalibrationSets ^= calibrationStandards[i + startOffset].id;
                                  console.log("New value :", val.toString(2));

                                  //Sending data to PLC
                                  let opcValue = CreateOpcValue(sensor.NodeId + '.Set_wSelectedCalibrationSets', val);
                                  await setPlcPropertyAsync(config, opcValue, {
                                      userLabel: 'label.event.SelectedCalibrationSetsModified',
                                      unit: '',
                                      eventTriggering: UserEventTriggering.BeforeAction
                                  }, dispatch);

                              } catch (error) {
                                  //TODO: Add better error handling !
                                  alert(error.message);
                              }
                          }}/>}
            </Col>
            <Col>
                {!IsEmptyOrNullOrUndefined(calibrationStandards[i + lineNumber + startOffset].label) &&
                <Checkbox key={i + lineNumber}
                          isChecked={(sensor.Set_wSelectedCalibrationSets & calibrationStandards[i + lineNumber + startOffset].id) === calibrationStandards[i + lineNumber + startOffset].id}
                          label={intl.formatMessage({id: calibrationStandards[i + lineNumber + +startOffset].label},
                              {
                                  mode: sensor.Set_bSTDManualCalibration ? intl.formatMessage({id: 'label.Manual'}).toLowerCase() : intl.formatMessage({id: 'label.Automatic'}).toLowerCase(),
                                  value: checkBoxValue2
                              })}
                          onChange={async () => {
                              try {
                                   console.log("Val before:", sensor.Set_wSelectedCalibrationSets.toString(2));
                                  //Set the right bit in the array set
                                  let val = sensor.Set_wSelectedCalibrationSets ^= calibrationStandards[i + lineNumber + startOffset].id;
                                   console.log("New value :", val.toString(2));

                                  //Sending data to PLC
                                  let opcValue = CreateOpcValue(sensor.NodeId + '.Set_wSelectedCalibrationSets', val);
                                  await setPlcPropertyAsync(config, opcValue, {
                                      userLabel: 'label.event.SelectedCalibrationSetsModified',
                                      unit: '',
                                      eventTriggering: UserEventTriggering.BeforeAction
                                  }, dispatch);

                              } catch (error) {
                                  //TODO: Add better error handling !
                                  alert(error.message);
                              }
                          }}/>}
            </Col>
        </Row>)
    }

    return lines;
}

/**
 * Renders first tab of the Standard Calibration process
 * @param config
 * @param dispatch
 * @param intl
 * @param sensor
 * @param standardCalibrationModeOptions
 */
function renderStandardCalibrationFirstStep(config: IConfigReducer, dispatch: any, intl: IntlShape, sensor: AnalogueSensorHam, standardCalibrationModeOptions: IPanelOptions[]) {

    const modeInitialSelectedIndex = standardCalibrationModeOptions.findIndex(({value}) => value === sensor.Set_bSTDManualCalibration);

    let calibrationCheckboxes: ICheckBoxDefinition[];
    let calibrationSets: ICalibrationSetsDefinition[];

    let sensorUnit = sensor.Values[0].Cfg_suSensorUnit.Unit;
    let deadBand = getSensorDefaultDeadBand(sensor.Values[0].Cfg_dDeadBands)
    const stabilityFactorUnit = "%/min";

    if (sensor.Values[0].Cfg_suSensorUnit.Name === "PH") {
        calibrationCheckboxes = pHCalibrationStandards;
        calibrationSets = pHCalibrationSets;
    } else {
        sensorUnit = "μS/cm"; //For conductivity, calibration is always made in μS/cm
        deadBand = 1
        calibrationCheckboxes = conductivityCalibrationStandards;
        calibrationSets = conductivityCalibrationSets;
    }

    return <>
        <Row style={{paddingTop: '15px'}}>
            <Col xs="8">
                <Dropdown
                    id='CalibrationSet'
                    options={calibrationSets}
                    value={sensor.Set_wSelectedCalibration.toString()}
                    onSubmit={async (selectedOption: any) => {
                        try {
                            //Sending data to PLC
                            let opcValue = CreateOpcValue(sensor.NodeId + '.Set_wSelectedCalibration', parseInt(selectedOption.id));
                            await setPlcPropertyAsync(config, opcValue, {
                                userLabel: 'label.event.SelectedCalibrationSetChosen',
                                unit: '',
                                eventTriggering: UserEventTriggering.BeforeAction
                            }, dispatch);

                        } catch (error) {
                            //TODO: Add better error handling !
                            alert(error.message);
                        }
                    }}
                    disabled={sensor.Sts_bSTDSelectionInProgress}
                />
            </Col>
            <Col xs="4" style={{height: '35px'}}>
                <SwitchSelector
                    onChange={async () => {
                        try {
                            //Sending data to PLC
                            const newValue = !sensor.Set_bSTDManualCalibration;
                            let opcValue = CreateOpcValue(sensor.NodeId + '.Set_bSTDManualCalibration', newValue);

                            let eventInfo = {
                                userLabel: newValue ? 'label.event.ManualCalibrationModeSet' : 'label.event.AutoCalibrationModeSet',
                                unit: '',
                                eventTriggering: UserEventTriggering.BeforeAction
                            }

                            await setPlcPropertyAsync(config, opcValue, eventInfo, dispatch);

                        } catch (error) {
                            //TODO: Add better error handling !
                            alert(error.message);
                        }
                    }}
                    options={standardCalibrationModeOptions}
                    fontSize={16}
                    initialSelectedIndex={modeInitialSelectedIndex}
                    forcedSelectedIndex={modeInitialSelectedIndex}
                />
            </Col>
        </Row>
        <Row style={{paddingTop: '15px'}}>
            <Col xs="8">
                <Container>
                    {!sensor.Sts_bSTDSelectionInProgress && renderCheckboxesLine(config, dispatch, intl, sensor, calibrationCheckboxes)}
                </Container>
            </Col>
            <Col xs="4">
                <Label
                    editorId='SetPointOne'>{intl.formatMessage({id: 'label.ReferenceValueOne'})}</Label>
                <br/>
                <NumericTextBox
                    id='SetPointOne'
                    format={"0" + getDigitFormat(sensor.Set_rSTDCalibrationValue1, deadBand) + " " + sensorUnit}
                    min={sensor.Out_rPMC1Min}
                    max={sensor.Out_rPMC1Max}
                    step={1}
                    disabled={!sensor.Set_bSTDManualCalibration}
                    spinners={false}
                    value={sensor.Set_rSTDCalibrationValue1}
                    onChange={async (e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            try {
                                let opcValue = CreateOpcValue(sensor.NodeId + '.Set_rSTDCalibrationValue1', e.value);
                                await setPlcPropertyAsync(config, opcValue, {
                                    userLabel: 'label.event.StandardCalibrationValueOneSet',
                                    unit: sensorUnit,
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            } catch (error) {
                                //TODO: Add better error handling !
                                alert(error.message);
                            }
                        }
                    }}
                />
                <br/>
                <Label
                    editorId='SetPointTwo'>{intl.formatMessage({id: 'label.ReferenceValueTwo'})}</Label>
                <br/>
                <NumericTextBox
                    id='SetPointTwo'
                    format={"0" + getDigitFormat(sensor.Set_rSTDCalibrationValue2, deadBand) + " " + sensorUnit}
                    min={sensor.Out_rPMC1Min}
                    max={sensor.Out_rPMC1Max}
                    step={1}
                    disabled={!sensor.Set_bSTDManualCalibration}
                    spinners={false}
                    value={sensor.Set_rSTDCalibrationValue2}
                    onChange={async (e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            try {
                                let opcValue = CreateOpcValue(sensor.NodeId + '.Set_rSTDCalibrationValue2', e.value);
                                await setPlcPropertyAsync(config, opcValue, {
                                    userLabel: 'label.event.StandardCalibrationValueTwoSet',
                                    unit: sensorUnit,
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            } catch (error) {
                                //TODO: Add better error handling !
                                alert(error.message);
                            }
                        }
                    }}
                />
                <br/>
                <Label
                    editorId='StabilityFactor'>{intl.formatMessage({id: 'label.CalibrationStabilityFactor'})}</Label>
                <br/>
                <NumericTextBox
                    id='StabilityFactor'
                    format={"0" + getDigitFormat(sensor.Set_rPMC1StabilityFactor, deadBand) + " " + stabilityFactorUnit}
                    min={0}
                    max={sensor.Out_rMaxPMC1StabilityFactor}
                    step={1}
                    disabled={!sensor.Set_bSTDManualCalibration}
                    spinners={false}
                    value={sensor.Set_rPMC1StabilityFactor}
                    onChange={async (e) => {
                        //Propagate change on Enter button press
                        if (e.nativeEvent.key === "Enter") {
                            try {
                                let opcValue = CreateOpcValue(sensor.NodeId + '.Set_rPMC1StabilityFactor', e.value);
                                await setPlcPropertyAsync(config, opcValue, {
                                    userLabel: 'label.event.StabilityFactorSet',
                                    unit: stabilityFactorUnit,
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            } catch (error) {
                                //TODO: Add better error handling !
                                alert(error.message);
                            }
                        }
                    }}
                />
            </Col>
        </Row>
    </>
}

/**
 *
 * @param config
 * @param dispatch
 * @param intl
 * @param sensor
 */
function renderStandardCalibrationSetPointProcessing(config: IConfigReducer, dispatch: any, intl: IntlShape, sensor: AnalogueSensorHam, stepId: number) {

    let timer = sensor.Sts_wStabilityTimerET / sensor.Sts_wStabilityTimerPT * 100;

    return <>
        <Row style={{paddingTop: '15px'}}>
            <Col>
                <Container>
                    <Row>
                        <Col>
                            <Label
                                editorId='CurrentCalibrationValue'>{intl.formatMessage({id: 'label.CurrentCalibrationValue'})}</Label>
                            <div id='CurrentCalibrationValue' style={{fontWeight: 'bold', fontSize: '19px'}}>
                                {sensor.Out_rCurrentValue.toFixed(getDigitNumber(sensor.Out_rCurrentValue, getSensorDeadBand(sensor, 'Out_rCurrentValue', sensor.Out_rCurrentValue))) + ' ' + sensor.Values[0].Cfg_suSensorUnit.Unit}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label
                                editorId='CurrentCalibrationQuality'>{intl.formatMessage({id: 'label.CurrentQuality'})}</Label>
                            <div id='CurrentCalibrationQuality' style={{fontWeight: 'bold', fontSize: '19px'}}>
                                {sensor.Sts_rSensorQuality.toFixed(getDigitNumber(sensor.Sts_rSensorQuality, 0)) + ' %'}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <Col>
                <div style={{margin: '0 auto', width: 'fit-content'}}>
                    <div style={{
                        fontWeight: 'bold',
                        fontSize: '19px',
                        margin: '0 auto',
                        width: 'fit-content'
                    }}>{sensor.Sts_wStabilityTimerET}/{sensor.Sts_wStabilityTimerPT} s
                    </div>
                    <CircularProgressBar value={parseFloat(timer.toFixed(0))} useThemeColors={true}/>
                    <br/>
                    <Button
                        style={{width: '100%'}}
                        disabled={sensor.Sts_wStabilityTimerET < 30}
                        onClick={async () => {
                            try {
                                //Sending data to PLC
                                let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bSkipStabilityTimer', true);
                                await setPlcPropertyAsync(config, opcValue, {
                                    userLabel: 'label.event.StabilityTimerSkipped',
                                    unit: '',
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);

                            } catch (error) {
                                //TODO: Add better error handling !
                                alert(error.message);
                            }
                        }}>{intl.formatMessage({id: 'label.Skip'})}</Button>
                </div>

            </Col>
        </Row>
    </>
}

/**
 * Renders step cnntent for Standard Calibration Process
 * @param config
 * @param dispatch
 * @param intl
 * @param sensor
 * @param stepId
 * @param standardCalibrationModeOptions
 */
function renderCurrentStepContentForStandardCalibration(config: IConfigReducer, dispatch: any, intl: IntlShape, sensor: AnalogueSensorHam, stepId: number, standardCalibrationModeOptions: IPanelOptions[]) {

    switch (stepId) {
        case 0 :
            return <Container>
                {renderCalibrationStatus(config, dispatch, intl, sensor, CalibrationType.Standard, stepId)}
                {renderStandardCalibrationFirstStep(config, dispatch, intl, sensor, standardCalibrationModeOptions)}
            </Container>
        case 1 :
            return <Container>
                {renderCalibrationStatus(config, dispatch, intl, sensor, CalibrationType.Standard, stepId)}
                {renderStandardCalibrationSetPointProcessing(config, dispatch, intl, sensor, stepId)}
            </Container>
        case 2 :
            return <Container>
                {renderCalibrationStatus(config, dispatch, intl, sensor, CalibrationType.Standard, stepId)}
                {renderStandardCalibrationSetPointProcessing(config, dispatch, intl, sensor, stepId)}
            </Container>
    }

    return <></>
}

/**
 * HamiltonSensorCalibration component
 * @param nodeId
 * @constructor
 */
export default function HamiltonSensorCalibration({nodeId}: IHamiltonSensorCalibrationProps) {

    const intl = useIntl();
    const dispatch = useDispatch();
    const [dialogOpen, openDialog] = useState(false);
    const [confirmationDialogOpen, openConfirmationDialog] = useState(false);
    const [calibrationType, setCalibrationType] = useState(CalibrationType.None);
    const [currentStep, setCurrentStep] = React.useState(0);

    const standardCalibrationModeOptions: IPanelOptions[] = [
        {
            label: intl.formatMessage({id: 'label.Automatic'}),
            value: false,
            selectedBackgroundColor: "#0F69AF",
        },
        {
            label: intl.formatMessage({id: 'label.Manual'}),
            value: true,
            selectedBackgroundColor: "#0F69AF"
        }
    ];

    const calibrationSteps = [
        {label: intl.formatMessage({id: 'label.CalibrationStart'})},
        {label: intl.formatMessage({id: 'label.CalibrationStepOne'})},
        {label: intl.formatMessage({id: 'label.CalibrationStepTwo'})}
    ];

    //Getting information from store
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config);

    //Get reference to the sensor
    const sensor: AnalogueSensorHam = _.find(config.Instances.ControlModules, (item: ControlModuleBase) => {
        return item.NodeId === nodeId;
    }) as AnalogueSensorHam;

    async function openCalibrationDialog(calibrationType: CalibrationType) {
        setCalibrationType(calibrationType);

        try {
            //Sending data to PLC
            let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bCalibrationMode', true);
            await setPlcPropertyAsync(config, opcValue, {
                userLabel: 'label.event.CalibrationStarted',
                unit: '',
                eventTriggering: UserEventTriggering.BeforeAction
            }, dispatch);

        } catch (error) {
            //TODO: Add better error handling !
            alert(error.message);
        }

        openDialog(true);
    }

    /**
     * Determines if the Next button is disabled or not
     */
    function isNextButtonDisabled() {

        if (calibrationType === CalibrationType.Product) {
            if (currentStep === 0 && !IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.InitialMeasurementSuccessful)) {
                return true;
            }
        }

        if (calibrationType === CalibrationType.Standard) {
            if (currentStep === 0 && sensor.Sts_bSTDSelectionInProgress) {
                return true;
            }

            if (currentStep === 1 && !IsBitActivated(sensor.Sts_wSTDCalibrationStatus, StandardCalibrationStatus.StepTwoSetPointCalibrationSuccessful)) {
                return true
            }
        }

        return false;
    }

    /**
     * Continue button for the dialog "Put the sensor in the solution" for standard calibration
     */
    const onContinue = async () => {

        if (currentStep === 0) {
            let opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bInitiateSTDCalibration', true);
            await setPlcPropertyAsync(config, opcValue, {
                userLabel: 'label.event.StandardCalibrationStarted',
                unit: '',
                eventTriggering: UserEventTriggering.BeforeAction
            }, dispatch);
        }

        if (currentStep === 1) {
            let opcValue = CreateOpcValue(sensor.NodeId + '.Set_wSTDCalibrationSteps', 9);
            await setPlcPropertyAsync(config, opcValue, {
                userLabel: 'label.event.StandardCalibrationStepSet',
                unit: '',
                eventTriggering: UserEventTriggering.BeforeAction
            }, dispatch);
        }

        setCurrentStep(currentStep + 1);
        openConfirmationDialog(false);
    };

    /**
     * Next button click handler
     */
    const onNextStep = async () => {
        if (calibrationType === CalibrationType.Standard) {
            openConfirmationDialog(true)
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    /**
     * Determines if the Exit button is disabled or not
     */
    function isExitButtonDisabled() {
        if (calibrationType === CalibrationType.Product) {
            return !IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.ReadyForProductCalibration);
        }

        if (calibrationType === CalibrationType.Standard) {
            if (currentStep === 0 && sensor.Sts_bSTDSelectionInProgress) {
                return true;
            }

            if (currentStep === 1 && (IsBitActivated(sensor.Sts_wSTDCalibrationStatus, StandardCalibrationStatus.UpdatingStandardSelection) || IsBitActivated(sensor.Sts_wSTDCalibrationStatus, StandardCalibrationStatus.StepTwoSetPointCalibrationInProgress))) {
                return true;
            }

            if (currentStep === 2 && (!IsBitActivated(sensor.Sts_wSTDCalibrationStatus, StandardCalibrationStatus.StepTwoSetPointCalibrationSuccessful) && !IsBitActivated(sensor.Sts_wSTDCalibrationStatus, StandardCalibrationStatus.StandardCalibrationFailed))) {
                return true;
            }
        }

        return false;
    }

    /**
     * Exit button click handler
     */
    const onExit = () => {

        openDialog(false);
        setCurrentStep(0);
    };

    /**
     * Determines if the Finish button is disabled or not
     */
    function isFinishButtonDisabled() {
        if (calibrationType === CalibrationType.Product)
            return !IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.ProductCalibrationFailed) && !IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.ProductCalibrationSuccessful);

        if (calibrationType === CalibrationType.Standard) {
            if (!IsBitActivated(sensor.Sts_wSTDCalibrationStatus, StandardCalibrationStatus.StepThreeSetPointCalibrationSuccessful) && !IsBitActivated(sensor.Sts_wSTDCalibrationStatus, StandardCalibrationStatus.StandardCalibrationFailed)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Finish button click handler
     */
    const onFinish = () => {
        openDialog(false);
        setCurrentStep(0);
    };

    //Get sensor current currentStep, with unit
    let sensorValueString = sensor.Out_rCurrentValue.toFixed(getDigitNumber(sensor.Out_rCurrentValue, getSensorDeadBand(sensor, 'Out_rCurrentValue', sensor.Out_rCurrentValue))).toString() + ' ' + sensor.Values[0].Cfg_suSensorUnit.Unit;

    //Vars for the restore xxxx calibration
    let showRestoreProductCalibrationButton = !IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.ProductCalibrationActive) || IsBitActivated(sensor.Sts_wSTDCalibrationStatus, StandardCalibrationStatus.RestoringProductCalibration)
    let showRestoreStandardCalibrationButton = IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.ProductCalibrationActive) || IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.RestoringStandardCalibration)

    //Calculating overall calibration status
    let overallStatus: OverallStatus;

    if (IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.ProductCalibrationActive)) {
        overallStatus = OverallStatus.ProductCalibrationActive;
    } else if (IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.RestoringStandardCalibration)) {
        overallStatus = OverallStatus.RestoringStandardCalibration;
    } else if (IsBitActivated(sensor.Sts_wSTDCalibrationStatus, StandardCalibrationStatus.RestoringProductCalibration)) {
        overallStatus = OverallStatus.RestoringProductCalibration;
    } else if (IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.UpdatingCalibrationData)) {
        overallStatus = OverallStatus.UpdatingCalibrationData;
    } else {
        overallStatus = OverallStatus.StandardCalibrationActive;
    }

    return <>
        <Container>
            <Row>
                <Col>
                    <div>
                        <span
                            style={{
                                fontSize: '19px',
                                fontWeight: 'bold',
                            }}>{sensor.Sts_wSensorType === 0 ? intl.formatMessage({id: 'label.SensorType'}, {'useType': 'MultiUse'}) : intl.formatMessage({id: 'label.SensorType'}, {'useType': 'SingleUse'})}</span>
                    </div>
                    <div
                        style={{
                            fontSize: '19px',
                            fontWeight: 'bold'
                        }}>{intl.formatMessage({id: 'label.' + OverallStatus[overallStatus]})}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Container>
                        <Row style={{paddingTop: '15px'}}>
                            <Col style={{padding: '0px'}}>
                                <Button
                                    style={{width: '100%'}}
                                    onClick={() => openCalibrationDialog(CalibrationType.Standard)}>{intl.formatMessage({id: 'label.StandardCalibration'})}</Button>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: '15px'}}>
                            <Col style={{padding: '0px'}}>
                                <Button
                                    style={{width: '100%'}}
                                    onClick={() => openCalibrationDialog(CalibrationType.Product)}>{intl.formatMessage({id: 'label.ProductCalibration'})}</Button>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: '15px'}}>
                            <Col style={{padding: '0px'}}>
                                <Button
                                    style={{width: '100%'}}
                                    onClick={async () => {
                                        try {
                                            //Sending data to PLC
                                            let opcValue: apiOpcValue;
                                            let eventInfo = {
                                                userLabel: '',
                                                unit: '',
                                                eventTriggering: UserEventTriggering.BeforeAction
                                            }
                                            if (showRestoreProductCalibrationButton) {
                                                opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bRestoreCP6Calibration', true);
                                                eventInfo.userLabel = 'label.event.ProductCalibrationRestored'
                                            }
                                            if (showRestoreStandardCalibrationButton) {
                                                opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bRestoreSTDCalibration', true);
                                                eventInfo.userLabel = 'label.event.StandardCalibrationRestored'
                                            }
                                            await setPlcPropertyAsync(config, opcValue, eventInfo, dispatch);
                                        } catch (error) {
                                            //TODO: Add better error handling !
                                            alert(error.message);
                                        }
                                    }}>
                                    {showRestoreProductCalibrationButton ? intl.formatMessage({id: 'label.RestoreProductCalibration'}) : ''}
                                    {showRestoreStandardCalibrationButton ? intl.formatMessage({id: 'label.RestoreStandardCalibration'}) : ''}
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: '15px'}}>
                            <Col style={{padding: '0px'}}>
                                <Button
                                    style={{width: '100%'}}
                                    disabled={!IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.CP6_Active) && !IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.CP6_InitialMeasurement) && !IsBitActivated(sensor.Sts_wCP6CalibrationStatus, ProductCalibrationStatus.CP6_Assigned)}
                                    onClick={async () => {
                                        try {
                                            //Sending data to PLC
                                            let opcValue: apiOpcValue;
                                            opcValue = CreateOpcValue(sensor.NodeId + '.Inp_bCancelCP6Calibration', true);

                                            await setPlcPropertyAsync(config, opcValue, {
                                                userLabel: 'label.event.ProductCalibrationCanceled',
                                                unit: '',
                                                eventTriggering: UserEventTriggering.BeforeAction
                                            }, dispatch);
                                        } catch (error) {
                                            //TODO: Add better error handling !
                                            alert(error.message);
                                        }
                                    }}>
                                    {intl.formatMessage({id: 'label.CancelProductCalibration'})}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col>
                    <div style={{margin: '0 auto', width: 'fit-content', paddingBottom: '15px'}}>
                        <Label
                            editorId='CurrentSensorValue'>{intl.formatMessage({id: 'label.CurrentSensorValue'})}</Label>
                        <div id='CurrentSensorValue' style={{fontWeight: 'bold', fontSize: '26px'}}>
                            {sensorValueString}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        {
            dialogOpen &&
            <Dialog title={intl.formatMessage({id: 'label.SensorCalibrationProcess'})}
                    width={1000}
                    height={500}
                    closeIcon={false}>
                <Stepper value={currentStep} items={calibrationSteps} animationDuration={false}/>
                {calibrationType === CalibrationType.Standard && renderCurrentStepContentForStandardCalibration(config, dispatch, intl, sensor, currentStep, standardCalibrationModeOptions)}
                {calibrationType === CalibrationType.Product && renderCurrentStepContentForProductCalibration(config, dispatch, intl, sensor, currentStep)}
                <DialogActionsBar>
                    <button className="k-button"
                            disabled={isExitButtonDisabled()}
                            onClick={onExit}>{intl.formatMessage({id: 'label.Exit'})}</button>
                    {currentStep < 2 ? <button className="k-button"
                                               disabled={isNextButtonDisabled()}
                                               onClick={onNextStep}>{intl.formatMessage({id: 'label.Next'})}</button> :
                        <button className="k-button"
                                disabled={isFinishButtonDisabled()}
                                onClick={onFinish}>{intl.formatMessage({id: 'label.CalibrationFinish'})}</button>}
                </DialogActionsBar>
            </Dialog>
        }
        {
            confirmationDialogOpen &&
            <Dialog title={intl.formatMessage({id: 'label.SensorCalibrationProcess'})}
                    width={500}
                    height={250}
                    closeIcon={false}>
                <span style={{fontWeight: "bold", fontSize: "20px"}}>
                    {intl.formatMessage({id: 'label.PutSensorInSolution'})}
                </span>
                <DialogActionsBar>
                    <button className="k-button"
                            onClick={() => openConfirmationDialog(false)}>{intl.formatMessage({id: 'label.Cancel'})}</button>
                    <button className="k-button"
                            onClick={onContinue}>{intl.formatMessage({id: 'label.Continue'})}</button>
                </DialogActionsBar>
            </Dialog>
        }
    </>
}