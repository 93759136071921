import React, {useEffect, useRef, useState} from 'react'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import {useIntl} from 'react-intl'
import HistoryTrendsView from "./trends/HistoryTrendsView";
import LiveTrendsView from './trends/LiveTrendsView';

const TrendsView = () => {

    const intl = useIntl()
    const [selectedTab, setSelectedTab] = useState(0)

    return (
        <div id="TrendViewContainer" style={{height: 'calc(100% - 200px)', width: '100%'}}>
            <TabStrip
                selected={selectedTab}
                onSelect={(tab) => setSelectedTab(tab.selected)}
                animation={false}
                style={{height:'100%'}}
            >
                <TabStripTab title={intl.formatMessage({id: 'label.Live'})}>
                    <LiveTrendsView/>
                </TabStripTab>
                <TabStripTab title={intl.formatMessage({id: 'label.History'})}>
                    <HistoryTrendsView/>
                </TabStripTab>
            </TabStrip>
        </div>
    )
}

export default TrendsView;