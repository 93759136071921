import React from 'react'
import {PortWidget} from '@projectstorm/react-diagrams'

import {
    BaseArtifactWidgetProps,
    BaseArtifactWidgetState,
    BaseArtifactWidget
} from '../../BaseArtifact/BaseArtifactWidget'
import {EmptyArtifactModel} from './EmptyArtifactModel'
import * as DefaultPort from '../../Port'
import * as StoreTypes from 'StoreTypes'
import {connect} from 'react-redux'
import {IConfigReducer} from '../../../../store/reducers/configReducer'

export interface EmptyNodeWidgetProps extends BaseArtifactWidgetProps<EmptyArtifactModel> {
    size: number
    config: IConfigReducer
}

export interface EmptyNodeWidgetState extends BaseArtifactWidgetState<EmptyArtifactModel> {
}

class EmptyArtifactWidget extends BaseArtifactWidget<EmptyNodeWidgetProps, EmptyNodeWidgetState> {
    constructor(props: EmptyNodeWidgetProps) {
        super(props)
        this.state = {controlPanel: false, selected: 0, show: false, virtualSelected: 0}
    }

    calculateLabelsPosition = () => {
        if (this.labelRef.current) {
            const labelDimensions = {
                width: this.labelRef.current.offsetWidth,
                height: this.labelRef.current.offsetHeight
            }

            const labelCoordinates = {
                x: this.props.size / 2 - labelDimensions.width / 2,
                y: this.props.size - 50
            }

            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`
        }
    };

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

    render() {
        return (
                <div className='EmptyArtifact-node'
                    style={{
                        position: 'relative',
                        width: this.props.size,
                        height: this.props.size
                    }}
                >
                    <PortWidget
                        port={this.props.node.getPort('left')} engine={this.props.engine}
                        style={{
                            position: 'absolute',
                            top: this.props.size / 2 - 8,
                            left: 0,
                            //backgroundColor: "red"

                        }}
                    >
                        <DefaultPort.Port/>
                    </PortWidget>
                    <PortWidget
                        port={this.props.node.getPort('right')} engine={this.props.engine}
                        style={{
                            position: 'absolute',
                            top: this.props.size / 2 - 8,
                            left: 0,
                            //backgroundColor: "green"
                        }}
                    >
                        <DefaultPort.Port/>
                    </PortWidget>
                    {super.render()}
                </div>
        )
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(EmptyArtifactWidget)
