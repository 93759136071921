/**
 * Visual builder for Pump Artifact
 */
export default class PumpVisual {
    /**
     * Builds up svg
     * @param isManual
     * @param isRunning
     * @param regulationMode
     * @param name Pump name
     * @returns SVG as a string
     */

    private static drawInterlock() {
        return `<g id="g35" transform="matrix(1.67117,0,0,1.67117,-75.155515,-184.41825)">
          <g transform="matrix(0.0499242,0,0,0.0499242,56.5783,121.979)" serif:id="circle3030" id="circle30301">
            <circle id="circle29" style="fill:#5d0a0a;stroke:#ff2b2e;stroke-width:44.59999847px" r="171.304" cy="0" cx="0" />
          </g>
          <g transform="matrix(0.0499242,0,0,0.0499242,56.5783,121.979)" id="path3818">
            <path id="path32" style="fill:#ff2b2e;fill-rule:nonzero" d="m -116.99,106.245 31.82,31.82 236.31,-236.31 -31.819,-31.82 z" />
          </g>
        </g>`
    }

    static build(isManual: boolean, isRunning: boolean, regulationMode: number, name: string, alarmState: number, interlocked: boolean): string {
        let regulationInitials = ''
        switch (regulationMode) {
            case 1:
                //Speed regulation params
                regulationInitials = 'Sp'
                break
            case 2:
                // Weight regulation params
                regulationInitials = 'We'
                break
            case 3:
                //Flow PID regulation params
                regulationInitials = 'Fl'
                break
            case 4:
                //Delta P regulation params
                regulationInitials = 'Dp'
                break
        }

        let fill = isRunning ? 'rgb(15,105,175)' : '#494953'
        let outerCircle = 'rgb(213,213,217)'

        switch (alarmState) {
            case 1: {
                fill = '#FCE9EE'
                outerCircle = '#E61E50'
                break;
            }
            case 2: {
                fill = '#FFFAEB'
                outerCircle = '#FFC832'
                break;
            }
        }

        return `<g transform="matrix(1,0,0,1,0.0016694,0.0016694)">
        <g transform="matrix(1,0,0,1,-2.52887,-2.52887)">
            <g transform="matrix(0.832171,0,0,0.832171,3.69224,3.69224)">
                <path d="M44,22C44,34.15 34.15,44 22,44C9.85,44 0,34.15 0,22C0,9.85 9.85,0 22,0C34.15,0 44,9.85 44,22Z" style="fill:none;fill-rule:nonzero;stroke:${isManual ? 'rgb(34,34,34)' : 'transparent'};stroke-width:2.8px;stroke-dasharray:2.8,2.8;"/>
                <path d="M44,22C44,34.15 34.15,44 22,44C9.85,44 0,34.15 0,22C0,9.85 9.85,0 22,0C34.15,0 44,9.85 44,22Z" style="fill:${outerCircle};fill-rule:nonzero;"/>
                <path d="M42,22C42,33.046 33.046,42 22,42C10.954,42 2,33.046 2,22C2,10.954 10.954,2 22,2C33.046,2 42,10.954 42,22Z" style="fill:white;fill-rule:nonzero;"/>
                <path d="M40,22C40,31.941 31.941,40 22,40C12.059,40 4,31.941 4,22C4,12.059 12.059,4 22,4C31.941,4 40,12.059 40,22Z" style="fill:${fill};fill-rule:nonzero;"/>
                <g transform="matrix(1,0,0,1,11.2167,10.805)">
                    <path class="${isRunning ? 'helice' : ''}" d="M1.467,2.517C1.28,2.047 1.753,1.529 2.272,1.735C9.089,4.432 16.255,6.356 23.509,7.429C24.071,7.512 24.251,8.22 23.821,8.563L23.139,9.108C17.594,13.538 12.578,18.592 8.19,24.17C7.879,24.565 7.247,24.397 7.173,23.901L7.093,23.366C6.024,16.224 4.136,9.228 1.467,2.517Z" style="fill:none;fill-rule:nonzero;stroke:white;stroke-width:2.5px;stroke-linejoin:miter;stroke-miterlimit:4;"/>
                </g>
            </g>
            <g transform="matrix(0.864161,0,0,0.864161,25.4425,26.0152)">
                <path d="M17.75,7C17.75,3.826 15.174,1.25 12,1.25L7,1.25C3.826,1.25 1.25,3.826 1.25,7C1.25,10.174 3.826,12.75 7,12.75L12,12.75C15.174,12.75 17.75,10.174 17.75,7Z" style="fill:rgb(213,213,217);stroke:rgb(217,213,213);stroke-width:1.5px;stroke-linejoin:miter;stroke-miterlimit:4;"/>
            </g>
            <g transform="matrix(1.39957,0,0,1.39957,28.9839,34.6118)">
                <text x="0px" y="0px" style="font-family: 'Lato',sans-serif;font-weight:bold;font-size:6px;fill:rgb(34,34,34);">${regulationInitials}</text>
            </g>
        </g>
       ${interlocked?this.drawInterlock():''}
    </g>`
    }
}
