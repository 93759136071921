import { apiSequenceMacroSteps } from '../../../../models/Api/apiSequenceMacroSteps'

export default (settings: any, macroStep: apiSequenceMacroSteps) => {
  Object.keys(settings).forEach((key) => {
    switch (key) {
      case 'EndCriteriaDropdownSelected': {
        switch (settings.EndCriteriaDropdownSelected.id) {
          case '1': {
            // Reset low speed end criterias
            macroStep.SequenceSteps[25].SequenceCriterias[0].IModeType = 0
            macroStep.SequenceSteps[25].SequenceCriterias[0].IModId = 0
            macroStep.SequenceSteps[25].SequenceCriterias[0].IOperator = 0
            macroStep.SequenceSteps[25].SequenceCriterias[0].ICode = 0
            macroStep.SequenceSteps[25].SequenceCriterias[0].RValue = 0
            macroStep.SequenceSteps[25].ActionIModType = 0
            macroStep.SequenceSteps[25].ActionIModId = 0
            macroStep.SequenceSteps[25].ActionICode = 0
            macroStep.SequenceSteps[25].ActionRValue = 0

            // Default time behavior
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 1002
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 0
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 4
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.timeCriteria
            break
          }
          case '2': {
            // Weight sum threshold
            if (settings.hasEndCriteriaLowSpeed) {
              // Low speed behavior, move weightsum to step 25 to leave room for low speed in steps 26 27
              macroStep.SequenceSteps[25].SequenceCriterias[0].IModeType = 2
              macroStep.SequenceSteps[25].SequenceCriterias[0].IModId = 9
              macroStep.SequenceSteps[25].SequenceCriterias[0].IOperator = 2
              macroStep.SequenceSteps[25].SequenceCriterias[0].ICode = 1
              macroStep.SequenceSteps[25].SequenceCriterias[0].RValue = settings.endCriteriaLowSpeedThreshold
              macroStep.SequenceSteps[25].ActionIModType = 1
              macroStep.SequenceSteps[25].ActionIModId = 1
              macroStep.SequenceSteps[25].ActionICode = 3
              macroStep.SequenceSteps[25].ActionRValue = settings.endCriteriaLowSpeedSetpoint
            } else {
              // Reset low speed end criterias
              macroStep.SequenceSteps[25].SequenceCriterias[0].IModeType = 0
              macroStep.SequenceSteps[25].SequenceCriterias[0].IModId = 0
              macroStep.SequenceSteps[25].SequenceCriterias[0].IOperator = 0
              macroStep.SequenceSteps[25].SequenceCriterias[0].ICode = 0
              macroStep.SequenceSteps[25].SequenceCriterias[0].RValue = 0
              macroStep.SequenceSteps[25].ActionIModType = 0
              macroStep.SequenceSteps[25].ActionIModId = 0
              macroStep.SequenceSteps[25].ActionICode = 0
              macroStep.SequenceSteps[25].ActionRValue = 0
            }

            macroStep.SequenceSteps[27].SequenceCriterias[0].IModeType = 2
            macroStep.SequenceSteps[27].SequenceCriterias[0].IModId = 9
            macroStep.SequenceSteps[27].SequenceCriterias[0].IOperator = 2
            macroStep.SequenceSteps[27].SequenceCriterias[0].ICode = 1
            macroStep.SequenceSteps[27].SequenceCriterias[0].RValue = settings.weightSumThreshold
            break
          }
        }
        break
      }
    }
  })
}
