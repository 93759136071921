import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {Col, Container, Row} from 'reactstrap';
import {Button, Dropdown, Theme} from '@liquid-design/liquid-design-react'
import {apiOpcValue} from "../../../models/Api/apiOpcValue";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {DriveControlPID} from "../../../models/PLC/DriveControlPID";
import {FloatingLabel} from '@progress/kendo-react-labels';
import {IConfigReducer} from "../../../store/reducers/configReducer";
import {useDispatch, useSelector} from "react-redux";
import StoreTypes from "StoreTypes";
import {Feed} from "../../../models/PLC/Feed";
import {AnalogueSensorVirtual} from "../../../models/PLC/AnalogueSensorVirtual";
import {IUserEventInfo, setPlcProperty} from "../../../utils/plcUtils";
import {
    getDigitNumber,
    getEnumTextCode, getSensorDeadBand,
    IRegulationMode,
    renderPIDRegulationParams,
    renderSpeedRegulationParams
} from "./settingsHelpers";
import CommonSwitches from "./CommonSwitches";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import {FormattedMessage, useIntl} from "react-intl";
import {getPumpStateOptions} from './StateOptions'

export interface IPumpControlPanelProps {
    nodeId: string
}

export default function PumpControlPanel({nodeId}: IPumpControlPanelProps) {

    const dispatch = useDispatch();
    const intl = useIntl();
    const [accessGranted, setAccessGranted] = useState(false)
    const [accessRefuse, setAccessRefused] = useState(false)
    const [passwordModalOpen, setPasswordModalOpen] = useState(false)
    const [passwordValue, setPasswordValue] = useState('')
    //Getting information from store
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)

    //Flag that indicates if the SPTFF mode is activated (neede for regulation Interlock)
    const isSptffModeActivated = (config.Unit.Set.iUserOptionWeightUser || config.Unit.Set.iFactOptionWeight) && config.Unit.Set.bVisibilityWE101;

    //Hard coded regulation modes for DriveControlPID, non SPTFF Mode
    let regulationModes: IRegulationMode[];

    //Removing flow Regulation, if Flowmeters are not installed
    if (!config.Unit.Set.iFactOptionFlowmeters) {
        regulationModes = [
            {name: intl.formatMessage({id: "label.SpeedRegulation"}), id: '1', textCode: 'label.SpeedRegulation'},
            {name: intl.formatMessage({id: "label.DeltaPRegulation"}), id: '4', textCode: 'label.DeltaPRegulation'}
        ];
    } else {
        regulationModes = [
            {name: intl.formatMessage({id: "label.SpeedRegulation"}), id: '1', textCode: 'label.SpeedRegulation'},
            {name: intl.formatMessage({id: "label.FlowRegulation"}), id: '3', textCode: 'label.FlowRegulation'},
            {name: intl.formatMessage({id: "label.DeltaPRegulation"}), id: '4', textCode: 'label.DeltaPRegulation'}
        ];
    }

    //In SPTFF Mode, only Sp regulation is available for the Feed pump
    if (isSptffModeActivated) {
        regulationModes = [
            {name: intl.formatMessage({id: "label.SpeedRegulation"}), id: '1', textCode: 'label.SpeedRegulation'}
        ];
    }

    //Get the pump associated ControlModule
    const pumpModule: DriveControlPID = _.find(config.Instances.ControlModules, (item: ControlModuleBase) => {
        return item.NodeId === nodeId;
    }) as DriveControlPID;

    useEffect(() => {
        setCurrentRegulationMode(pumpModule.Set_iControlStyle)
    }, [pumpModule.Set_iControlStyle])

    //Get the EquipmentModule that contains the ControlModule
    const equipmentModule: Feed = _.find(config.Instances.EquipmentModules, (em: any) => {
        return _.find(em.ControlModules, (cm: ControlModuleBase) => {
            return cm.NodeId === nodeId;
        });
    }) as Feed;

    //Getting initial ControlStyle
    const [currentRegulationMode, setCurrentRegulationMode] = useState(pumpModule.Set_iControlStyle);

    /**
     Applies new regulation mode to the PLC
     **/
    const applyRegulationModeChange = () => {

        let controlStyle: apiOpcValue = new apiOpcValue();
        controlStyle.NodeId = pumpModule.NodeId + '.Set_iControlStyle';
        controlStyle.Value = currentRegulationMode;

        //Pushing information to the PLC
        setPlcProperty(config, controlStyle, {
            userLabel: 'label.event.RegulationModeChanged', unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            enumValue: getEnumTextCode(regulationModes, currentRegulationMode)
        }, dispatch);
    };

    /**
     * Changes local value of the control style
     * @param selectedOption
     */
    const localRegulationModeChange = (selectedOption: any) => {
        setCurrentRegulationMode(parseInt(selectedOption.id));
    };

    /**
     Switching mode between Start <-> Stop
     **/
    const onStateChange = (newValue: any) => {

        let state: apiOpcValue = new apiOpcValue();
        state.NodeId = nodeId + '.Inp_bCommandManual';
        state.Value = newValue;

        let eventInfo: IUserEventInfo = {
            userLabel: newValue ? 'label.event.Started' : 'label.event.Stopped',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }

        setPlcProperty(config, state, eventInfo, dispatch);
    };

    /**
     Switching mode between Automatic <-> Manual
     **/
    const onModeChange = (newValue: any) => {

        let mode: apiOpcValue = new apiOpcValue();
        mode.NodeId = nodeId + '.Set_bMode';
        mode.Value = !newValue;

        let eventInfo: IUserEventInfo = {
            userLabel: mode.Value ? 'label.event.ManualModeSet' : 'label.event.AutoModeSet',
            unit: '',
            eventTriggering: UserEventTriggering.BeforeAction,
            showValue: false
        }

        setPlcProperty(config, mode, eventInfo, dispatch);
    };

    /**
     * Rendering speed regulation parameters
     **/
    function renderRegulationParameters() {
        let currentPumpSpeed = pumpModule.Sts_rSpeedFeedback.toFixed(getDigitNumber(pumpModule.Sts_rSpeedFeedback, getSensorDeadBand(pumpModule, 'Sts_rSpeedFeedback', pumpModule.Sts_rSpeedFeedback))) + '%'

        switch (currentRegulationMode.toString()) {
            case '1' :
                //Speed regulation params
                return renderSpeedRegulationParams(config, !pumpModule.Set_bMode, pumpModule, dispatch, intl);
            case '3':
                //Flow PID regulation params
                return renderPIDRegulationParams(config, !pumpModule.Set_bMode, currentPumpSpeed, equipmentModule.FlowPID, equipmentModule.FeedFlow, dispatch, pumpModule.Cfg_sTag, intl, false, accessGranted, setAccessGranted, passwordModalOpen, setPasswordModalOpen, passwordValue, setPasswordValue, accessRefuse, setAccessRefused);
            case '4':
                //Delta P regulation params
                return renderPIDRegulationParams(config, !pumpModule.Set_bMode, currentPumpSpeed, equipmentModule.PressurePID, config.Instances.ControlModules['DP001'] as AnalogueSensorVirtual, dispatch, pumpModule.Cfg_sTag, intl, false, accessGranted, setAccessGranted, passwordModalOpen, setPasswordModalOpen, passwordValue, setPasswordValue, accessRefuse, setAccessRefused);
            default:
                return <>
                    <h3><FormattedMessage id='label.NoRegulationModeSelected'/></h3>
                </>;
        }
    }

    return (
        <Theme themeName={config.theme}>
            <Container>
                <CommonSwitches stateOptions={getPumpStateOptions(intl)} isAutoMode={!pumpModule.Set_bMode}
                                isStarted={pumpModule.Set_bMode ? pumpModule.Inp_bCommandManual : pumpModule.Inp_bCommandAuto}
                                onModeChange={onModeChange}
                                onStateChange={onStateChange} startStopDisabled={false}/>
                <Row style={{padding: '5px'}}>
                    <Col xs="6">
                        <FloatingLabel label={intl.formatMessage({id: "label.RegulationMode"})}
                                       style={{width: '90%'}}
                                       editorId='RegulationMode'
                                       editorDisabled={!pumpModule.Set_bMode}
                                       editorValue={currentRegulationMode.toString()}>
                            <Dropdown
                                id='RegulationMode'
                                options={regulationModes}
                                value={currentRegulationMode.toString()}
                                onSubmit={localRegulationModeChange}
                                disabled={!pumpModule.Set_bMode}
                            />
                        </FloatingLabel>
                        <br/>
                        <br/>
                        <div style={{width: '100%'}}>
                            <Button style={{float: 'right'}}
                                    disabled={currentRegulationMode === pumpModule.Set_iControlStyle}
                                    onClick={applyRegulationModeChange}>{intl.formatMessage({id: "label.ApplyRegulationMode"})}</Button>
                        </div>
                    </Col>
                    <Col xs="6">
                        {renderRegulationParameters()}
                    </Col>
                </Row>
            </Container>
        </Theme>
    )
}