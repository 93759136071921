import {NodeModelGenerics, PortModelOptions} from '@projectstorm/react-diagrams'

import {BaseArtifactModel} from '../../BaseArtifact/BaseArtifactModel'
import {EmptyPortModel} from './EmptyPortModel'
import ValvePorts from './EmptyPortsConfig'
import { IConditions } from '../../CustomLinks/PipeLink/PipeLinkModel'

export interface EmptyNodeModelGenerics {
    PORT: EmptyArtifactModel;
}

export class EmptyArtifactModel extends BaseArtifactModel<NodeModelGenerics & EmptyNodeModelGenerics> {
    constructor(code: string, name: string, nodeId: string, orientation: string = 'horizontal', conditions: IConditions[] = []) {
        super(code, name, nodeId, orientation, {type: 'Empty'}, conditions)
        ValvePorts.forEach((port: PortModelOptions) => this.addPort(
            new EmptyPortModel(port.name, port.alignment)
        ))
    }
}
