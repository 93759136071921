import {NodeModelGenerics, PortModelOptions} from '@projectstorm/react-diagrams'

import {BaseArtifactModel} from '../../BaseArtifact/BaseArtifactModel'
import {SensorPortArtifactModel} from './SensorPortModel'
import {HorizontalPorts, VerticalPorts} from './SensorPortsConfig'

export interface SensorNodeModelGenerics {
    PORT: SensorArtifactModel;
}

export class SensorArtifactModel extends BaseArtifactModel<NodeModelGenerics & SensorNodeModelGenerics> {
    constructor(code: string, name: string, nodeId: string, orientation: string = 'horizontal') {
        super(code, name, nodeId, orientation, {type: 'Sensor'})

        let SensorPorts = HorizontalPorts;

        if (orientation === 'vertical') {
            SensorPorts = VerticalPorts;
        }

        SensorPorts.forEach((port: PortModelOptions) => this.addPort(
            new SensorPortArtifactModel(port.name, port.alignment)
        ))
    }
}
