import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Button, Modal, Icon, Glyph} from '@liquid-design/liquid-design-react'
import SensorSummary from './SensorSummary/SensorSummary'
import * as StoreTypes from 'StoreTypes'
import {IConfigReducer} from '../store/reducers/configReducer'
import {withTheme} from 'styled-components'
import {IAnalogueSensor} from "../models/PLC/IAnalogueSensor";
import {IsNullOrUndefined} from "../utils/plcUtils";
import {useIntl} from "react-intl";
import './SensorSummarySidebar.css'
import {ControlModuleBase} from "../models/PLC/ControlModuleBase";
import { Sortable, SortableItemUIProps } from '@progress/kendo-react-sortable'

const styles: any = {
    pinListItem: {
        cursor: 'pointer',
        height: '60px',
        width: '100%',
        borderRadius: '10px',
        textAlign: 'center',
        lineHeight: '40px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
    }
}

export interface ISensorsSummarySidebarProps {
    config: IConfigReducer
    theme: any
}

const SensorsSummarySidebar = (props: ISensorsSummarySidebarProps) => {
    const {config} = props
    const [isDialogVisible, setIsDialogVisible] = useState(false)
    const [pinnedSensors, setPinnedSensors] = useState([] as string[])
    const {colors} = props.theme
    const intl = useIntl();

    /**
     * Loads previous pinned sensors list
     */
    useEffect(() => {
        window.fetch(`${config.APIBaseUrl}/Settings/api/Settings/v1/PinnedSensors`, {
            method: 'GET'
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (IsNullOrUndefined(resp.Tags)) {
                    setPinnedSensors([])
                } else {
                    setPinnedSensors(resp.Tags)
                }
            })
    }, [config.APIBaseUrl])

    /**
     * Switches dialog visibility
     */
    function switchDialogVisibility() {
        setIsDialogVisible(!isDialogVisible)
    }

    /**
     * Pin / un-pin a sensor
     * @param tag SensorArtifact tag to pin
     */
    function togglePin(tag: string) {
        const i = pinnedSensors.indexOf(tag)
        const newPinnedSensors = i > -1 ? pinnedSensors.filter((item) => item !== tag) : [
            ...pinnedSensors,
            tag
        ]
        window.fetch(`${config.APIBaseUrl}/Settings/api/Settings/v1/PinnedSensors`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Tags: newPinnedSensors || []
            })
        })
            .then((resp) => resp.json())
            .then((resp) => {
                setPinnedSensors(resp.Tags)
            })
            .catch(console.error)
    }

    function sortPins(tags: string[]) {
        setPinnedSensors(tags)
        window.fetch(`${config.APIBaseUrl}/Settings/api/Settings/v1/PinnedSensors`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Tags: tags
            })
        })
          .catch(console.error)
    }

    const controlModules = config.NodeId ? config.Instances.ControlModules : {}
    const sensors: ControlModuleBase[] = []

    Object.keys(controlModules).forEach((tag) => {
        const controlModule = controlModules[tag]
        if (controlModule.Sts_bEnable && (controlModule.ModuleNature === 2 || controlModule.ModuleNature === 4)) {
            sensors.push(controlModule)
        }
    })

    //Splitting all available sensors in rows of 4 sensors
    const rows = Math.ceil(sensors.length / 4);
    let sensorRows: Array<Array<ControlModuleBase>> = [];

    for (let i = 0; i < rows; i++) {
        sensorRows.push(sensors.slice(i * 4, i * 4 + 4))
    }
    const content = sensorRows.map((row, idx) => {
        return (
            <div className='row' style={{marginBottom: '2.5%'}} key={idx}>
                {row.map((sens: ControlModuleBase) =>
                    <div key={sens.Cfg_sTag} className='col-3 justify-content-center'
                         onClick={() => togglePin(sens.Cfg_sTag)}>
                        <div style={{
                            ...styles.pinListItem,
                            backgroundColor: pinnedSensors.indexOf(sens.Cfg_sTag) > -1 ? colors.primary.base : colors.white.base,
                            color: pinnedSensors.indexOf(sens.Cfg_sTag) > -1 ? colors.white.base : colors.primary.base
                        }} className='frame'>
                            <b>{sens.Cfg_sTag}</b>
                        </div>
                    </div>
                )}
            </div>
        )
    })

    return (
        <>
            <div className="SummarySidebar">
                {pinnedSensors.length ? (
                  <Sortable
                    idField={"name"}
                    data={config.NodeId ? pinnedSensors
                      .filter((name) => {
                          const sensor = config.Instances.ControlModules[name]
                          return sensor.Sts_bEnable && (sensor.ModuleNature === 2 || sensor.ModuleNature === 4)
                      })
                      .map((name) => ({ name })) : []}
                    itemUI={SensorSummaryCustomUI}
                    onDragOver={(event) => sortPins((event.newState as { name: string }[]).map((sensor) => sensor.name))}
                  />
                ) : null}
                <div className='frame '>
                    <Glyph size={40} name="settings" color={'#9e9ea2'} onClick={() => switchDialogVisibility()}/>
                </div>
                {isDialogVisible && (
                    <Modal label={intl.formatMessage({id: 'label.SelectPinnedSensors'})} open={isDialogVisible}
                           onClose={() => switchDialogVisibility()}>
                        <div className='container'>
                            {content}
                        </div>
                    </Modal>
                )}
            </div>
        </>
    )
}

/**
 * SensorSummaryCustomUI styles based on grabbing state
 * @param isActive True if card is currently grabbed
 */
const getBaseItemStyle = (isActive: boolean) => ({
    fontSize: "16px",
    textAlign: "center",
    outline: "none",
    cursor: "move",
    display: "block",
    opacity: isActive ? 0.5 : 1
});

/**
 * Custom Drag & Drop card
 */
const SensorSummaryCustomUI = (props: SortableItemUIProps) => {
    // Type-safe dataItem
    const { name } = props.dataItem as { name: string }
    const { attributes, forwardRef, style } = props
    return (
      <div style={{
          ...getBaseItemStyle(attributes['aria-grabbed']), // Change styles if card is grabbed (-> lower opacity)
          ...Object.keys(attributes).length ? style : { display: 'none' } // Don't display ghost card on grab
      }} ref={forwardRef} { ...attributes }>
          <SensorSummary key={name} sensor={name}/>
      </div>
    )
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default withTheme(
    connect(mapStateToProps)(
        React.memo(SensorsSummarySidebar, (prevProps, nextProps) => {
            return (
                prevProps.config.theme === nextProps.config.theme &&
                prevProps.config.APIBaseUrl === nextProps.config.APIBaseUrl &&
                prevProps.config.locale === nextProps.config.locale &&
                prevProps.config.localeRecords === nextProps.config.localeRecords &&
                prevProps.config.Unit.Set === nextProps.config.Unit.Set
            )
        })
    )
)
