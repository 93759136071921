import {
    LabelModel,
    LinkModel,
    LinkModelGenerics,
    LinkModelListener,
    PortModel,
    PortModelAlignment
} from '@projectstorm/react-diagrams-core'
import {DefaultLabelModel} from '@projectstorm/react-diagrams'
import {BezierCurve} from '@projectstorm/geometry'
import {BaseModelOptions, DeserializeEvent} from '@projectstorm/react-canvas-core'
import {IConfigReducer} from '../../../../store/reducers/configReducer'

export interface PipeLinkModelListener extends LinkModelListener {
    // Original libes: Error TS2411 -  TRY TO FIND OUT WHY
    // colorChanged?(event: BaseEntityEvent<PipeLinkModel> & { color: null | string }): void;
    // widthChanged?(event: BaseEntityEvent<PipeLinkModel> & { width: 0 | number }): void;
    colorChanged?(event: any): void;

    widthChanged?(event: any): void;

    flowStatusChanged?(event: any): void;
}

export interface IConditions {
    condition: string | string[],
    value: any,
    operator?: 'OR' | 'AND'
}

export interface PipeLinkModelOptions extends BaseModelOptions {
    width?: number;
    color?: string;
    selectedColor?: string;
    curvyness?: number;
    config?: any;
    type?: string;
    testName?: string;
    flowActive?: boolean;
    isRunning?: boolean;
    flag?: 'AND' | 'OR';
    isEnabled?: IConditions[];
}

export interface PipeLinkModelGenerics extends LinkModelGenerics {
    LISTENER: PipeLinkModelListener;
    OPTIONS: PipeLinkModelOptions;
}

export default class PipeLinkModel extends LinkModel<PipeLinkModelGenerics> {
    public code: string;
    public displayName: string;

    constructor(code: string, displayName: string, options: PipeLinkModelOptions = {}) {
        super({
            type: 'PipeLink',
            width: options.width || 10,
            color: '#0F69AF',
            selectedColor: options.selectedColor || '#2DBECD',
            curvyness: 50,
            flowActive: options.flowActive || false,
            isRunning: options.isRunning || true,
            ...options,
        })
        this.code = code
        this.displayName = displayName
    }

    calculateControlOffset(port: PortModel): [number, number] {
        if (port.getOptions().alignment === PortModelAlignment.RIGHT) {
            return [this.options.curvyness, 0]
        } else if (port.getOptions().alignment === PortModelAlignment.LEFT) {
            return [-this.options.curvyness, 0]
        } else if (port.getOptions().alignment === PortModelAlignment.TOP) {
            return [0, -this.options.curvyness]
        }
        return [0, this.options.curvyness]
    }

    getSVGPath(): string {
        if (this.points.length === 2) {
            const curve = new BezierCurve()
            curve.setSource(this.getFirstPoint().getPosition())
            curve.setTarget(this.getLastPoint().getPosition())
            curve.setSourceControl(
                this.getFirstPoint()
                    .getPosition()
                    .clone()
            )
            curve.setTargetControl(
                this.getLastPoint()
                    .getPosition()
                    .clone()
            )
            if (this.sourcePort) {
                curve.getSourceControl().translate(
                    ...this.calculateControlOffset(
                        this.getSourcePort()
                    )
                )
            }

            if (this.targetPort) {
                curve.getTargetControl().translate(
                    ...this.calculateControlOffset(
                        this.getTargetPort()
                    )
                )
            }
            return curve.getSVGCurve()
        }
    }

    serialize() {
        return {
            ...super.serialize(),
            width: this.options.width,
            color: this.options.color,
            curvyness: this.options.curvyness,
            selectedColor: this.options.selectedColor,
            flowActive: this.options.flowActive
        }
    }

    deserialize(event: DeserializeEvent<this>) {
        super.deserialize(event)
        this.options.color = event.data.color
        this.options.width = event.data.width
        this.options.curvyness = event.data.curvyness
        this.options.selectedColor = event.data.selectedColor
        this.options.flowActive = event.data.flowActive
    }

    addLabel(label: LabelModel | string) {
        if (label instanceof LabelModel) {
            return super.addLabel(label)
        }
        const labelOb = new DefaultLabelModel()
        labelOb.setLabel(label)
        return super.addLabel(labelOb)
    }

    setWidth(width: number) {
        this.options.width = width
        this.fireEvent({width}, 'widthChanged')
    }

    setColor(color: string) {
        this.options.color = color
        this.fireEvent({color}, 'colorChanged')
    }

    getFlow() {
        return this.options.flowActive
    }

    setFlow(status: boolean) {
        this.options.flowActive = status
        this.fireEvent({status}, 'flowStatusChanged')
    }
}
