import React from 'react'
import {useSelector} from 'react-redux'
import StoreTypes from 'StoreTypes'
import {IConfigReducer} from '../../../store/reducers/configReducer'

import MacroStep, {IMacroStep} from './MacroStep'
import {useIntl} from "react-intl";

const StopEquipment = (props: Partial<IMacroStep>) => {

    const intl = useIntl()
    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const isTransfertOptionWired = config.Unit.Set.iUserOptionTransfert
    const isFiltratePCVOptionWired = config.Unit.Set.iFactOptionFiltratePCV


    function validConfiguration(triggerChanges = true) {
        props.onSave(true, {
            isFiltratePCVOptionWired,
            isTransfertOptionWired
        })
    }

    return (
        <MacroStep
            id={props.id}
            macroStepName={'label.StopEquipment'}
            lightBoxLabel={'label.StopEquipment'}
            sequenceID={props.sequenceID}
            stepNumber={props.stepNumber}
            isDraggable={props.isDraggable}
            isModel={props.isModel}
            isNotEditable={true}
            onDelete={props.onDelete}
            onSave={validConfiguration}
        />
    )
}

export default StopEquipment
