import React from 'react'
import VerticalValveNodeWidget from './VerticalValveArtifactWidget'
import { VerticalValveArtifactModel } from './VerticalValveArtifactModel'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'

export class VerticalValveArtifactFactory extends AbstractReactFactory<VerticalValveArtifactModel, DiagramEngine> {
  constructor () {
    super('VerticalValve')
  }

  generateReactWidget (event: any): JSX.Element {
    return <VerticalValveNodeWidget engine={this.engine} size={60} node={event.model} />
  }

  generateModel (event: any) {
    return new VerticalValveArtifactModel('defaultCode', 'defaultName', 'i=0')
  }
}
