import React from 'react'
import {PortWidget} from '@projectstorm/react-diagrams'

import {ControlledValveArtifactModel} from './ControlledValveArtifactModel'
import {
    BaseArtifactWidgetProps,
    BaseArtifactWidgetState,
    BaseArtifactWidget
} from '../../BaseArtifact/BaseArtifactWidget'

import ControlledValveVisual from './ControlledValveVisual'

import * as DefaultPort from '../../Port'
import {TabStrip, TabStripTab} from "@progress/kendo-react-layout";
import ValveControlPanel from "../../ControlPanels/ValveControlPanel";
import AlarmSettingsPanel from "../../ControlPanels/AlarmSettingsPanel";
import _ from "lodash";
import {IConfigReducer} from "../../../../store/reducers/configReducer";
import StoreTypes from "StoreTypes";
import {connect} from "react-redux";
import {StepMotorValve} from "../../../../models/PLC/StepMotorValve";
import {injectIntl, IntlShape} from "react-intl";
import StepMotorValveCalibration from "../../CalibrationPanels/StepMotorValveCalibration";
import {getDigitNumber, getSensorDeadBand} from "../../ControlPanels/settingsHelpers";

export interface ControlledValveNodeWidgetProps extends BaseArtifactWidgetProps<ControlledValveArtifactModel> {
    height: number
    width: number
    config: IConfigReducer
    intl: IntlShape
}

export interface ControlledValveNodeWidgetState extends BaseArtifactWidgetState<ControlledValveArtifactModel> {
}

class ControlledValveArtifactWidget extends BaseArtifactWidget<ControlledValveNodeWidgetProps, ControlledValveNodeWidgetState> {
    constructor(props: ControlledValveNodeWidgetProps) {
        super(props)
        this.state = {controlPanel: true, selected: 0, show: false, virtualSelected: 0}

        //Flag that indicates if the SPTFF mode is activated (neede for regulation Interlock)
        const isSptffModeActivated = (this.props.config.Unit.Set.iUserOptionWeightUser || this.props.config.Unit.Set.iFactOptionWeight) && this.props.config.Unit.Set.bVisibilityWE101;

        if (isSptffModeActivated) {
            this.controlPanelTitleAddon = this.props.intl.formatMessage({id: "label.SPTFFModeActivated"});
        }
    }

    calculateLabelsPosition = () => {
        if (this.labelRef.current) {
            const labelDimensions = {
                width: this.labelRef.current.offsetWidth,
                height: this.labelRef.current.offsetHeight
            }

            const labelCoordinates = {
                x: this.props.width / 2 - labelDimensions.width / 2,
                y: this.props.height - 50
            }

            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`

        }
    };

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

    renderControlPanelContent() {

        const membrane: any = _.find(this.props.config.Instances.EquipmentModules, (em: any) => {
            return em.Cfg_sTag.includes("MEMBRANE");
        });

        return (<TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
            <TabStripTab title='Control'>
                <ValveControlPanel nodeId={this.props.node.nodeId} membraneNodeId={membrane.NodeId}/>
            </TabStripTab>
            <TabStripTab title='Alarm'>
                <AlarmSettingsPanel nodeId={this.props.node.nodeId}/>
            </TabStripTab>
            <TabStripTab title='Calibration'>
                <StepMotorValveCalibration nodeId={this.props.node.nodeId}/>
            </TabStripTab>
        </TabStrip>);
    }

    render() {
        const valve = this.props.config.Instances.ControlModules[this.props.node.displayName] as StepMotorValve

        //Get valve mode
        let valveMode = valve.Cfg_bValveType ? this.props.intl.formatMessage({id: 'label.Closed'}) : this.props.intl.formatMessage({id: 'label.Opened'});
        //valveMode = valveMode.slice(0, 3);

        let alarmState = 0
        if (valve.Sts_bAlarm) {
            if (valve.Sts_bAlarmSystem || valve.Sts_bAlarmSafety || valve.Sts_bAlarmCriticalProcess) {
                alarmState = 1
            } else {
                alarmState = 2
            }
        }

        return (
            <>
                <p style={{
                    fontFamily: 'Lato',
                    borderRadius: '5px',
                    color: '#494953',
                    fontSize: '20px',
                    border: 0,
                    margin: 0,
                    cursor: this.state.controlPanel ? 'pointer' : 'auto',
                    width: "max-content"
                }}
                   onClick={() => {
                       if (!this.state.show)
                           super.showPopup(undefined);
                   }}>
                    {this.props.node.displayName}
                    <br/>
                    <span style={{
                        fontFamily: 'Lato',
                        fontWeight: 1000,
                        color: '#494953',
                        fontSize: '28px'
                    }}>{valve.Sts_rValvePositionFeedback.toFixed(getDigitNumber(valve.Sts_rValvePositionFeedback, getSensorDeadBand(valve, 'Sts_rValvePositionFeedback', valve.Sts_rValvePositionFeedback))) + '%'}&nbsp;{valveMode}
                    </span>
                </p>
                <div
                    className='ControlledValveArtifact-node'
                    style={{
                        position: 'relative',
                        width: this.props.width,
                        height: this.props.height,
                        cursor: this.state.controlPanel ? 'pointer' : 'auto'
                    }}
                >
                    <svg
                        width={this.props.width}
                        height={this.props.height}
                        viewBox='0 0 16 16'
                        preserveAspectRatio='xMidYMid'
                        dangerouslySetInnerHTML={{
                            __html: ControlledValveVisual.build(valve.Set_bMode, alarmState, valve.Set_iControlStyle, false)
                        }}
                        onClick={(e) => {
                            if (!this.state.show)
                                super.showPopup(e)
                        }}
                    />
                    <PortWidget
                        port={this.props.node.getPort('left')} engine={this.props.engine}
                        style={{
                            position: 'absolute',
                            top: this.props.height / 2 - 2,
                            left: 0
                        }}
                    >
                        <DefaultPort.Port/>
                    </PortWidget>
                    <PortWidget
                        port={this.props.node.getPort('right')} engine={this.props.engine}
                        style={{
                            position: 'absolute',
                            top: this.props.height / 2 - 2,
                            right: 12
                        }}
                    >
                        <DefaultPort.Port/>
                    </PortWidget>
                    {super.render()}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(injectIntl(ControlledValveArtifactWidget))
