import {IConfigReducer} from "../../../store/reducers/configReducer";
import {PID} from "../../../models/PLC/PID";
import {Col, Container, Row} from "reactstrap";
import {FloatingLabel} from "@progress/kendo-react-labels";
import {NumericTextBox} from "@progress/kendo-react-inputs";
import {apiOpcValue} from "../../../models/Api/apiOpcValue";
import {IsNullOrUndefined, setPlcProperty} from "../../../utils/plcUtils";
import React from "react";
import {DriveControlPID} from "../../../models/PLC/DriveControlPID";
import {StepMotorValve} from "../../../models/PLC/StepMotorValve";
import {Regul} from "../../../models/PLC/Regul";
import {IAnalogueSensor} from "../../../models/PLC/IAnalogueSensor";
import {IntlShape} from "react-intl";
import {UserEventTriggering} from "../../../models/PLC/UserEventTriggering";
import _ from "lodash";
import {ControlModuleBase} from "../../../models/PLC/ControlModuleBase";
import {SensorUnit} from "../../../models/PLC/SensorUnit";
import {Button, Modal, TextField, Headline} from '@liquid-design/liquid-design-react'
import {DeadBand, DeadBandList} from "../../../models/PLC/DeadBand";


export interface IRegulationMode {
    name: any;
    id: string;
    textCode: string;
}

export interface IPanelOptions {
    label: any;
    value: boolean;
    selectedBackgroundColor: string;
}

export const rampRateInfo = {
    Unit: "'%'/s",
    DigitFormat: '0.0'
}

export function getEnumTextCode(regulationModes: any, currentRegulationMode: number) {
    let regulationMode = _.find(regulationModes, {id: currentRegulationMode.toString()});

    if (IsNullOrUndefined(regulationMode) || IsNullOrUndefined(regulationMode.textCode))
        return '';

    return regulationMode.textCode;
}

export function renderEmptyCol() {
    return <></>;
}

function countDecimals(value: number) {
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
}

export function getSensorDefaultDeadBand(list:DeadBandList) {

    if (IsNullOrUndefined(list))
        return 0

    return DeadBandList.getDefaultDeadBand(list)
}

export function getAnalogSensorDeadBand(sensor: IAnalogueSensor, property: string, value: number) {

    if (IsNullOrUndefined(sensor))
        return 0

    let listedValue = sensor.Values.find(val => val.Name === property);

    if (IsNullOrUndefined(listedValue)) {
        return 0
    } else {
        return DeadBandList.getDeadBandFor(listedValue.Cfg_dDeadBands, value)
    }
}

export function getSensorDeadBand(sensor: ControlModuleBase, property: string, value: number) {

    if (IsNullOrUndefined(sensor))
        return 0

    let listedValue = sensor.Values.find(val => val.Name === property);

    if (IsNullOrUndefined(listedValue)) {
        return 0
    } else {
        return DeadBandList.getDeadBandFor(listedValue.Cfg_dDeadBands, value)
    }
}

export function getSensorUnit(sensor: ControlModuleBase, property: string): SensorUnit {

    if (IsNullOrUndefined(sensor))
        return SensorUnit.None

    let listedValue = sensor.Values.find(val => val.Name === property);

    if (IsNullOrUndefined(listedValue)) {
        return SensorUnit.None
    } else {
        return listedValue.Cfg_suSensorUnit
    }
}

/**
 * Gets the number of digits, based on the current measured measuredValue
 * @param measuredValue
 * @param deadBand
 */
export function getDigitNumber(measuredValue: number, deadBand: number) {

    if (deadBand > 0) {
        return countDecimals(deadBand)
    }

    let absValue = Math.abs(measuredValue)

    if (absValue > 1000)
        return 0;

    if (absValue > 100 && absValue <= 1000)
        return 1

    if (absValue > 10 && absValue <= 100)
        return 2

    if (absValue > 1 && absValue <= 10)
        return 3

    if (absValue <= 1)
        return 4
}

export function getDigitFormat(measuredValue: number, deadBand: number) {
    if (deadBand > 0) {
        let decimals = countDecimals(deadBand)
        if (decimals === 0)
            return ''
        else
            return '.' + '0'.repeat(decimals)
    }

    let absValue = Math.abs(measuredValue)

    if (absValue > 1000)
        return '';

    if (absValue > 100 && absValue <= 1000)
        return '.0'

    if (absValue > 10 && absValue <= 100)
        return '.00'

    if (absValue > 1 && absValue <= 10)
        return '.000'

    if (absValue <= 1)
        return '.0000'
}

/**
 * Renders a PID Regulation with one regulation sensor
 * @param intl
 * @param accessGranted
 * @param setAccessGranted
 * @param passwordModalOpen
 * @param setPasswordModalOpen
 * @param passwordValue
 * @param setPasswordValue
 * @param accessRefused
 * @param setAccessRefused
 *
 */
export function generatePasswordModal(intl: IntlShape, accessGranted: boolean, setAccessGranted: any, passwordModalOpen: boolean, setPasswordModalOpen: any, passwordValue: string, setPasswordValue: any, accessRefused: boolean, setAccessRefused: any, isParameter: boolean) {
    const parameterPassword = isParameter ? "CogentL@bUser" : "Merck!68"
    return <Modal
        label={intl.formatMessage({id: 'label.EnterPasswordTitle'})}
        open={passwordModalOpen}
        onClose={() => {
            setPasswordModalOpen(false)
        }}
    >
        <div style={{textAlign: 'center'}}>
            {!accessRefused &&
            <Headline type='H3'>
                {intl.formatMessage({id: "label.EnterPassword"})}
            </Headline>}
            {accessRefused &&
            <Headline type='H3'>
                {intl.formatMessage({id: "label.ErrorPassword"})}
            </Headline>}
            <br/>
            <TextField
                value={passwordValue}
                onChange={(name: string) => setPasswordValue(name)}
                grey
                type='password'
            />
            <br/>
            {!accessRefused &&
            <Button size='big'
                    appearance='highlight' icon='arrowCheck'
                    isIconFilled={true} onClick={() => {
                if (passwordValue === parameterPassword) {
                    setAccessGranted(true);
                    setPasswordModalOpen(false)
                } else {
                    setAccessRefused(true)
                }
            }}>
            </Button>}
            {accessRefused &&
            <Button size='big'
                    appearance='highlight' icon='close'
                    isIconFilled={true} onClick={() => {
                setAccessRefused(false)
            }}>
            </Button>}
        </div>
    </Modal>
}

/**
 * Renders simple speed regulation
 * @param config
 * @param autoMode
 * @param pumpModule
 * @param dispatch
 * @param additionalColContent
 * @param additionalValueColContent
 * @param intl
 */
export function renderSpeedRegulationParams(config: IConfigReducer, autoMode: boolean, pumpModule: DriveControlPID, dispatch: any, intl: IntlShape, additionalColContent: any = undefined, additionalValueColContent: any = undefined) {
    let speedSetPoint = autoMode ? pumpModule.Speed.AutoSP : pumpModule.Speed.ManualSP;
    let deadBand = getSensorDeadBand(pumpModule, 'Sts_rSpeedFeedback', pumpModule.Sts_rSpeedFeedback)

    return <Container>
        <Row>
            <Col>
                <FloatingLabel label={intl.formatMessage({id: "label.CurrentValue"})}
                               editorId='CurrentSpeed'
                               editorValue={pumpModule.Sts_rSpeedFeedback.toFixed(getDigitNumber(pumpModule.Sts_rSpeedFeedback, deadBand)) + ' %'}
                               style={{width: '100%'}}>
                    <div id='CurrentSpeed' style={{fontWeight: 'bold', fontSize: '16px', width: '100%'}}>
                        {pumpModule.Sts_rSpeedFeedback.toFixed(getDigitNumber(pumpModule.Sts_rSpeedFeedback, deadBand)) + ' %'}
                    </div>
                </FloatingLabel>
            </Col>
            {additionalValueColContent !== undefined ? additionalValueColContent() : renderEmptyCol()}
        </Row>
        <Row>
            <Col>
                <FloatingLabel label={intl.formatMessage({id: "label.SetPoint"})} editorId='SpeedSetPoint'
                               editorValue={speedSetPoint.toString()}>
                    <NumericTextBox
                        id='SpeedSetPoint'
                        format={"p" + getDigitNumber(speedSetPoint, deadBand)}
                        disabled={autoMode}
                        min={pumpModule.Speed.SPMin / 100}
                        max={pumpModule.Speed.SPMax / 100}
                        step={0.1}
                        spinners={false}
                        value={speedSetPoint / 100}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {

                                let mode: apiOpcValue = new apiOpcValue();
                                if (autoMode)
                                    mode.NodeId = pumpModule.Speed.NodeId + '.AutoSP';
                                else
                                    mode.NodeId = pumpModule.Speed.NodeId + '.ManualSP';
                                mode.Value = e.value * 100;

                                setPlcProperty(config, mode, {
                                    sourceTag: pumpModule.Cfg_sTag,
                                    userLabel: 'label.event.SpeedSetPointSet',
                                    unit: '%',
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }
                        }}
                    />
                </FloatingLabel>
                <br/>
                <FloatingLabel label={intl.formatMessage({id: "label.RampRate"})} editorId='RampRate'
                               editorValue={pumpModule.Speed.RampRate.toString()}>
                    <NumericTextBox
                        id='RampRate'
                        format={rampRateInfo.DigitFormat + ' ' + rampRateInfo.Unit}
                        disabled={autoMode}
                        min={0.5}
                        max={100}
                        step={0.5}
                        spinners={false}
                        value={pumpModule.Speed.RampRate}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {
                                let mode: apiOpcValue = new apiOpcValue();
                                mode.NodeId = pumpModule.Speed.NodeId + '.RampRate';
                                mode.Value = e.value;

                                setPlcProperty(config, mode, {
                                    sourceTag: pumpModule.Cfg_sTag,
                                    userLabel: 'label.event.RampRateSet',
                                    unit: '',
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }
                        }}
                    />
                </FloatingLabel>
            </Col>
            {additionalColContent !== undefined ? additionalColContent() : renderEmptyCol()}
        </Row>
    </Container>;
}

/**
 * Renders a PID Regulation with one regulation sensor
 * @param config
 * @param autoMode
 * @param artifactMainValue
 * @param regulationPID
 * @param regulationSensor
 * @param dispatch
 * @param sourceTag
 * @param intl
 * @param accessGranted
 * @param setAccessGranted
 * @param passwordModalOpen
 * @param setPasswordModalOpen
 * @param passwordValue
 * @param setPasswordValue
 * @param accessRefused
 * @param setAccessRefused
 *
 */
export function renderPIDRegulationParams(config: IConfigReducer, autoMode: boolean, artifactMainValue: string, regulationPID: PID, regulationSensor: any, dispatch: any, sourceTag: string, intl: IntlShape, showRampRate: boolean, accessGranted: boolean, setAccessGranted: any, passwordModalOpen: boolean, setPasswordModalOpen: any, passwordValue: string, setPasswordValue: any, accessRefused: boolean, setAccessRefused: any) {

    //Calculating current value, with unit
    let unit = regulationSensor.Values[0].Cfg_suSensorUnit.Unit;
    let controlModule = config.Instances.ControlModules[regulationSensor.Cfg_sTag] as unknown as IAnalogueSensor;
    let deadBand = getSensorDeadBand(config.Instances.ControlModules[regulationSensor.Cfg_sTag], 'Out_rCurrentValue', controlModule.Out_rCurrentValue)
    let currentValue: string = controlModule.Out_rCurrentValue.toFixed(getDigitNumber(controlModule.Out_rCurrentValue, deadBand)) + ' ' + unit;
    const parameterPassword = "Merck68!"

    let sensorSetPoint = autoMode ? regulationPID.AutoSP : regulationPID.ManualSP;
    return <>
        <Container>
            <Row>
                <Col xs="6">
                    <FloatingLabel label={intl.formatMessage({id: "label.CurrentValue"})}
                                   editorId='ArtifactMainValue'
                                   editorValue={artifactMainValue}
                                   style={{width: '100%'}}>
                        <div id='ArtifactMainValue' style={{fontWeight: 'bold', fontSize: '16px'}}>
                            {artifactMainValue}
                        </div>
                    </FloatingLabel>
                </Col>
                <Col xs="6">
                    <FloatingLabel label={intl.formatMessage({id: "label.ControlCurrentValue"})}
                                   editorId='CurrentValue'
                                   editorValue={currentValue}
                                   style={{width: '100%'}}>
                        <div id='CurrentValue' style={{fontWeight: 'bold', fontSize: '16px'}}>
                            {currentValue}
                        </div>
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col xs="6">
                    <FloatingLabel label={intl.formatMessage({id: "label.SetPoint"})} editorId='SetPoint'
                                   editorValue={autoMode ? regulationPID.AutoSP.toString() : regulationPID.ManualSP.toString()}>
                        <NumericTextBox
                            id='SetPoint'
                            format={"0" + getDigitFormat(sensorSetPoint, deadBand) + " " + unit}
                            disabled={autoMode}
                            min={regulationPID.SPMin}
                            max={regulationPID.SPMax}
                            step={1}
                            spinners={false}
                            value={sensorSetPoint}
                            onChange={(e) => {
                                //Propagate change on Enter button press
                                if (e.nativeEvent.key === "Enter") {
                                    let mode: apiOpcValue = new apiOpcValue();
                                    mode.NodeId = autoMode ? regulationPID.NodeId + '.AutoSP' : regulationPID.NodeId + '.ManualSP';
                                    mode.Value = e.value;

                                    setPlcProperty(config, mode, {
                                        sourceTag: sourceTag,
                                        userLabel: 'label.event.PidSetPointSet',
                                        unit: unit,
                                        eventTriggering: UserEventTriggering.BeforeAction
                                    }, dispatch);
                                }
                            }}
                        />
                    </FloatingLabel>
                    {showRampRate && <>
                        <br/>
                        <FloatingLabel label={intl.formatMessage({id: "label.RampRate"})} editorId='RampRate'
                                       editorValue={regulationPID.RampRate.toString()}>
                            <NumericTextBox
                                id='RampRate'
                                format={rampRateInfo.DigitFormat + ' ' + rampRateInfo.Unit}
                                disabled={autoMode}
                                min={0.5}
                                max={100}
                                step={0.5}
                                spinners={false}
                                value={regulationPID.RampRate}
                                onChange={(e) => {
                                    //Propagate change on Enter button press
                                    if (e.nativeEvent.key === "Enter") {
                                        let mode: apiOpcValue = new apiOpcValue();
                                        mode.NodeId = regulationPID.NodeId + '.RampRate';
                                        mode.Value = e.value;

                                        setPlcProperty(config, mode, {
                                            sourceTag: sourceTag,
                                            userLabel: 'label.event.PidRampRateSet',
                                            unit: '',
                                            eventTriggering: UserEventTriggering.BeforeAction
                                        }, dispatch);
                                    }
                                }}
                            />
                        </FloatingLabel>
                    </>}
                </Col>
                <Col xs="6">
                    <FloatingLabel label={intl.formatMessage({id: "label.Gain"})} editorId='DifferentialP'
                                   editorValue={regulationPID.Kp.toString()}>
                        <NumericTextBox
                            id='DifferentialP'
                            format={"0" + getDigitFormat(regulationPID.Kp, 0)}
                            disabled={autoMode || !accessGranted}
                            min={0}
                            max={100}
                            step={1}
                            spinners={false}
                            value={regulationPID.Kp}
                            onChange={(e) => {
                                //Propagate change on Enter button press
                                if (e.nativeEvent.key === "Enter") {
                                    let pidKp: apiOpcValue = new apiOpcValue();
                                    pidKp.NodeId = regulationPID.NodeId + '.Kp';
                                    pidKp.Value = e.value;

                                    setPlcProperty(config, pidKp, {
                                        sourceTag: sourceTag,
                                        userLabel: 'label.event.PidGainSet',
                                        unit: '',
                                        eventTriggering: UserEventTriggering.BeforeAction
                                    }, dispatch);
                                }
                            }}
                        />
                    </FloatingLabel>
                    <br/>
                    <FloatingLabel label={intl.formatMessage({id: "label.Integral"})} editorId='DifferentialI'
                                   editorValue={regulationPID.Ki.toString()}>
                        <NumericTextBox
                            id='DifferentialI'
                            format={"0" + getDigitFormat(regulationPID.Ki, 0) + " /s"}
                            disabled={autoMode || !accessGranted}
                            min={0}
                            max={100}
                            step={1}
                            spinners={false}
                            value={regulationPID.Ki}
                            onChange={(e) => {
                                //Propagate change on Enter button press
                                if (e.nativeEvent.key === "Enter") {
                                    let pidKi: apiOpcValue = new apiOpcValue();
                                    pidKi.NodeId = regulationPID.NodeId + '.Ki'
                                    pidKi.Value = e.value;

                                    setPlcProperty(config, pidKi, {
                                        sourceTag: sourceTag,
                                        userLabel: 'label.event.PidIntegralSet',
                                        unit: '/s',
                                        eventTriggering: UserEventTriggering.BeforeAction
                                    }, dispatch);
                                }
                            }}
                        />
                    </FloatingLabel>
                    <br/>
                    <FloatingLabel label={intl.formatMessage({id: "label.Derivative"})}
                                   editorId='DifferentialD'
                                   editorValue={regulationPID.Kd.toString()}>
                        <NumericTextBox
                            id='DifferentialD'
                            format={"0" + getDigitFormat(regulationPID.Kd, 0) + " s"}
                            disabled={autoMode || !accessGranted}
                            min={0}
                            max={100}
                            step={1}
                            spinners={false}
                            value={regulationPID.Kd}
                            onChange={(e) => {
                                //Propagate change on Enter button press
                                if (e.nativeEvent.key === "Enter") {
                                    let pidKd: apiOpcValue = new apiOpcValue();
                                    pidKd.NodeId = regulationPID.NodeId + '.Kd';
                                    pidKd.Value = e.value;

                                    setPlcProperty(config, pidKd, {
                                        sourceTag: sourceTag,
                                        userLabel: 'label.event.PidDerivativeSet',
                                        unit: 's',
                                        eventTriggering: UserEventTriggering.BeforeAction
                                    }, dispatch);
                                }
                            }}
                        />
                    </FloatingLabel>
                    <br/>
                    <Button style={{float: 'right', width: '100%'}}
                            disabled={accessGranted}
                            onClick={() => {
                                setPasswordModalOpen(true)
                            }}>{intl.formatMessage({id: "label.ChangePidParameters"})}</Button>
                </Col>
            </Row>
            {passwordModalOpen &&
            generatePasswordModal(intl, accessGranted, setAccessGranted, passwordModalOpen, setPasswordModalOpen, passwordValue, setPasswordValue, accessRefused, setAccessRefused, true)
            }
        </Container>
    </>;
}

export function renderPositionRegulationParams(config: IConfigReducer, autoMode: boolean, valve: StepMotorValve, dispatch: any, intl: IntlShape) {
    let positionSetPoint = autoMode ? valve.Position.AutoSP : valve.Position.ManualSP;
    //Get valve mode
    let valveMode = valve.Cfg_bValveType ? intl.formatMessage({id: 'label.Closed'}) : intl.formatMessage({id: 'label.Opened'});
    let deadBand = getSensorDeadBand(valve, 'Sts_rValvePositionFeedback', valve.Sts_rValvePositionFeedback)
    return <Container>
        <Row>
            <Col>
                <FloatingLabel label={intl.formatMessage({id: "label.CurrentPosition"})}
                               editorId='CurrentPosition'
                               editorValue={valve.Sts_rValvePositionFeedback.toFixed(getDigitNumber(valve.Sts_rValvePositionFeedback, deadBand)) + ' % ' + valveMode}
                               style={{width: '100%'}}>
                    <div id='CurrentPosition' style={{fontWeight: 'bold', fontSize: '16px'}}>
                        {valve.Sts_rValvePositionFeedback.toFixed(getDigitNumber(valve.Sts_rValvePositionFeedback, deadBand)) + ' % ' + valveMode}
                    </div>
                </FloatingLabel>
            </Col>
        </Row>
        <Row>
            <Col>
                <FloatingLabel label={intl.formatMessage({id: "label.SetPoint"})} editorId='positionSetPoint'
                               editorValue={positionSetPoint.toString()}>
                    <NumericTextBox
                        id='positionSetPoint'
                        format={"0" + getDigitFormat(positionSetPoint, deadBand) + " %" + valveMode}
                        disabled={autoMode}
                        min={valve.Position.SPMin / 100}
                        max={valve.Position.SPMax / 100}
                        step={0.1}
                        spinners={false}
                        value={positionSetPoint / 100}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {
                                let mode: apiOpcValue = new apiOpcValue();
                                if (autoMode)
                                    mode.NodeId = valve.Position.NodeId + '.AutoSP';
                                else
                                    mode.NodeId = valve.Position.NodeId + '.ManualSP';
                                mode.Value = e.value * 100;

                                setPlcProperty(config, mode, {
                                    sourceTag: valve.Cfg_sTag,
                                    userLabel: 'label.event.PositionSetPointSet',
                                    unit: '% ' + valveMode,
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }
                        }}
                    />
                </FloatingLabel>
            </Col>
        </Row>
    </Container>;
}

export /**
 * Renders Regul control paranmeters
 * @param config
 * @param autoMode
 * @param valve
 * @param control
 * @param regulationSensor
 * @param dispatch
 * @param intl
 */
function renderRegulRegulationParams(config: IConfigReducer, autoMode: boolean, valve: StepMotorValve, control: Regul, regulationSensor: any, dispatch: any, intl: IntlShape, accessGranted: boolean, setAccessGranted: any, passwordModalOpen: boolean, setPasswordModalOpen: any, passwordValue: string, setPasswordValue: any, accessRefused: boolean, setAccessRefused: any) {

    let controlSetPoint = autoMode ? control.AutoSP : control.ManualSP;
    let unit = regulationSensor.Values[0].Cfg_suSensorUnit.Unit;
    let controlModule = config.Instances.ControlModules[regulationSensor.Cfg_sTag] as unknown as IAnalogueSensor;
    let deadBand = getSensorDeadBand(config.Instances.ControlModules[regulationSensor.Cfg_sTag], 'Out_rCurrentValue', controlModule.Out_rCurrentValue)
    let currentValue: string = controlModule.Out_rCurrentValue.toFixed(getDigitNumber(controlModule.Out_rCurrentValue, deadBand)) + ' ' + unit;

    //Get valve mode
    let valveMode = valve.Cfg_bValveType ? intl.formatMessage({id: 'label.Closed'}) : intl.formatMessage({id: 'label.Opened'});

    return <Container>
        <Row>
            <Col>
                <FloatingLabel label={intl.formatMessage({id: "label.CurrentPosition"})}
                               editorId='CurrentPosition'
                               editorValue={valve.Sts_rValvePositionFeedback + ' % ' + valveMode}
                               style={{width: '100%'}}>
                    <div id='CurrentPosition' style={{fontWeight: 'bold', fontSize: '16px'}}>
                        {valve.Sts_rValvePositionFeedback.toFixed(getDigitNumber(valve.Sts_rValvePositionFeedback, getSensorDeadBand(valve, 'Sts_rValvePositionFeedback', valve.Sts_rValvePositionFeedback))) + ' % ' + valveMode}
                    </div>
                </FloatingLabel>
            </Col>
            <Col>
                <FloatingLabel label={intl.formatMessage({id: "label.ControlCurrentValue"})}
                               editorId='CurrentValue'
                               editorValue={currentValue}
                               style={{width: '100%'}}>
                    <div id='CurrentValue' style={{fontWeight: 'bold', fontSize: '16px'}}>
                        {currentValue}
                    </div>
                </FloatingLabel>
            </Col>
            <Col>
                <FloatingLabel label={intl.formatMessage({id: "label.CurrentSampleTime"})}
                               editorId='CurrentSampleTime'
                               editorValue={control.CurrentSampleTime.toString()}
                               style={{width: '100%'}}>
                    <div id='CurrentSampleTime' style={{fontWeight: 'bold', fontSize: '16px'}}>
                        {control.CurrentSampleTime.toFixed(getDigitNumber(control.CurrentSampleTime, 0)).toString() + " s"}
                    </div>
                </FloatingLabel>
            </Col>
        </Row>
        <Row>
            <Col>
                <FloatingLabel label={intl.formatMessage({id: "label.SetPoint"})} editorId='regulSetPoint'
                               editorValue={controlSetPoint.toString()}>
                    <NumericTextBox
                        id='regulSetPoint'
                        format={"0" + getDigitFormat(controlSetPoint, deadBand) + " " + unit}
                        disabled={autoMode}
                        min={control.SPMin}
                        max={control.SPMax}
                        step={0.1}
                        spinners={false}
                        value={controlSetPoint}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {
                                let mode: apiOpcValue = new apiOpcValue();
                                if (autoMode)
                                    mode.NodeId = control.NodeId + '.AutoSP';
                                else
                                    mode.NodeId = control.NodeId + '.ManualSP';
                                mode.Value = e.value;

                                setPlcProperty(config, mode, {
                                        sourceTag: valve.Cfg_sTag,
                                        userLabel: 'label.event.RegulSetPointSet',
                                        unit: unit,
                                        eventTriggering: UserEventTriggering.BeforeAction
                                    }, dispatch
                                )
                                ;
                            }
                        }}
                    />
                </FloatingLabel>
            </Col>
            <Col>
                <FloatingLabel label={intl.formatMessage({id: "label.ActionLimit"})} editorId='ActionLimit'
                               editorValue={control.ActionLimit.toString()}>
                    <NumericTextBox
                        id='ActionLimit'
                        format={"p" + getDigitNumber(control.ActionLimit, 0)}
                        disabled={autoMode || !accessGranted}
                        min={0}
                        max={1}
                        step={0.1}
                        spinners={false}
                        value={control.ActionLimit / 100}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {
                                let actionReal: apiOpcValue = new apiOpcValue();
                                actionReal.NodeId = control.NodeId + '.ActionLimit';
                                actionReal.Value = e.value * 100;

                                setPlcProperty(config, actionReal, {
                                    sourceTag: valve.Cfg_sTag,
                                    userLabel: 'label.event.ActionLimitSet',
                                    unit: '',
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }
                        }}
                    />
                </FloatingLabel>
                <br/>
                <FloatingLabel label={intl.formatMessage({id: "label.ActionHigh"})} editorId='ActionHigh'
                               editorValue={control.ActionHighReal.toString()}>
                    <NumericTextBox
                        id='ActionHigh'
                        format="0"
                        disabled={autoMode || !accessGranted}
                        min={0}
                        max={valve.Cfg_iDistanceFullCourse}
                        step={0.1}
                        spinners={false}
                        value={control.ActionHighReal}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {
                                let actionReal: apiOpcValue = new apiOpcValue();
                                actionReal.NodeId = control.NodeId + '.ActionHighReal';
                                actionReal.Value = e.value;

                                setPlcProperty(config, actionReal, {
                                    sourceTag: valve.Cfg_sTag,
                                    userLabel: 'label.event.ActionHighSet',
                                    unit: '',
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }
                        }}
                    />
                </FloatingLabel>
                <br/>
                <FloatingLabel label={intl.formatMessage({id: "label.ActionMedium"})} editorId='ActionMedium'
                               editorValue={control.ActionMediumReal.toString()}>
                    <NumericTextBox
                        id='ActionMedium'
                        format="0"
                        disabled={autoMode || !accessGranted}
                        min={0}
                        max={valve.Cfg_iDistanceFullCourse}
                        step={0.1}
                        spinners={false}
                        value={control.ActionMediumReal}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {
                                let actionReal: apiOpcValue = new apiOpcValue();
                                actionReal.NodeId = control.NodeId + '.ActionMediumReal';
                                actionReal.Value = e.value;

                                setPlcProperty(config, actionReal, {
                                    sourceTag: valve.Cfg_sTag,
                                    userLabel: 'label.event.ActionMediumSet',
                                    unit: '',
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }
                        }}
                    />
                </FloatingLabel>
                <br/>
                <FloatingLabel label={intl.formatMessage({id: "label.ActionLow"})} editorId='ActionLow'
                               editorValue={control.ActionMediumReal.toString()}>
                    <NumericTextBox
                        id='ActionLow'
                        format="0"
                        disabled={autoMode || !accessGranted}
                        min={0}
                        max={valve.Cfg_iDistanceFullCourse}
                        step={0.1}
                        spinners={false}
                        value={control.ActionLowReal}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {
                                let actionReal: apiOpcValue = new apiOpcValue();
                                actionReal.NodeId = control.NodeId + '.ActionLowReal';
                                actionReal.Value = e.value;

                                setPlcProperty(config, actionReal, {
                                    sourceTag: valve.Cfg_sTag,
                                    userLabel: 'label.event.ActionLowSet',
                                    unit: '',
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }
                        }}
                    />
                </FloatingLabel>
                <br/>
                <br/>
                <Button style={{float: 'right',}}
                        disabled={accessGranted}
                        onClick={() => {
                            setPasswordModalOpen(true)
                        }} size='small'>{intl.formatMessage({id: "label.ChangePidParameters"})}</Button>
            </Col>
            <Col>
                <FloatingLabel label={intl.formatMessage({id: "label.CurrentSampleTimeSetPoint"})}
                               editorId='CurrentSampleTimeSetPoint'
                               editorValue={control.SetSampleTime.toString()}>
                    <NumericTextBox
                        id='CurrentSampleTimeSetPoint'
                        format={"0" + getDigitFormat(control.SetSampleTime, 0) + " s"}
                        disabled={autoMode || !accessGranted}
                        min={0.1}
                        step={0.1}
                        spinners={false}
                        value={control.SetSampleTime}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {
                                let sampleTime: apiOpcValue = new apiOpcValue();
                                sampleTime.NodeId = control.NodeId + '.SetSampleTime';
                                sampleTime.Value = e.value;

                                setPlcProperty(config, sampleTime, {
                                    sourceTag: valve.Cfg_sTag,
                                    userLabel: 'label.event.RegulSampleTimeSet',
                                    unit: 's',
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }
                        }}
                    />
                </FloatingLabel>
                <br/>
                <FloatingLabel label={intl.formatMessage({id: "label.MediumBand"})}
                               editorId='MediumBand'
                               editorValue={control.MediumBand.toString()}>
                    <NumericTextBox
                        id='MediumBand'
                        format={"0" + getDigitFormat(control.MediumBand, deadBand) + " " + unit}
                        disabled={autoMode || !accessGranted}
                        min={control.LowBand}
                        step={0.01}
                        spinners={false}
                        value={control.MediumBand}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {
                                let sampleTime: apiOpcValue = new apiOpcValue();
                                sampleTime.NodeId = control.NodeId + '.MediumBand';
                                sampleTime.Value = e.value;

                                setPlcProperty(config, sampleTime, {
                                    sourceTag: valve.Cfg_sTag,
                                    userLabel: 'label.event.MediumBandSet',
                                    unit: unit,
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }
                        }}
                    />
                </FloatingLabel>
                <br/>
                <FloatingLabel label={intl.formatMessage({id: "label.LowBand"})}
                               editorId='LowBand'
                               editorValue={control.LowBand.toString()}>
                    <NumericTextBox
                        id='LowBand'
                        format={"0" + getDigitFormat(control.LowBand, deadBand) + " " + unit}
                        disabled={autoMode || !accessGranted}
                        min={control.DeadBand}
                        max={control.MediumBand}
                        step={0.01}
                        spinners={false}
                        value={control.LowBand}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {
                                let sampleTime: apiOpcValue = new apiOpcValue();
                                sampleTime.NodeId = control.NodeId + '.LowBand';
                                sampleTime.Value = e.value;

                                setPlcProperty(config, sampleTime, {
                                    sourceTag: valve.Cfg_sTag,
                                    userLabel: 'label.event.LowBandSet',
                                    unit: unit,
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }
                        }}
                    />
                </FloatingLabel>
                <br/>
                <FloatingLabel label={intl.formatMessage({id: "label.DeadBand"})}
                               editorId='DeadBand'
                               editorValue={control.DeadBand.toString()}>
                    <NumericTextBox
                        id='DeadBand'
                        format={"0" + getDigitFormat(control.DeadBand, deadBand) + " " + unit}
                        disabled={autoMode || !accessGranted}
                        min={0.01}
                        max={control.LowBand}
                        step={0.01}
                        spinners={false}
                        value={control.DeadBand}
                        onChange={(e) => {
                            //Propagate change on Enter button press
                            if (e.nativeEvent.key === "Enter") {
                                let sampleTime: apiOpcValue = new apiOpcValue();
                                sampleTime.NodeId = control.NodeId + '.DeadBand';
                                sampleTime.Value = e.value;

                                setPlcProperty(config, sampleTime, {
                                    sourceTag: valve.Cfg_sTag,
                                    userLabel: 'label.event.DeadBandSet',
                                    unit: unit,
                                    eventTriggering: UserEventTriggering.BeforeAction
                                }, dispatch);
                            }
                        }}
                    />
                </FloatingLabel>
                <br/>
                <br/>
            </Col>
        </Row>
        {passwordModalOpen &&
        generatePasswordModal(intl, accessGranted, setAccessGranted, passwordModalOpen, setPasswordModalOpen, passwordValue, setPasswordValue, accessRefused, setAccessRefused, true)
        }
    </Container>;
}