import { PortModelAlignment } from '@projectstorm/react-diagrams'

export const Port360Deg =  [
  {
    name: 'in',
    alignment: PortModelAlignment.LEFT
  },
  {
    name: 'outangle',
    alignment: PortModelAlignment.BOTTOM
  },
  {
    name: 'out',
    alignment: PortModelAlignment.RIGHT
  }
]

export const Port360DegSwitched =  [
  {
    name: 'in',
    alignment: PortModelAlignment.RIGHT
  },
  {
    name: 'outangle',
    alignment: PortModelAlignment.BOTTOM
  },
  {
    name: 'out',
    alignment: PortModelAlignment.LEFT
  }
]
