import React, { useEffect, useRef, useState } from 'react'
import {useSelector} from 'react-redux'
import StoreTypes from 'StoreTypes'
import {IConfigReducer} from '../../../store/reducers/configReducer'
import {Col, Row} from 'reactstrap'
import {useIntl} from 'react-intl'
import {NumericTextBox} from '@progress/kendo-react-inputs'
import {Accordion, Dropdown, RadioButton} from '@liquid-design/liquid-design-react'

import useMountEffect from '../../../utils/useMountEffect'

import {AnalogueSensorVirtual} from '../../../models/PLC/AnalogueSensorVirtual'
import {AnalogueSensorHam} from '../../../models/PLC/AnalogueSensorHam'
import {AnalogueSensorLevitronics} from '../../../models/PLC/AnalogueSensorLevitronics'
import {Feed} from '../../../models/PLC/Feed'
import {Retentate} from '../../../models/PLC/Retentate'
import {Membrane} from '../../../models/PLC/Membrane'
import {Filtrate} from '../../../models/PLC/Filtrate'
import {Tank} from '../../../models/PLC/Tank'
import {AnalogueSensorWired} from '../../../models/PLC/AnalogueSensorWired'
import {StepMotorValve} from '../../../models/PLC/StepMotorValve'
import {DeadBandList} from '../../../models/PLC/DeadBand'

import {updateControlValue, updateDropdownValue} from './Helpers/UpdateHelpers'
import {getDigitFormat, getDigitNumber} from '../../Diagram/ControlPanels/settingsHelpers'
import MacroStep, {IMacroStep} from './MacroStep'
import {DriveControlPID} from "../../../models/PLC/DriveControlPID";

const Diafiltration = (props: Partial<IMacroStep>) => {

    const config: IConfigReducer = useSelector((state: StoreTypes.ReducerState) => state.config)
    const intl = useIntl()
    const areValuesLoadedRef = useRef(false)

    let SIC001_P001DropdownValues: any[] = []
    if (!config.Unit.Set.iFactOptionFlowmeters) {
        SIC001_P001DropdownValues = [
            {name: intl.formatMessage({id: 'label.SpeedRegulation'}), id: '1', dbValue: 7},
            {name: intl.formatMessage({id: 'label.DeltaPRegulation'}), id: '2', dbValue: 6}
        ]
    } else {
        SIC001_P001DropdownValues = [
            {name: intl.formatMessage({id: 'label.SpeedRegulation'}), id: '1', dbValue: 7},
            {name: intl.formatMessage({id: 'label.DeltaPRegulation'}), id: '2', dbValue: 6},
            {name: intl.formatMessage({id: 'label.FlowRegulation'}), id: '3', dbValue: 5}
        ]
    }

    const PCV101DropdownValues = [
        {name: intl.formatMessage({id: 'label.Position'}), id: '1', dbValue: 2},
        {name: intl.formatMessage({id: 'label.Pressure'}), id: '2', dbValue: 3},
        {name: intl.formatMessage({id: 'label.TMPRegulation'}), id: '3', dbValue: 5}
    ]

    const SIC401_P401DropdownValues = [
        {name: intl.formatMessage({id: 'label.SpeedRegulation'}), id: '1', dbValue: 1},
        {name: intl.formatMessage({id: 'label.DeltaLevelRegulation'}), id: '2', dbValue: 2}
    ]

    const PCV201DropdownValues = [
        {name: intl.formatMessage({id: 'label.Position'}), id: '1', dbValue: 2},
        {name: intl.formatMessage({id: 'label.Pressure'}), id: '2', dbValue: 3},
        {name: intl.formatMessage({id: 'label.TMPRegulation'}), id: '3', dbValue: 5}
    ]

    const EndCriteriaDropdownValues = [
        {name: intl.formatMessage({id: 'label.Time'}), id: '1', dbValue: 1002}
    ]

    const isFiltratePCVOptionWired = config.Unit.Set.iFactOptionFiltratePCV
    const isFiltratePhOptionWired = config.Unit.Set.iUserOptionFiltratePh
    const isUVOptionWired = config.Unit.Set.iUserOptionUV
    const isFlowmetersOptionWired = config.Unit.Set.iFactOptionFlowmeters
    const isTransfertOptionWired = config.Unit.Set.iUserOptionTransfert
    const isTankWeightWired = config.Instances.ControlModules['WI001'].Sts_bEnable
    const isFiltrateWeightWired = config.Unit.Set.bVisibilityWE201


    if (isFiltratePhOptionWired) {
        EndCriteriaDropdownValues.push({name: intl.formatMessage({id: 'label.AT201Threshold'}), id: '3', dbValue: 17})
        EndCriteriaDropdownValues.push({name: intl.formatMessage({id: 'label.AT202Threshold'}), id: '4', dbValue: 17})
    }

    if (isUVOptionWired) {
        EndCriteriaDropdownValues.push({name: intl.formatMessage({id: 'label.AT203Threshold'}), id: '5', dbValue: 18})
    }

    if (isFlowmetersOptionWired) {
        PCV201DropdownValues.push({name: intl.formatMessage({id: 'label.FlowRegulation'}), id: '4', dbValue: 4})
        EndCriteriaDropdownValues.push({name: intl.formatMessage({id: 'label.FT201Threshold'}), id: '6', dbValue: 16})
    }
    if (isFiltrateWeightWired) {
        EndCriteriaDropdownValues.push({
            name: intl.formatMessage({id: 'label.WeightSumThreshold'}),
            id: '7',
            dbValue: 117
        })
    }

    //VCF not available if no flow meters or filtrate weight
    if (isFlowmetersOptionWired || isFiltrateWeightWired) {
        EndCriteriaDropdownValues.push( {name: intl.formatMessage({id: 'label.DiafiltrationsNumber'}), id: '2', dbValue: 200})
    }

    useMountEffect(() => {
        if (props.loadedValues) {
            // CONCENTRATION CONFIG
            setIsAutoModeOn(
                props.loadedValues[0].ActionICode !== 6
            )
            setMemorization(
                props.loadedValues[28].ActionICode === 10
            )
            setinitialDiafVolMode(
                props.loadedValues[3].ActionRValue === 1
            )
            //Initlai Diafiltration volume
            const INITInitialDiafVolume = props.loadedValues[2].ActionRValue
            setinitialDiafVolume(
                INITInitialDiafVolume
            )
            // P001/SIC001
            const INITSIC001_P001Dropdown = props.loadedValues[14].ActionICode
            setSIC001_P001DropdownSelected(
                SIC001_P001DropdownValues[SIC001_P001DropdownValues.findIndex((val) => val.dbValue === INITSIC001_P001Dropdown)]
            )
            const INITSIC001_P001SetPoint = props.loadedValues[14].ActionRValue
            setSIC001_P001SetPointSelected(
                INITSIC001_P001SetPoint
            )
            // PCV101
            const INITPCV101Dropdown = props.loadedValues[17].ActionICode
            setPCV101DropdownSelected(
                PCV101DropdownValues[PCV101DropdownValues.findIndex((val) => val.dbValue === INITPCV101Dropdown)]
            )
            const INITPCV101SetPoint = props.loadedValues[17].ActionRValue
            setPCV101SetPointSelected(
                INITPCV101SetPoint
            )
            // P401/SIC401
            const INITSIC401_P401Dropdown = props.loadedValues[20].ActionRValue
            const INITSIC401_P401Value = SIC401_P401DropdownValues.findIndex((val) => val.dbValue === INITSIC401_P401Dropdown)
            setSIC401_P401DropdownSelected(
                SIC401_P401DropdownValues[INITSIC401_P401Value !== -1 ? INITSIC401_P401Value : 0]
            )
            const INITSIC401_P401SetPoint = props.loadedValues[21].ActionRValue
            setSIC401_P401SetPointSelected(
                INITSIC401_P401SetPoint
            )
            const INITSIC401_P401LevelStart = props.loadedValues[18].ActionRValue
            setSIC401_P401LevelStart(
                INITSIC401_P401LevelStart
            )
            const INITSIC401_P401LevelStop = props.loadedValues[19].ActionRValue
            setSIC401_P401LevelStop(
                INITSIC401_P401LevelStop
            )
            const INITSIC401_P401StartSelected = props.loadedValues[22].ActionRValue
            setSIC401_P401StartSelected(
                INITSIC401_P401StartSelected
            )
            // PCV201
            const INITPCV201Dropdown = props.loadedValues[24].ActionICode
            setPCV201DropdownSelected(
                PCV201DropdownValues[PCV201DropdownValues.findIndex((val) => val.dbValue === INITPCV201Dropdown)]
            )
            const INITPCV201SetPoint = props.loadedValues[24].ActionRValue
            setPCV201SetPointSelected(
                INITPCV201SetPoint
            )
            // END CRITERIA
            const INITEndCriteriaDropdown = props.loadedValues[27].SequenceCriterias[0].IModeType

            if (INITEndCriteriaDropdown === 17) {
                //Get index of the good AI and use it to get the selected item
                let idx = props.loadedValues[27].SequenceCriterias[0].IModId === 2 ? "3" : "4"
                let selected = EndCriteriaDropdownValues.find((val) => val.dbValue === 17 && val.id === idx)
                setEndCriteriaDropdownSelected(selected)
            } else {
                setEndCriteriaDropdownSelected(
                    EndCriteriaDropdownValues[EndCriteriaDropdownValues.findIndex((val) => val.dbValue === INITEndCriteriaDropdown)]
                )
            }

            switch (INITEndCriteriaDropdown) {
                case 1002: {
                    setTimeCriteria(
                        props.loadedValues[27].SequenceCriterias[0].RValue
                    )
                    setTimeCriteriaHour(
                        Math.floor(props.loadedValues[27].SequenceCriterias[0].RValue / 3600)
                    )
                    setTimeCriteriaMinute(
                        Math.floor((props.loadedValues[27].SequenceCriterias[0].RValue % 3600) / 60)
                    )
                    setTimeCriteriaSecond(props.loadedValues[27].SequenceCriterias[0].RValue % 60
                    )
                    break
                }
                case 200: {
                    setDiafiltrationsNumberCriteria(
                        props.loadedValues[4].ActionRValue
                    )
                    break
                }
                case 16: {
                    setInferiorFI201Threshold(
                        props.loadedValues[27].SequenceCriterias[0].RValue
                    )
                    break
                }
                case 17: {
                    if (props.loadedValues[27].SequenceCriterias[0].IModId === 2) {
                        setInferiorAI201Threshold(
                            props.loadedValues[27].SequenceCriterias[0].RValue
                        )
                        setSuperiorAI201Threshold(
                            props.loadedValues[27].SequenceCriterias[1].RValue
                        )
                    } else {
                        setInferiorAI202Threshold(
                            props.loadedValues[27].SequenceCriterias[0].RValue
                        )
                        setSuperiorAI202Threshold(
                            props.loadedValues[27].SequenceCriterias[1].RValue
                        )
                    }
                    break
                }
                case 18: {
                    setInferiorAI203Threshold(
                        props.loadedValues[27].SequenceCriterias[0].RValue
                    )
                    setSuperiorAI203Threshold(
                        props.loadedValues[27].SequenceCriterias[1].RValue
                    )
                    break
                }
                case 117: {
                    setWeightSumThreshold(
                        props.loadedValues[27].SequenceCriterias[0].RValue
                    )
                    break
                }
            }
        }
        areValuesLoadedRef.current = true
    })

    useEffect(() => {
        if (areValuesLoadedRef.current && props.onSave) {
            validConfiguration(false)
        }
    }, [props.onSave, validConfiguration])

    const [isAutoModeOn, setIsAutoModeOn] = useState(false)
    const [memorization, setMemorization] = useState(false)
    const [initialDiafVolMode, setinitialDiafVolMode] = useState(false)
    const [initialDiafVolume, setinitialDiafVolume] = useState(0)

    const [SIC001_P001DropdownSelected, setSIC001_P001DropdownSelected] = useState(SIC001_P001DropdownValues[0])
    const [SIC001_P001SetPointSelected, setSIC001_P001SetPointSelected] = useState(0)

    const [PCV101DropdownSelected, setPCV101DropdownSelected] = useState(PCV101DropdownValues[0])
    const [PCV101SetPointSelected, setPCV101SetPointSelected] = useState(0)

    const [PCV201DropdownSelected, setPCV201DropdownSelected] = useState(PCV201DropdownValues[0])
    const [PCV201SetPointSelected, setPCV201SetPointSelected] = useState(0)

    const [SIC401_P401DropdownSelected, setSIC401_P401DropdownSelected] = useState(SIC401_P401DropdownValues[0])
    const [SIC401_P401SetPointSelected, setSIC401_P401SetPointSelected] = useState(0)
    const [SIC401_P401StartSelected, setSIC401_P401StartSelected] = useState(0)

    const [SIC401_P401LevelStart, setSIC401_P401LevelStart] = useState(0)
    const [SIC401_P401LevelStop, setSIC401_P401LevelStop] = useState(0)

    const [timeCriteria, setTimeCriteria] = useState(0)
    const [timeCriteriaHour, setTimeCriteriaHour] = useState(0)
    const [timeCriteriaMinute, setTimeCriteriaMinute] = useState(0)
    const [timeCriteriaSeconds, setTimeCriteriaSecond] = useState(0)
    const [diafiltrationsNumberCriteria, setDiafiltrationsNumberCriteria] = useState(0)
    const [inferiorAI201Threshold, setInferiorAI201Threshold] = useState(0)
    const [superiorAI201Threshold, setSuperiorAI201Threshold] = useState(0)
    const [inferiorAI202Threshold, setInferiorAI202Threshold] = useState(0)
    const [superiorAI202Threshold, setSuperiorAI202Threshold] = useState(0)
    const [inferiorAI203Threshold, setInferiorAI203Threshold] = useState(0)
    const [superiorAI203Threshold, setSuperiorAI203Threshold] = useState(0)
    const [inferiorFI201Threshold, setInferiorFI201Threshold] = useState(0)
    const [weightSumThreshold, setWeightSumThreshold] = useState(0)

    const [EndCriteriaDropdownSelected, setEndCriteriaDropdownSelected] = useState(EndCriteriaDropdownValues[0])

    function validConfiguration(triggerChanges: boolean = true) {
        props.onSave(triggerChanges, {
            isAutoModeOn,
            memorization,
            SIC001_P001DropdownSelected,
            SIC001_P001SetPointSelected,
            PCV101DropdownSelected,
            PCV101SetPointSelected,
            PCV201DropdownSelected,
            PCV201SetPointSelected,
            SIC401_P401DropdownSelected,
            SIC401_P401SetPointSelected,
            SIC401_P401LevelStart,
            SIC401_P401LevelStop,
            EndCriteriaDropdownSelected,
            timeCriteria,
            diafiltrationsNumberCriteria,
            inferiorAI201Threshold,
            superiorAI201Threshold,
            inferiorAI202Threshold,
            superiorAI202Threshold,
            inferiorAI203Threshold,
            superiorAI203Threshold,
            inferiorFI201Threshold,
            weightSumThreshold,
            isFiltratePCVOptionWired,
            isFlowmetersOptionWired,
            isTransfertOptionWired,
            initialDiafVolMode,
            initialDiafVolume,
            SIC401_P401StartSelected,
        })
    }

    function updateTimeCriteria(valToModif: number, value: number) {
        let temptimeCriteria: number
        switch (valToModif) {
            case 1 : {
                setTimeCriteriaHour(value)
                temptimeCriteria = value * 3600 + timeCriteriaMinute * 60 + timeCriteriaSeconds
                break
            }
            case 2 : {
                setTimeCriteriaMinute(value)
                temptimeCriteria = timeCriteriaHour * 3600 + value * 60 + timeCriteriaSeconds
                break
            }
            case 3 : {
                setTimeCriteriaSecond(value)
                temptimeCriteria = timeCriteriaHour * 3600 + timeCriteriaMinute * 60 + value
                break
            }

        }
        updateControlValue(false, setTimeCriteria, temptimeCriteria)
    }

    function generateEndCriteriaFields() {
        switch (EndCriteriaDropdownSelected.id) {
            case '1':
                return (
                    <>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.TimeHour'})}
                            format={'0 h'}
                            spinners={false}
                            min={0}
                            value={timeCriteriaHour}
                            onChange={(val) => updateTimeCriteria(1, val.value)}
                        />
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.TimeMinute'})}
                            format={'0 m'}
                            spinners={false}
                            min={0}
                            max={59}
                            value={timeCriteriaMinute}
                            onChange={(val) => updateTimeCriteria(2, val.value)}
                        />
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.TimeSecond'})}
                            format={'0 s'}
                            spinners={false}
                            min={0}
                            max={59}
                            value={timeCriteriaSeconds}
                            onChange={(val) => updateTimeCriteria(3, val.value)}
                        />
                    </>
                )
            case '2':
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.DiafiltrationsNumber'})}
                        spinners={false}
                        min={0}
                        max={100}
                        value={diafiltrationsNumberCriteria}
                        onChange={(val) => updateControlValue(false, setDiafiltrationsNumberCriteria, val.value)}
                    />
                )
            case '3': {
                const AI201 = config.Instances.ControlModules['AI201'] as AnalogueSensorHam
                return (
                    <>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.InferiorAT201Threshold'})}
                            spinners={false}
                            min={AI201.Cfg_rEGUMinimum}
                            max={superiorAI201Threshold}
                            value={inferiorAI201Threshold}
                            format={`0${getDigitFormat(inferiorAI201Threshold, DeadBandList.getDeadBandFor(AI201.Values[0].Cfg_dDeadBands, inferiorAI201Threshold))} ${AI201.Values[0].Cfg_suSensorUnit.Unit}`}
                            onChange={(val) => updateControlValue(false, setInferiorAI201Threshold, val.value)}
                        />
                        <br/>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.SuperiorAT201Threshold'})}
                            spinners={false}
                            min={inferiorAI201Threshold}
                            max={AI201.Cfg_rEGUMaximum}
                            value={superiorAI201Threshold}
                            format={`0${getDigitFormat(superiorAI201Threshold, DeadBandList.getDeadBandFor(AI201.Values[0].Cfg_dDeadBands, superiorAI201Threshold))} ${AI201.Values[0].Cfg_suSensorUnit.Unit}`}
                            onChange={(val) => updateControlValue(false, setSuperiorAI201Threshold, val.value)}
                        />
                    </>
                )
            }
            case '4': {
                const AI202 = config.Instances.ControlModules['AI202'] as AnalogueSensorHam
                return (
                    <>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.InferiorAT202Threshold'})}
                            spinners={false}
                            min={AI202.Cfg_rEGUMinimum}
                            max={superiorAI202Threshold}
                            value={inferiorAI202Threshold}
                            format={`0${getDigitFormat(inferiorAI202Threshold, DeadBandList.getDeadBandFor(AI202.Values[0].Cfg_dDeadBands, inferiorAI202Threshold))} ${AI202.Values[0].Cfg_suSensorUnit.Unit}`}
                            onChange={(val) => updateControlValue(false, setInferiorAI202Threshold, val.value)}
                        />
                        <br/>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.SuperiorAT202Threshold'})}
                            spinners={false}
                            min={inferiorAI202Threshold}
                            max={AI202.Cfg_rEGUMaximum}
                            value={superiorAI202Threshold}
                            format={`0${getDigitFormat(superiorAI202Threshold, DeadBandList.getDeadBandFor(AI202.Values[0].Cfg_dDeadBands, superiorAI202Threshold))} ${AI202.Values[0].Cfg_suSensorUnit.Unit}`}
                            onChange={(val) => updateControlValue(false, setSuperiorAI202Threshold, val.value)}
                        />
                    </>
                )
            }
            case '5': {
                const AI203 = config.Instances.ControlModules['AI203'] as AnalogueSensorHam
                return (
                    <>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.InferiorAT203Threshold'})}
                            spinners={false}
                            min={AI203.Cfg_rEGUMinimum}
                            max={superiorAI203Threshold}
                            value={inferiorAI203Threshold}
                            format={`0${getDigitFormat(inferiorAI203Threshold, DeadBandList.getDeadBandFor(AI203.Values[0].Cfg_dDeadBands, inferiorAI203Threshold))} ${AI203.Values[0].Cfg_suSensorUnit.Unit}`}
                            onChange={(val) => updateControlValue(false, setInferiorAI203Threshold, val.value)}
                        />
                        <br/>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.SuperiorAT203Threshold'})}
                            spinners={false}
                            min={inferiorAI203Threshold}
                            max={AI203.Cfg_rEGUMaximum}
                            value={superiorAI203Threshold}
                            format={`0${getDigitFormat(superiorAI203Threshold, DeadBandList.getDeadBandFor(AI203.Values[0].Cfg_dDeadBands, superiorAI203Threshold))} ${AI203.Values[0].Cfg_suSensorUnit.Unit}`}
                            onChange={(val) => updateControlValue(false, setSuperiorAI203Threshold, val.value)}
                        />
                    </>
                )
            }
            case '6':
                const FI201 = config.Instances.ControlModules['FI201'] as AnalogueSensorLevitronics
                return (

                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.InferiorFT201Threshold'})}
                        spinners={false}
                        min={0}
                        max={99999}
                        value={inferiorFI201Threshold}
                        format={`0${getDigitFormat(inferiorFI201Threshold, DeadBandList.getDeadBandFor(FI201.Values[1].Cfg_dDeadBands, inferiorFI201Threshold))} ${FI201.Values[1].Cfg_suSensorUnit.Unit}`}
                        onChange={(val) => updateControlValue(false, setInferiorFI201Threshold, val.value)}
                    />
                )
            case '7':
                const WI201 = config.Instances.ControlModules['WI201'] as AnalogueSensorWired
                const WI202 = config.Instances.ControlModules['WI202'] as AnalogueSensorWired
                const WI203 = config.Instances.ControlModules['WI203'] as AnalogueSensorWired
                let max: number
                let weightSensorNumber: number = 0
                if (config.Unit.Set.bVisibilityWE201 === true && config.Unit.Set.bVisibilityWE202 === false && config.Unit.Set.bVisibilityWE203 === false) {
                    weightSensorNumber = 1
                }
                if (config.Unit.Set.bVisibilityWE202 === true && config.Unit.Set.bVisibilityWE203 === false) {
                    weightSensorNumber = 2
                }
                if (config.Unit.Set.bVisibilityWE203 === true) {
                    weightSensorNumber = 3
                }
                switch (weightSensorNumber) {
                    case 1: {
                        max = WI201.Cfg_rEGUMaximum
                        break
                    }
                    case 2 : {
                        max = WI201.Cfg_rEGUMaximum + WI202.Cfg_rEGUMaximum
                        break
                    }
                    case 3 : {
                        max = WI201.Cfg_rEGUMaximum + WI202.Cfg_rEGUMaximum + WI203.Cfg_rEGUMaximum
                        break
                    }
                }
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.WeightSumThreshold'})}
                        spinners={false}
                        min={0}
                        max={max}
                        value={weightSumThreshold}
                        format={`0${getDigitFormat(weightSumThreshold, DeadBandList.getDeadBandFor(WI201.Values[0].Cfg_dDeadBands, weightSumThreshold))} ${WI201.Values[0].Cfg_suSensorUnit.Unit}`}
                        onChange={(val) => updateControlValue(false, setWeightSumThreshold, val.value)}
                    />
                )
        }
    }

    function generateSIC001_P001Input() {
        const EM = config.Instances.EquipmentModules['FEED001'] as Feed
        switch (SIC001_P001DropdownSelected ? SIC001_P001DropdownSelected.id : '1') {
            case '1': {
                // Speed
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={0}
                        max={1}
                        value={SIC001_P001SetPointSelected / 100}
                        format={`p${getDigitNumber(SIC001_P001SetPointSelected, DeadBandList.getDeadBandFor(config.Instances.ControlModules['P001/SIC001'].Values[0].Cfg_dDeadBands, SIC001_P001SetPointSelected))}`}
                        onChange={(val) => updateControlValue(true, setSIC001_P001SetPointSelected, val.value)}
                    />
                )
            }
            case '2': {
                // Delta P
                const control = config.Instances.ControlModules['DP001'] as AnalogueSensorVirtual
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.PressurePID
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={SIC001_P001SetPointSelected}
                        format={`0${getDigitFormat(SIC001_P001SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, SIC001_P001SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setSIC001_P001SetPointSelected, val.value)}
                    />
                )
            }
            case '3': {
                // Flow
                const control = EM.FeedFlow
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.FlowPID
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={SIC001_P001SetPointSelected}
                        format={`0${getDigitFormat(SIC001_P001SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, SIC001_P001SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setSIC001_P001SetPointSelected, val.value)}
                    />
                )
            }
        }
    }

    function generatePCV101Input() {
        const EM = config.Instances.EquipmentModules['RETENTATE001'] as Retentate
        let valveMode = (config.Instances.ControlModules['PCV101'] as StepMotorValve).Cfg_bValveType ? intl.formatMessage({id: 'label.Closed'}) : intl.formatMessage({id: 'label.Opened'})

        switch (PCV101DropdownSelected ? PCV101DropdownSelected.id : '1') {
            case '1': {
                // Position
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={0}
                        max={1}
                        value={PCV101SetPointSelected / 100}
                        format={`0${getDigitFormat(PCV101SetPointSelected, DeadBandList.getDeadBandFor(config.Instances.ControlModules['PCV101'].Values[0].Cfg_dDeadBands, PCV101SetPointSelected))} % ${valveMode}`}
                        onChange={(val) => updateControlValue(true, setPCV101SetPointSelected, val.value)}
                    />
                )
            }
            case '2': {
                // Pressure
                const control = config.Instances.ControlModules['PI101'] as AnalogueSensorVirtual
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.PressureRegul
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={PCV101SetPointSelected}
                        format={`0${getDigitFormat(PCV101SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, PCV101SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setPCV101SetPointSelected, val.value)}
                    />
                )
            }
            case '3': {
                // TMP
                const control = (config.Instances.EquipmentModules['MEMBRANE001'] as Membrane).TMP
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.TMPRegul
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={PCV101SetPointSelected}
                        format={`0${getDigitFormat(PCV101SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, PCV101SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setPCV101SetPointSelected, val.value)}
                    />
                )
            }
        }
    }

    function generateSIC401_P401Input() {
        const EM = config.Instances.EquipmentModules['TANK001'] as Tank
        const weightSensor = config.Instances.ControlModules[EM.WeightSensor.Cfg_sTag] as AnalogueSensorWired
        const unit = weightSensor.Values[0].Cfg_suSensorUnit.Unit
        const transferPump = config.Instances.ControlModules['P401/SIC401'] as DriveControlPID;

        return (
            <>
                <NumericTextBox
                    label={intl.formatMessage({id: 'label.SetPoint'})}
                    spinners={false}
                    min={transferPump.Speed.SPMin / 100}
                    max={transferPump.Speed.SPMax / 100}
                    value={SIC401_P401SetPointSelected / 100}
                    format={`p${getDigitNumber(SIC401_P401SetPointSelected, DeadBandList.getDeadBandFor(config.Instances.ControlModules['P401/SIC401'].Values[0].Cfg_dDeadBands, SIC401_P401SetPointSelected))}`}
                    onChange={(val) => updateControlValue(true, setSIC401_P401SetPointSelected, val.value)}
                />
                {SIC401_P401DropdownSelected.id === '2' && (
                    <>
                        <br/>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.LevelStart'})}
                            spinners={false}
                            min={weightSensor.Cfg_rEGUMinimum}
                            max={SIC401_P401LevelStop}
                            value={SIC401_P401LevelStart}
                            format={`0${getDigitFormat(SIC401_P401LevelStart, DeadBandList.getDeadBandFor(weightSensor.Values[0].Cfg_dDeadBands, SIC401_P401LevelStart))} ${unit}`}
                            onChange={(val) => updateControlValue(false, setSIC401_P401LevelStart, val.value)}
                        />
                        <br/>
                        <NumericTextBox
                            label={intl.formatMessage({id: 'label.LevelStop'})}
                            spinners={false}
                            min={SIC401_P401LevelStart}
                            max={weightSensor.Cfg_rEGUMaximum}
                            value={SIC401_P401LevelStop}
                            format={`0${getDigitFormat(SIC401_P401LevelStop, DeadBandList.getDeadBandFor(weightSensor.Values[0].Cfg_dDeadBands, SIC401_P401LevelStop))} ${unit}`}
                            onChange={(val) => updateControlValue(false, setSIC401_P401LevelStop, val.value)}
                        />
                    </>
                )}
            </>
        )
    }

    function generatePCV201Input() {
        const EM = config.Instances.EquipmentModules['FILTRATE001'] as Filtrate
        let valveMode = (config.Instances.ControlModules['PCV201'] as StepMotorValve).Cfg_bValveType ? intl.formatMessage({id: 'label.Closed'}) : intl.formatMessage({id: 'label.Opened'})

        switch (PCV201DropdownSelected ? PCV201DropdownSelected.id : '1') {
            case '1': {
                // Position
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={0}
                        max={1}
                        value={PCV201SetPointSelected / 100}
                        format={`0${getDigitFormat(PCV201SetPointSelected, DeadBandList.getDeadBandFor(config.Instances.ControlModules['PCV201'].Values[0].Cfg_dDeadBands, PCV201SetPointSelected))} % ${valveMode}`}
                        onChange={(val) => updateControlValue(true, setPCV201SetPointSelected, val.value)}
                    />
                )
            }
            case '2': {
                // Pressure
                const control = EM.FiltratePressureSensor
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.PressureRegul
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={PCV201SetPointSelected}
                        format={`0${getDigitFormat(PCV201SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, PCV201SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setPCV201SetPointSelected, val.value)}
                    />
                )
            }
            case '3': {
                // TMP
                const control = (config.Instances.EquipmentModules['MEMBRANE001'] as Membrane).TMP
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.TMPRegul
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={PCV201SetPointSelected}
                        format={`0${getDigitFormat(PCV201SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, PCV201SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setPCV201SetPointSelected, val.value)}
                    />
                )
            }
            case '4': {
                // Flow
                const control = EM.FiltrateFlowSensor
                const unit = control.Values[0].Cfg_suSensorUnit.Unit
                const regulation = EM.FlowRegul
                return (
                    <NumericTextBox
                        label={intl.formatMessage({id: 'label.SetPoint'})}
                        spinners={false}
                        min={regulation.SPMin}
                        max={regulation.SPMax}
                        value={PCV201SetPointSelected}
                        format={`0${getDigitFormat(PCV201SetPointSelected, DeadBandList.getDeadBandFor(control.Values[0].Cfg_dDeadBands, PCV201SetPointSelected))} ${unit}`}
                        onChange={(val) => updateControlValue(false, setPCV201SetPointSelected, val.value)}
                    />
                )
            }
        }
    }

    return (
        <MacroStep
            id={props.id}
            macroStepName={'label.Diafiltration'}
            lightBoxLabel={'label.DiafiltrationOptions'}
            sequenceID={props.sequenceID}
            stepNumber={props.stepNumber}
            isDraggable={props.isDraggable}
            isModel={props.isModel}
            onDelete={props.onDelete}
            onSave={validConfiguration}
        >
            <Accordion title={intl.formatMessage({id: 'label.DiafiltrationOptions'})}>
                <div style={{minHeight: '200px'}}>
                    <Row>
                        <Col>
                            <br/>
                            <RadioButton
                                isSelected={isAutoModeOn}
                                onClick={() => setIsAutoModeOn(true)}
                                label={intl.formatMessage({id: 'label.AutoModeOn'})}
                            />
                            <br/>
                            <RadioButton
                                isSelected={!isAutoModeOn}
                                onClick={() => setIsAutoModeOn(false)}
                                label={intl.formatMessage({id: 'label.AutoModeOff'})}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br/>
                            <RadioButton
                                isSelected={!initialDiafVolMode}
                                onClick={() => setinitialDiafVolMode(false)}
                                label={intl.formatMessage({id: 'label.InitialDiafVolCalcMode'})}
                            />
                            <br/>
                            {isTankWeightWired ?
                                <RadioButton
                                    isSelected={initialDiafVolMode}
                                    onClick={() => setinitialDiafVolMode(true)}
                                    label={intl.formatMessage({id: 'label.InitialDiafVolCalcModeWeight'})}
                                /> : null}
                        </Col>
                        <Col>
                            <br/>
                            {!initialDiafVolMode ?
                                <NumericTextBox
                                    label={intl.formatMessage({id: 'label.InitialDiafVolume'})}
                                    spinners={false}
                                    format={`0${getDigitFormat(initialDiafVolume, DeadBandList.getDeadBandFor(config.Instances.ControlModules['VOL001'].Values[0].Cfg_dDeadBands, initialDiafVolume))} ${config.Instances.ControlModules['VOL001'].Values[0].Cfg_suSensorUnit.Unit}`}
                                    min={0}
                                    value={initialDiafVolume}
                                    onChange={(val) => updateControlValue(false, setinitialDiafVolume, val.value)}
                                /> : null}
                            <br/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br/>
                            <RadioButton
                                isSelected={memorization}
                                onClick={() => setMemorization(true)}
                                label={intl.formatMessage({id: 'label.Memorize'})}
                            />
                            <br/>
                            <RadioButton
                                isSelected={!memorization}
                                onClick={() => setMemorization(false)}
                                label={intl.formatMessage({id: 'label.NoMemorize'})}
                            />
                        </Col>
                    </Row>
                </div>
            </Accordion>
            <Accordion title="P001/SIC001">
                <div style={{minHeight: '200px'}}>
                    <Row>
                        <Col>
                            <Dropdown
                                label={intl.formatMessage({id: 'label.RegulationMode'})}
                                options={SIC001_P001DropdownValues}
                                value={SIC001_P001DropdownSelected.id}
                                onSubmit={(val: any) => updateDropdownValue(setSIC001_P001DropdownSelected, val, [setSIC001_P001SetPointSelected])}
                            />
                        </Col>
                        <Col>
                            {generateSIC001_P001Input()}
                        </Col>
                    </Row>
                </div>
            </Accordion>
            <Accordion title="PCV101">
                <div style={{minHeight: '200px'}}>
                    <Row>
                        <Col>
                            <Dropdown
                                label={intl.formatMessage({id: 'label.RegulationMode'})}
                                options={PCV101DropdownValues}
                                value={PCV101DropdownSelected.id}
                                onSubmit={(val: any) => updateDropdownValue(setPCV101DropdownSelected, val, [setPCV101SetPointSelected])}
                            />
                        </Col>
                        <Col>
                            {generatePCV101Input()}
                        </Col>
                    </Row>
                </div>
            </Accordion>
            {isTransfertOptionWired && (
                <Accordion title="P401/SIC401">
                    <div style={{minHeight: '200px'}}>
                        <Row>
                            <Col>
                                <br/>
                                <RadioButton
                                    isSelected={SIC401_P401StartSelected === 0}
                                    onClick={() => setSIC401_P401StartSelected(0)}
                                    label={intl.formatMessage({id: 'label.Stoppump'})}
                                />
                                <br/>
                                <RadioButton
                                    isSelected={SIC401_P401StartSelected === 1}
                                    onClick={() => setSIC401_P401StartSelected(1)}
                                    label={intl.formatMessage({id: 'label.Startpump'})}
                                />
                            </Col>
                        </Row>
                        {SIC401_P401StartSelected === 1 && <Row>
                            <Col>
                                <Dropdown
                                    label={intl.formatMessage({id: 'label.RegulationMode'})}
                                    options={SIC401_P401DropdownValues}
                                    value={SIC401_P401DropdownSelected.id}
                                    onSubmit={(val: any) => updateDropdownValue(setSIC401_P401DropdownSelected, val, [setSIC401_P401SetPointSelected, setSIC401_P401LevelStart, setSIC401_P401LevelStop])}
                                />
                            </Col>
                            <Col>
                                {generateSIC401_P401Input()}
                            </Col>
                        </Row>}
                    </div>
                </Accordion>
            )}
            {isFiltratePCVOptionWired && (
                <Accordion title="PCV201">
                    <div style={{minHeight: '200px'}}>
                        <Row>
                            <Col>
                                <Dropdown
                                    label={intl.formatMessage({id: 'label.RegulationMode'})}
                                    options={PCV201DropdownValues}
                                    value={PCV201DropdownSelected.id}
                                    onSubmit={(val: any) => updateDropdownValue(setPCV201DropdownSelected, val, [setPCV201SetPointSelected])}
                                />
                            </Col>
                            <Col>
                                {generatePCV201Input()}
                            </Col>
                        </Row>
                    </div>
                </Accordion>
            )}
            <Accordion title={intl.formatMessage({id: 'label.EndCriteria'})}>
                <div style={{minHeight: '200px'}}>
                    <Row>
                        <Col>
                            <Dropdown
                                label={intl.formatMessage({id: 'label.EndCriteria'})}
                                options={EndCriteriaDropdownValues}
                                value={EndCriteriaDropdownSelected.id}
                                onSubmit={(val: any) => updateDropdownValue(setEndCriteriaDropdownSelected, val, [setTimeCriteria, setDiafiltrationsNumberCriteria, setInferiorAI201Threshold, setSuperiorAI201Threshold, setInferiorAI202Threshold, setSuperiorAI202Threshold, setInferiorAI203Threshold, setSuperiorAI203Threshold, setInferiorFI201Threshold, setWeightSumThreshold])}
                            />
                        </Col>
                        <Col>
                            {generateEndCriteriaFields()}
                        </Col>
                    </Row>
                </div>
            </Accordion>
            <br/>
        </MacroStep>
    )
}

export default Diafiltration
