import { PortModelAlignment } from '@projectstorm/react-diagrams'

export const Port0Deg = [
  {
    name: 'in',
    alignment: PortModelAlignment.TOP
  },
  {
    name: 'outangle',
    alignment: PortModelAlignment.LEFT
  },
  {
    name: 'out',
    alignment: PortModelAlignment.BOTTOM
  }
]

export const Port0DegSwitched = [
  {
    name: 'in',
    alignment: PortModelAlignment.BOTTOM
  },
  {
    name: 'outangle',
    alignment: PortModelAlignment.LEFT
  },
  {
    name: 'out',
    alignment: PortModelAlignment.TOP
  }
]
