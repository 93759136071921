import React from 'react'
import {PortWidget} from '@projectstorm/react-diagrams'

import {SensorArtifactModel} from './SensorArtifactModel'
import {
    BaseArtifactWidgetProps,
    BaseArtifactWidgetState,
    BaseArtifactWidget
} from '../../BaseArtifact/BaseArtifactWidget'

import SensorVisual from './SensorVisual'

import * as DefaultPort from '../../Port'
import {connect} from 'react-redux'
import * as StoreTypes from 'StoreTypes'
import {IConfigReducer} from '../../../../store/reducers/configReducer'
import {TabStrip, TabStripTab} from '@progress/kendo-react-layout'
import AlarmSettingsPanel from '../../ControlPanels/AlarmSettingsPanel'
import {IAnalogueSensor} from "../../../../models/PLC/IAnalogueSensor";
import HeatExchangerControlPanel from '../../ControlPanels/HeatExchangerControlPanel'

export interface SensorNodeWidgetProps extends BaseArtifactWidgetProps<SensorArtifactModel> {
    size: number
    node: any
    config: IConfigReducer
}

export interface SensorNodeWidgetState extends BaseArtifactWidgetState<SensorArtifactModel> {
}

class SensorArtifactWidget extends BaseArtifactWidget<SensorNodeWidgetProps, SensorNodeWidgetState> {
    constructor(props: SensorNodeWidgetProps) {
        super(props)
        this.state = {controlPanel: true, selected: 0, show: false, virtualSelected: 0}
    }

    calculateLabelsPosition = () => {
        if (this.labelRef.current) {
            const labelDimensions = {
                width: this.labelRef.current.offsetWidth,
                height: this.labelRef.current.offsetHeight
            }

            let labelCoordinates = {
                x: this.props.size / 2 - labelDimensions.width / 2,
                y: this.props.size
            }

            if (this.props.node.orientation === 'vertical') {
                labelCoordinates = {
                    x: this.props.size,
                    y: this.props.size / 2 - labelDimensions.height / 2
                }
            }

            this.labelRef.current.style.transform = `translate(${labelCoordinates.x}px, ${labelCoordinates.y}px)`
        }
    };

    performAction(actionName: string, actionProps: any): void {
        throw new Error('Method not implemented.')
    }

    renderControlPanelContent(): any {
        if (this.props.node.nodeId.includes('F_AnalogueSensorTCU')) {
            return (
              <TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
                  <TabStripTab title='Control'>
                      <HeatExchangerControlPanel nodeId={this.props.node.nodeId}/>
                  </TabStripTab>
                  <TabStripTab title='Alarm'>
                      <AlarmSettingsPanel nodeId={this.props.node.nodeId}/>
                  </TabStripTab>
              </TabStrip>
            )
        } else {
            let calibrationContent = super.renderCalibrationContent(this.props.node.nodeId);
            return (<TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
                <TabStripTab title='Alarm'>
                    <AlarmSettingsPanel nodeId={this.props.node.nodeId}/>
                </TabStripTab>
                {calibrationContent && <TabStripTab title='Calibration'>
                    {calibrationContent}
                </TabStripTab>}
            </TabStrip>);
        }

    }

    renderHorizontalPortWidgets(): any {
        return <>
            <PortWidget
                port={this.props.node.getPort('left')} engine={this.props.engine}
                style={{
                    position: 'absolute',
                    top: this.props.size / 2 - 8,
                    left: 2
                }}
            >
                <DefaultPort.Port/>
            </PortWidget>
            <PortWidget
                port={this.props.node.getPort('right')} engine={this.props.engine}
                style={{
                    position: 'absolute',
                    top: this.props.size / 2 - 8,
                    right: 4
                }}
            >
                <DefaultPort.Port/>
            </PortWidget>
        </>
    }

    renderVerticalPortWidgets(): any {
        let leftPort = this.props.node.getPort('left');
        let rightPort = this.props.node.getPort('right');

        return <>
            <PortWidget
                port={leftPort} engine={this.props.engine}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: this.props.size / 2 - 8
                }}
            >
                <DefaultPort.Port/>
            </PortWidget>
            <PortWidget
                port={rightPort} engine={this.props.engine}
                style={{
                    position: 'absolute',
                    top: this.props.size - 20,
                    right: this.props.size / 2 - 8
                }}
            >
                <DefaultPort.Port/>
            </PortWidget>
        </>
    }

    render() {
        const sensor = this.props.config.Instances.ControlModules[this.props.node.displayName] as unknown as IAnalogueSensor
        let alarmState = 0
        if (sensor.Sts_bAlarm) {
            if (sensor.Sts_bAlarmSystem || sensor.Sts_bAlarmSafety || sensor.Sts_bAlarmCriticalProcess) {
                alarmState = 1
            } else {
                alarmState = 2
            }
        }
        return (
            <div
                className='SensorArtifact-node'
                style={{
                    position: 'relative',
                    width: this.props.size,
                    height: this.props.size,
                    cursor: 'pointer'
                }}
            >
                <svg
                    width={this.props.size}
                    height={this.props.size}
                    viewBox='0 0 33 33'
                    preserveAspectRatio='xMidYMid'
                    dangerouslySetInnerHTML={{
                        __html: SensorVisual.build(alarmState, this.props.node.displayName)
                    }}
                    onClick={(e) => {
                        if (!this.state.show)
                            super.showPopup(e)
                    }}
                />
                {this.props.node.orientation === 'horizontal' ? this.renderHorizontalPortWidgets() : this.renderVerticalPortWidgets()}
                {super.render()}
            </div>
        )
    }
}

const mapStateToProps = (state: StoreTypes.ReducerState) => ({
    config: state.config
})

export default connect(mapStateToProps)(SensorArtifactWidget)
