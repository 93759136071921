import {BaseArtifactModel} from '../BaseArtifact/BaseArtifactModel'
import {ModuleBase} from '../../../models/PLC/ModuleBase'
import {IAnalogueSensor} from '../../../models/PLC/IAnalogueSensor'
import {IControlConfig} from '../../../config/diagram.config'
import {IConfigReducer} from '../../../store/reducers/configReducer'
import {IntlShape} from 'react-intl'

export class DefaultGeneration {

    protected config: IConfigReducer
    protected intl: IntlShape

    constructor(config: IConfigReducer, intl: IntlShape) {
        this.config = config
        this.intl = intl
    }

    /**
     * Get mapped artifacts by displayName
     * @param artifacts
     */
    getMappedArtifacts(artifacts: BaseArtifactModel[]) {
        /**
         * Map Artifact by displayName
         */
        const mappedArtifacts: Record<string, BaseArtifactModel> = {}
        artifacts.forEach((artifact) => {
            mappedArtifacts[artifact.displayName] = artifact
        })

        return mappedArtifacts
    }

    /**
     * Get mapped controls by Cfg_sTag
     * @param EMLoaded Loaded equipmentModules Unit configuration
     */
    getMappedControls(EMLoaded: ModuleBase) {
        /**
         * Map ControlModuleLinks -> ControlModuleBase by Cfg_sTag to get easy access to each property
         */
        const mappedControls: Record<string, IAnalogueSensor> = {}
        EMLoaded.ControlModules.forEach((CM) => {
            mappedControls[CM.Cfg_sTag] = this.config.Instances.ControlModules[CM.Cfg_sTag] as unknown as IAnalogueSensor
        })

        return mappedControls
    }

    /**
     * Get mapped config by Cfg_sTag
     * @param EMConfig Loaded equipmentModules config
     */
    getMappedConfig(EMConfig: any) {
        /**
         * Map IControlConfig by Cfg_sTag
         */
        const mappedConfig: Record<string, IControlConfig> = {}
        EMConfig.controls.forEach((CM: IControlConfig) => {
            mappedConfig[CM.name] = CM
        })

        return mappedConfig
    }

    /**
     * Add analogueSensor labels to artifacts
     * @param artifact
     * @param analogueSensor
     * @param isSensor
     * @param hasPopup
     */
    addLabels(artifact: BaseArtifactModel, analogueSensor: IAnalogueSensor, isSensor: boolean, hasPopup:boolean = true) {
        artifact.addLabel(`${analogueSensor.NodeId}\\${analogueSensor.Cfg_sTag}`, '0', {isSensor, hasPopup})
    }

    addCustomLabel(artifact: BaseArtifactModel, title: string, value: string) {
        artifact.addLabel(`TEXT-CUSTOM`, '', {title, value})
    }
}