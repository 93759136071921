import React, {Component} from "react";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import NotificationIcon from "./NotificationIcon";
import NotificationContent from "./NotificationContent";
import "./alarmNotification.css";
import {withRouter} from "react-router";
import {FormattedMessage} from "react-intl";
import {Button} from "@liquid-design/liquid-design-react";

/**
 * This is the alarm notification list component that drop downs on click of notification icon.
 *
 * @class NotificationList
 * @extends {Component}
 */
class NotificationList extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
            defaultDisplayLength: 10,
            itemList: [],
            hasMoreItems: false,
            collapseList: true
        };
        this.handleClearAll = this.handleClearAll.bind(this);
        this.handleViewAll = this.handleViewAll.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleClearAll() {
        this.props.clearAllNotification();
    }

    handleOnClick(data) {
        this.props.handleOnClick(data);
        this.props.history.push('/alarms')
    }

    toggle() {
        if (this.props.alarmList.length) {
            this.setState({
                dropdownOpen: !this.state.dropdownOpen
            });
        } else {
            this.props.history.push('/alarms')
        }
    }

    handleViewAll() {
        if (this.props.alarmList && this.props.alarmList.length > 0) {
            this.setState({
                dropdownOpen: true,
                itemList: this.props.alarmList,
                hasMoreItems: false,
                collapseList: false
            });
        }
    }

    componentDidMount() {
        if (this.props.alarmList && this.props.alarmList.length > 0) {
            this.setState({
                itemList: this.props.alarmList.slice(0, this.state.defaultDisplayLength)
            });
            if (this.props.alarmList.length > 10) {
                this.setState({
                    hasMoreItems: true
                });
            }
        }
    }

    getClassName(category) {
        if (category) {
            category = category.toLowerCase();
            switch (category) {
                case "safety":
                case "system":
                case "critical":
                    return "content_bkground_red";
                case "warning":
                    return "content_bkground_orange";
                case "ack_alm":
                    return "content_bkground_gery";
                case "unack_alm":
                    return "content_bkground_white";
                case "info":
                    return "content_bkground_blue";
                default:
                    return "content_bkground_blue";
            }
        } else {
            return "content_bkground_blue";
        }
    }

    renderNotificationList() {
        const alarmList = []
        this.props.alarmList.forEach((item, index) => {
            !item.Acknowledged && alarmList.push(<DropdownItem
                key={index}
                className={item.Critical ? "content_bkground_red" : "content_bkground_orange"}
            >
                <NotificationContent
                    index={index}
                    content={item}
                    onReportSelection={this.props.onReportSelection}
                    onAlarmRemoval={() => this.handleOnClick()}
                />
            </DropdownItem>)
        });
        return alarmList
    }

    /**
     * Render function of the component
     *
     * @returns the HTML of the alarm notification list component.
     * @memberof NotificationList
     */
    render() {
        return (
            <>
                <UncontrolledDropdown>
                    <DropdownToggle
                        tag="span"
                        title="alarm"
                        onClick={this.toggle}
                        data-toggle="dropdown"
                        aria-expanded={this.state.dropdownOpen}
                    >
                        <NotificationIcon count={this.props.count}/>
                    </DropdownToggle>
                    {this.props.alarmList && this.props.alarmList.length ?
                        <DropdownMenu right style={{
                            position: 'absolute',
                            top: '70px !important',
                            right: '0',
                            zIndex: 9999999,
                            paddingBottom: '0'
                        }}>
                            <div style={{maxHeight: 400, overflow: 'hidden auto'}}>
                                {this.renderNotificationList()}
                            </div>
                            <div style={{padding: '10px'}}>
                                <Button style={{width: '100%'}} onClick={this.handleClearAll}><FormattedMessage
                                    id="label.AcknowledgeAll"/></Button>
                            </div>
                        </DropdownMenu> : null}

                </UncontrolledDropdown>
            </>
        );
    }
}

export default withRouter(NotificationList);
